import React, { useState, useContext } from 'react';
import {
    Text,
    View,
    Switch
} from 'react-native';
import {  containers, text } from '../theme';
import { SettingsContext } from '../Managers/Settings';

export const SwitchRow = props => {
    
    let [value, updateValue] = useState(props.value)
    const { colors } = useContext(SettingsContext)
    const change = (value) => {
        
        updateValue(value)
        props.onChange && props.onChange(value)
        
    }

    return(
        <View style={[
            containers.betweenRow, 
            {
                marginVertical: 5,
                width: '100%',
            }, 
            props.style ? props.style : {}]}
        >
            <Text style={[
                    text.tiny,
                    text.bold,
                    text.base(colors),
                    { marginHorizontal: props.leftPadding ? props.leftPadding : 0}, 
                    props.textStyle ? props.textStyle : null]}>{props.title}</Text>
            <Switch
                ios_backgroundColor={colors?.transparent}
                trackColor={{false: 'grey', true: props.trackColor || colors?.accent}}
                thumbColor={text.base(colors)}
                onValueChange={change}
                value={value}
            />
            </View>
    )
 
}

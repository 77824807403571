import React, { useEffect, useState } from 'react';
import { 
    View,
 } from 'react-native';
import { containers} from '../theme.js'
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Zoom, Collapse, Chip } from '@material-ui/core';
import { darken } from '../Utilities';
import EditIcon from '@material-ui/icons/Edit';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { CardHeader, SmallCard } from './card.jsx';
import Button from './Button.js';
import {useAlert} from '../Managers/Alert' 
import { useOrgs } from '../Managers/Orgs.js';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
    // backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    
    '&:hover, &:focus': {
      backgroundColor: darken(theme.palette.primary.main, 1)
    },
  },
  generate: {
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    marginTop: 15,
    padding: 5,

    '&:hover': {
      boxShadow: '0 7px 14px rgba(0,0,0,0.18), 0 5px 5px rgba(0,0,0,0.22)',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }

}));


export const OrgCodes = ({org}) => {  
  const classes = useStyles();
  const [ codes, updateCodes ] = useState([])
  const [loaded, setLoaded] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const OrgsManager = useOrgs()
  const Alert = useAlert()

  const handleEdit = () => {
    setEdit((prev) => !prev);
  }

  const handleDelete = (codeToDelete) => () => {
    OrgsManager.deleteOrgCode(org.id, codeToDelete.code)
    .then(() => {
       let c = codes.filter((code) => code?.id !== codeToDelete?.id)
       updateCodes(c)
    })
    .catch(e => {
      Alert.alert('There was a problem', 'There was a problem deleting your organization code. ' + e.error)
    })
  };

  const handleGenerate = () => {
    OrgsManager.generateOrgCode(org.id)
    .then(getCodes)
    .catch(e => Alert.alert('There was a problem', 'There was a problem generating your organization code. ' + e.error))
  };

  const getCodes = () => {
    OrgsManager.getValidCodes(org?.id)
    .then(_codes => {
      updateCodes(_codes);
      setLoaded(true)
    })
    .catch(e => Alert.alert('There was a problem', 'There was a problem fetching your organization codes. ' + e.error))
  }

  useEffect(() => {
    getCodes()
  }, [org])


  if(!org?.admin){
    return <></>
  }

  return(
    <Collapse in={true}>
      <SmallCard >
        <CardHeader title={'Access Codes'} buttonProps={{
          iconOnly: true, 
          onClick: handleEdit,
          icon: <EditIcon />
        }} />                        
          <Collapse in={loaded} timeout={1000} >
            <div className={classes.root}>
              {_.map(codes, (item, index) =>{
                return( 
                  <Zoom 
                    in={loaded}  
                    key={item.id}
                    style={{ transitionDelay: (index * 50).toString() + 'ms' }}
                    >
                      <Chip
                        label={item.code}
                        color={'primary'}
                        onDelete={edit ? handleDelete(item) : null}
                        className={classes.chip}
                      />
                  </Zoom>
                )
              })}
            </div>
          </Collapse>
          <View style={[containers.centerColumn]}>
                <Button
                    iconOnly
                    icon={<AddRoundedIcon style={{fontSize: 30}} />}
                    onClick={handleGenerate}
                />
          </View >
      </SmallCard>
      </Collapse>
    )

}
 
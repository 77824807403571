import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSettings } from '../Managers/Settings';
import { withSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { PasswordReset } from './password-reset';
import { Main } from './main';
import Intro from './intro';
import { Loading } from './loading';
import { AUTHENTICATION_STATUS } from '../Constants';
import { FixV2Bug } from './fix-v2-bug';
import { useUser } from '../Managers/UserManager';
import { useNetwork } from '../Managers/Database';


 export default function Root(){
    
    // useEffect(() => {
    //     // migration bug fix
    //     // check if v2 is set
    //     // if not remote all storage and logout
    //     // let v2 = localStorage.getItem("v2")
    //     // if(!v2){
    //     //     localStorage.clear()
    //     //     logout()
    //     //     localStorage.setItem("v2", true)
    //     // } else {
            
    //     // }
    // },[])
    // const {logout} = useUser()
    const {theme} = useSettings()


    return (      
        <ThemeProvider >
            <Router>
                <Switch>
                    <Route path="/forgetful">
                        <PasswordReset />
                    </Route>
             
                    <Route path="/logout">
                        <FixV2Bug />
                    </Route>
                    <Route path="/auth/:page">
                        <Intro />
                    </Route>
                    <PrivateRoute />
       
                    
                    </Switch>
            </Router>
        </ThemeProvider>   
)
            
}


const PrivateRoute = ({ children,  ...rest }) => {
    const { ready } = useNetwork()
    const {user, authenticated} = useUser()
    // console.log(ready, user, authenticated)
    return ( 
      <Route
        {...rest}
        render={({ location }) => {
            if(!ready || authenticated === AUTHENTICATION_STATUS.unknown){
                return <Loading />
            } else if(authenticated === AUTHENTICATION_STATUS.unauthenticated){
                return <Redirect to={'/auth/login'} />
            } else if(authenticated === AUTHENTICATION_STATUS.authenticated){
                 if(!!user?.id && (!user?.first_name || !user?.last_name)){
                    return  <Redirect 
                        to={{
                        pathname: "/auth/name",
                        state: { from: location }
                        }}
                    />
                } else if(!!user?.id && !user?.prefs?.currentOrg) {
                    return <Redirect 
                        to={{
                        pathname: "/auth/join",
                        state: { from: location }
                        }}
                    />
                } else {
                    return <Main location={location}/>
                }
            }
        }
        }
      />
    );
  }

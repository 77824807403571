import React, { useState, useEffect, useRef } from 'react'

import {
    View,
    Text,
    TouchableOpacity,
    TextInput,
    Animated,
    LayoutAnimation,
} from 'react-native'
import { Hint } from './exports';
import { containers, text, input, shadows} from '../theme';
// import { Icon } from 'react-native-elements'
import _ from 'lodash'
import { SettingsContext, useSettings } from '../Managers/Settings';
import {  invertColor } from '../Utilities';
// import { TagSearch } from './TagSearch';
import { widthPercentageToDP } from '../Fork/react-native-responsive-screen';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import { AnimatePresence, motion } from 'framer-motion';
import { AlertContext, useAlert } from '../Managers/Alert';
import { useOrgs } from '../Managers/Orgs';
import { useContacts } from '../Managers/Contacts';

const defaultTag = {name: '', official: false}

export const TagsEditor = React.memo(props => {
  const {colors, appTheme, } = useSettings()
  let [ tagColor, updateTagColor] = useState(colors.accent)
  let [ tags, updateTags] = useState(props.tags || [])
  let [ tag, updateTag] = useState({...defaultTag})
  let [ category, updateCategory] = useState(props.category)
  let [ editing, updateEditing] = useState(props.editing)
  let [ timeout, updateTimeout] = useState(null)
  
  const Alert = useAlert()
  const OrgsManager = useOrgs()

  const showEditing = props.editing && !props.system  ? new Animated.Value(1) : new Animated.Value(0)


  let tagRef = React.createRef()
  // workaround for backspace on non backspace keypress
  
  // console.log(props.category)
  const onTagChange = (value) => {
    // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    updateTag({...tag, name: value})
  }
  const _addTag = async (newTag) =>  {
      let _tags = _.filter(tags, t => t.name != newTag.name)
      _tags.push(newTag)
      updateTags(_tags)
      updateTag({...defaultTag})
      return _tags
  }

  const postTag = async (_tag) => {
    try{
      await OrgsManager.createTag(category?.org_id, category.id, _tag)
    } catch(e) {
      Alert.alert('There was a problem', e)
      _removeTag(_tag)
    }
  }

  const createTag = (name) => {
    let _tag = {...defaultTag}
    _tag.name = name
    postTag(_tag)
    return _tag      
  }

  const addTag =  async () =>  {
      const name = tag?.name || ''
      if(_.trim(name) !== ''){
          const newName = name.split(' ')
                            .map(sub => _.upperFirst(sub))
                            .join('')
          await _addTag(createTag(newName))
          props?.onChange && props.onChange(tags)
      } else {
        // you pressed the add button without input....lets blur this field to help you out.
        if(!!tagRef){

          tagRef.blur()
        }
      }
  }

  const _removeTag = async (oldTag) => {   
    let _tags =_.filter(tags, t => t.name != oldTag.name)
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    updateTags(_tags)
    updateTag({...defaultTag})
    _deleteTag(oldTag)
    return _tags
  }

  const _deleteTag = async (_tag) => {
    try{
      await OrgsManager.deleteTag(category?.org_id, category?.id, _tag?.id)  
    } catch(e) {
      Alert.alert('There was a problem', e)
    }
  }

  const tagPressed = async (tag) => {

    if(editing && props.system) {
    } else {
      // Navigation.gotoTagSearch(tag)
    }
  }

  const onDelete = async (tag) => {
    if(props.system){
      return
    }
    try{ 
      let _tags = await _removeTag(tag) 
      props?.onChange && props.onChange(_tags)
    }
    catch(e) { 
      // console.log(e)
    }
    
  }
  
  const shouldPopTag = (event) => {
    if(timeout){return}
    else{ setTimeout(() => updateTimeout(null), 40) }

    let key = event?.nativeEvent?.key
    updateTimeout(true)

    if(key == 'Backspace' &&
       tag.name == '' && 
       tags.length > 0){
      // remove the last tag from the list.
      const _tag = tags[tags.length - 1]
      onDelete(_tag)
    }
  }

  const focusTags = () => {
    tagRef?.focus && tagRef.focus()
  }

  const blurTags = () => {
    tagRef?.blur && tagRef.blur()
    if(tags.length === 0){
      updateEditing(false)
      // Animated.spring(showEditing, {toValue: 0, friction: 7}).start()
    }
  }

  const getTags = () => {
    return _.map(tags, (tag) => {
      
      return(
        <EditableTag 
          showEditing={showEditing}
          popover={<Text> Hi </Text>}
          key={tag.name} 
          selected={true}
          onPress={tagPressed} 
          onDelete={onDelete}
          color={category?.color || tagColor} 
          tag={tag} 
        />
      )
      })
    
  }

  useEffect(() => {
    if(props.selected){
      
      if(!category?.org_id){
        // console.log(category)
        updateTags(category?.tags)
      } else if (category?.org_id) {
        // console.log(category)
        OrgsManager.getTagsForCategory(category?.org_id, category?.id)
        .then(_tags => {
          // console.log(_tags)
          updateTags(_tags)
        })
        .catch(e => Alert.alert('Whoops', e))
      }
 
    }
  }, [props.selected])

  return (
      <View
        style={[
          styles.container, 
          {
            ...props.containerStyle,
            alignSelf: 'stretch'
          }
        ]}
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps="always"
      >
        {tags?.length === 0 && <Hint>Add your first Tag for {category?.name}</Hint>}
        <Animated.View style={[
          input.variable,
          {
            backgroundColor: colors?.accent,                
            shadowColor: 'black',
              ...shadows.default(0, colors),
            }
            ]} 
          >
          <TouchableOpacity onPress={focusTags}>
            <View style={[containers.wrapLeftRow]} >
              {getTags()}
              <TextInput
                underlineColorAndroid="transparent"
                keyboardShouldPersistTaps='always'
                keyboardAppearance={appTheme}
                ref={ref => tagRef = ref}
                value={tag?.name}
                onChangeText={onTagChange}
                onSubmitEditing={addTag}
                onBlur={blurTags}
                blurOnSubmit={false}
                onKeyPress={shouldPopTag}
                style={[text.base(colors)]}
              />
            </View>
          </TouchableOpacity> 
        </Animated.View>
        {tags?.length > 0 && category && <Hint>You can backspace to remove the last tag </Hint>}
      </View>
    )

})


export const Tags = ({contactCategories, org, person}) => {
  const [editing, setEditing] = useState(false);
  const [potentialCategories, setPotentialCategories] = useState({});
  const [categories, setCategories] = useState({});
  const OrgsManager = useOrgs();
  const Alerts = useAlert();


  useEffect(() => {
    getPotentialCategories()
    const _categories = {};
    _.forEach(contactCategories, c => _categories[c.id] = c)
    setPotentialCategories()
    setCategories({..._categories})

  },[])

  const getPotentialCategories = () => {
    let id = org?.id
    if(!id){
      return
    }
    OrgsManager.getCategoriesForOrg(id)
    .then(cats => {
      let obj = {}
      _.forEach(cats, c => obj[c.id] = c)
      setPotentialCategories(obj)
    })
    .catch(e => Alerts.alert('Whoops', e))
  }
 
  const toggleEditing = () => setEditing(!editing)


  const onChange = (cat_id, tags) => {
    let _cats = {...categories}
    if(!_cats[cat_id]){
      _cats[cat_id] = {...potentialCategories[cat_id]}
    }
    _cats[cat_id].tags = tags
    setCategories({..._cats})
  }

  return(
    <View style={{alignSelf: 'stretch', maxWidth: '100vw'}}>
      <View style={containers.leftColumn}>
        {_.map(_.keys(potentialCategories), key => {
          const cat = potentialCategories[key]
          let selectedTags = [];
          if(categories[key]){
            selectedTags = categories[key]?.tags || []
          }
          return (
            <ButtonCat 
              person={person}
              editing={editing} 
              selectedTags={selectedTags}
              key={cat.id} 
              category={cat} 
              onChange={onChange}
            />  
          )
        })}
      </View>
    </View>
  )   
}



export const ButtonCat = props => {
  const _selectedTags = props?.selectedTags || []
  const _person = props?.person  // _.picked so only has id
  const [ expanded, setExpanded] = React.useState(false)
  const [ person, setPerson] = React.useState(_person)
  const [ selectedTags, setSelectedTags] = React.useState(_selectedTags)
  const Alert = useAlert()
  const name = _.get(props, 'category.name', '')
  const color = _.get(props, 'category.color', "#FFFFFF")
  const ContactManager = useContacts()
  let  cstyle = {
    paddingLeft: 15,
    paddingRight: 5,
    height: 30,
    borderRadius: 15,
    backgroundColor:  color,
    ...containers.centerColumn
  }
  

  const outerStyle={
    ...containers.leftColumn,
    marginVertical: 5,
    borderRadius: 15,
    borderStyle: 'solid', 
  }
  
  const onEditing = () => {
    setExpanded(!expanded)   
  }
  const tagPressed = (tag, shouldSelect) => {
    // console.log(tag, shouldSelect)
    if(!expanded) {
      // search for the tag
      return 
    }
    if(shouldSelect){
      bind(tag)
    } else {
      unbind(tag)
    }
  }

  const bind = async (tag) => {
    // move tag to selected
    const undo = [...selectedTags]
    const _selected = _.uniqBy([...selectedTags, tag], 'id')
    setSelectedTags(_selected)

    // now start network stuff
    try{
      await ContactManager.bindTagtoContact(tag, person)
      Alert.success(tag.name + ' Applied')
      props.onChange && props.onChange(props?.category?.id, _selected)
    } catch(e) {
      console.error(e)
      Alert.warning('Tag not added', )
      setSelectedTags(undo)
      
    }
    
  }

  const unbind = async (tag) => {
    // move tag to selected
    const undo = [...selectedTags]
    const _un_selected = _.filter(selectedTags, t => t.id !== tag.id)
    setSelectedTags(_un_selected)

    // now start network stuff
    try{
      await ContactManager.unbindTagtoContact(tag, person)
      Alert.default(tag.name + ' Removed')
      props.onChange && props.onChange(props?.category?.id, _un_selected)
    } catch(e) {
      Alert.alert('Tag not added', e)
      setSelectedTags(undo)
    }
  }

  const iconVariants = {
    expanded: { rotate: 0 },
    notExpanded: { rotate: 180},
  }

    const textStyle = {
      color: invertColor(color),
      ...text.bold,
      ...props.textStyle
    }
    return(
      <View style={outerStyle}>


        <View style={containers.wrapLeftRow}>
          <TouchableOpacity 
            style={{...cstyle}} 
            onPress={onEditing}
            >
              <View style={[containers.leftCenterRow]}>
                <Animated.Text style={textStyle}>{_.truncate(name, {length: 35})} </Animated.Text>
                <motion.div 
                  animate={expanded ? 'expanded' : 'notExpanded'} 
                  style={containers.centerColumn}
                  variants={iconVariants}>
                  <DoubleArrowRoundedIcon  style={{color: invertColor(color)}}/>
                </motion.div>
              </View>
          </TouchableOpacity> 
          <AnimatePresence>
            {_.map(props?.category?.tags, (t, i) => {
              const selected = _.filter(selectedTags, c => c.id === t.id ).length > 0
              return(  
                    (expanded || selected) &&
                    <motion.div 
                      key={'tag-' + t.name + i}
                      initial={{scale: 0.6, opacity: 0, x: -10}}
                      exit={{scale: 0.6, opacity: 0, x: -10, transition: {delay: i * 0.005}}}
                      animate={{opacity: 1, scale: 1, x: 0, transition: {delay: i * 0.005}}}>
                    <ButtonTag 
                        onPress={() => tagPressed(t,!selected)}
                        color={color}
                        selected={selected}
                        tag={t}/>
                      </motion.div>
                )})}
            </AnimatePresence>
          </View>
    </View>
    )


}



export const EditableTag = props => {

  const name = _.get(props, 'tag.name', '')
  const color = props?.tag?.color || props?.color || '#FFFFFF'
  const selected = props?.selected
  const variants = {
    selected: {
      backgroundColor:  color,
      
    },
    notSelected: {
      backgroundColor: 'transparent',
    }
  }
  const cstyle = {
    
    borderRadius: 15,
    margin: 5,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2, 
    borderStyle: 'solid',
    borderColor: color,
    height: 30,
    ...shadows.avatar,
    ...containers.centerColumn
  }

  const onPress = () => {
      props.onPress && props.onPress(props.tag)
  }
  const onDelete  = () => {
    props.onDelete && props.onDelete(props.tag)
  }


  let showEditing = props.showEditing ? props.showEditing : new Animated.Value(0)
    return(
      <SettingsContext.Consumer>
        {({colors}) => {
                const textStyle = {
                  color: invertColor(color),
                  ...text.bold,
                  ...props.textStyle
                }
                return(
                    
                      <motion.div 
                        transition={{ duration: 0.5 }}
                        style={cstyle} 
                        animate={selected ? 'selected'  : 'notSelected'}
                        variants={variants}
                        onClick={onPress}>
                        <Text style={textStyle}>
                          {_.truncate(name, {length: 35})}
                        </Text>
                      </motion.div>
                
                )
        }}
      </SettingsContext.Consumer>
 
    )
}


export const ButtonTag = props => {
  return <EditableTag {...props} />
}



// export const SearchTag =props => {
//   const cstyle = {
//     backgroundColor: props.color || 'white',
//     borderRadius: 15,
//     paddingLeft: 15,
//     paddingRight: 5,
//     height: 30,

//   }
//   const onPress = () => {
//       props.onPress && props.onPress(props.tag)
//   }
//   const onDelete  = () => {
//     props.onDelete && props.onDelete(props.tag)
//   }
//   const name = _.get(props, 'tag.name', '')
//   const color = _.get(props, 'tag.color', "#00FD00")
//   let showEditing = props.showEditing ? props.showEditing : new Animated.Value(0)
//     return(
//       <Tooltip 
//         height={heightPercentageToDP(80)}
//         width={widthPercentageToDP(90)}
//         backgroundColor={'red'}
//         popover={<TagSearch tag={props.tag} />}>
//       {/* <TouchableOpacity 
//         style={{ margin: 3, ...shadows.default(1), alignSelf: 'center'}} 
//         // onPress={onPress}
//         > */}
//         <Animated.View  style={[cstyle, containers.centerRow, ]}>
//           <Animated.Text style={{
//             color: invertColor(color),
//             transform: [{translateX: showEditing.interpolate({
//               inputRange: [0, 1],
//               outputRange: [10, 0]
//             })}] 
            
//             }}>{_.truncate(name, {length: 35})} </Animated.Text>
//           <Animated.View style={{transform: [{scale: showEditing}] }}>
//             <Icon 
//               onPress={onDelete}
//               name={'times-circle'} 
//               type={'font-awesome'} 
//               color={String(invertColor(color))} />
//           </Animated.View>  
//       </Animated.View>
//     {/* </TouchableOpacity> */}
//     </Tooltip>
//     )
// }


const styles = {  
    category: (_colors, _wp, catColor)=>  {
      return{
        marginBottom: -15,
        minHeight: 60,
        
        width: _wp(100),
        borderTopLeftRadius: 20, 
        borderTopRightRadius: 20, 
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: _colors?.foreground,
        backgroundColor: _colors?.background,
      }
    },
    container: {
        marginVertical: 10
    },
    padding: {
        paddingHorizontal: 20
    }


}





import React from 'react';
import { View } from 'react-native';
import { hpCss, wpCss } from '../Fork/react-native-responsive-screen';
import { containers } from '../theme';


export const IntroContainer = props => {
    return(
        <View style={[
            containers.centerBetweenColumn, 
            containers.padding,
            {
                width: 700,
                maxWidth: wpCss(90),
                minWidth: wpCss(50),
                alignSelf: 'center',
                minHeight: '100vh',
            }


            ]}>
                {props.children}
        </View>
    )
}
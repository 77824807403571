import { Database }  from './exports';
import Fuse from 'fuse.js'
import _ from 'lodash';

const fuseOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  includeMatches: true,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: 0.3,
  distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    {name: "first_name", weight: 2.3},
    {name: "last_name", weight: 2},    
    "address.address1",
    "address.city",
    "address.state",
    "address.zip",
    "address.country",
    "email",
    "phones.number"
  
  ]
};

const atoptions = {
  isCaseSensitive: true,
  // includeScore: false,
  // shouldSort: true,
   includeMatches: false,
  // findAllMatches: false,
   minMatchCharLength: 4,
   //location: 0,
   threshold: 0,
   distance: 0,
   useExtendedSearch: true,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys:[
    {name: 'attendance.sessions.event_id'}
  ]
}

export class Local{

    constructor(){
      this.fuse = new Fuse([], fuseOptions)
      this.attend = new Fuse([],atoptions )
      
    }
    fuse;
    data;
    setDataSorce = (data) => {
      this.fuse.setCollection(data)
      this.attend.setCollection(data)
      // console.log('RESET DATA to ', data)
      this.data = data
    }

    search = (txt) => _.map(this.fuse.search(txt), i => i.item)
  
    attendance = async (att) => {
      // there seems to be a bug with the !value part of fuse.js
      // !value will return as if it was 'value or =value
      // sooo we search for all our positives and then
      // search for all our negatives as if they were positives
      // then we just subtract our negatives out from our positives
      // to achieve what we wanted in the first place.  
      // console.log(att)
    
      let ids = _.map(att.events, d => {return {"attendance.sessions.event_id": `${d}`}})
      let negatives = _.filter(ids, obj => _.startsWith(obj["attendance.sessions.event_id"], '!' ))
      negatives = _.map(negatives, obj => { return {"attendance.sessions.event_id" : _.replace(obj["attendance.sessions.event_id"], '!','')} })
      // console.log('Negatives are = ', negatives)
      if(!ids || ids.length === 0){ return this.postFilterAttendance(this.data, att)}
      let pattern = att.conjunction === 'and' ? {$and : ids } : {$or : ids}
      let negativePattern = {$or : negatives}
      // console.log(JSON.stringify(pattern))
      // console.log(JSON.stringify(negativePattern))
      let results = _.map(this.attend.search(pattern), i => i.item)
      let negativeResults = _.map(this.attend.search(negativePattern), i => i.item)
      // console.log(results, negativeResults)
      results = _.difference(results, negativeResults)
      // console.log('DATA AFTER SUBTRACTION', results)
      return this.postFilterAttendance(results, att)
      

    }
    postFilterAttendance = async (data, att) => {
      if(att.grade){
       data =  _.filter(data, d => _.includes(att.grade, d.grade))
      }
      return this.attendedOrMissed(data, att)
    }

    attendedOrMissed = async (data, att) => {
      if(att.attended){
        let attendance = att.attended
        let count;
        if(_.endsWith(attendance, 'm')){
          // or more
          count = +_.replace(attendance, 'm','')
          // console.log(count)
          data = _.filter(data, contact => {
              const sessions = contact?.attendance?.[0]?.sessions
              // console.log(sessions?.length >= count)
              return sessions?.length >= count
            })
        } else if (_.endsWith(attendance, 'l')){
          // or less
          count = +_.replace(attendance, 'l','')
          data = _.filter(data, contact => {
              const sessions = contact?.attendance?.[0]?.sessions
              return sessions?.length <= count
            })
        } else {
          count = +attendance
          data = _.filter(data, contact => {
            const sessions = contact?.attendance?.[0]?.sessions
            return sessions?.length === count
          })
        }

      } else if( att.missed){
        let missed = att.missed
        let count;
        if(_.endsWith(missed, 'm')){
          // or more
          count = +_.replace(missed, 'm','')
          data = _.filter(data, contact => {
              const total = contact?.attendance?.[0]?.num_events
              const sessions = contact?.attendance?.[0]?.sessions
              if(!total || !sessions){ return false}
              return sessions?.length <= (total - count)
            })
        } else if (_.endsWith(missed, 'l')){
          // or less
          count = +_.replace(missed, 'l','')
          data = _.filter(data, contact => {
            const total = contact?.attendance?.[0]?.num_events
            const sessions = contact?.attendance?.[0]?.sessions
            if(!total || !sessions){ return false}
            return sessions?.length >= (total - count)
            })
        } else {
          count = +missed
          data = _.filter(data, contact => {
            const sessions = contact?.attendance?.[0]?.sessions
            const total = contact?.attendance?.[0]?.num_events
            if(!total || !sessions){ return false}
            return sessions?.length === (total - count)
          })
        }
      } 

      return {contacts: data}
    }
}

export class Search{

    
    // @depricated
    filter = (searchText, data) => {
      const fuse = new Fuse(data, fuseOptions)
      const result =  _.map(fuse.search(searchText), i => i.item)
      return result
    } 

    // @depricated
    _filter = (searchText, data) => { 
      // console.log(searchText, data.length)
      let filtered = data
        if(searchText === ''){return filtered}
        filtered =  _.filter(filtered, item => {
          for( let property of _.keys(item)) {
              if(!item[property]){continue}
              if(String(item[property]).toLowerCase().indexOf(searchText.toLowerCase()) > -1){ 
                return true
              }
              if(item.address){
                for( let property of _.keys(item.address)) {
                  if(!item.address[property]){continue}
                  if(String(item.address[property]).toLowerCase().indexOf(searchText.toLowerCase()) > -1){ 
                    return true
                  }
                }
              }
           }  
          return false
          })
        return filtered
      }
      txt = async (txt) => await Database.get(`/search?q=${txt}&only=contacts`)
      tags = async (tag) => await Database.get(`/search?tag=${tag}`)
      search = async(params) => await this._base(params)

      onlyContacts =  async(params) => this._base(params,'&only=contacts')
      onlyContactsCsv = async(org_id, params) => this._baseCsv(org_id, params)
      onlySeries = async(params) => this._base(params,'&only=series')
      onlyEvents = async(params) => this._base(params,'&only=events')
      onlyTags = async(params) => this._base(params,'&only=tags')
      _base = async (params, only = '')  => {
          const searchable = `/search?${this._buildSearchable(params)}${only}`
          return await this._callDbGet(searchable);
      }
      _baseCsv = async (org_id, params) => {
        
        try{
          let csv  = await Database.get(`/orgs/${org_id}/contacts.csv?csv_headers=first_name,last_name,phone,email,grade,address`, false, true)
          
        // const csv = await this._callDbGet(searchable, true);
        // console.log(csv)
        const url = window.URL.createObjectURL(new Blob([csv]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download','download.csv');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        return csv;
        } catch(e) {
          // Alert.error(e)
        }
      }
      _buildSearchable = (params) => {
        const keys = _.keys(params)
        const searchable = 
              _.map(keys, (key, index) => {
                  // const first = 0
                  // const last = keys.length
                  const value = _.isArray(params[key]) ? _.join(params[key], ',') : params[key]
                  return `${key}=${value}`
                }
              ).join('&')
            
        return searchable
      }
      _callDbGet = async (url, isCsv = false) => {
        try{
          // console.log(url)
          let result =  await Database.get(url, false, isCsv)
          // console.log(result)
          return result
      } catch(e) {
        console.log(e)
        if(e) {return Promise.reject(e)}
        return Promise.reject('There was a problem with that request')
      }
    }
  }
  
  
  const memberOptions = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: true,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.3,
    distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    keys: [
      {name: "user.first_name", weight: 2.3},
      {name: "user.last_name", weight: 2}, 
      {name: "user.email", wieght: 1.5}   
    ]
  };

export class MemberLocal{
    org; 

    constructor(){
        this.fuse = new Fuse([], memberOptions)

    }
    data;
    setDataSorce = (data) => {
        this.fuse.setCollection(data)
        this.data = data
      }
  
    search = (txt) => {
        if(txt === ''){
            return this.data
        }
        return _.map(this.fuse.search(txt), i => i.item)
    }
    
}
  
  
import React from 'react';
import { ChevronRightRounded} from '@material-ui/icons'

import { useSettings } from '../Managers/Settings';

export const Right = props => {

      const {colors} = useSettings()

      return(
        <ChevronRightRounded 
          fontSize={'large'}
          style={{color: 'white'}} />
      )

  }
 

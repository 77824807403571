import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../Managers/UserManager';




export const FixV2Bug = () => {
    const history = useHistory()
    const UserManager = useUser()

    useEffect(() => {
        
        UserManager.logout().then(
            () => {
                history.push('/auth/login')
            }
        )
        
    }, [])


    return (<></>)
}
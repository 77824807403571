import React, {useState, useEffect} from 'react';
import { 
    TouchableOpacity,
    View, 
    Text,
 } from 'react-native';
 import _ from 'lodash';
 import { containers, text, shadows } from '../theme'
 import { useSettings}  from '../Managers/Settings';
import { UserManager, EventManager } from '../Managers/exports';
import { Collapse, Grow } from '@material-ui/core';

export const SeriesPicker = (props) => {
    let [series, updateSeries] = useState([])
    useEffect(() => {
        EventManager.getSeries(props?.org?.id)
        .then(updateSeries)
        .catch(e => {
            // console.log(typeof e)
        })
        
    }, [props?.org])
    const {expanded} = props
    
    const onSelect = () => {
        props.onSelect && props.onSelect()
    }

    return(
        !!expanded && <Collapse in={!!expanded}>
                <View
                    style={{marginBottom: 20, marginHorizontal: 0, ...containers.leftRowWrap, height: 'auto'}}
                >
                    {_.map(series,(seri,i) =>{

                        return(
                            <Grow key={i} in={!!expanded} timeout={300 + (i * 100)}>
                                <div>
                                <SingleSeries 
                                    delay={props.delay || 0}
                                    index={i}
                                    onSelect={onSelect}
                                    seri={seri} 
                                    expanded={expanded} 
                                    count={series.length}
                                />
                                </div>
                            </Grow>
                                )}
                                
                        )
                    } 

                </View>
                </Collapse>
 
    )

}

export const SingleSeries = (props) => {
    let { expanded, seri, } = props   
    const onSelect = () => {
        props.onSelect && props.onSelect()
    }
    const {colors, prefs} = useSettings()
    const selected = seri.id === _.get(prefs, 'currentSeries.id')
    const onPress = () => {
        UserManager.updatePrefs({currentSeries: seri}); onSelect()
    }
    return(
        !!expanded && 
            <TouchableOpacity 
                onPress={onPress}
                style={{
                    height: 'auto',
                    borderRadius: 20,
                    margin: 10,
                    padding:20,
                    ...shadows.paper,
                    backgroundColor: selected ? colors?.accent : colors?.background,
                    ...containers.centerColumn
            }} >
                <Text style={[text.small, selected ? text.accentContrast(colors) : text.base(colors), text.bold]}>{seri.name}</Text>
            </TouchableOpacity>
        )

}

import React, {useState, useContext, createContext, useEffect} from 'react';
import _, { set } from 'lodash';
import { lightThemeColors, darkThemeColors} from '../theme';
import { DarkMapTheme, LightMapTheme, SETTINGS_TABLE } from '../Constants';
import { createTheme } from '@material-ui/core';
import { invertColor } from '../Utilities';
import { useStorage } from './Storage';

function defaultTheme() {
  
  const theme = createTheme({
    palette: {
      primary: {
        main: "#15bfbc",
        contrastText: '#464646',
      }, 

      accent: {
        main: "#15bfbc"
      },
      background: {
        default: "#EEEEEE"
      },
    },
    overrides: {
      MuiTab: {
        root: {
          transition: 'all .5s',
          fontSize: '1rem',
          textTransform: 'none',

          '&$selected': {
            '&, &:hover': {
              // fontWeight: 'bold',
              color: "#15bfbc",
            },
          },
        },
        wrapper: {
          flexDirection:'row',
          justifyContent:'start',
          paddingLeft: 20,
        },
      },
    },
  })

  return theme
}


export function defaultSettings(mode = 'light') {
  const initialMode = 'light'
  return{
    appTheme: initialMode,
    colors:  mode === 'light' ? lightThemeColors() : darkThemeColors(),
    accent: null,
    prefs: {},
    shortcuts: [],
    mapTheme: initialMode === 'light' ? LightMapTheme(lightThemeColors()) : DarkMapTheme(darkThemeColors()),
    theme: defaultTheme() 
  
  }
}



function createCustomTheme(colors) {

  const theme = createTheme({
    palette: {
      primary: {
        main: colors.accent,
        contrastText: invertColor(colors.accent),
      }, 
      // secondary: {
      //   main: colors.secondary
      // },
      accent: {
        main: colors.accent
      },
      background: {
        main: colors.page,
        default: colors.page
      }
    },
    overrides: {
      MuiTab: {
        root: {
          transition: 'all .5s',
          fontSize: '1rem',
          textTransform: 'none',

          '&$selected': {
            '&, &:hover': {
              // fontWeight: 'bold',
              color: colors.accent,
            },
          },
        },
        wrapper: {
          flexDirection:'row',
          justifyContent:'start',
          paddingLeft: 20,
        },
      },
    },
  })

  return theme
}

export const SettingsContext = createContext(defaultSettings())

export const useSettings = () => useContext(SettingsContext)

export const SettingsProvider = ({children}) => {

    const [settings, setSettings] = useState(defaultSettings())
    const Storage = useStorage()
    useEffect(() => {
      Storage.getEncryptedTable(SETTINGS_TABLE)
      .then(setSettings)
    }, [])

    useEffect(() => {
      Storage.setEncryptedTable(settings, SETTINGS_TABLE)
    }, [settings])

     

    const updatePrefs = (prefs) => {
      let _settings = {...settings}
      const accent = prefs?.accent
      if(accent){
        set(_settings, 'colors.accent', accent)
        set(_settings, 'accent', accent)
        _settings = setMapStyle(_settings)
        _settings.theme = createCustomTheme(_settings.colors)
      }
      setSettings({..._settings})
    }

    const updateTheme = (appTheme) => {
      let _settings = {...settings}
      _settings.appTheme = appTheme
      _settings = updateColors(_settings)
      _settings = setMapStyle(_settings)
      setSettings({..._settings})
    }

    const updateColors = (_settings) => {
      _settings.colors = _settings.appTheme === 'dark' ? darkThemeColors() : lightThemeColors()
      if(_settings?.prefs?.accent){
        _settings.colors.accent = _settings.prefs.accent
      }
      return _settings
    }

    const setMapStyle = (_settings) => {
      const {appTheme, colors} = _settings
      _settings.mapTheme = appTheme === 'dark' ? DarkMapTheme(colors) : LightMapTheme(colors)
      return _settings
    }
    
    const toggleTheme = () => {
      const appTheme =  settings.appTheme  === 'dark' ? 'light' : 'dark'
      updateTheme(appTheme)
    }

    const setAccentColor = (color) => {
      let {prefs} = settings
      prefs.accent = color
      updatePrefs(prefs)
    }
    
    const getDefaultAccentColor = () => settings?.colors?.primary
  
    const resetAccentColor = () => setAccentColor(settings?.colors?.primary)
  
    const clear = () => {
      let s = defaultSettings()
      setSettings({...s})
    }

    return(
      <SettingsContext.Provider value={{
        ...settings,
        updatePrefs,
        updateTheme,
        updateColors,
        setMapStyle,
        toggleTheme,
        setAccentColor,
        getDefaultAccentColor,
        resetAccentColor,
        clear
      }}>
        {children}
      </SettingsContext.Provider>
    )
    
    
  }
  
  
  
  
import React from 'react';
import { 
     Animated,
     View,
     TouchableOpacity,
     Text,
     LayoutAnimation,
     ScrollView,
     } from 'react-native';
import { today, containers, text, grade } from '../theme';
import { SettingsContext } from '../Managers/Settings';
// import { ListItem, Icon } from 'react-native-elements';
import { ListItem } from '../Components/exports';
import _ from 'lodash';
import Moment from 'moment';
import { EventManager, Navigation } from '../Managers/exports';
import { wp} from '../Fork/react-native-responsive-screen';
import { getMomentCalendar } from '../Constants';
import {ChevronRightRounded} from '@material-ui/icons'
import { Fade } from '../Animations/Fade';

export class TodayWidget extends React.Component{
    static contextType = SettingsContext
    state = {
         expanded : false,
         past: {},
         current: {},
         future: {},
         ...this.props
    }
    ani = new Animated.Value(0)
    recal;
    preSetRecal;
    id;
    itemSubscription;
    navigationWarningSub;
    constructor(props) {
        super(props)
        this.id = _.random(0, 0xFFFFF)
        // console.log('Initialize' ,this.id)
        
    }
    componentDidMount(){

            this.itemSubscription = EventManager.getScheduleSubject()
            this.itemSubscription.subscribe(({past, current, future}) => {
                
                this.setState({past, current, future})
            })

            this.navigationWarningSub =  Navigation.getPageChangeWarning()
            this.navigationWarningSub.subscribe(this.unsubscribe)

        if(this.recal || this.preSetRecal){ return }
        let time = this.getClosetRecal()
        this.preSetRecal = setTimeout(() => this.startRecal(), time)
        
        
    }

    getClosetRecal = () => {
        // console.log('start recal in ', ((62 - Moment(_.now()).seconds()) % 15))

        return ((62 - Moment(_.now()).seconds()) % 15)
    }

    startRecal = () => {
        // console.log('starting Recal')
        this.recal = setInterval(EventManager.updateItemsFor, 15000)
    }

    unsubscribe = () => {
        clearTimeout(this.preSetRecal)
        clearInterval(this.recal)
        try{
            this.itemSubscription.unsubscribe()
             this.navigationWarningSub.unsubscribe()
        } catch(e) {
            // dont care
        }
        // console.log('clearing preset and recal', this.preSetRecal, this.recal)
        this.preSetRecal = null
        this.recal = null
    }
    componentWillUnmount(){
        
        this.unsubscribe()
        // console.log('killing', this.id)
    }

    renderRight = () => {
        
        let { colors } = this.context
        return(

            <Animated.View style={{
                transform:[
                    {translateY: -12},
                    {rotate: this.ani.interpolate({
                        inputRange: [0,1],
                        outputRange: ['90deg', '-90deg']
                        })}] 
                }}>
                <ChevronRightRounded  style={{fontSize: 60, ...text.accentContrast(colors)}}/>
            </Animated.View>
        )
    }
    hasMeetingsToday= () => {
        let { past, current, future} = this.state
        let pcount    = _.get(past, 'meetings.length') 
        let fcount  = _.get(future, 'meetings.length')
        let ccount = _.get(current, 'meetings.length')
        let total = pcount + fcount + ccount
        return !!total
    }

    willMeetWithContactsToday = () => {
        let { past, current, future} = this.state
        let pcount    = _.get(past, 'contacts.length') 
        let fcount  = _.get(future, 'contacts.length')
        let ccount = _.get(current, 'contacts.length')
        let total = pcount + fcount + ccount
        return !!total
    }


    getSummary = () => {
        let {past, future, current} = this.state
        let pcount    = _.get(past, 'meetings.length') 
        let fcount  = _.get(future, 'meetings.length')
        let ccount = _.get(current, 'meetings.length')
        let total = pcount + fcount + ccount

        let date = this.props.date ? this.props.date : Moment()
        if(!total){
            return 'Nothing to do today!'
        } 
        // how many meetings are past and how many are future
        // when does the next meeting start
        // quick link to join meeting?  
        // zoom extentions?


        let message = '' // = `You have ${total} meetings today.`
        if(!!pcount && !fcount && !ccount){
            message = `All Done for today!`
        } else if (!!pcount && !!fcount && !ccount){
            message = `You have ${total} meetings today. ${pcount} down, ${fcount} more to go!`
        } else if( !pcount && !!fcount && !!ccount){
            message = `${_.get(current, 'meetings[0].name', '')} is happening now, then ${fcount} more meeting${this.plaural(fcount)} to go.`
        } else if( !pcount && !fcount && !!ccount){
            message = `${_.get(current, 'meetings[0].name', '')} is happening now.`
            
        } else if(!pcount && !!fcount && !ccount){
            message  = `You have ${total} meetings coming up ${getMomentCalendar(date)}!`
        } else if(!!pcount && !fcount && !!ccount){
            message = `Currently on your last meeting`
        } else if(!!pcount && !!fcount && !!ccount) {
            message = `${_.get(current, 'meetings[0].name', '')} is happening now,  ${pcount} ${this.toBe(pcount)} completed, and there ${this.toBe(fcount)} ${fcount} more to go!`
            // message = `${past}, ${future}, ${current}`
        }
        return message
        
    }
    toBe = (count) => count === 1 ? 'is' : 'are'
    plaural = (count) => count === 1 ? '' : 's'

    open = () => {
        
        this.setState({expanded: true})
        Animated.spring(
            this.ani,
            {
                toValue: 1,
                friction: 3,
                duration: 700,
                useNativeDriver: true
            } 
        ).start()
        // Navigation.setTabVisibility(false)
        this.props.onExpand && this.props.onExpand(true)
    } 



    close = () => {
        this.setState({expanded: false, full: false})
        Animated.spring(
            this.ani,
            {
                toValue: 0,
                friction: 3,
                duration: 700,
                useNativeDriver: true
            }
        ).start()
        Navigation.setTabVisibility(true)
        this.props.onClose && this.props.onClose(true)
        this.props.onFullPage && this.props.onFullPage(false)
    }

    renderMeetings = () => {
        const{ past, current, future} = this.state
        const date = this.props.date ? Moment(this.props.date) : Moment()
        if(!this.hasMeetingsToday()){ return <></>}


        const { colors } = this.context
        const textStyle = [text.bold, text.base(colors)]
        const titleStyle= [text.bold, text.small, text.base(colors)]
        const f = _.map([...future.meetings], (meeting, index) =>{
            const time_15 = Moment(meeting.start_date).subtract(15, 'minutes')
            const within15 = Moment(date).isBefore(Moment(meeting.start_date)) && Moment(date).isAfter(time_15)
                return <ListItem 
                    key={meeting.id}
                    underlayColor={colors?.transparent}
                    contentContainerStyle={{width:'100%'}}
                    containerStyle={today.meetingTile(colors)}
                    title={meeting.name}
                    titleStyle={titleStyle}
                    subtitle={within15 ? Moment(meeting.start_date).fromNow() : 'Coming up'}
                    subtitleStyle={textStyle}
                    rightTitleStyle={titleStyle}
                    onPress={() => EventManager.delete(meeting)}
                    rightTitle={Moment(meeting.start_date).format('h:mm A')}
                    rightSubtitle={Moment(meeting.end_date).format('h:mm A')}
                    rightSubtitleStyle={textStyle}
            />})
        let p = _.map( [...past.meetings], (meeting, index) =>{
                return <ListItem 
                    key={meeting.id}
                    underlayColor={colors?.transparent}
                    contentContainerStyle={{width:'100%'}}
                    containerStyle={today.meetingTile(colors)}
                    title={meeting.name || ''}
                    titleStyle={textStyle}
                    rightTitleStyle={textStyle}
                    subtitle={'Completed'}
                    onPress={() => EventManager.delete(meeting)}
                    subtitleStyle={textStyle}
                    rightTitle={Moment(meeting.start_date).format('h:mm A')}
                    rightSubtitle={Moment(meeting.end_date).format('h:mm A')}
                    rightSubtitleStyle={textStyle}
            />})

        let c = _.map( [...current.meetings], (meeting, index) =>{
                let time_15 = Moment(meeting.end_date).subtract(15, 'minutes')
                let within15 = Moment(date).isBefore(Moment(meeting.end_date)) && Moment(date).isAfter(time_15)
                return <ListItem 
                    key={meeting.id}
                    underlayColor={colors?.transparent}
                    contentContainerStyle={{width:'100%'}}
                    containerStyle={today.meetingTile(colors)}
                    title={meeting.name}
                    titleStyle={textStyle}
                    rightTitleStyle={textStyle}
                    subtitle={within15 ? 'Ending ' + Moment(meeting.end_date).fromNow() : 'Happening Now'}
                    onPress={() => EventManager.delete(meeting)}
                    subtitleStyle={[textStyle, text.accent(colors)]}
                    rightTitle={Moment(meeting.start_date).format('h:mm A')}
                    rightSubtitle={Moment(meeting.end_date).format('h:mm A')}
                    rightSubtitleStyle={textStyle}
            />})

        return [...f, ...c, ...p]
       
    }

    renderContacts = () => {
        let { past, future, current } = this.state
        let p = _.get(past, 'contacts', [])
        let c = _.get(current, 'contacts', [])
        let f = _.get(future, 'contacts', [])
        let contacts = _.flatten([...p, ...f, ...c])
        return _.map(contacts, (contact, index) => <ContactTileToday key={index} person={contact}/> )
    }

    renderHeading = () => {
        let { expanded } = this.state
        let { colors } = this.context
        return(
                <ListItem 
                    key={'todaytitle'}
                    onPress={expanded ? this.close : this.open }
                    containerStyle={today.listItem}
                    title={this.state.title ? this.state.title : 'Today'}
                    titleStyle={{...today.title(colors), ...text.large}}
                    subtitle={!expanded || !this.hasMeetingsToday() ? this.getSummary() : null}
                    subtitleStyle={today.subtitle(colors)}
                    rightIcon={this.renderRight()}
                />
        )
    }

    setFull = (full) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        this.setState({full})
        this.props.onFullPage && this.props.onFullPage(full)
    }

    renderMore = () => <></> 
    
    
    render(){   
        let { colors} = this.context
        const { expanded, full} = this.state
        return(
            
            <View 
                style={[
                    today.outer(colors,wp,  !!full)
                    ]}>
                {this.renderHeading()}
                {<Fade collapsed={!expanded}> <View style={containers.centerTopColumn}>{this.renderMeetings()}</View></Fade>}
                {<Fade collapsed={!expanded}> <Text style={[today.title(colors), text.tiny]}>{ this.willMeetWithContactsToday() ? "You'll Interact With ..." : ''}</Text></Fade>}
                {<Fade collapsed={!expanded}> 
                    <ScrollView
                        horizontal
                        contentContainerStyle={{padding: 10}}
                    >
                        {this.renderContacts()}
                    
                    </ScrollView>
                </Fade>}
            </View>
            
            
        )
    }
}

export class DaySchedule extends TodayWidget{
    componentDidMount(){
        this.setup(this.props)
    }
    setup = (props) => {
        EventManager.updateItemsFor(props.date, props.contact)
        .then(({past, current, future}) => {
            this.setState({past, current, future, full: true, expanded: true})
        })
        .catch(e => console.error(e))
        this.setState({title: getMomentCalendar(props.date)})
    }

    componentDidUpdate(prev){
        if(!_.isEqual(prev, this.props)){
         this.setup({...this.props})
        }
    }


    renderHeading = () => {
        let { expanded, } = this.state
        let { colors } = this.context
        return(
            <ListItem 
                onPress={expanded ? this.close : this.open }
                containerStyle={today.listItem}
                title={this.state.title ? this.state.title : 'Today'}
                titleStyle={today.title(colors)}
                subtitleStyle={today.subtitle(colors)}
        />
        )
    }
    render(){   
        let { colors} = this.context
        const { expanded, full} = this.state
        if(!this.hasMeetingsToday()){ return <></> }
        return(
            
            <View 
                style={[today.outer(colors, wp, full), {width: '100%', borderRadius: 0, shadowColor: 'transparent'}]}>
                {this.renderHeading()}
                {!!expanded && <View style={containers.centerTopColumn}>{this.renderMeetings()}</View>}
                {!!expanded && <Text style={[today.title(colors), text.tiny]}>{this.willMeetWithContactsToday() ?  "You'll Interact With ..." : ''}</Text>}
                {!!expanded && <ScrollView
                    horizontal
                    contentContainerStyle={{padding: 10}}
                >
                    {this.renderContacts()}
                    
                </ScrollView>}
                {this.renderMore()}
            </View>
            
        )
    }
}

export class GAppointments extends DaySchedule{
    renderHeading = () => {
        let { expanded, } = this.state
        let { colors } = this.context
        return(
            <ListItem 
                onPress={expanded ? this.close : this.open }
                containerStyle={today.listItem}
                title={this.state.title ? this.state.title : 'Today'}
                titleStyle={today.appointmentTitle(colors)}
                subtitleStyle={today.subtitle(colors)}
        />
        )
    }
    

    componentDidMount(){
        
        this.action()
        
    }

    action = async  () => {
        let { past, current, future } = await EventManager.updateItemsFor(this.props.date, this.props.contact)
            // console.log(past, current, future)
            this.setState({past, current, future, full: true, expanded: true, title: getMomentCalendar(this.props.date)})
    }
 
    
    render(){   
        let { colors, appTheme} = this.context
        const { expanded, full} = this.state
        if(!this.hasMeetingsToday()){ return <></> }
        return(
            
            <View 
                style={[today.appointmentOuter(colors, appTheme, wp, full), {width: '100%', borderRadius: 0, shadowColor: 'transparent'}]}>
                {this.renderHeading()}
                {!!expanded && <View style={containers.centerTopColumn}>{this.renderMeetings()}</View>}
            </View>
            
        )
    }
}

export class Appointments extends React.Component{
    
    componentDidMount(){
        
        this.id = _.random(0, 0xffffff)
        EventManager.addListener(this.id, this.forceUpdate)
    }
    componentDidUpdate(prev){
        if(!_.isEqual(prev, this.props)){
            this.setState({...this.props})
        }
    }
    
    
    componentWillUnmount(){
        EventManager.removeListener(this.id)
    }

    refs = []
    render(){
        if(!this.refs){
            this.refs = []
        }
        return(
            <>
                <GAppointments  date={Moment().add(1, 'day').startOf('day')} contact={this.props.contact}/>
                <GAppointments  date={Moment()} contact={this.props.contact}/>
                <GAppointments  date={Moment().subtract(1, 'day').endOf('day')} contact={this.props.contact}/>
            </>
        )
    }
}

const ContactTileToday = (props) => {

    const grade = _.get(props, 'person.grade')
    const first = _.get(props, 'person.first_name')
    const last = _.get(props, 'person.last_name')
    const name = first + ' ' + last

    return(
        <SettingsContext.Consumer>
            {({colors}) => {
                return (
                    <TouchableOpacity style={[
                        today.contactTile(colors),
                        
                    ]}
                        onPress={() => Navigation.gotoContact(props.person)}
                    >
                        <View style={containers.betweenCenterRow}>
                            <TodayGrade grade={grade}/>
                            <Text style={[text.tiny, text.base(colors)]}>
                                {name}
                            </Text>
                        </View>
                    </TouchableOpacity>
                )
            }}
        </SettingsContext.Consumer>
        
    )
}

const TodayGrade = (props) => {
    const { grade: g }  = props
    return(
        <SettingsContext.Consumer>
        {({colors, appTheme}) => {
            return (
                <View style={grade.tiny(colors)}>
                    
                    <Text style={[
                        text.tiny,
                        text.bold,
                        text.reverseBase(appTheme)]}>
                        {g}
                    </Text>
                </View>
            )
        }}
    </SettingsContext.Consumer>
    )
}



import React, { useContext } from 'react';
import { SettingsContext } from '../Managers/Settings';
import { containers } from '../theme';
import { Tags } from './Tags';

import _ from 'lodash';
import { SmallCard, CardHeader } from './card';

export const ContactTags = props => {

    const { org, person } = props
   const tag_categories = person?.tag_categories || []
   
   const renderHeader = () => {
        return (
            <div style={{...containers.betweenRow}}>
                <CardHeader title={'Tags'} style={{paddingHorizontal: 0}} />                        
          </div>
        )
    }

    const updateTags = (tags) => {
        // alert(tags)

    }
      const {colors} = useContext(SettingsContext)

      return(
        <SmallCard>
          {renderHeader()}
          <Tags
              org={org}
              person={_.pick(person, ['id'])}
              containerStyle={{margin:0}}
              contactCategories={tag_categories} 
              onChange={updateTags}
          />
        </SmallCard>
      )
}
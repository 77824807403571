import React, { useContext, useEffect } from 'react'
import {
  View,
  Text,
  TouchableOpacity
} from 'react-native';
import { containers, text } from '../theme';
import { SettingsContext } from '../Managers/Settings';
import { Input } from './exports'
export const AddressInput = props => {

  let [address, setAddress] = React.useState({})
  let [immutable, setImmutable] = React.useState(props.immutable)
  

  useEffect(() => {
    setAddress(props.address)

  }, [''])


  const onAddressChange = (key, value) => {
    let _address = { ...address }
    _address[key] = value
    setAddress(_address)


    if (props.onChange) {
      props.onChange(_address)
    }
  }

  const clearFields = () => {
    if (immutable) { return }
    let _address = {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      prisonId: ''
    }
     setAddress(_address)
    if (props.onChange) {
      props.onChange(address)
    }
  }


  const hasAddress = () => {
    // const { address } = this.state
    if (!address) { return false }
    var { address1, address2, city, state, zip, country, prisonId } = address
    return (
      address1 !== '' ||
      address2 !== '' ||
      city !== '' ||
      state !== '' ||
      zip !== '' ||
      country !== '' ||
      prisonId !== '')
  }

  const { colors } = useContext(SettingsContext)
    return (
      <>
        <Input
          editable={!immutable}
          underlineColorAndroid="transparent"
          placeholder="Address 1"
          value={address?.address1 || ''}
          onChangeText={val => onAddressChange('address1', val)}
        ></Input>
        <Input
          editable={!immutable}
          underlineColorAndroid="transparent"
          placeholder="Address 2"
          value={address?.address2 || ''}
          onChangeText={val => onAddressChange('address2', val)}

        ></Input>
        <View style={{ maxWidth: '100%', ...containers.betweenRow, alignSelf: 'stretch' }}>
          <Input
            editable={!immutable}
            underlineColorAndroid="transparent"
            placeholder="City"
            value={address?.city || ''}
            onChangeText={val => onAddressChange('city', val)}
            style={{ flex: 3, marginRight: 2 }}
          ></Input>
          <Input
            editable={!immutable}
            underlineColorAndroid="transparent"
            placeholder="State"
            value={address?.state || ''}
            onChangeText={val => onAddressChange('state', val)}
            style={{ flex: 1, marginHorizontal: 2 }}
          ></Input>
          <Input
            editable={!immutable}
            underlineColorAndroid="transparent"
            placeholder="Zip"
            value={address?.zip || ''}
            onChangeText={val => onAddressChange('zip', val)}
            style={{ flex: 2, marginLeft: 2 }}
          ></Input>
        </View>
        <Input
          editable={!immutable}
          underlineColorAndroid="transparent"
          placeholder="Country"
          value={address?.country || ''}
          onChangeText={val => onAddressChange('country', val)}
          style={{ width: '100%' }}
        ></Input>
        {hasAddress() && !immutable ? <View style={[containers.centerRow, { marginVertical: 20 }]}>
          <TouchableOpacity onPress={() => clearFields()}>
            <Text style={[text.superTiny, text.base(colors)]}>Clear Address Fields</Text>
          </TouchableOpacity>
        </View> : null}
      </>
    )

}


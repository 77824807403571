import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MemberAvatar } from './MemberAvatars';
import { Owner } from './ContactDetail';
import { Drawer as _Drawer, withStyles } from '@material-ui/core';
import Button from './Button';
import { Add } from '@material-ui/icons';

import { alerts, containers } from '../theme';
import { noop } from 'lodash';
import {useAlert} from '../Managers/Alert'
import { useContacts } from '../Managers/Contacts';

const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));



export const OwnerAvatar = ({onOpen, owner, contact_id}) => {
    const _onOpen = () =>{
        onOpen && onOpen(contact_id)
    }
    return (
        <div style={containers.centerColumn}>
            {!!owner && <MemberAvatar
                onPress={_onOpen}
                person={owner}
            />}
            {!owner && <Button 
                onClick={_onOpen}
                iconOnly
                icon={<Add />}
            />}
        </div>
    )
}

const Drawer = withStyles({
    paper: {
        width: 'max(20vw, 300px)',
        padding: 10
    }
})(_Drawer)


// local functions are for the contactImportTable
// if local is set, verification will be skipped
// and the database writes will not take place
// instead, onLocalBind, and onLocalUnbind will be called
// respectively
export const OwnerDrawer = ({contact_id, org, onClose, onOwnerUpdated, local, onLocalBind, onLocalUnbind}) => {
    const [person, setPerson] = useState()
    const Alert = useAlert()
    const ContactManager = useContacts()
    React.useEffect(() => {
        !!contact_id && ContactManager.getSingleContact({id: contact_id})
        .then(setPerson)  
        .catch(console.error)
    },[contact_id])

    const onSelectContactOwner = async (member) => {
        const member_id = member?.id
        const owner_id = person?.owner?.user?.id
        
        if(local){
            if(member_id === owner_id){
                unbind(member)
              } else {
                bind(member)
              }  
        } 
          if(member_id === owner_id){
            verifyUnbind(member)
          } else {
            verifyBind(member)
          }  
    }

    const verifyUnbind = (member) => {
        Alert.warning(
            `Unassign ${person?.first_name} from ${member?.first_name}. You can always change this later`, 
            alerts.yesno(() => unbind(member), noop)
        )
    }

    const verifyBind = (member) => {
        Alert.warning(
            `Assign ${person?.first_name} to ${member?.first_name}?`, 
            alerts.yesno(() => bind(member), noop)
        )
    }

    const unbind = async (member) => {
        if(local){
            onLocalUnbind(member)
            return
        }
        try{
            const contact = await ContactManager.unbindContactToUser(person?.id, member?.id)
            const new_person = {
                ...person,
                ...contact
                }
            setPerson(new_person)
            onOwnerUpdated && onOwnerUpdated(new_person)
            
            } catch(e) {
            try{
                Alert.alert('There was a problem', e)
            } catch(e) {
                console.error(e)
            }
        }
    }

    const bind = async  (member) => {
        if(local){
            // return the member for assignment
            onLocalBind(member)
            return
        }
        try{
            const {contact} = await ContactManager.bindContactToUser(person?.id, member?.id)
            const new_person = {
            ...person,
            ...contact
            }
            setPerson(new_person)
            onOwnerUpdated && onOwnerUpdated(new_person)
            } catch(e) {
            try{
                console.error(e)
                Alert.alert('There was a problem', e)
            } catch(e) {
                // console.log(e)
                
                
            }
            }
        
    }
    return(
        <Drawer open={!!contact_id}
            variant="temporary"
            anchor={'right'}
            onClose={onClose}
            style={{minWidth: 300}}
        >
            <Owner 
                editing={true} 
                onSelectContactOwner={onSelectContactOwner}
                org={org}
                owner={person?.owner?.user}
            />

        </Drawer>
    )
}
import React, {useContext, useState, useEffect} from 'react'
import _, {  set } from 'lodash';
import {v4 as getUUID} from 'uuid';
import { DATABASE_CACHE, NETWORK_TABLE, SECURE_API_BASE } from '../Constants';
import Moment from 'moment';
import { createContext } from 'react';
import { useStorage } from './Storage';
import Frisbee from 'frisbee';
import { BehaviorSubject } from 'rxjs';



const DatabaseContext = createContext()

export const useNetwork = () => useContext(DatabaseContext)
export const DatabaseProvider = ({children}) => {
    
    const api = new Frisbee()
    const _currentHeaders = new BehaviorSubject({})
    const Storage = useStorage()
    const [header, setHeader] = useState({
        tokenType: '',
        client: '',
        accessToken: '',
        uid: ''
    })

    const [serverConnected, setServerConnected] = useState(true)
    const [ready, setReady] = useState(false)
    const [valid, setValid] = useState(null)
    const [_cache, _setCache] = useState({})

    useEffect(() => {
      const call = async () => {
        try{
          let _header = await Storage.getEncryptedTable(NETWORK_TABLE)
          setHeader({..._header})  
        }catch(e) {
          console.error(e)
        } finally {
          setReady(true)
        }

      }
      call()

        initialize()
    }, []) 

    const initialize = async () => {
        let response  = await Storage.getEncryptedTable(DATABASE_CACHE)
        // console.log(response)
        _.forEach(response, r => {
            r.sub = new BehaviorSubject(r.response)
        })
        if(response) {
            _setCache({...response})
        }
    }


    const wait = (delay) => {
        return new Promise((resolve) => {
          setTimeout(resolve, delay)
        })
        
    }

    const get = async (path, shouldPersist = false, isCsv = false, retryCount = 0) => {
          // console.log(path)
        // make sure network is setup
        if(retryCount > 3){
          // console.log(path, 'failed after ', retryCount, 'retries')
            return Promise.reject({error: 'To many retries.  There was a problem loading your network settings'})
        }
        // retryCount && console.log('Retry ', retryCount)
        if(!ready){
            // console.log('waiting ', retryCount)
            await wait(500)
            return get(path, shouldPersist, isCsv, retryCount + 1)
        }
        
        try{
            let response = await  _get(path, isCsv) 
            // console.log(path, response)
             if(_.isArray(response)){
                 _.forEach(response, r => r.sync = true)
             } else if (_.isPlainObject(response)){
                 response.sync = true
             }
            shouldPersist && setCache(path, response)
            // console.log(response)
            return response
        } catch(e) {
            if(shouldPersist){
                let data;
                try{
                    data = _cache[path]?.sub?.getValue?.()
                } catch(e) {

                }
                return Promise.reject({error: e.error, data})
            } else {
                return Promise.reject({error: e.error})
            }
        }
    }

    const setCache = async (path, response) => {
        let cache = {..._cache}
        if(!cache[path]){
            // console.log('reset', path)
                cache[path] = {sub: new BehaviorSubject(), time: _.now()}
        }
        else if(!cache[path]?.sub){
            // console.log('reset just ', path)
            cache[path].sub = new BehaviorSubject()
        }
        cache[path].sub.next(response)
        // console.log(path, ' sending')
        cache[path].time = _.now()
        cache[path].response = response
        _setCache({...cache})
        saveCacheToStorage()
    }

    const deleteFromCache = async (path) => {
        let cache = {..._cache}
        if(cache[path]){
            cache[path].sub.complete()
            cache[path] = null
        }
        _setCache({...cache})
    }

    const saveCacheToStorage = () => {
        const cache = {..._cache}
        _.forEach(_.keys(cache), key => cache[key] = {response: cache[key].response, time: cache[key].time } )
        Storage.setEncryptedTable(cache, DATABASE_CACHE)
        
    }

    const getCacheSubscription = (path) => {
        let cache = {..._cache}
        if(!cache[path]){
            cache[path] = {sub: new BehaviorSubject(), time: _.now()}
        }
        if(!cache[path]?.sub){
            cache[path].sub = new BehaviorSubject()
        }
        // console.log('Cache path of ', path, ' = ', cache[path])
        try{
            getCachePriority(path)
        } catch(e) {
            console.error("THE ERROR IS HERE", e)
        }
        _setCache(cache)
        return cache[path].sub.asObservable()
        
    }

    const updateCache = (path, object) => {
        // get a list of all of the paths in the cache
        // update all paths with parts of the
        // path objects that match parts of the paths in 
        // cache.  Then search those response for the 
        // item you just updated and merge them together.
        // Note this should not be used for deletions as
        // the object is merged

        const parts = _.split(path, '/')
        for(let part of parts){
            if(part === ""){ continue}
            // console.log('Part = ', part)
            _.forEach(_.keys(_cache), p => {
                
                const item = _cache[p]
                // console.log('Cache item = ', p)
                if(_.includes(p, part)){
                    // console.log('HIT on ', p, part)
                    // you search this response for 
                    // the item we just updated
                    // if the item is an array.  
                    // since thats the only time
                    // we would need to do this
                    if(_.isArray(item.sub.getValue())){
                        // console.log('Found array with hit')
                        
                        let res = _.map(item.sub.getValue(), r => {
                            return r.id !== object.id ? r : _.merge(r, object)

                        })
                        // console.log('setting ', p, ' to ', res)
                        setCache(p,res)
                    }
                }
            })   
        }
    }

    const removeFromArrayCache = (path, object) => {
        const parts = _.split(path, '/')
        for(let part of parts){
            if(part === ""){ continue}
            // console.log('Part = ', part)
            _.forEach(_.keys(_cache), p => {
                
                const item = _cache[p]
                // console.log('Cache item = ', p)
                if(_.includes(p, part)){
                    // console.log('HIT on ', p, part)
                    // you search this response for 
                    // the item we just updated
                    // if the item is an array.  
                    // since thats the only time
                    // we would need to do this
                    if(_.isArray(item.sub.getValue())){
                        // console.log('Found array with hit')
                        // console.log(item.sub.getValue())
                        let res = _.filter(item.sub.getValue(), r => r?.id !== object?.id)
                        // console.log(res)
                        setCache(p,res)
                    }
                }
            })   
        }
    }


    

    const put = async ( path, object, manager, shouldSync = true) =>{
        try{
            let response = await _put(path, object)            
            // console.log('put to  ', path)
            updateCache(path, response)
            return {...response, sync: true}
            
        } catch(e) {
            return Promise.reject({error: e.error})
        }
    }

    const getCachePriority = async(path, forceNetwork = false) => {
            // console.log(path, 'cache = ', _cache[path])
            if(!_cache[path] || forceNetwork){   
                // console.log('Forcing network get from cache because', !this._cache[path] ? 'Cache miss' : `ForceNetwork = ${forceNetwork}`)     
                return get(path, true)
            } else if(Moment(_.now()).isAfter(Moment(_cache[path].time).add(30, 'seconds'))){ 
                // if the last network update was over 5 min ago, get it from network
                    // console.log('Cache date is too old for ', path, ' getting new data')
                return get(path, true)
            } else {
                const value = _cache[path].sub.getValue() || _cache[path].response
                // console.log('Value = ', value)
                // value && console.log( 'Return from cache for ', path)
                // !value && console.log( 'Return from network for ', path)
                return value ? value : get(path, true)
            }
                
    }

    const updateCachePathsWithNewItem = (response, cachePaths) => {
        _.forEach(cachePaths, path => {
            // console.log('Starting path = ', path)
            _.forEach(_.keys(_cache), key => {
                const item = _cache[key]
                // console.log('checking cache item = ', key)
                if(_.includes(key, path)){
                    // hit add this response into this item.
                    // note that this implies that we have
                    // an array.
                    // console.log('match on path =', path, ' and item = ', item)
                    const res = [...item?.response, response]
                    
                    setCache(key, res)
                    // console.log('response ', response, ' added to cache ')
                }
            })
        })
    }

    const post = async ( path, object, manager,  cachePaths=[]) => {

        // check if there are any p1 items that need to be posted.
        // if so, save the item for later and tell the user to sync
        try{
            if(!!object && !object.id){
                object.id = await getUUID()
            }
            let response = await _post(path, object)
            if(!!object && !!response && !response.id){
                // this is a workaround for when the server
                // doesn't return id like it should 🤷🏾‍♂️
                response.id = object.id
            }
            if(cachePaths.length > 0){
                updateCachePathsWithNewItem(response, cachePaths)
            }
            return {...response, sync: true}
            
        } catch(e) {
            return Promise.reject(e);
        }
    }

    const remove = async (path, item, manager = null) => {
        try{
            await _delete(path)
            removeFromArrayCache(path, item)
            deleteFromCache(path)
            return true
        } catch(e) {
            console.error(e)
            return Promise.reject({error: e.error})
        }

    }
    

    const clearCache = () => {
        clearHeaders()
        _.forEach(_.values(_cache), v => {
            if(v.sub){
                if(_.isPlainObject(v.sub.getValue())){
                    v.sub.next({})
                } else if (_.isArray(v.sub.getValue())){
                    v.sub.next([])
                } else {
                    v.sub.next(null)
                }

            }
        })
        _setCache(null)

    }


    // network classes


    const getHeaderSubscription = () => _currentHeaders.asObservable()
    
    const getCredentialsStatus = () => valid.asObservable()
  
    const testValidCreds = async () => {
      try{
        // console.log('Starting Cred Test')
        let test = await _get('/auth/test')    
        setValid(!!test)   
        return !!test
      } catch(e){
        // console.error(e)
        setValid(false)
        return false
      }
  
    }
  
    const testServerConnection = async () => {
      if(await _testServerConnection()){
        setServerConnected(true)
        testValidCreds()
      } else {
        setServerConnected(false)
      }
    }
  
    const _testServerConnection = async () => {
      // test saved credentials and connection to server  
      
      try{
        let response = await _get('/search')
        // console.log('login test response', response)
        // console.log('break')
        if(response.status === 401) {
          // console.log('break')
          return false
        }
        else{ return true}
      } catch(e){
        if(e?.errors){
          // you can hit the server
          return true
        } else {
          return false
        }
      }
    }
  
  
  
    const _get = async (path, isCsv = false) => {  
    
      try{
        let _headers = getHeader(isCsv)
          let response = await api.get(SECURE_API_BASE + path,  {headers: _headers, mode: "cors"})
          saveHeaders(response)
          if(!response.ok){
              let error = await processError(response)
              return Promise.reject(error)
          }
          setServerConnected(true)
          return response.body
      } catch(e) {
          let error = {error: e.message || '', status: null}
          return Promise.reject(error)
      }
    }
  
    const getRaw = async (path) => {
      try{
          let response = await api.get(path)
              
        if(!response.ok){
          let error = await  processError(response)
          return  Promise.reject(error)
        }
        setServerConnected(true)
        return response.body
      } catch(e){
        let error = {error: e.message || '', status: null}
        return Promise.reject(error)
        
      }
    }
  
    const postRaw = async (path, body, headers) => {
        try{
          
         let response = await api.post(path, {body, headers})
          if(!response.ok){
            let error = await  processError(response)
            Promise.reject(error)
            
        } else {
          setServerConnected(true)
          return response.body
        }   
       } catch(e){
        let error = {error: e.message || '', status: null}
        return Promise.reject(error)
        
      }
        
    }
    const putRaw = async (path, body, headers) => {
      try{
          
       let response = await api.put(path, {body, headers, mode: 'cors'})
        if(!response.ok){
          let error = await  processError(response)
          return Promise.reject(error)
          
      } else {
        setServerConnected(true)
        return response.body
      }   
     } catch(e){
      let error = {error: e.message || '', status: null}
      return Promise.reject(error)
      
    }
      
  }
  
  const processError = async (response) => {
      let { body , status}  = response
      
      if(status >= 500){
          return {error: "The Server had a problem with that request", status}
      } 
      if(!!body.errors){
        let err = ''
        // console.log(body?.errors?.full_messages)
          if(body?.errors?.full_messages){
            err = _.join(body.errors.full_messages, '; ')
            // console.log(err)
          } else {
            err = JSON.stringify(body.errors)
            .replace(/[[\]"{}]/g, "")
          }
          
  
          return { error: err, status}
      } else if (!!body && body.error) {
        // console.log('not unknown', body.error)
        return { error: body.error, status: status}
      } else {
        return { error: 'Unknown Error', status: status }
      }
  }
  
  const extractError = (e) => {
      
      if(e?.error) { return e.error}
      if(e?.errors && typeof e?.errors?.length === typeof 0) { return e.errors[0]}
      if(e?.errors && e?.errors?.full_messages && e?.errors?.full_messages?.[0]) {return e.errors.full_messages[0]}
      return e
    }
  
    const _post = async (path, body) => {
        let headers = getHeader()
        try{
          let response = await api.post(SECURE_API_BASE + path, { body, headers, mode: 'cors' })
          saveHeaders(response)    
          if(!response.ok){
            
              let error = await  processError(response)
              return Promise.reject(error)
          }
          setServerConnected(true)
          return response.body
      } catch(e){
        let error = {error: e.message || '', status: null}
        return Promise.reject(error)
      }
    }
  
    const _put =  async (path, body) => {
      let headers = getHeader()
      
      try{
          let response = await api.put(SECURE_API_BASE + path, { body, headers, mode: 'cors'})
          saveHeaders(response)
          if(!response.ok){
            let error = await  processError(response)
            return Promise.reject(error)
           }
          setServerConnected(true)
          return response.body
      } catch(e){
        let error = {error: e.message || '', status: null}
        return Promise.reject(error)
        
      }
    }
  
    const getHeader = () => {
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token-type' : header.tokenType,
        'client' : header.client,
        'access-token':  header.accessToken,
        'uid' : header.uid
      }
      return headers
    }
  
  
    const _delete = async (path) => {
      let headers = getHeader()
      try{

        let response = await api.del(SECURE_API_BASE + path, { mode: 'cors', headers,})
  
      saveHeaders(response)
        if(!response.ok){
          let error = await  processError(response)
          Promise.reject(error)
        }
        setServerConnected(true)
        
        return true
      } catch(e){
        let error = {error: e.message || '', status: null}
        return Promise.reject(error)
        
      }
    }
  
  
    const saveHeaders = (response ) => {
       let {headers} = response

       let _header = {

       }

        let tokenType =   headers.get('token-type')
        let client =  headers.get('client')
        let accessToken =  headers.get('access-token')
        let uid =  headers.get('uid')

        set(_header, 'tokenType', tokenType)
        set(_header, 'client', client)
        set(_header, 'accessToken', accessToken)
        set(_header, 'uid', uid)
        setHeader({..._header})

        if(tokenType && client && accessToken && uid){
          Storage.setEncryptedTable(_header, NETWORK_TABLE)
          if(!valid && response.ok){
            setValid(true)
            }
        } else {
          setValid(false)
        }
          _currentHeaders.next({..._header})

    }
  
    const clearHeaders = () => {
      const _header = {
        tokenType :  null,
        client : null,
        accessToken : null,
        uid : null
      }

      setHeader({..._header})
      Storage.clearTable(NETWORK_TABLE)
      _currentHeaders.next({..._header})
      setValid(false)
    }
  
    return(
        <DatabaseContext.Provider value={{
            get,
            post,
            put,
            remove,
            getCachePriority,
            clearHeaders,
            header,
            valid,
            ready
        }}>
          {children}
        </DatabaseContext.Provider>
    )

}



import React, { useContext } from 'react';
import { 
    View,
    TextInput
 } from 'react-native';
import { SectionBar } from "./exports";
import { text, containers, input} from "../theme"
import { SettingsContext } from '../Managers/Settings';
export const Notes =  props => {
    let container = [containers.centerColumn, {alignSelf: 'stretch'}]
    let {style, padding, ...rest} = props
    let extra = {}
    if(padding){
      extra.paddingHorizontal = 20
    }

    const { colors, appTheme } = useContext(SettingsContext)
    return(
    <View style={container}>
      <SectionBar title='Notes'/>
        <TextInput
          underlineColorAndroid="transparent"
          style={[input.textarea(colors, appTheme), text.base(colors), padding ? {padding: 20} : {}, style]}
          multiline
          {...rest}
      />
    </View>
    )

}

import React from 'react';
import { SettingsContext } from '../Managers/Settings';
import { wpCss, hpCss } from '../Fork/react-native-responsive-screen';
import Particles from "react-tsparticles";


export const ParticleBackdrop = ({hoverEffects = true, clickEffects = true, parallax = true, bubble = true }) => {

    return (

      <SettingsContext.Consumer>
        {({colors}) => {
          return(
          <>
            <Particles
              style={{position: 'absolute', transition: 'all 0.4s ease', zIndex: -1}}
              height={hpCss(100)}
              id="tsparticles"
              options={{
                background: {
                  color: {
                    value: colors?.foreground,
                  },
                },
                fpsLimit: 60,
                interactivity: {
                  detectsOn: "window",
                  events: {
                    onClick: {
                      enable: true,
                      mode: clickEffects ? "repulse" : null,
                    },
                    onHover: {
                      enable: hoverEffects,
                      mode: bubble ? "bubble" : null,
                      parallax: {
                        enable: parallax,
                        force: 50,
                        smooth: 30,
                      }
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 400,
                      duration: 2,
                      opacity: 0.6,
                      size: 7,
                    },
                    push: {
                      quantity: 2,
                    },
                    repulse: {
                      distance: 150,
                      duration: 0.9,
                    },
                  },
                },
                particles: {
                  color: {
                    // value: "#ffffff",
                    value: colors?.accent,
                  },
                  // links: {
                  //   color: "#ffffff",
                  //   distance: 150,
                  //   enable: true,
                  //   opacity: 0.5,
                  //   width: 1,
                  // },
                  // links: {
                  //   color: colors?.accent,
                  //   distance: 250,
                  //   enable: true,
                  //   opacity: 0.2,
                  //   width: 2,
                  // },
                  // collisions: {
                  //   enable: true,
                  // },
                  move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 1,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      value_area: 900,
                    },
                    value: 20,
                  },
                  opacity: {
                    value: 0.4,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    random: true,
                    value: 5,
                  },
                },
                detectRetina: true,
              }}
            />
            {/* <IntoTitle>Loading Outreach</IntoTitle> */}
          </>
          )
        }}
      </SettingsContext.Consumer>


  )
}

import React, {useContext, useState, useEffect, createContext} from 'react'
import { forEach, get, isEqual, keys } from 'lodash';
import { createManagerInfo } from '../Utilities';
import { USER_DATA_TABLE, SECURE_API_BASE, BASE, AUTHENTICATION_STATUS} from '../Constants';
import { useNetwork } from './Database';
import { useStorage } from './Storage';
import { useSettings } from '../Managers/Settings';
import { useAlert } from './Alert';


export const UserContext = createContext()

export const useUser = () => useContext(UserContext)

export const UserProvider = ({children}) => {

  const [user, setUser] = useState()
  const [authenticated, setAuthenticated]  = useState(AUTHENTICATION_STATUS.unknown)
  const managerName = createManagerInfo('USER_MANAGER', 1)  


  const Database = useNetwork()
  const {ready} = useNetwork() // i know
  const alert = useAlert()
  const Storage = useStorage()
  const Settings = useSettings()

  
  useEffect(() => {
    if(!ready){ return }
    Database.get('/auth/test')
    .then(u => {
      // console.log(u)
      if(u) {
        _saveAndDistributeUser(u, true)
        setAuthenticated(AUTHENTICATION_STATUS.authenticated)
      }
    
    }).catch(e => {
      setAuthenticated(AUTHENTICATION_STATUS.unauthenticated)
    })

  }, [ready])

  
  const checkValidProfile = async () => {
    return onLoad()

  }


  const _saveAndDistributeUser = async (_user, writeBack = false) => {
    if(isEqual(_user, user)){
      return
    }
    setUser(_user)
    if(writeBack){
       Storage.setEncryptedTable(_user, USER_DATA_TABLE)
    }

    if(!!_user){
      const {first_name, last_name, email, phone} = _user
      Settings.updatePrefs({..._user.prefs, first_name, last_name, email, phone })
      // MARK remove this from settings and add to useUser
    }
  }

 const updateUser = async  (_user) => {
    try{
      let result = await Database.put(
        '/users/update',
        _user,
        managerName,
        true
      )
        _saveAndDistributeUser(result, true)
      return result
    } catch(e) {
      alert.alert(e.error)
    }
  }

  const deleteOrgPrefs = async () => {
    try{
      await updatePrefs({
        currentOrg: null,
        currentSeries: null
      })

    } catch(e) {
      // console.log(e)
    }
  }

  const updatePrefs = async (update) => {
    if(!user){ return }
    let prefs = {...user.prefs} || {}
    const _keys = keys(update)
    forEach(_keys, key => prefs[key] = update[key])
    try{
      let result = await Database.put(
              '/users/prefs',
              {prefs},
              managerName,
              false
            )
      prefs = result
      Settings.updatePrefs(result)
      _saveAndDistributeUser({...user, prefs}, true)
      return result
    } catch(e) {
      return Promise.reject(e.error)
    }
  }

  const setAccentColor = async (accent) => {
    Settings.setAccentColor(accent)
    await updatePrefs({accent})
    
  }

  const resetAccentColor = () => {
    const accent = Settings.getDefaultAccentColor()
    updatePrefs({accent})
    Settings.resetAccentColor()
  } 

  const onLoad = async () => {
    
    try{
     let _user = await Storage.getEncryptedTable(USER_DATA_TABLE)
      !!_user && _saveAndDistributeUser(_user)
      return _user
    } catch(e) {
      // Alert.alert('A Problem Occurred', e.message)
    }
  }


  //  _updateIsLoggedIn = (value) => {
    //setAuthenticated(value)
    // _updateShouldLogin()
  // }
  
  // _updateShouldLogin = () => {
  //   let value = 
  //     !!user?.id 
  //     && !authenticated._value 
  //     && !!networkConnected._value

  //   _shouldRelogin.next(value)
  // }


  const changeUserInfo = (key, value) => {
    if(user[key]){
      let _user = {...user}
      _user[key] = value
      _saveAndDistributeUser(_user, true)
    }
  }
  
  const login = async (email, password) => {
    try{
        let _user = await Database.post(
          '/auth/sign_in.json', 
          { email, password,},      
          managerName,
        )

      _saveAndDistributeUser(_user, true)
      

     setAuthenticated(AUTHENTICATION_STATUS.authenticated)
      return _user
    } catch (e) {
      return Promise.reject(e.error)
    }
  }


  const logout = async (callback) => {
    setUser(null)
    try{
      Database.clearHeaders()
    } catch(e) {
      console.log('There was an error clearing Headers', e)
    }
    try{
      Database.clearCache()
    } catch(e) {
      console.log('There was an error clearing the database cache', e)
    }
    try{
      Storage.clear()
    } catch(e) {
      console.log('There was an error clearing Storage', e)
    }
    try{
      Settings.clear()
    } catch(e) {
      console.log('There was an error clearing settings', e)
    }
    try{
      callback && callback()
    } catch(e) {
      console.log('There was an error calling the provided callback function',e)
    }
    try{
      // Firebase.logout()
    } catch(e) {
      console.log('There was an error logging out of firebase', e)
    }

    setAuthenticated(AUTHENTICATION_STATUS.unauthenticated)
    return 
  }
 
  
  const register = async (email, password, password_confirmation, first_name, last_name) => {
    
    let body = {
      email,
      password,
      password_confirmation,
      first_name,
      last_name,
    }
    try{
      
      let data = await Database.post('/auth', body, managerName)
      
      let _user = get(data, 'data', null)
      _saveAndDistributeUser(_user, true)
     setAuthenticated(AUTHENTICATION_STATUS.authenticated)
      return _user
    } catch (e) {
      return Promise.reject(e.error)
    }

  }

  const updateProfileImage = async (url) => {
    updateUser({image: url})
  }

  const setDisplayImage = async (uri) => {    
    if(!user?.id){ return Promise.reject('No User Logged in')}
    //temp code

      const path = `ProfilePictures/${user.id}/`
      try{
        // let img = await Firebase.uploadImage(path, 'profile', uri)
        // console.log(img)
        // updateProfileImage(img)
      } catch(e) {
        // console.log(e)
      }
  }

  const resetPassword = async (email) => {
    try{
        
       let response = await Database.post('/auth/password', { email, redirect_url: BASE + '/forgetful' }, managerName)
       
       if(response && response.message){
          return response.message
       }
       return true
    } catch(e){
      return Promise.reject(e.error)
    }
  }

  const changePassword = async (password, password_confirmation, headers) => {
    try{
      let result = await Database.putRaw(SECURE_API_BASE + '/auth/password', {password, password_confirmation}, {...headers, 'Content-Type': 'application/json'} )
      if(result.message){
        return result.message
      } else { return result}
    } catch(e) {
      return Promise.reject(e.error)
    }
  }

    return(
      <UserContext.Provider value={{
        authenticated,
        user,
        prefs: user?.prefs,
        login,
        register,
        deleteOrgPrefs,
        logout,
        changePassword,
        setAccentColor,
        updatePrefs,
        updateUser,
        updateProfileImage,
        resetPassword
      }}>
        {children}
      </UserContext.Provider>
    )
}
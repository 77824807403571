import React from 'react';
import _ from 'lodash';
import {   
    ContactDetail,
    ContactTimeline,
    Attendance,
    ContactTags,
    BackButton,
    Studies
} from '../Components/exports';
import { useContacts } from '../Managers/Contacts';
import { alerts, containers } from '../theme';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSettings } from '../Managers/Settings';
import { useHistory, useParams} from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion';
import { Loading } from './loading';
import { useAlert } from '../Managers/Alert';
import { useUser } from '../Managers/UserManager';


export const Contact = ({org}) => {
    const [ person , setPerson] = React.useState()
    const [entered, setEntered] = React.useState(false)
    const history = useHistory()
    const {contact_id: id} = useParams()
    const ContactManager = useContacts()
    const {prefs} = useUser()
    const Alert = useAlert()


    React.useEffect(() => {
        !!id && ContactManager.getSingleContact({id})
        .then(p => {

            // p.surveys={"id":1428,"survey_id":7,"contact_id":85410,"viewer_id":null,"title":"\"Eye has not seen, nor ear heard, nor have entered into the heart of man the things which God has prepared for those who love Him.\" - 1 Corinthians 2:9","code":"live","created_at":"2020-10-20T15:03:56.697-04:00","updated_at":"2020-10-20T15:03:56.697-04:00"}
            setPerson(p)
            // setEntered(true)
        })
        .catch(e => console.log(e))
    },[id])

    const onChange = async (_person) => {
        try{
             await ContactManager.updateContact(_person)
             // need full = true option for update contact4 so we dont have to get the contact again
             let result = await ContactManager.getSingleContact(_person)

            setPerson(result)
        } catch(e){
            // console.error(e)
            Alert.error(e)
        }

    }
    const onSelectContactOwner = async (member) => {
        const member_id = member?.id
        const owner_id = person?.owner?.user?.id
        
          if(member_id === owner_id){
            verifyUnbind(member)
          } else {
            verifyBind(member)
          }  
    }

    const unbind = async (member) => {
        try{
            await ContactManager.unbindContactToUser(person?.id, member?.id)
            } catch(e) {
            try{
                Alert.alert('There was a problem', e)
            } catch(e) {
            // console.log(e)
            }
        }
        
    }
    const bind = async  (member) => {
        try{
            const {contact} = await ContactManager.bindContactToUser(person?.id, member?.id)
            const new_person = {
            ...person,
            ...contact
            }
            setPerson(new_person)

            } catch(e) {
            try{
                console.error(e)
                Alert.alert('There was a problem', e)
            } catch(e) {
                // console.log(e)
                
                
            }
            }
        
    }

    const verifyDelete = () => {
        Alert.alert('', `Are you sure you want to delete ${person?.first_name}`, alerts.yesno(deleteContact, _.noop))
    }
    const deleteContact = async () => {
        try{
            ContactManager.deleteContact(person)
            history.replace('/dashboard/contacts/all')
        } catch(e) {
            Alert.error(e)
        }
    }
    const verifyUnbind = (member) => {
        Alert.alert(`Unassign ${person?.first_name} to ${member?.first_name}`, `You can always change this later`, alerts.yesno(() => unbind(member), _.noop))
    }
    const verifyBind = (member) => {
    Alert.warning(`Assign ${person?.first_name} to ${member?.first_name}?`, alerts.yesno(() => bind(member), _.noop))
    }
    
    if(!person){
        return(
            <AnimatePresence>
                <motion.div
                    key="contact-load"
                    exit={{ opacity: 0 }}
                >
                    <Loading />
                </motion.div>   
            </AnimatePresence>
        )
    }
       
// stagger don't work, only on first render? individual delay for now

// todo: add exit animation

   const variants = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        delayChildren: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }

  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 200 },
    exit: { opacity: 0, x: -200 }
  }



    return(

    <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        style={{ paddingTop: 40, ...containers.wrapTopRow, position: 'relative'}}
    >
        
        <motion.div
        style={{position: 'absolute', top: 0, left: 0}}
            variants={item}
            exit="exit"
            transition={{delay: 0.0}}
        >
            <BackButton />
        </motion.div>

        <motion.div
            variants={item}
            exit="exit"
            transition={{delay: 0}}
        >
            <ContactDetail 
                org={org}
                onSelectContactOwner={onSelectContactOwner}
                person={person}
                onDelete={verifyDelete}
                onChange={onChange}
            />
        </motion.div>
        {/* <motion.div
                variants={item}
                exit="exit"
                transition={{delay: .07}}
            >
                    <ContactSurveys surveys={person?.surveys}/>   
            </motion.div>
    */}
            { 
                !!prefs?.currentSeries?.name && (
                    <motion.div
                        variants={item}
                        exit="exit"
                        transition={{delay: .07}}
                        >
                    <Attendance 
                        title={`Attendance for ${prefs?.currentSeries?.name} `
                        }
                    series={prefs?.currentSeries} 
                        person={person} />
                    </motion.div>
                    
                )
            }
            <motion.div
                variants={item}
                exit="exit"
                transition={{delay: .14}}
            >
                <Studies
                    person={person}
                />
            </motion.div>
            <motion.div
                variants={item}
                exit="exit"
                transition={{delay: .14}}
            >
                <ContactTags
                    org={prefs?.currentORg}
                    person={person}
                />
            </motion.div>
            
            <motion.div
                variants={item}
                exit="exit"
                transition={{delay: .21}}
            >
                <ContactTimeline person={person}/>
            </motion.div>
    </motion.div>
    )

}




import React from 'react';
import { SettingsContext } from '../Managers/Settings';
import { hpCss, wp, wpCss } from '../Fork/react-native-responsive-screen';
import {   Slide } from '@material-ui/core';
import _ from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import {  IntroSection as Section, IntroTitle, Input, SingleButton, Logo} from '../Components/exports';
import { UserManager } from '../Managers/exports';

import { useHistory, useLocation} from 'react-router-dom';
import { surface, text } from '../theme';
import { View } from 'react-native';
import { useAlert } from '../Managers/Alert';
const qs = require('qs')


export const PasswordReset  = props => {
    const [pass, setPass] = React.useState('')
    const [conf, setConf] = React.useState('')
    const [reset, setReset] = React.useState(false)
    const location = useLocation()
    const history = useHistory()
    const Alert = useAlert()
    const headers = qs.parse(location.search, { ignoreQueryPrefix: true })
    const submit = async () => {
        if(!pass){
            Alert.error('Password Required')
            return
        } 
        if(!!pass && pass !== conf){
            Alert.error('Passwords do not match')
            return
        }
        try{
           let result =  await UserManager.changePassword(pass, conf, headers)
           setReset(true)
           
           if(_.isString(result)){
            Alert.success(result)
            
           } else {
               
               Alert.success('Password Reset Successfull')
           }
           goHome()
            
        } catch(e) {
            Alert.error(e)
        }
    }

    const goHome = () => {
        history.replace('/auth/login')
    }

    const renderLoginButton = () => {
      

        if(reset){
            return(
                <SettingsContext.Consumer>
                  {({colors}) => {
                    return(
                        
                        <SingleButton
                            style={{
                            alignSelf: 'center',
                            borderRadius: 5, 
                            marginTop: 20,
                            width: 400,
                            backgroundColor: colors?.primary, 
                            height: 60,
                            }}
                            onPress={goHome}
                            title={"Home" }
                        />
                    
                    )
                  }}
                </SettingsContext.Consumer>
            )
        } else{
            return(
                <SettingsContext.Consumer>
                  {({colors}) => {
                    return(
                      <SingleButton
                        style={{
                          alignSelf: 'center',
                          borderRadius: 5, 
                          marginTop: 20,
                          width: 400,
                          backgroundColor: colors?.primary, 
                          height: 60,
                        }}
                        onPress={submit}
                        title={"Reset" }
                    />
                    )
                  }}
                </SettingsContext.Consumer>
            )
       
        }
    }
      
    return(
        <SettingsContext.Consumer>
            {({colors}) => {
                return(
                    <LinearGradient 
                        style={{position: 'absolute', bottom: 0, top:0, right: 0, left: 0, height: hpCss(100), width: wpCss(100)}}
                        colors={[colors?.plight, colors?.pdark]}>
                         
                        
                        <View style={[surface.paper(colors, wp), {paddingVertical: 30}]}>
                            <Section>
                                <Logo hideWords />
                            <IntroTitle style={text.contrast(colors)}>
                                    Reset Password
                                </IntroTitle>
                                <Slide in={true} direction={'left'} timeout={500}>
                                <div>
                                    <Input 
                                            placeholder={'Password'}
                                            secureTextEntry
                                            placeholderTextColor={colors?.primary}
                                            onChangeText={setPass}
                                        />
                                </div>
                            </Slide>
                            <Slide in={true} direction={'left'} timeout={300}>
                                <div>
                                        <Input 
                                                placeholder={'Password Confirmation'}
                                                secureTextEntry
                                                onSubmitEditing={submit}
                                                placeholderTextColor={colors?.primary}
                                                onChangeText={setConf}
                                            />
                                    </div>
                            </Slide>
                            <Slide in={true} direction={'left'} timeout={400}>
                                <div>
                                    {renderLoginButton()}
                                </div>
                            </Slide>
                            
                            </Section>
                            </View>
                        
                            
                        
                    </LinearGradient>
                    )
            }}
        </SettingsContext.Consumer>
    )
    
}
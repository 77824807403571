import React from 'react';
import { 
    TextInput,
 } from 'react-native';
import {  useSettings } from '../Managers/Settings';
import { text, searchbar } from '../theme';
import _ from 'lodash';


export const Input = React.forwardRef(({ style, inputProps, hasError, onTextChange, ...rest }, ref) => {

    const {colors, appTheme} = useSettings()

    const _onTextChange = (txt) => onTextChange && onTextChange(txt)

    const focus = () => ref?.focus && ref.focus()

    const blur = () => ref?.blur && ref.blur()

    const containerStyle = style ? style : {}

    return(
        <TextInput
            className={"input"}
            keyboardAppearance={appTheme}
            placeholderTextColor={colors?.text}
            underlineColorAndroid="transparent"
            ref={ref}
            autoCorrect={false}
            autoCapitalize="none"
            onChangeText={_onTextChange}
            style={ {
                height: 50, 
                ...text.base(colors),
                    outline: 'none',
                    ...inputProps, 
                    ...searchbar.contactPage(colors), 
                    maxWidth: 600,
                    ...containerStyle,
                    ...ErrorConfig(hasError) 
            }}
            {...rest}
        />
    )
    
 })

 const ErrorConfig = (hasError) => {
     if(hasError){
         return {
            borderWidth: 2,
            borderStyle: 'dashed',
            borderColor: 'red',
         }
     } 
     return {}


 }
import React from 'react'
import styled from 'styled-components'
import { useSettings } from '../Managers/Settings'
import Button, { ButtonProps } from './Button'

import Text from './text'


const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
const Container = styled.div`
    position: relative;
    background-color: ${props => props.colors.card};
    box-shadow: 0px 0px 30px rgba( 0, 0, 0, 0.2);
    width: 450px;
    height: 530px;
    padding: 1em;
    margin: 1em;
    border-radius: 5px;
    max-width: 90vw;
    @media min-width(950px){
        flex-shrink: 0;
    }
`




const T1 = styled(Text)`
    font-size: 1.3em;
    font-weight: 600;
`
const T2 = styled(Text)`
    font-size: 1em;
    font-weight: 400;
`

type CHP = {
    title: string,
    subtitle: string,
    buttonProps: ButtonProps,
    buttons: any // rendered constant
}

export const CardHeader = (props: CHP) => {
    const {title, subtitle, buttonProps, buttons} = props
    return(
        <Row>
            <Col>
                <T1>{title}</T1>
                <T2>{subtitle}</T2>
            </Col>
                {buttonProps && <Button {...buttonProps} />}
                {!buttonProps && buttons}
        </Row>
    )
} 


export function Card({className, children, style}){

    const {colors} = useSettings()
    return(
        <Container colors={colors} className={className} style={style}>
            {children}
        </Container>
    )
}

export const WideCard = styled(Card)`
    height: 530px;
    width: 900px
`

export const SmallCard = styled(Card)`
    height: auto;
    min-height: 150px;
    ${props => props.horizontal ? "width: auto" : ""};
    min-width: min(450px, 80vw);
`

export const FullCard = styled(WideCard)`
    height: 100%;
    width: 100%;
    margin: 2em auto;
    max-width: 95vw;
`

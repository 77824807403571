import React from 'react';
import { 
    View,
 } from 'react-native';
import { Slide} from '@material-ui/core';
import _ from 'lodash';
import PersonOutline from '@material-ui/icons/PersonOutline'
import { SettingsContext } from '../Managers/Settings';
import { text, containers,  } from '../theme';
import {   BottomButtons, IntroSection as Section, IntroContainer as Container, IntroTitle, Input} from '../Components/exports';

 export const Name = React.memo(props => {
    
    const { 
        onTextChange,
        onNext, 
        first, 
        last,
        onBack,
        activated,
        direction,
        getTimeout,
    } = props


    return(
        <SettingsContext.Consumer>
            {({colors}) => {
                 return(
                    <Container>
                        <Section  style={{flex:1, ...containers.centerColumn, ...containers.padding}}>
                            <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
                                <div>
                                      <IntroTitle>What's your full name?</IntroTitle>
                                 </div>
                            </Slide>
                            <Slide in={activated} direction={direction} timeout={getTimeout.middle()}>
                                <div style={{...containers.centerRow, width: '100%'}}>
                                        <Input 
                                            onTextChange={onTextChange}
                                            onSubmitEditing={onNext}
                                            placeholder={'First and Last name'}
                                            style={{marginTop: 20}}
                                            icon={<PersonOutline style={text.base(colors)}/>}
                                        />
                                </div>
                            </Slide>
              
                        </Section>
                        <Section >
                            <Slide in={activated} direction={direction} timeout={getTimeout.center()}>
                                <div>
                                <View  style={containers.centerRow} >
                                    {_.map(first, (f,i) => {
                                            return(
                                                <Slide direction={'down'} key={String(i + f)} in={true} >
                                                    <div>
                                                        <IntroTitle>{f}</IntroTitle>
                                                    </div>                                            
                                                </Slide>
                                            )
                                        }
                                )}
                                </View>
                                </div>
                            </Slide>
                            <Slide in={activated} direction={direction} timeout={getTimeout.center()}>
                                <div>
                                    <View  style={containers.centerRow} >
                                        {_.map(last, (f,i) => {
                                            return(
                                                <Slide direction={'up'} key={String(i + f)} in={true} >
                                                    <div>
                                                        <IntroTitle>{f}</IntroTitle>
                                                    </div>                                            
                                                </Slide>
                                            )
                                        }
                                    )}
                                </View>
                            </div>
                            </Slide>
                        </Section>
                        <BottomButtons 
                            onBack={onBack}
                            onNext={onNext}
                        />
                    </Container>
                )
            }}
        </SettingsContext.Consumer>
    )
 })


import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs';
import { uniqBy, filter, first, map, findIndex, } from 'lodash'
import { Table } from './Table';
import { ContactAttendenceCell } from './ContactAttendenceCell';
import { Checkbox } from '@material-ui/core';
import { useSettings } from '../Managers/Settings';
import { useContacts } from '../Managers/Contacts';
import { GradePicker } from './GradePicker';
import { useAlert } from '../Managers/Alert';
import { ADVENTIST } from '../Constants';
var calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar);


  
export const ContactsImportTable = ({ series_id, contacts, title, onGradeChange, onContactSelected, onAdventistChange} ) => {
  const {colors} = useSettings()
  const ContactManager = useContacts()
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const Alert = useAlert()

  const generateSDA = (member) => {
    return `${member ? "" : "Not "}${ADVENTIST}`
  }

  useEffect(() => {
    // why are we casting a boolean to a upper cased string?
    // glad you asked....
    // Table component doens't know how to display a boolean....
    // ......
    // .....🙄
    const _data = map(contacts, c => ({
      ...c, 
      name: `${c?.first_name} ${c.last_name}`, 
      address1: c?.address?.address1,
      address2: c?.address?.address2,
      city: c?.address?.city,
      state: c?.address?.state,
      zip: c?.address?.zip,
      country: c?.address?.country,
      displayMember:  generateSDA(c?.member)
      })
    )
    setData(_data)
  }, [contacts])


  const onViewColumnsChange = (name) => {
    // find the column by name and toggle its visibility
    let _columns = [...columns]
    let index = findIndex(_columns, c => c.name === name)
    if(index < 0){
      Alert.error("Not Found")
      return
    } 

    _columns[index].options.display = !_columns[index].options.display
    setColumns(_columns)
  }



  const onClick = async (contact, meta) => {
      if(meta?.colIndex === 10){
        const value = !contact?.member
        try{
          await ContactManager.setSDA([contact.id], value)
          // update local list
          let all = data
          if(!!all?.[meta?.dataIndex]){
            all[meta.dataIndex].member = value
            all[meta.dataIndex].displayMember = generateSDA(value)
            setData([...all])
          }
          
        } catch(e) {
          Alert.error(e)
        }
        

      } else if(meta?.colIndex === 9) {   
        // archive selected.  
        // probably not going to use
      } else if(meta?.colIndex === 0 || meta?.colIndex === 17) {

      } else {
        onContactSelected && onContactSelected(contact)
      }
      
    }



    const onRowsDelete = async  (rowsDeleted, data, newTableData) => {
      let actionables = map(rowsDeleted?.data, row => contacts[row.dataIndex]?.id)

        try{
          // await ContactManager.setContactsArchive(actionables, !archived)
          return true
        } catch(e) {
          Alert.error(e)
          return false
        }
      
    }

    const _onGradeChange = (grade, tableMeta) => {
      onGradeChange(grade, tableMeta.rowIndex)
    }

    const _onAdventistChange = (change, tableMeta) =>  {
      onAdventistChange(change.target.value, tableMeta.rowIndex)
    }

    useEffect(() => {

      if(columns.length !== 0 || contacts?.length === 0 ){
        return
      }
      let _columns = [

        {
          name: "grade",
          label: "Grade",
          options: {
            filter: true,
            display: true,
            customBodyRender: (grade, tableMeta, updateValue) => {
              // NOTE
              // rowData[1] is the id field
              // if the id is moved below,
              // its index will need to change also
              const onChange = (_grade) => _onGradeChange(_grade, tableMeta)
              return (
                    <GradePicker
                        onSelect={onChange} 
                        inlist
                        person={{
                            grade,
                            sync: true,
                            id: tableMeta.rowData[1]
                        }}
                    />
              );
            }
          }
        },
        {
            name: "id",
            options: {
              filter: false,
              display: false
            }
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              display: false,
              hint: "You can access all properties from the column select on the top right of this table.",
            }
          },
        {
          name: "first_name",
          label: "First Name",
          options: {
            filter: false,
            display: true
          }
        },
        {
          name: "last_name",
          label: "Last Name",
          options: {
            filter: false,
            display: true
          }
        },
        {
          name: "email",
          label: "Email",
          options: {
            filter: false,
            display: true
          }
        },
   
   
        {
          name: "created_at",
          label: "Created",
          options: {
            filter: false,
            display: true,
            customBodyRender: (created_at, tableMeta, updateValue) => {
              return (
                dayjs(created_at).format("MM/DD/YYYY")
              );
            }
          }
        },
        {
            name: "gender",
            label: "Gender",
            options: {
              filter: true,
              display: false,
              customBodyRender: (gender, tableMeta, updateValue) => {
                  return gender || "Unknown"    
              }
            }
          },   
          {
            name: "source",
            label: "Source",
            options: {
              filter: true,
              display: false,
              customBodyRender: (source, tableMeta, updateValue) => {
                  return source || "Unknown"    
              }
            }
          },
          {
            name: "displayMember",
            label: "Adventist",
            options: {
              filter: true,
              sort: true,
              display: true,
              customBodyRender: (member, tableMeta, updateValue) => {
                const onChange = (value) => _onAdventistChange(value, tableMeta)
                return (
                  <Checkbox checked={member === ADVENTIST} style={{color: colors.accent}} onChange={onChange}/> 
                );
              }
            }
          },
        {
            name: "attendance",
            label: "Attendance",
            options: {
              filter: false,
              sort: false,
              display: series_id ? true : "excluded",
              customBodyRender: (attendance) => {
                  if(!attendance){ return <></> }
                  const filtered = first(uniqBy(filter(attendance, series => series?.id === series_id), 'id'))
                  if (!filtered){ return <></> }
                  return(<ContactAttendenceCell attendance={filtered}/>)
              }
            }
          },
          {
            name: "address1",
            label: "Address",
            options: {
              filter: false,
              sort: true,
              display: false,
            }
          },
          {
            name: "address2",
            label: "Address2",
            options: {
              filter: false,
              sort: true,
              display: false
            }
          },
          {
            name: "city",
            label: "City",
            options: {
              filter: false,
              sort: true,
              display: false
            }
          },
          {
            name: "state",
            label: "State",
            options: {
              filter: true,
              sort: true,
              display: false
            }
          },
          {
            name: "zip",
            label: "Zip/Postal Code",
            options: {
              filter: true,
              sort: true,
              display: true
            }
          },
        //   {
        //     name: "owner.user",
        //     label: "Assigned To",
        //     options: {
        //       filter: true,
        //       sort: true,
        //       display: true, 
        //       customBodyRender: (user, meta) => {
        //         return(<OwnerAvatar owner={user} onOpen={onOpenOnwerAssign} contact_id={meta.rowData[1]} />)
        //       }
        //     }
        //   }
      ];
      setColumns(_columns)
      
    }, [contacts])


   

    return(
          <Table 
            title={title}
            columns={columns}
            data={data}
            onRowsDelete={onRowsDelete}
            onClick={onClick}
            onViewColumnsChange={onViewColumnsChange}
            options={{
              download: false
            }}
        />
    )
}


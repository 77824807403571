import React from 'react';
import { SettingsContext } from '../Managers/Settings';
import { 
    Text,
    View,

 } from 'react-native';
import { Chip, Grow, IconButton} from '@material-ui/core';
import { Input } from './Input';
import _ from 'lodash';
import { containers, text } from '../theme';

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { CardHeader } from './card';
import { AlertContext } from '../Managers/Alert';

export class Zips extends React.Component {
    static contextType = AlertContext;
    state={
        ...this.props,
        txt: '',
    }



    componentDidUpdate(prev){
        if(!_.isEqual(prev, this.props)){
            const {defaults, editing} = this.props
            let zips = this.props.zips || this.state.zips
            const {errorKey} = this.props
            const p = _.uniq(zips)
            const txt = _.join(zips, ' ')
            this.setState({editing, zips: p, txt, defaults, errorKey})
        }
    }

    isValid = (txt) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(txt) || /^(?:[ABCEGHJ-NPRSTVXY]\d[A-Z][ -]?\d[A-Z]\d)$/i.test(txt)
    updateTxt = (txt) => {
        let splits = _.split(_.replace(txt, /,\s*|\s+,/g,' '), ' ')
        const last = _.last(splits)
        let zips = _.uniq(splits)
        if(!this.isValid(last)){
            // this.props.onChange && this.props.onChange(this.state.zips)
        } else {
            this.props.onChange && this.props.onChange(zips)
        }
        this.setState({txt, zips})
        
    }


    getAddNumber = () => {
        return(
                <View style={containers.centerRow}>
                    <IconButton 
                        color={'primary'}                        
                        onClick={this._addZip}    
                    >
                         <AddRoundedIcon />
                    </IconButton>
                 </View>
        )
    }
     hasDuplicates = (a) => {
        return _.uniq(a).length !== a.length; 
    }

    _addZip = () => {
        const {txt } = this.state
        let splits = _.split(_.replace(txt, /,\s*|\s+,/g,' '), ' ')
        let zs = _.uniq(splits)
        
        let removed = []
        zs = _.filter(zs, z => {
             if(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(z) || /^(?:[ABCEGHJ-NPRSTVXY]\d[A-Z][ -]?\d[A-Z]\d)$/i.test(z)){
                return true
            } else {
                removed.push(z)
                return false
            }
        })
    
        if(txt === ''){
            this.context.default('You\'ll need to type something')
            return 
        }
        
        const tx = _.join(zs, ' ')
        this.props.onChange && this.props.onChange(zs)
        this.setState({zips: zs, txt: tx})
        if( this.hasDuplicates(splits)){
            this.context.warning('Some of the zip/postal codes were duplicates and were removed.')
        }
        if(removed.length > 0){
            let arr =  _.map(removed, (m,i) => i + 1 === removed.length ? m : m + ', ')
            let str2 = _.join(arr, ' ')
            let str = 'Removed Invalid Zip/Postal Codes: ' + str2
            this.context.error(str)
        }
        return zs
    }

    _deleteZip = (zip) => {
        this.context.default(`${zip} Removed`)
        let zips = _.filter(this.state.zips, z => z !== zip)
        // console.log(zips)
        
        let txt = _.replace(this.state.txt, zip, '' )

        this.setState({zips, txt})
        this.props.onChange && this.props.onChange(zips)
    }

    renderInputs = () => {

        const { zips, editing, txt, editable, errorKey } = this.state
        if(!editing){
            return <></>
        }

        if(!editable){
            return <></>
        }
        return(
                <View style={{width: '100%'}}>
                    <Input 
                        style={{width: 'auto'}} 
                        blurOnSubmit={true}
                        value={txt}
                        hasError={errorKey === 'zips'}
                        placeholder={'i.e 34203 / A1A-1A1' }
                        onChangeText={this.updateTxt}
                        onSubmitEditing={this._addZip}
                    />
                </View>
            
        )
    }
    

    renderText = () => {
        const { zips, editing, editable} = this.state
   
            return(
                <>
                    <View style={containers.leftRowWrap}>
                    {_.map(zips, (z, index) => {
                        return (
        
                        <Zip 
                            zip={z}
                            editing={editing}
                            deleteZip={this._deleteZip}
                            key={'zip' + z}
                            index={index}
                        />
                        
                        )
                    })}
                
                    </View> 
                    
                </> 
        )
    }
    reset = () => {
        // console.log(this.state)
        this.setState({zips: _.cloneDeep(this.state.defaults)})
    }


    render(){
        const { zips, editing, editable, displayOnly } = this.state
        
        return(
            <SettingsContext>
            {({colors}) => (
            <View style={[containers.leftColumn, {marginBottom: 20, width: '100%'}]}>
                <div style={{...containers.betweenCenterRow, alignSelf: 'stretch'}}>
                    
            
                    <CardHeader title={'Zip/Postal Codes'} />    
                {!editable && !displayOnly && <IconButton onClick={this.reset}>
                    <Text style={text.contrast(colors)}>Reset</Text>
                </IconButton>
                }
                </div>
                {this.renderInputs()}
                {this.renderText()}
            </View>  
            )}
            </SettingsContext>            
           
        )
    }
}


const Zip = props => {

    const {editing, deleteZip, index, zip} = props
    const valid = !!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) || !!/^(?:[ABCEGHJ-NPRSTVXY]\d[A-Z][ -]?\d[A-Z]\d)$/i.test(zip)
    return(
        <Grow 
            style={{margin: 2}} 
            in={true} 
            timeout={_.random(0, 400)} 
            key={'zip-' + zip}>
            <Chip 
                label={zip}
                color={valid ? 'primary' : 'secondary'}
                key={zip + index} 
                onDelete={editing ? () => deleteZip(zip) : null}
            />
        </Grow>
    )
}
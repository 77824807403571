import React , {useContext} from 'react'
import AES from 'crypto-js/aes';
import Crypto from 'crypto-js';
import _ from 'lodash';
import AsyncStorage from '@react-native-community/async-storage';
import { 
    NETWORK_TABLE,
    USER_DATA_TABLE, 
    SETTINGS_TABLE, 
    UNSYNCED_ITEMS, 
    DATABASE_CACHE 
} from '../Constants';


const StorageContext = React.createContext()
export const useStorage = () => useContext(StorageContext)
export class StorageManagement{
    init = async () => {
        // console.log('storage lives')
        return true
    }
    ekey = 'asdfjkl';
    decrypt = (item) => {
        if(!item){ return }
        try{
            const bytes = AES.decrypt(item, this.ekey)
            const raw = bytes.toString(Crypto.enc.Utf8)
            const revived = JSON.parse(raw)
            return revived
        } catch(e) {
            // console.log(e)
        }
        
    }
     
    encrypt = (item) => {
        let str = JSON.stringify(item)
        const encrypted = AES.encrypt(str, this.ekey).toString()
        return encrypted
    }



    getEncryptedItems = (table_name) => {
        return new Promise((resolve, reject) => {
            this.get(table_name)
                .then(encrypted_results => { 
                    let results = _.map(encrypted_results, result => this.decrypt(result))
                    resolve(results)
                })
                .catch(e => {
                    reject(e)
                })
            })
        } 

    getEncryptedTable = (table_name) => {
        return new Promise((resolve, reject) => {
            this.get(table_name)
            .then(encrypted_table =>{ 
                let results = this.decrypt(encrypted_table)
                resolve(results)
            })
            .catch(e => {
                reject(e)
            })
        })
    } 
   
    setEncryptedItems = async (items, table_name) => {
        try{
            let local_items = await Storage.get(table_name) 
            if(!local_items){ local_items = {} }
    
            _.forEach(items, item => {
            let id = item.id
            local_items[id] = Storage.encrypt(item)
            })
            
            let success = await Storage.set(local_items, table_name)
            return success   
        } catch(e) {
            // console.log(e)
        }
    }

    setEncryptedTable = async (items, table_name) => {
        let encrypted_items = this.encrypt(items)
        return this.set(encrypted_items, table_name)
    }
   
    get = (table_name) => {
        return new Promise((resolve, reject) => {
            try{
            AsyncStorage && AsyncStorage.getItem(table_name, (error, result) => {
                if (error) {  reject(error);  }
                result = JSON.parse(result)
                resolve(result)          
            })
            } catch (e) {
                reject(e)
            }
        })  
    }
   set = (items, table_name) => { 
    //    console.log('setting Storage')
        return new Promise((resolve, reject) => {     
        let value = JSON.stringify(items)     
        AsyncStorage && AsyncStorage.setItem(table_name, value, (e) => {
            if(e) { reject(e)
            } else { resolve() }
        })
    })
   }



     clearTable = async (table) => {
         try{
             table && AsyncStorage && AsyncStorage.removeItem(table)
             return true
         } catch(e){
             return Promise.reject(e)
         }
     }

     clear = () => {
                AsyncStorage.multiRemove([
                USER_DATA_TABLE,
                SETTINGS_TABLE,
                UNSYNCED_ITEMS,
                DATABASE_CACHE,
                NETWORK_TABLE
            ])
            

            this.get(SETTINGS_TABLE)
            .then(data => {
                // console.log('\n\nSETTINGS AFTER DELETE', data)
            })
    } 
 }

 const Storage = new StorageManagement()
 export const StorageProvider = ({children}) => {


    return(
        <StorageContext.Provider value={{...Storage}}>
            {children}
        </StorageContext.Provider>
    )
 }
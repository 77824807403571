import React, {useState, useEffect, useContext, createContext} from 'react'

import { SOCKET_BASE } from '../Constants';
import { forEach, get, keys } from 'lodash';
import { useNetwork } from './Database';



const SocketContext = createContext()

export const useSocket = () => useContext(SocketContext)

export const SocketProvider = ({children}) => {
    const [socket, setSocket] = useState()
    const [count, setCount] = useState(0)
    const [listeners, setListerners]  = useState({})
    const {valid, header} = useNetwork()
    const orgId = '' // MARK this should be implemented

    useEffect(() => {
        shouldGetSocket()
    }, [header, valid])

    const shouldGetSocket = (force=false) => {
        // console.log(this.header, this.orgId)
        if(!valid || !!force){
            kill()
        } else if( !!valid && !!header?.uid  && !socket){
            setup()
        } else {
        }
    }

    
    const addListener = (key, callback) => {  
        let _list = listeners
        _list[key] = callback
        setListerners({..._list})
    }

    const removeListener = (key) => {
        let _list = listeners
        try{
            delete _list[key]
        } catch(e) {

        }
        setListerners({..._list})
    }

    const kill = () => {

        socket?.close && socket.close()
        setSocket(null)
    }

    const setup = async () => {
        // console.log('SETTING UP SOCKET')
    
        let _socket = new WebSocket(
            SOCKET_BASE,
            [],
            { headers: header }
        )

        _socket.onmessage  = onMessage
        _socket.onerror = onError
        _socket.onclose = onClose
        setSocket({..._socket})
    }

    const onError = console.error
    
    const onClose = () => setCount(0)
    
    const onMessage = (details) => {
        if(count === 0){
            let identifier = JSON.stringify( {
                channel: 'DeviceChannel',
                orgId: orgId,
                kind: 'all'
            })
            socket.send(JSON.stringify({
                command: "subscribe",
                identifier
            }))
        }
        setCount(count + 1 )
        
        let data = get(details,'data')
        data = JSON.parse(data)
        let type = get(data, 'type')
        if(type === 'ping'){
            // console.log('SOCKET ping')
        } else {
            extractData(details)
        }
        

    }

    const extractData = (message) => {
        let data = get(message, 'data')
        data = JSON.parse(data)
        data = get(data, 'message')
        if(data) {
            let updatePaths = keys(data)
            forEach(updatePaths, key => {
                if(listeners[key]){
                    // console.log('SOCKET Data for ', key, ' found')
                    listeners[key](data[key])
                } else {
                    // console.log('SOCKET key of ', key, ' found but no callback registered')
                }                
            })
        }
    }


    return(
        <SocketContext.Provider value={{
            addListener,
            removeListener
        }}>
        {children}
        </SocketContext.Provider>
    )
}

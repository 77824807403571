import React from 'react';
import { View } from 'react-native';
import HistoryIcon from '@material-ui/icons/History';
import { Fade, IconButton } from '@material-ui/core';
import _ from 'lodash';



export class Undo extends React.PureComponent{

    constructor(props){
        super(props)
        this.state={
            history: [],
            item: null,
        }
        // console.log('reconstructing ')
    }
    
    
    componentDidUpdate(prev){
        
        if(!_.isEqual(prev.item, this.props.item)){
            
            const { history, item } = this.state
            if(history.length === 0 && !this.props.item){
                return
            } else if(history.length === 0 && !item && !!this.props.item){
                this.setState({item: this.props.item})
            } else{
                this.setState({history: [...history, prev.item], item: prev.item})
            }
        }
    }

    changed = () => this.state.history.length > 0
    onUndo = () => {
        const { history } = this.state
        if(history.length > 0){
            const last = history.pop()
            this.props.onUndo &&  this.props.onUndo(last)
            this.setState({history})
        }
    }
    render(){
        // console.log('history', this.state.history)
        return(
            <Fade in={this.changed()}>
                <View style={{position: 'absolute', top:0, right: 0, transform: [{translateY: -50}]}}>
                    <IconButton 
                        aria-label="edit" 
                        style={{marginTop: 5, transition: 'all .2s ease'}} 
                        onClick={this.onUndo}
                        color={'default'}
                        >
                        <HistoryIcon />
                    </IconButton>
                </View>
            </Fade>
        )
    }
}

// export const Undo = props => {
//     const changed = () => {
//         if(props.changed){
//             return props.changed()
//         } else {
//             return false
//         }
//     }
//     const onUndo = () => {
//         props.onUndo && props.onUndo()
//     }
// return(
//     <Fade in={changed()}>
//     <View style={{position: 'absolute', top:0, right: 0, transform: [{translateY: -50}]}}>
//       <IconButton 
//             aria-label="edit" 
//             style={{marginTop: 5, transition: 'all .2s ease'}} 
//             onClick={onUndo}
//             color={'default'}
//             >
//             <HistoryIcon />
//         </IconButton>
//       </View>
//     </Fade>
// )
// }
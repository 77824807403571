import React, {useContext, useEffect} from 'react';
import { 
    Animated,
    View,
    Text
 } from 'react-native';
import { SettingsContext, useSettings } from '../Managers/Settings';
import { containers, text } from '../theme';
import _ from 'lodash';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { wp } from '../Fork/react-native-responsive-screen';
import { addApostraphe, darken, lighten } from '../Utilities';
import styled from 'styled-components';


 export const ContactAttendenceCell = props => {
    const { attendance , contact} = props
    return(
        <Mini 
            contact={contact}
            series={attendance}
        />
    )
 }
 const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));


 const Mini = props => {
    const { series, contact } = props
    let sessions = [];
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const width = Math.min(wp(100) / (series?.sessions?.length + 10), 10)
    // console.log(wp(100) / (series?.sessions?.length + 10))
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    for(let i = 0; i<series.num_events; i++){
        sessions.push(
            <MiniCell 
                key={'sesssmalll' + i} 
                title={(i+1)}
                progress={0}
                width={width}

            />
            )
    }

    _.forEach(series.sessions, sess => {
            sessions[sess.event_index] = <MiniCell 
                key={'sesssmalll' + sess.event_index} 
                progress={sess.progress}
                width={width}
            />

    })
  
    const open = Boolean(anchorEl);
    const id =  'mouse-over-data';
    const { colors } = useSettings();
    return(
        <>
        {/* <IconButton
            aria-owns={open ? id : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        > */}
            <View 
                aria-owns={open ? id : undefined  }
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            
            style={{
                ...containers.leftBottomRow,
                flexWrap: 'wrap'
            }}>
                {sessions}
            </View>
        {/* </IconButton> */}
        <Popover
            id={id}
            className={classes.popover}
            classes={{
                paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            >
            <Text style={[text.bold, text.small, text.contrast(colors)]}>{`${addApostraphe(contact?.first_name)} Attendance`}</Text>
            <ContactAttendenceForSeries series={series}/>
        </Popover>
    </>
    )
 
 }
 

 const MiniCell = props => {
    const { progress, width} = props
    const { colors } = useContext(SettingsContext)
    const height = 20;
    return(
        <View 
            style={{
                height: (progress > 0 ? progress * height : width), 
                maxHeight: height,
                width,
                marginHorizontal: 1,
                backgroundColor: (progress  > 0 ? colors?.accent : colors?.transparent),
                borderColor: colors?.accent,
                borderWidth: 1,
                borderRadius: width/2,
                marginVertical: 2

            }}
        />
    )
 }

 const Container = styled.div`
    display: flex;
    max-width: 100%;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap;

    @media (max-width: 600px){
        flex-wrap: wrap;
    }
 `

 export const ContactAttendenceForSeries = React.memo(props => {
    const { series } = props
    if(!series?.num_events){
        return
    }
    let sessions = []

    for(let i = 0; i < series.num_events; i++){
        sessions.push(
            <Cell 
                key={i + 'jefiwo'}
                title={(i+1)}
                index={i}
                num_events={series.num_events}
                progress={0}
            />
            )
    }

    _.forEach(series.sessions, sess => {
        sessions[sess.event_index] = <Cell 
            key={sess.event_index}
            title={(sess.event_index + 1)}
            index={sess.event_index}
            progress={sess.progress}
        />
    })

    return(
        
        <Container >
            {sessions}
        </Container>
    )
 })


 const CellContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 20px;
    margin: 0.1vw;
    @media (max-width: 600px){
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }
 `

 const OnlyDesktop = styled.div`
    margin: 5px;
    @media (max-width: 700px){
        display: none;
    }
 `


 const Cell = React.memo(props => {
    const { index, title, progress: value} = props

    const defaultWidth = 20
    const _animated_height = value ? new Animated.Value(0) : new Animated.Value(1)
    const maxHeight = 100
    const scale = value ? new Animated.Value(0) : new Animated.Value(1)
    
    useEffect(() => {
        if(value === 0){
            return
        }
        Animated.timing(
            _animated_height,
            {
                toValue: maxHeight,
                duration: 500,
                delay: 50 * index,
            }
        ).start()
        Animated.timing(
            scale,
            {
                toValue: 1,
                duration: 500,
                delay: 50 * index,
            }
        ).start()
        

        
    })

    const margin = 5
    

    const { colors } = useContext(SettingsContext)
    return(
        <CellContainer>
            <OnlyDesktop>
                <View style={{
                    height: 100,
                    width: defaultWidth ,
                    borderRadius: defaultWidth / 2,
                    backgroundColor: darken(colors?.accent, 6) ,
                    ...containers.centerBottomColumn,
                    overflow: 'hidden'


                }}>
                <Animated.View 
                    style={{
                        height: _animated_height,
                        backgroundColor: (value  > 0 ? colors?.accent : colors?.text),
                        width: defaultWidth,
                        borderRadius: defaultWidth / 2,
                }}
                />
                </View>
            </OnlyDesktop>
            <Animated.View style={{
                transform: [{scale}],
                marginTop: margin, 
                ...containers.centerColumn,
                backgroundColor:  value > 0 ? lighten(colors?.accent, 4) : colors?.transparent,
                height: defaultWidth,
                width: defaultWidth,
                borderRadius: defaultWidth / 2,
                borderColor: colors?.accent,
                borderWidth: 2,

            }}>
                <Text style={[
                    text.center,
                    text.superTiny,
                    text.bold,
                    value > 0 ? text.accentContrast(colors) : text.base(colors)
                ]}>
                {title}
                </Text>
            </Animated.View>
        </CellContainer>
    )

})


import React, { useEffect, useState } from 'react';
import _, { filter, findIndex, keys, map, orderBy, upperFirst } from 'lodash';
import CreateContact from '../Components/CreateContact';
import { ContactManager, EventManager } from '../Managers/exports';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { RouteTransition } from '../Animations/RouteTransition';
import { Switch, useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { Contact } from './contact';
import { ContactsTable } from '../Components/ContactsTable'
import { OwnerDrawer } from '../Components/owner-popover';
import { useAlert } from '../Managers/Alert';
import { CardHeader, FullCard } from '../Components/card';
import { Add, BarChart, ImportContacts, ImportExport } from '@material-ui/icons';
import { containers } from '../theme';
import Button from '../Components/Button';
import { useSettings } from '../Managers/Settings';
import {Importer} from '../Components/importer'
import dayjs from 'dayjs';
import { AreaChart, ColumnChart, PieChart } from 'react-chartkick'
import {ButtonGroup, Collapse} from '@material-ui/core'
import 'chartkick/chart.js'
import { darken, lighten } from '../Utilities';
import { useOrgs } from '../Managers/Orgs';
import { useContacts } from '../Managers/Contacts';
import { useEvents } from '../Managers/Events'


const ChartType = {
  column: "column",
  area: "area",
  pie: "pie"
}


export const Contacts = ({archived}) =>{
    const ref = React.createRef()
    const [ selectedContact, setSelectedContact ] = React.useState()
    const [chartType, setChartType] = useState(ChartType.column)
    const [chartData, setChartData] = useState({label: "", data: []})
    const [reload, setReload] = useState(0)
    const [contactToAssignOwnerTo, setContactToAssignOnwerTo] = useState()
    const [ contacts, setContacts] = useState([])
    const [title, setTitle ] = useState("")
    const [org, setOrg] = useState()
    const [series, setSeries] = useState()
    const [ adding, setAdding] = React.useState(false)
    const history = useHistory()
    const location = useRouteMatch()
    const {org_id, series_id} = useParams()
    const all = useParams()
    const {url: currentRoute} = useRouteMatch()
    const {colors} = useSettings()
    const OrgsManager = useOrgs()
    const ContactManager = useContacts()
    const EventManager = useEvents()
    const Alert = useAlert()


  useEffect(() => {
    const call =  async () => {
      if(org_id && !series_id){
        

        let  _contacts = await ContactManager.getContactsForOrg(org_id, 0)
        if(archived){
          _contacts = filterArchived(_contacts)
        } else {
          _contacts = filterUnarchived(_contacts)
        }
        setContacts(_contacts)
        setTitle(`All Contacts`)
      } else if(org_id && series_id){
        let  _contacts = await ContactManager.getContactsForOrgAndSeries(org_id, series_id)
        if(archived){
          _contacts = filterArchived(_contacts)
        } else {
          _contacts = filterUnarchived(_contacts)
        }
        setContacts(_contacts)
      }
      
    }
    call()
    
  },[org_id, series_id, reload])

  useEffect(() => {
    let datum = {}
    const order = orderBy(contacts, ["created_at"], ['asc'])
    for(let contact of order) {
        let day = dayjs(contact.created_at).startOf('day').format('MM/DD/YYYY')
        if(!datum[day]){
          datum[day] = 1
        } else {
          datum[day] += 1
        }
    }

    if(keys(datum).length === 1 && contacts?.length > 1){
      // need to drill down the data
      datum = {}
      for(let contact of order) {
        let time = dayjs(contact.created_at).startOf('hour').format('MM/DD/YYYY hh:mm a')
        if(!datum[time]){
          datum[time] = 1
        } else {
          datum[time] += 1
        }
      }
    }
    setChartData(datum)
    


  }, [contacts])

  useEffect(() => {
    if(org_id && series_id){
      try{
        EventManager.getSingleSeries(org_id, series_id)
        .then(ser => {
          setSeries(ser)
          setTitle(ser?.name || "")
        })
        .catch(console.error)
      } catch(e) {
        Alert.error(e)
      }
    }
  }, [org_id, series_id])

  useEffect(() => {
      if(org_id){
          OrgsManager.getOrg(org_id)
          .then(setOrg)
          .catch(Alert.error)
      }
  }, [org_id])

  const filterArchived = (data) => {
      return filter(data, d => d.archived)
  }
  const filterUnarchived = (data) => {
    return filter(data, d => !d.archived)
  }


    const onSelect = (contact) => {
        setSelectedContact(contact)
        history.push(`${currentRoute}/contact/${contact.id}`)

    } 
    
    const onAdd = () => {
        history.push(`${currentRoute}/create`)
    }
    const onImport = () => {
      history.push(`${currentRoute}/import`)
    }

    const onCreate = (contact) => {
        setAdding(false)
    }

    const onBack = async () => {
        setSelectedContact(null)
        setAdding(false)       
        history.go(-1)
    }

    const onOpenOnwerAssign = (contact_id) => {
      setContactToAssignOnwerTo(contact_id)
    }
    const onCloseOnwerAssign = () => {
      setContactToAssignOnwerTo(null)
    }

    const onOwnerUpdated = (contact) => {
      let _contacts = contacts
      let index = findIndex(_contacts, c => c.id === contact?.id)
      if(index > -1){
        _contacts[index] = contact
        setContacts([..._contacts])
      } else {
        Alert.error("There was a problem refreshing the data.  Please refresh the your screen manually to see your changes.")
      }
    }


    const onArchiveToggle = async (ids, setSelectedRows) => {
      try{
        await ContactManager.setContactsArchive(ids, !archived)
        setReload(reload + 1)
        setSelectedRows([])
      } catch(e) {
        Alert.error(e)
      }
    }

    const ChartButtons = (
      <ButtonGroup variant="outlined" aria-label="outlined button group">
          {map(ChartType, type => {
            const onClick = () => setChartType(type)
            return <Button
              key={type} 
              title={upperFirst(type)} 
              fill={type === chartType}
              invert={type === chartType}
              color={colors.accent}
              onClick={onClick} />
          })}
      </ButtonGroup>

    )


    
    const id = location?.params?.id
      return(
        <Switch>

            <RouteTransition
                exact
                path={`${currentRoute}/import`}
                >
                <Importer org_id={org_id} series_id={series_id} />
            </RouteTransition>
            <RouteTransition 
              exact
              path={`${currentRoute}`}
              noFade={true}
            >
 

            <div style={{minWidth: '80vw'}}>
              <div style={{...containers.leftRow, marginBottom: '0.5em'}}>
                <Button 
                  invert
                  fill
                  title={"New Contact"}
                  icon={<Add />}
                  onClick={onAdd}/>
                {/* <Button 
                  invert
                  fill
                  title={"Import"}
                  icon={<ImportExport />}
                  onClick={onImport}/> */}
              </div>

              <FullCard>
                <CardHeader title={`${series?.name ? series.name + " Registrations" : title}`} 
                  buttons={ChartButtons}
                />
                <Collapse in={chartType === ChartType.column} timeout={1000}>
                  <ColumnChart 
                    data={chartData}
                    colors={[colors.accent, lighten(colors.accent, 4), darken(colors.accent, 4)]}
                  />
                </Collapse>
                <Collapse in={chartType === ChartType.pie} timeout={1000}>
                  <PieChart 
                    data={chartData}
                    colors={[colors.accent, lighten(colors.accent, 4), darken(colors.accent, 4)]}
                  />
                </Collapse>
                <Collapse in={chartType === ChartType.area} timeout={1000}>
                  <AreaChart 
                    data={chartData}
                    colors={[colors.accent, lighten(colors.accent, 4), darken(colors.accent, 4)]}
                  />
                </Collapse>
              </FullCard>
              <ContactsTable 
                  archived={archived}
                  onAdd={onAdd}
                  onArchiveToggle={onArchiveToggle}
                  onContactSelected={onSelect}
                  onOpenOnwerAssign={onOpenOnwerAssign}
                  series_id={series_id} 
                  title={archived ? "Archived Contacts" : title }
                  contacts={contacts}
                  org={org}/> 
                <OwnerDrawer 

                    onOwnerUpdated={onOwnerUpdated}
                    contact_id={contactToAssignOwnerTo}
                    org={org}
                    onClose={onCloseOnwerAssign}
                  />
              </div>
            </RouteTransition>
                  

            <RouteTransition 
              path={`${currentRoute}/create`}
            >
                <CreateContact 
                    onCreate={onCreate} 
                    onCancel={onBack}
                    org={org}
                    series={series}
                  />
              </RouteTransition>
            <RouteTransition 
              exact
              path={`${currentRoute}/contact/:contact_id`}
              noFade={true}
            >
              <Contact
                org={org}
              />
            </RouteTransition>
  
          </Switch>
      

      )

} 




import React, {useEffect, useState} from 'react';

import { map } from 'lodash';
import {useParams} from 'react-router-dom'
import { DecisionCardTable } from '../Components/DecisionCardTable';
import { TabbedPageView } from '../Components/TabbedPageView';
import { FullCard } from '../Components/card';
import { useNetwork } from '../Managers/Database';


export function DecisionCards(){
    const [buttons, setButtons] = useState([])
    const {org_id, series_id} = useParams()
    const Database = useNetwork()
    useEffect(() => {
        const call = async () => {
            let _surveys =  await Database.get(`/series/${series_id}/surveys`)
            const _buttons = map(_surveys, (survey, i) => {
                  return {
                      title: survey?.title, 
                      view: () => <DecisionCardTable org_id={org_id} series_id={series_id} survey={survey} />
                    }
            })
            setButtons(_buttons)
        }
        call()
    }, [org_id, series_id])



    return(
        <FullCard>
            <TabbedPageView  
                buttons={buttons}
            />
        </FullCard>
    )
}
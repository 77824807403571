import React, { useState } from 'react'
import {
  View,
  Text
} from 'react-native'
import { containers, surface, text} from '../theme'
import {  
  Gender, 
  Notes, 
  TwoButtonOption, 
  AddressInput,
  Input,
  SectionBar,
  GradePicker,
  Hint,
} from '../Components/exports'
import { wp, wpCss} from '../Fork/react-native-responsive-screen'
import _, { get, replace, set } from 'lodash'
import { useSettings } from '../Managers/Settings'
import { Slide } from '@material-ui/core'
import { formatPhone } from '../Utilities'
import { withRouter} from 'react-router-dom'
import { BackButton } from './BackButton'
import { motion } from 'framer-motion'
import { useAlert } from '../Managers/Alert'
import { useHistory, useRouteMatch} from 'react-router-dom';
import { useContacts } from '../Managers/Contacts'

 const defaultContact = {
  id: '',
  prefix: '',
  first_name: '',
  last_name: '',
  suffix:'',
  email: '',
  gender: 'Female',
  age: null,
  grade: 'A',
  events: [],
  owner: null,
  member: false,
  archived: false,
  address: {
    address1:'',
    address2:'',
    city: '',
    state: '',
    zip: '',
    prisonId: '',
    country: '',
},
phone: {number: '', kind: 'mobile'},
tags: [],
}



export default function CreateContact({
  onCreate,
  org,
  series,
  team,
  onCancel,
  force

}) {

  const history = useHistory()
  const Alert = useAlert()
  const {colors} = useSettings()
  const shouldPop = false//  this.props.navigation.getParam('shouldPop'),
  const [submitting, setSubmitting] = useState(false)
  const [contact, setContact] = useState({
                                      ...defaultContact,
                                      owner: { org, team }
                                    })
  const [name, setName] = useState('') // name will be broken up into first and last name 
  const [img, setImage]= useState('')
  const [phones, setPhones] = useState([])

  const [localID, setLocalID] = useState('')
  const [genderIndex, setGenderIndex] = useState(1)
  const [gender, setGender] = useState("")
  const [note, setNote] = useState('')
  const [pageSelected, setPageSelected] = useState(false)
  const {url: currentRoute} = useRouteMatch()
   const ContactManager = useContacts()
  

  const reset = () => {
    setSubmitting(false)
    setContact({
            ...defaultContact,
            owner: { 
              org,
              team,
            }
          })
     setName('') 
     setImage('')
     setPhones([])
     setLocalID('')
     setGenderIndex(1)
     setNote('')
     setPageSelected(false)
    
  }


  const select = (pageSelected = true) => setPageSelected(pageSelected)
  
  const deselect = () => select(false)


  const onNameChange = (_name) => {
    const _contact = {...contact}
    let [first_name, ...last_name] = _.map(_.split(_name, ' '), n => _.upperFirst(n))
    last_name = _.join(last_name, ' ')

    _contact.first_name = _.upperFirst(first_name)
    _contact.last_name = _.upperFirst(last_name)
    setContact({..._contact})
    setName(_name)
  }

  const onEmailChange = (value) => {
    const _contact = {...contact}
    _contact.email = value
    setContact({..._contact})
  }

  const onChangeText = (key, value) => {
    const _contact = {...contact}
    _contact[key] = value
    setContact({..._contact})
  }

  const onAddressChange = (address) => {
    const _contact = {...contact}
    _contact.address = address
    setContact({..._contact})
    
  }

  const submit = async () => {
 
    if(submitting){
      return 
    }
    let _contact = {...contact}

    setSubmitting(true)
    try{
      await validate()
      await _validAddress(_contact.address)
    } catch(e) {
        Alert.alert('Whoops', e)
        return
    } finally {
      setSubmitting(false)
    }

    if(get(_contact, 'phone.number') != ''){
      _contact.phones = [_contact.phone]
    }
    if(!_contact?.owner?.org){
        _contact.owner.org = org
    }
    if(series){
      _contact.series_id = series?.id
    }

    try{
      let newContact = await ContactManager.createContact(_contact)
        Alert.success(newContact?.first_name + ' Created')
        onCreate && onCreate(newContact)
        if(history.replace){
          const route = replace(currentRoute, '/create', '')
            history.replace(`${route}/contact/${newContact.id}`)
        }
        reset()
      
    } catch(e) {
      Alert.alert('Whoops',e)      
    } finally{
      setSubmitting(false)
    }
  }

  const validate = async  () => {
    if (!contact.first_name || contact.first_name === "" ) { return Promise.reject('First Name Required.') }
    if (!contact.last_name || contact.last_name === ""  ) { return Promise.reject('Last Name Required.') }
    if (!contact.gender || contact.gender === ""  ) { return Promise.reject('Gender Required.') }
    if (!contact.grade || contact.grade === ""  ) { return Promise.reject('Grade Required.') }
    const emailreg = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)
    if(!emailreg.test(contact?.email)){
      Alert.warning(`${contact?.email} is not a valid email. `)
    }
    return true
  }

  const updateTags = (tags) => {
    let _contact = {...contact}
    _contact.tags = tags
     setContact({..._contact})
  }

  const onPhoneChange = (text) => {
    let _contact = {...contact}
    text = formatPhone(text)
    set(_contact, 'phone.number', text)
    setContact({..._contact})
  }
  
  const _hasAddress = (address) => {
    const valid = _.keys(address)
    .map(key => address[key])
    .reduce((a, b) => !!a || !!b)
    return valid
  }

  const _validAddress = async (address) => {
      const {address1, city, state, zip} = address
      
      const hasAddress = !!address1 || !!city || !!state || !!zip
      if(!hasAddress){ return true }

      const valid = !!address1 && !!city && !!state && !!zip
      if(!valid){ return Promise.reject('You must provide Address 1, City, State and Zip Code')}
      return true    
  }
  const updateGender = (index) => {       
      setGender(!!index ? 'Male' : 'Female')
      setGenderIndex(!!index ? 1 : 0)
  }

  const updateGrade = (interest) => {
    let _contact = {...contact}
    _contact.grade = interest
    setContact({..._contact})
  }
  const back = () => {
    onCancel && onCancel()
  }

  const renderName = () => {
    let { first_name: first, last_name: last} = contact

      return(    
        <> 
          <View  style={containers.centerRow} >
            {_.map(first, (f,i) => {
                      return(
                          <Slide direction={'down'} key={String(i + f)} in={true} >
                              <div>
                                  <Text style={[text.bold, text.contrast(colors), text.xlarge]}>{f}</Text>
                              </div>                                            
                          </Slide>
                      )
                  }
          )}
          </View>
          <View  style={containers.centerRow} >
              {_.map(last, (f,i) => {
                  return(
                      <Slide direction={'up'} key={String(i + f)} in={true} >
                          <div>
                              <Text style={[text.bold, text.contrast(colors), text.xlarge]}>{f}</Text>
                          </div>                                            
                      </Slide>
                  )
              }
        )}
        </View>
      </>)

  }



  let {
        email,
        phone,
        tags,
        notes,
        owner
    } = contact


  return (
    <>
    <View style={containers.leftCenterRow}>
      <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            >
            <BackButton />
        </motion.div>
      <Hint>New Contact {(org?.name ? 'for ' + org?.name : '') + (!!series?.name ? ' - ' + series?.name : '')}</Hint>
    </View>
      
    <View 
        keyboardShouldPersistTaps={'handled'}
        contentContainerStyle={[styles.container(colors)]}
        style={{alginSelf: 'stretch', maxWidth: 900, ...surface.contactsTable(colors, wp)}}
        scrollEnabled={true}>
          
      
        {renderName()}
        <View style={{marginHorizontal: 10, alignSelf: 'stretch'}}>
          <GradePicker onSelect={updateGrade} person={contact}/>
            <View style={[containers.leftRowWrap, {alignSelf: 'stretch'}]}>
            <View style={{flex: 1, margin: 5, minWidth: 300}}>
              <SectionBar title={'Name and Email'} />
              <Input
                  placeholder="First and Last Name"
                  value={name}
                onChangeText={onNameChange}
                  
                  autoCorrect={false}
                  autoCapitalize="words"
              />
              <Input
                placeholder="Email (Optional)"
                value={email}
              onChangeText={onEmailChange}
                keyboardType="email-address"
                autoCorrect={false}
                autoCapitalize="none"
                />
              <Input
                placeholder="Phone Number (Optional)"
                value={phone.number}
                onChangeText={onPhoneChange}
                returnKeyType='done'
                // keyboardType="numeric"
                />
              </View>
              <View style={{flex: 1, margin: 5, minWidth: 300}}>
                <SectionBar title={'Address'} />
                <AddressInput onChange={onAddressChange} />
              </View>
          </View>


        </View>
        <Gender onChange={updateGender}/>
        

        <Notes
          placeholder={'Add a Note'}
          value={notes}
          onChangeText={val => onChangeText('note', val)}
          padding/>
        <View style={[styles.subContainer, {marginBottom: 40}]}>
            <TwoButtonOption
            leftAction={back}
            rightAction={submit}
            rightTitle={'Submit'}/> 
          
          
        </View>
      </View>
      </>
  )
}


const styles = {
    container: (colors) =>  {
      return{
        backgroundColor: colors?.background,
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: wpCss(100)
      }
      
    },
    outerContainer: (colors)  => {
      return{
        backgroundColor: colors?.danger,
        alignSelf: 'center',
        maxWidth: wpCss(100),
        
        
      }
    },
    subContainer: {
        paddingVertical: 10,
        
    }
  }




import React from 'react';
import {
    View,
} from 'react-native';
import {
    makeStyles
} from '@material-ui/core';

import { join, map, upperFirst, split } from 'lodash';
import { wp } from '../Fork/react-native-responsive-screen';
import { TwoButtonOption, SectionBar, AddressInput, Zips } from '../Components/exports';
// import {  OutreachTableToolbar } from '../Components/OutreachTables';
import { SettingsContext } from '../Managers/Settings';
import { containers, surface } from '../theme';

import { EntityFields } from './org-edit';
import ReactDropdown from 'react-dropdown';
import { CONFERENCES } from '../Constants';

import 'react-dropdown/style.css';
import './dropdown.css'

const useStyles = makeStyles((theme) => ({
    dropdown: {
        backgroundColor: 'red'
    
    }
    
  }));

export const AddOrg = props => {

    const classes = useStyles();
    
    let {org, zipsEditable, suborg, errorKey} = props
    if(!org){
        org = {
            contact_person: {},
            name: '',
            email: '',
            address: {},
            phone: '',
            religion: 'SDA',
            parent_id: null,
            zips: [],

            pastor:{
                
            }
        }
    
    }

    const onChange = async (_org) => {
        if(!!props?.onChange) {
            try{
                await props.onChange(_org)
            } catch(errorKey) {
                console.log(errorKey)
            }
            
        }
         
    }
    const onChangeAddress = (value) => {
        onChange({
            ...org,
            address: value
        })
    }

    const onChangeFields = (value) => {
        
        let {email, name, phone} = value
        name = upperFirst(name)
        onChange({
            ...org,
            email,
            name,
            phone
        })
    }
    const onChangeFieldPastor = (value) => {
        let {email, name, phone} = value
        let [first_name, ...last_name] = map(split(name, ' '), n => upperFirst(n))
        last_name = join(last_name, ' ')
        onChange({
            ...org,
            pastor:{
                email,
                first_name, 
                last_name,
                phone
            }
      
        })
    }
    const onChangeContactPerson = (value) => {
        let {email, name, phone} = value
        // you can't change the name :)
        // last_name = _.join(last_name, ' ')
        onChange({
            ...org,
            contact_person:{
                ...org.contact_person,
                email,
                phone
            }
      
        })
    }
    const onZipChange=(zips) =>{
        // console.log(zips)
        onChange({
            ...org,
            zips
        })
    }
 

    const onCancel = () => {
        props.onBack && props.onBack()
    };

    const submit = async () => {
        try{
            props.onSubmit && props.onSubmit(org)
        } catch(e) {
            
        }
        
    }


    const onConferenceChange = ({value}) => {
        onChange({
            ...org,
            conference: value
        })
    }

    let toolBarProps = {
        numSelected: 0,
        headerValue: props.title ||  "Add Organization"
    }

    if (!org) {
        return (<div />)
    }
    
    
    return (
        <SettingsContext.Consumer>
            {({colors}) => {
                return(
                    <View
                        className={classes.root}
                        keyboardShouldPersistTaps={'handled'}
                        style={{ alginSelf: 'stretch' }}
                        scrollEnabled={true}>
                    <>
                        <View style={surface.paper(colors, wp)}>
                            {/* <OutreachTableToolbar {...toolBarProps} /> */}
                            <SectionBar title={'Name'} />
                            <View style={[containers.leftRowWrap, { alignSelf: 'stretch' }]}>
                                {!!org ? <EntityFields
                                    entity={org}
                                    onChange={onChangeFields}
                                    email={org.email}
                                    name={org.name}
                                    phone={org.phone}
                                    nameErrors={['name']}
                                    phoneErrors={['phone']}
                                    emailErrors={['email']}
                                    errorKey={errorKey}
                                    entityType={'Org'}
                                />
                                    : null}
                                
                                { !suborg &&<SectionBar title={'Contact Person\'s Information'} raw/>}
                                 { !suborg && <EntityFields
                                    entityType={'Your'}
                                    entity={org.contact_person || {name: '', email: '', phone: ''}}
                                    onChange={onChangeContactPerson}
                                    emailDisabled
                                    email={org?.contact_person?.email ? org.contact_person.email : ''}
                                    nameDisabled
                                    name={org?.contact_person?.name ? org.contact_person.name : ''}
                                    nameTitle={'First and Last Name'}
                                    nameErrors={['contact_person.first_name','contact_person.last_name']}
                                    phoneErrors={['contact_person.phone']}
                                    emailErrors={['contact_person.email']}
                                    phone={org?.contact_person?.phone ? org.contact_person.phone : ''}

                                    errorKey={errorKey}
                                />}
                               { !suborg && <SectionBar title={'Pastor\'s Information'} raw/>}
                                {!suborg && <EntityFields
                                    entityType={'Pastor\'s'}
                                    entity={org.pastor}
                                    nameTitle={"Pastor's First and Last Name"}
                                    onChange={onChangeFieldPastor}
                                    email={ org?.pastor?.email ? org.pastor.email :  '' }
                                    name={org?.pastor?.name ? org.pastor.name :  '' }
                                    phone={ org?.pastor?.phone ? org.pastor.phone :  '' }
                                    nameErrors={['pastor.first_name','pastor.last_name']}
                                    phoneErrors={['pastor.phone']}
                                    emailErrors={['pastor.email']}
                                    errorKey={errorKey}
                                />}
                                <SectionBar title={'Conference'} />                    
                                <ReactDropdown
                                    className={'dropdown'}
                                    menuClassName={'menu'}
                                    controlClassName={errorKey === "conference" ? "control_error" : 'control'}
                                    arrowClassName={'arrow'}
                                    placeholderClassName={'placeholder'}
                                    options={Array.from(CONFERENCES).sort()}
                                    onChange={onConferenceChange}
                                    style={{backgroundColor: 'red'}}
                                    value={org?.conference}
                                    placeholder="Select an Conference" 
                                    
                                    />
                                <SectionBar title={'Address'} />
                                <AddressInput
                                    address={org?.address }
                                    errorKey={errorKey}
                                    // style={{ marginHorizontal: 20 }}
                                    onChange={onChangeAddress} />
                                <Zips   
                                    editable={zipsEditable}
                                    defaults={org?.immutableZips}
                                    errorKey={errorKey}
                                    editing={true}
                                    zips={org?.zips}
                                    onChange={onZipChange}
                                />

                            </View>
                            
                            {!props.hideSubmit && 
                                <TwoButtonOption
                                    leftAction={onCancel}
                                    rightAction={submit}
                                    rightTitle={'Submit'} />
                            }
                            
                        </View>
                    </>
                </View>
                )
            }}
   

        </SettingsContext.Consumer>
    )
}




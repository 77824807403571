
import React from 'react';
import {
    View ,
    Text,
    TouchableOpacity,    
    Slider,
} from 'react-native'

import _ from 'lodash'
import { text, containers ,surveyQuestionKinds, input, buttons} from '../theme';
import Moment from 'moment'
import { AddressInput } from '../Components/exports';
import { SettingsContext } from '../Managers/Settings';
import { Input } from './Input';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, IconButton, Radio, RadioGroup } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';

export class QuestionType extends React.Component{
    static contextType = SettingsContext
    constructor(props){
        super(props)
      
        this.state = {
          datePickerShowing: false,
          timePickerShowing: false,
          openChoice: '',
          address: null
      }

        const returnRef = this.props.returnRef || _.noop
        const key = this.props.index
        let question = this.props.question || {}
        let multipleChoiceSelected, radioSelected, address;
        // !!question && console.log(question.choices)
        let choices = _.has(question, 'choices') ?  _.map(question.choices, choice => _.assign({}, choice)) : []

        let { multi, radio, addr } = surveyQuestionKinds
        if(question.kind === multi.name && question.answers){
          if( typeof question.answers === typeof ''){
            question.answers = JSON.parse(question.answers);
            multipleChoiceSelected = question.answers
            _.merge(choices, question.answers)
          } else {
                // console.log('hit false condition', choices, question.answers)
                multipleChoiceSelected = question.answers
                _.merge(choices, question.answers)
          }
        } else {
          multipleChoiceSelected = {}
        }


        if( question.answers &&  question.kind === radio.name ){
            if( typeof question.answers === typeof '' ){
                question.answers = JSON.parse(question.answers);
                radioSelected = question.answers
                choices[question.answers.key] = question.answers  
            } else if(question.answers.key !== null ){
                radioSelected = question.answers
                choices[question.answers.key] = question.answers                  
            } else {
                radioSelected = null
            }

        }

  
        if( question.answers &&  question.kind === addr.name  && typeof question.answers === typeof '' ){
              address = JSON.parse(question.answers)

        }

        

        this.immutable = this.props.immutable 

        this.state = {
              ...this.state, 
              choices, 
              question, 
              multipleChoiceSelected, 
              radioSelected,
              address
            }
        returnRef(this, key )
    }


    export = () => {
      let { title, kind, choices, lower, upper, answers, id} = this.state.question
      let question = {title, kind, choices, lower, upper, answers, id}
      let {
        radio, 
        multi, 
        // date, time, 
        addr, 
        // range, 
        open, 
        // datetime
    } = surveyQuestionKinds
      switch(kind){
          case multi.name: question.answers = JSON.stringify(this.state.multipleChoiceSelected); return question
          case radio.name: question.answers = JSON.stringify(this.state.radioSelected); return question
          // case date.name : question.answers = ""; return question
          // case time.name : question.answers = ""; return question
          // case range.name: question.answers = ""; return question
          case addr.name: question.answers = JSON.stringify(this.state.address); return question
          case open.name : question.answers = this.state.openChoice; return question
          default: return question
      }
    }


    render(){
        let { radio, multi, date, time, addr, range, open, datetime} = surveyQuestionKinds
        if(!this.state.question){ return(<View></View>)}
        let question = this.state.question
        let renderMethod; 
        switch(question.kind){
            case multi.name:   {renderMethod = this.renderMultipleChoice; break }
            case radio.name:   {renderMethod = this.renderRadio;          break }
            case date.name:    {renderMethod = this.renderDate;           break }
            case datetime.name:{renderMethod = this.renderDateTime;       break }
            case time.name:    {renderMethod = this.renderTime;           break }
            case range.name:   {renderMethod = this.renderRange;          break }
            case open.name:    {renderMethod = this.renderOpen;           break }
            case addr.name:    {renderMethod = this.renderAddress;        break }
            default: renderMethod = () => <View></View>
        }
        return(renderMethod())
      }

    updateSlider = (value) => {
        let question = this.state.question;
        if(!question) { return }
        question.answers = String(value)
        this.setState({question})
    }
    
    renderMultipleChoice = () => {
        let question = this.state.question
        if(!question){ return <View></View>}
        return  <View>
            {this.state.choices.map((choice, key) => {
            return <View style={containers.betweenRow} key={key}>
                 <FormControlLabel
                    control={
                    <Checkbox
                        checked={choice.selected}
                        onChange={() => this.multipleChoiceSelected(choice, key)}
                        // name="checkedB"
                        color="primary"
                    />
                    }
                    label={choice.title}
                />
                {/* <CheckBox
                    title={choice.title}
                    
                    textStyle={[text.tiny, text.base(colors)]}
                    checked={choice.selected}
                    checkedColor={colors?.accent}
                    onPress={() => this.multipleChoiceSelected(choice, key)}/> */}
                </View>
            })}
           {!this.state.immutable ? this.renderResetButton() : null}
            </View>
    }

    multipleChoiceSelected = (newChoice, key) => {
        if(this.immutable) { return }
        let selected = this.state.multipleChoiceSelected
        
        if(!!selected && !!selected[key]){
            selected[key].selected = false
            selected[key] = null
            delete selected[key]
            this.setState({multipleChoiceSelected: selected})
        } else {
            newChoice.selected = true
            newChoice.key = key
            selected[key] = newChoice
            this.setState({multipleChoiceSelected: selected})
        }
    }

    renderRadio = () => {
       let question = this.state.question
        if(!question){ return <View></View>}
        return  (<RadioGroup
            name="___"
            onChange={this.radioSelected}
        >
        {this.state.choices.map((choice, key) => {
        return <View style={containers.betweenRow} key={key}>

            <FormControlLabel
                    control={
                        <Radio 
                            value={{choice, key}}
                            // onPress={() => this.radioSelected(choice,key)}
                        />
                    }
                    label={choice.title}
                />
 
            </View>
        })}
            {!this.state.immutable ? this.renderResetButton() : null}
        </RadioGroup>
        )
    }


    radioSelected = (data) => {
        console.log(data)
        let choice, key;
      if(this.immutable){return}
        let oldChoice = this.state.radioSelected
        if(oldChoice){ oldChoice.selected = false}
        choice.selected = true
        choice.key = key
        this.setState({radioSelected: choice})
    }

    
    renderDate = () => {
      const {colors} = this.context;
        let question = this.state.question
        if(!question){ return <View></View>}
        return  <View>
                <TouchableOpacity style={[containers.betweenRow, input.default]} onPress={() => this.openDateTime()}>
                {/* <Icon name='calendar' type='evilicon' color={colors?.accent}/> */}
                <IconButton >
                    <CalendarTodayIcon />
                </IconButton>
                <Text style={[text.tiny, text.base(colors)]}>{question.answers ? Moment(question.answers).format('LL'): 'Tap To Answer'}</Text>
                </TouchableOpacity>
                {/* <DateTimePicker
                    isVisible={this.state.datePickerShowing} 
                    onConfirm={(date) => this.submitDate(date)}
                    onCancel={()=> this.closeDateTime()}
                    mode={'date'}/> */}
                
                {!this.state.immutable ? this.renderResetButton() : null}
            </View>
    }

    renderDateTime = () => {
      const {colors} = this.context;
        let question = this.state.question
        if(!question){ return <View></View>}
        return  <View>
                <TouchableOpacity style={[containers.betweenRow, input.default]} onPress={this.openDateTime}>
                {/* <Icon name='calendar' type='evilicon' color={colors?.accent}/> */}
                <IconButton >
                    <CalendarTodayIcon />
                </IconButton>
                <Text style={[text.tiny, text.base(colors)]}>{question.answers ? Moment(question.answers).format('h:mm A, MMMM DD, YYYY'): 'Tap To Answer'}</Text>
                </TouchableOpacity>
                {/* <DateTimePicker
                     isVisible={this.state.datePickerShowing} 
                    onConfirm={(date) => this.submitDate(date)}
                    onCancel={()=> this.closeDateTime()}
                    mode={'datetime'}/> */}
                {!this.state.immutable ? this.renderResetButton() : null}
            </View>
    }

    clearMultipleChoiceSelection = () => {
       let selected = this.state.multipleChoiceSelected
        if(!selected) { return }
        _.keys(selected).forEach(key => {selected[key].selected = false})
    } 

    clearRadioSelection = () => {
        let oldChoice = this.state.radioSelected
        if(oldChoice){ oldChoice.selected = false}
    }

    clearAnswer = () => {
        let {question} = this.state
        question.answers = null
        this.clearRadioSelection()
        this.clearMultipleChoiceSelection()
        this.setState({question, radioSelected: null, multipleChoiceSelected: {}})
    }


    openDateTime = () => { if( !this.immutable) { this.setState({datePickerShowing: true})}}
    closeDateTime = () =>  this.setState({datePickerShowing: false}) 
    submitDate = (date) => {
        if( this.immutable) { return }
        let newDate = new Date(date).getTime()
        let {question} = this.state
        question.answers = newDate
        this.setState({question})
        this.closeDateTime()

    }

    renderTime = () => {
        const {colors} = this.context
        let question = this.state.question
        if(!question){ return <View></View>}
        return  <View>
                <TouchableOpacity style={[containers.betweenCenterRow, input.default]} onPress={() => this.openTime()}>
                {/* <Icon name='clock' type='evilicon' color={colors?.accent}/> */}
                <IconButton>
                    <QueryBuilderRoundedIcon />
                </IconButton>
                <Text style={[text.tiny, text.base(colors)]}>{question.answers ? Moment(question.answers).format('h:mm A'): 'Tap To Answer'}</Text>
                </TouchableOpacity>
                {/* {!this.state.immutable ? <DateTimePicker
                    isDarkModeEnabled={appTheme === 'dark'}
                    date={answers ? new Date(answers) : new Date()}
                    isVisible={this.state.timePickerShowing} 
                    value={answers ? new Date(answers) : new Date()}
                    onConfirm={(date) => this.submitTime(date)}
                    onCancel={()=> this.closeTime()}
                    mode={'time'}/>: null} */}
                {!this.state.immutable ? this.renderResetButton() : null}
            </View>
    }
    renderResetButton = () => {
       const {colors} = this.context;  
        const outerStyle={...containers.rightRow}
        return(
            <View style={outerStyle}>
            {!this.immutable ? <TouchableOpacity 
                style={buttons.smallOutline}
                onPress={() => this.clearAnswer()}>
                <Text style={[text.superTiny, text.base(colors)]}>Reset</Text>
            </TouchableOpacity> : null}
            </View>
        )
    }

    openTime = () => !this.immutable && this.setState({timePickerShowing: true})
    closeTime = () => this.setState({timePickerShowing: false})
    submitTime = (time) => {
        if( this.immutable) { return }
        let newTime = new Date(time).getTime()
        let question = this.state.question
        question.answers = newTime
        this.setState({question})
        this.closeTime()
    }

    renderRange = () => {
      const {colors} = this.context;
        let question = this.state.question
        let sliderValue = +_.get(question, 'answers', '')
        if(!question){ return <View></View>}
        return(
          <View style={containers.betweenColumn}>
            <View style={containers.centerColumn}>
              <Text style={[text.medium, text.contrast(colors)]}>{question.answers}</Text>
            </View>
            <View style={containers.betweenRow}>
              <Text  style={[{flex:1 , textAlign: 'center'}, text.tiny, text.base(colors)]}>{question.lower}</Text>
                <Slider
                      style={{flex: 10}} 
                      animationType={'spring'}
                      minimumTrackTintColor={colors?.accent}
                      steps={10}
                      disabled={this.immutable}
                      trackStyle={{backgroundColor: colors?.plight}}
                      minimumValue={question.lower}
                      value={sliderValue}
                      maximumValue={question.upper}
                      onValueChange={(value) => this.updateSlider(Math.floor(value))}/>
                  <Text  style={[{flex:2, textAlign: 'center'}, text.tiny, text.base(colors)]}>{question.upper}</Text>
              </View>
          </View>
        )
    }


    renderOpen = () => {
        const {colors, appTheme} = this.context
        let question = this.state.question
        if(!question){ return <View></View>}
        let { openChoice } = this.state
        let answer = _.isString(question.answers) ? question.answers : 'Invalid Answer'

        if(this.immutable){
          return(
            <View style={{padding: 10}}>
              <Text style={[text.base(colors)]}>{answer}</Text>
            </View>
          )
        }

        return  <Input
            underlineColorAndroid="transparent"
            placeholder={`What do you think?`}
            multiline
            value={openChoice ? openChoice : _.isString(question.answers) ? question.answers : ''}
            onChangeText={change => this.onOpenChange(change)}
            style={[input.textarea(colors, appTheme), {width: '100%'}]}
        />
    }
    renderAddress = () => {
        const colors = this?.context?.colors;
        let question = this.state.question
        if(!question){ return <View></View>}
        return (
        <AddressInput 
            immutable ={this.immutable} 
            address={this.state.address}
            backgroundColor = 'white'
            onChange={address => this.setState({address})} 
            style={{backgroundColor: colors?.transparent}}/>
        )
    }
    onOpenChange = (change) => {
        this.setState({openChoice: change})
    }
}
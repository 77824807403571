
import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs';
import { uniqBy, filter, first, map, findIndex, includes} from 'lodash'
import { Table } from './Table';
import { ContactAttendenceCell } from './ContactAttendenceCell';
import { Checkbox, IconButton, Tooltip } from '@material-ui/core';
import { useSettings } from '../Managers/Settings';
import { GradePicker } from './GradePicker';
import { useContacts } from '../Managers/Contacts';
import { OwnerAvatar } from './owner-popover';
import { useAlert } from '../Managers/Alert';
import { ADVENTIST } from '../Constants';
import styled from 'styled-components'
import { Delete, RestoreFromTrash } from '@material-ui/icons';
var calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar);


const FlexEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
export const ContactsTable = ({ 
    org, 
    series_id,
    contacts,
    title,
    onAdd,
    onArchiveToggle,
    onOpenOnwerAssign,
    onContactSelected,
    archived} ) => {
  const org_id = org
  const {colors} = useSettings()
  const ContactManager = useContacts()

  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const Alert = useAlert()

  const generateSDA = (member) => {
    return `${member ? "" : "Not "}${ADVENTIST}`
  }

  useEffect(() => {
    // why are we casting a boolean to a upper cased string?
    // glad you asked....
    // Table component doens't know how to display a boolean....
    // ......
    // .....🙄
    const _data = map(contacts, c => ({
      ...c, 
      name: `${c?.first_name} ${c.last_name}`, 
      address1: c?.address?.address1,
      address2: c?.address?.address2,
      city: c?.address?.city,
      state: c?.address?.state,
      zip: c?.address?.zip,
      country: c?.address?.country,
      displayMember:  generateSDA(c?.member)
      })
    )
    setData(_data)
  }, [contacts])


  const onViewColumnsChange = (name) => {
    // find the column by name and toggle its visibility
    let _columns = [...columns]
    let index = findIndex(_columns, c => c.name === name)
    if(index < 0){
      Alert.error("Not Found")
      return
    } 

    _columns[index].options.display = !_columns[index].options.display
    setColumns(_columns)


  }



  const onClick = async (contact, meta) => {
      if(meta?.colIndex === 10){
        const value = !contact?.member
        try{
          await ContactManager.setSDA([contact.id], value)
          // update local list
          let all = data
          if(!!all?.[meta?.dataIndex]){
            all[meta.dataIndex].member = value
            all[meta.dataIndex].displayMember = generateSDA(value)
            setData([...all])
          }
          
        } catch(e) {
          Alert.error(e)
        }
        

      } else if(meta?.colIndex === 9) {   
        // archive selected.  
        // probably not going to use
      } else if(meta?.colIndex === 0 || meta?.colIndex === 17) {

      } else {
        onContactSelected && onContactSelected(contact)
      }
      
    }


    const onDownload = (buildHeader, buildBody, columns, data) => { 
      //function(buildHead: (columns) => string, buildBody: (data) =>string, columns, data) => string
      let processed = preprocess(columns, data)
      return String(buildHeader(columns) + buildBody(processed))
    }

    const preprocess = (p, data) => {
      // change the react views to strings with actual meaning
    let activeColumns = filter(columns, c => c?.options?.display === true)

      let attendanceIndex = findIndex(activeColumns, c => c.name === 'attendance')
      let ownerIndex = findIndex(activeColumns, c => c.name === 'owner.user')
      let phoneIndex = findIndex(activeColumns, c => c.name === 'phones')

      for( let item of data){
        if(attendanceIndex > -1){
          // Attendance
          let attendance = item.data[attendanceIndex]
          for( let series_index in attendance){
            if(attendance[series_index]?.id === series_id){
              let series = attendance[series_index]
              let sessions = ""
              for(let i = 0; i < series.num_events; i++){
                if(includes(series.sessions.map(e => e.event_index), i)){
                  // add the night number with a delimiter at the end unless its the last night
                  sessions += (`${i !== 0 || sessions != "" ? ' ' : ''}${i + 1}`)
                } 
             }
             // assign this back to the array over the entire attendance object
             item.data[attendanceIndex] = sessions
            }
          }
        }

        // Owner
        if(ownerIndex > -1){
          let owner = item.data[ownerIndex]
          if(!owner){
            item.data[ownerIndex] = ""
          } else {
            item.data[ownerIndex] = `${owner?.first_name || ""}${owner?.last_name && " "}${owner?.last_name || ""}`
          } 
        }

        if(phoneIndex > -1){
          let phones = item.data[phoneIndex]
          if(!phones || phones?.length === 0){
            item.data[phoneIndex] = ""
          } else {
            item.data[phoneIndex] = phones?.[0].number || ""
          }
        }

      }
      return data
    }


    const onRowsDelete = async  (rowsDeleted, data, newTableData) => {
      let actionables = map(rowsDeleted?.data, row => contacts[row.dataIndex]?.id)

        try{
          await ContactManager.setContactsArchive(actionables, !archived)
          return true
        } catch(e) {
          Alert.error(e)
          return false
        }
      
    }

    // this function is altered for 
    // renderCustomSelectRows
    const _onArchiveToggle = async  (rowsDeleted, setSelectedRows) => {
      let actionables = map(rowsDeleted?.data, row => contacts[row.dataIndex]?.id)
      onArchiveToggle(actionables, setSelectedRows)
    }



    const renderCustomSelectRows = (selectedRows, displayData, setSelectedRows) => {
      const toggleArchive = () => _onArchiveToggle(selectedRows, setSelectedRows)
      return (<>
      <FlexEnd>
          {archived && 
          <Tooltip title={"Restore from Archive"}>
            <IconButton onClick={toggleArchive}>
              <RestoreFromTrash />
            </IconButton>
          </Tooltip>
          }
          {/* {!archived && <Tooltip title={"Assign to..."}>  
            <IconButton>
              <AccountCircle />
            </IconButton>
          </Tooltip>} */}
          {!archived && 
          <Tooltip title={"Archive Contacts"}>
            <IconButton onClick={toggleArchive}>
              <Delete />
            </IconButton>
          </Tooltip>
          }
      </FlexEnd>
      </>)
    }

    useEffect(() => {

      if(columns.length !== 0 || contacts?.length === 0 ){
        return
      }
      let _columns = [

        {
          name: "grade",
          label: "Grade",
          options: {
            filter: true,
            display: true,
            customBodyRender: (grade, tableMeta, updateValue) => {
              // NOTE
              // rowData[1] is the id field
              // if the id is moved below,
              // its index will need to change also
              return (
                <GradePicker 
                  mutate
                  inlist
                  person={{
                    grade,
                    sync: true,
                    id: tableMeta.rowData[1]
                  }}
                />
              );
            }
          }
        },
        {
            name: "id",
            options: {
              filter: false,
              sort: false,
              display: false
            }
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              display: true,
              hint: "You can access all properties from the column select on the top right of this table.",
            }
          },
        {
          name: "first_name",
          label: "First Name",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "last_name",
          label: "Last Name",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "email",
          label: "Email",
          options: {
            filter: false,
            display: false
          }
        },
   
   
        {
          name: "created_at",
          label: "Created",
          options: {
            filter: false,
            display: true,
            customBodyRender: (created_at, tableMeta, updateValue) => {
              return (
                dayjs(created_at).format("MM/DD/YYYY")
              );
            }
          }
        },
        {
            name: "gender",
            label: "Gender",
            options: {
              filter: true,
              display: false,
              customBodyRender: (gender, tableMeta, updateValue) => {
                  return gender || "Unknown"    
              }
            }
          },   
          {
            name: "source",
            label: "Source",
            options: {
              filter: true,
              display: false,
              customBodyRender: (source, tableMeta, updateValue) => {
                  return source || "Unknown"    
              }
            }
          },
          {
            name: "archived",
            label: "Archived",
            options: {
              filter: false,
              display: false,
              hint: "This field is only informatory.",
              customBodyRender: (archived, tableMeta, updateValue) => {
                return (
                  <Checkbox disabled checked={archived} style={{color: colors.accent}} /> 
                );
              }
            }
          },
          {
            name: "displayMember",
            label: "Adventist",
            options: {
              filter: true,
              sort: true,
              display: true,
              customBodyRender: (member, tableMeta, updateValue) => {
                return (
                  <Checkbox checked={member === ADVENTIST} style={{color: colors.accent}} /> 
                );
              }
            }
          },
        {
            name: "attendance",
            label: "Attendance",
            options: {
              filter: false,
              sort: false,
              display: series_id ? true : "excluded",
              customBodyRender: (attendance) => {
                  if(!attendance){ return <></> }
                  const filtered = first(uniqBy(filter(attendance, series => series?.id === series_id), 'id'))
                  if (!filtered){ return <></> }
                  return(<ContactAttendenceCell attendance={filtered}/>)
              }
            }
          },
          {
            name: "address1",
            label: "Address",
            options: {
              filter: false,
              sort: true,
              display: false,
            }
          },
          {
            name: "address2",
            label: "Address2",
            options: {
              filter: false,
              sort: true,
              display: false
            }
          },
          {
            name: "city",
            label: "City",
            options: {
              filter: false,
              sort: true,
              display: false
            }
          },
          {
            name: "state",
            label: "State",
            options: {
              filter: true,
              sort: true,
              display: false
            }
          },
          {
            name: "zip",
            label: "Zip/Postal Code",
            options: {
              filter: true,
              sort: true,
              display: true
            }
          },
          {
            name: "owner.user",
            label: "Assigned To",
            options: {
              filter: false,
              sort: false,
              display: true, 
              customBodyRender: (user, meta) => {
                return(<OwnerAvatar owner={user} onOpen={onOpenOnwerAssign} contact_id={meta.rowData[1]} />)
              }
            }
          },
          {
            name: "phones",
            label: "Phone",
            options: {
              filter: true,
              sort: true,
              display: false,
              customBodyRender: (phones, meta) => {
                return <>{phones?.[0]?.number}</>
              }
            }
          },
      ];
      setColumns(_columns)
      
    }, [contacts])


   

    return(
          <Table 
            title={title}
            columns={columns}
            data={data}
            onRowsDelete={onRowsDelete}
            onClick={onClick}
            onViewColumnsChange={onViewColumnsChange}
            onDownload={onDownload}
            options={{
              customToolbarSelect: renderCustomSelectRows,
              textLabels:{
                selectedRows: archived ? 
                {
                  delete: "Restore",
                  deleteAria: "Restore Selected Rows",
              }
                : {
                    delete: "Archive",
                    deleteAria: "Archive Selected Rows",
                }
              }}
            }
        />
    )
}


import React, {useState, useEffect, useContext, createContext} from 'react'
import { flatten, map, filter, now, unionWith } from 'lodash';
import { createManagerInfo } from '../Utilities';
import Moment from 'moment';
import { useSocket } from './Sockets';
import { useUser } from './UserManager';
import { useNetwork } from './Database';

// const topButtons = ['Reminder', 'Event', 'Appointment']

// const defaultEvent = {       
//   kind: topButtons[2],
//   name: 'Meeting ' + random(0, 0xfffff),
//   description: '',
//   tags: [],
//   start_date:  Moment().startOf('hour').add(60,'minute'),
//   end_date:  Moment().startOf('hour').add(90,'minute'),
//   times: [],     
//   address: {
//     address1: '',
//     address2: '',
//     city: '',
//     state: '',
//     zip: '',
//     prisonId: '',
//     country: ''
//   }}

// const EVENTS_TABLE = String('EVENTS_TABLE_NAME');
const EventContext = createContext()
export const useEvents = () => useContext(EventContext)

export const EventsProvider = ({children }) => {
  const managerName = createManagerInfo('EVENT_MANAGER', 2)
  const Socket = useSocket()
  const {user} = useUser()
  const Database = useNetwork()

  useEffect(() => {

  }, [])

  const [events, setEvents] = useState([])
  const [today, setToday] = useState([])


  const addContactToEvent = async (event, contact) => {
    if(!event?.id|| !contact?.id){
      return Promise.reject('The Event or Contact is missing its identification. ')
    }
    try{
      let result = await Database.post(`/events/${event.id}/contacts/${contact.id}`,{
        event, contact
      },
      managerName )

      getEvents()
      return result
    } catch(e){
      return Promise.reject(e.error)
    }
    

  }
  const getEvents = async () => {
    try{
      let e = await Database.get('/events')
      setEvents(e)
      updateItemsFor(e)
      return e
    } catch(e) {
      return Promise.reject(e?.error)
    }
  }

  const updateItemsFor = async (date = now(), contact = null) => {
    let past = getPastEventsFor(date, contact) || {meetings: [], contacts: []}
    let future = getFutureEventsFor(date, contact) || {meetings: [], contacts: []}
    let current = getCurrentEventsFor(date, contact) || {meetings: [], contacts: []}
    const _today = {past, current, future }
    // if update is for today, send on the today notification
    if(Moment(date).isSame( Moment() , 'day') && !contact){
      setToday({...today})
    }
    return _today
  }

  const getPastEventsFor = (date, contact) => {
    const eventDate = Moment(date)
    let es = filter(events, e => Moment(e.end_date).isBefore(eventDate))
    let meetings =  filter(es, p => Moment(p.start_date).isSame(eventDate, 'day'))
    if(!!contact){
      // ok, for each meeting
      // filter out meeting.contacts for meetings where our contact is include
      // this produces an array with length > 1 when the contact is found
      // if array is greater than 1, then we return true, and we add that 
      // meeting to our list of meetings...
      meetings = 
        filter(
          meetings, 
          m => filter(
            m.contacts, 
            c => c.id === contact.id && !!c.id
          )
        .length > 0
      )
    }
    let contacts = flatten(map(meetings, e => e.contacts))
    return {meetings, contacts}
  }

  const getFutureEventsFor = (date, contact) => {
    const eventDate = Moment(date)
    let future = filter(events, e => Moment(e.start_date).isAfter(eventDate))
    let meetings =  filter(future, f => Moment(f.start_date).isSame(eventDate, 'day'))
    if(!!contact){
      // ok, for each meeting
      // filter out meeting.contacts for meetings where our contact is include
      // this produces an array with length > 1 when the contact is found
      // if array is greater than 1, then we return true, and we add that 
      // meeting to our list of meetings...
      meetings = 
        filter(
          meetings, 
          m => filter(
            m.contacts, 
            c => c.id === contact.id && !!c.id
          )
        .length > 0
      )
    }
    let contacts = flatten(map(meetings, e => e.contacts))
    return {meetings, contacts}
  }

  const getCurrentEventsFor = (date, contact) => {
    const eventDate = Moment(date)
    let current = filter(events, e => Moment(e.start_date).isBefore(eventDate) && Moment(e.end_date).isAfter(eventDate))
    let meetings =  filter(current, c => Moment(c.start_date).isSame(eventDate, 'day'))
    if(!!contact){
      // ok, for each meeting
      // filter out meeting.contacts for meetings where our contact is include
      // this produces an array with length > 1 when the contact is found
      // if array is greater than 1, then we return true, and we add that 
      // meeting to our list of meetings...
      meetings = 
        filter(
          meetings, 
          m => filter(
            m.contacts, 
            c => c.id === contact.id && !!c.id
          )
        .length > 0
      )
    }
    let contacts = flatten(map(meetings, e => e.contacts))
    return {meetings, contacts}
  }

  const deleteEvent = async (event) => {
    if(!event?.id) {
      return Promise.reject('No Identification Found')
    }
    let path = `/events/${event?.id}`
    try{
      await Database.remove(path)
      let _events = filter(events, e => e.id !== event.id)
      setEvents([..._events])
    } catch(e) {
      return Promise.reject(e.error)
    } 
  }
    

  const create = async (event) => {    
    try{
      let newEvent = await Database.post('/events', event, managerName)
      const _events = unionWith([newEvent], events, (a,b) => a.id === b.id)
      setEvents([..._events])
      return newEvent
    } catch (e) {
      return Promise.reject(e?.error)
    }
  }

  const getSeries = async (orgId) => {
    if(!orgId){return Promise.reject('No Org Identification Found')}
      try{
        return Database.getCachePriority(`/orgs/${orgId}/series`, true)
      } catch(e) {
        return Promise.reject(e.error)
      }
   }

  const  getSeriesFromCache = async (orgId) => {
    if(!orgId){return Promise.reject('No Org Identification Found')}
      try{
        return Database.getCachePriority(`/orgs/${orgId}/series`, true)
      } catch(e) {
        return Promise.reject(e.error)
      }
   }
  const createSeries = async (orgId, series) => {
    if(!orgId){return Promise.reject('No Org Identification Found')}
    if(!series){return Promise.reject('No Series Provided')}
    try{
      return Database.post(`/orgs/${orgId}/series`, series)
    } catch(e) {
      return Promise.reject(e.error)
    }
  }

  const updateSeries = async (orgId, series) => {
    if(!orgId){return Promise.reject('No Org Identification Found')}
    if(!series?.id){return Promise.reject('No Series Identification Found')}
    try{
      return Database.put(`/orgs/${orgId}/series/${series?.id}`, series)
    } catch(e) {
      return Promise.reject(e.error)
    }
  }
  const deleteSeries = async (orgId, series) => {
    if(!orgId){return Promise.reject('No Org Identification Found')}
    if(!series?.id){return Promise.reject('No Series Identification Found')}
    try{
      return Database.remove(`/orgs/${orgId}/series/${series.id}`)
    } catch(e) {
      return Promise.reject(e.error)
    }
  }

  const getEventsForSeries = async (seriesId) => {
    if(!seriesId){return Promise.reject('No Series Identification Found')}
    try{
      return Database.get(`/events?series_id=${seriesId}`, true)
    } catch(e) {
      return Promise.reject(e.error)
    }
  }

  const getSingleSeries = async (org_id, series_id) => {
    if(!series_id){return Promise.reject('No Series Identification Found')}
    if(!org_id){return Promise.reject('No Series Identification Found')}
    try{
      return Database.get(`/series/${series_id}`, false)
    } catch(e) {
      return Promise.reject(e.error)
    }
  }

  return (
    <EventContext.Provider value={{
      today,
      events,
      addContactToEvent,
      getEvents,
      updateItemsFor,
      getPastEventsFor,
      getFutureEventsFor,
      getCurrentEventsFor,
      deleteEvent,
      create,
      deleteEvent,
      getSeries,
      getSeriesFromCache,
      createSeries,
      updateSeries,
      deleteSeries,
      getEventsForSeries,
      getSingleSeries
      
    }}>
      {children}
    </EventContext.Provider>
  )

}

  
  


 import { Platform } from 'react-native'
 import { invertColor } from './Utilities';
 import { wpCss, hpCss } from './Fork/react-native-responsive-screen';

export const base = ({
    secondary:   '#ff702e',
    sdark:       '#c53f00',
    slight:      '#ffa15c',
    primary:     '#15bfbc',
    pdark:       '#00a29f',
    plight:      '#00e3df',
    tertiary:     '#30acff',
    tdark:       '#007dcb',
    tlight:      '#77ddff',
    transparent: 'transparent',
    charcoal:    '#707070',
    danger:      'red',
    logoBlue:    '#004B80',
    gradeA:      '#00FF00',
    gradeB:      'orange',
    gradeC:      'darkorange',
    gradeD:      '#FF0000',
    gradeF:      '#989898',
    accent:      '#15bfbc'
}
)

export function darkThemeColors() { 
    return{
    ...base,
    text:        'white',      
    secondaryLabel: "lighgrey",
    elevated:    '#4A4A4A',    
    page:     '#131825',      
    foreground:  '#3B3B3B',    
    baseboard:   '#020202d0',  
    selected:    'black',      
    diselected: '#4A4A4A',    
    overlay:     'rgba(0, 0, 0, 0.70)', 
    aside:       '#2A3343',  
    navbar:       '#2A3343',  
    card:         '#2A3343',
    tablePopover: '#ffffff', 
    }
}

export function lightThemeColors() {
    return{
    ...base,
    text:        '#5f5f5f', 
    secondaryLabel: "grey",
    elevated:    'transparent', 
    page:        '#F4F4F4',
    foreground:  'white', 
    baseboard:   '#FFFFFFcc', 
    selected:     '#FEFEFE', 
    diselected:    '#9D9D9D', 
    overlay:     'rgba(250, 250, 250, 0.70)', 
    aside:       '#ffffff',  
    navbar:       '#ffffff',
    card:          '#ffffff',
    tablePopover: '#ffffff', 
    }
}


  export function colors() {
     return{
    
    secondary:     {base: '#ff702e', contrast: 'grey' },
    sdark:         {base: '#c53f00', contrast: 'white' },
    slight:        {base: '#ffa15c', contrast: 'black' },
    
    primary:       {base: '#15bfbc', contrast: 'white' },
    pdark:         {base: '#00a29f', contrast: 'white' },
    plight:        {base: '#00e3df', contrast: 'white' },
    
    tertiary:       {base: '#30acff', contrast: 'black' },
    tdark:         {base: '#007dcb', contrast:'black' },
    tlight:        {base: '#77ddff', contrast:'black' },
    transparent:   {base: 'transparent', contrast: 'transparent'},


    charcoal:      {base: '#707070', contrast: 'white' },
    danger:        {base: 'rgb(220, 20, 60)' ,  contrast: 'white' },
    logoBlue:      {base: '#004B80', contrast: 'white'},
    gradeA:        {base: '#00FF00', contrast: '#707070'},
    gradeB:        {base: 'orange', contrast: '#707070'},
    gradeC:        {base: 'darkorange', contrast: '#707070'},
    gradeD:        {base: '#FF0000', contrast: '#707070'},
    gradeF:        {base: '#989898', contrast: '#707070'},

    accent:        {base: '#15bfbc', contrast: 'white' }, 
    light:         {base: '#ececec', contrast: '#0077c2'},
    white:         {base: 'white', contrast: '#444444'},
    dark:          {base: '#444444', contrast: '#cccccc'},
    drawer:        {base: 'white', contrast: '#707070', solid: 'white'},
    lightText:     {base: 'lightgrey', contrast: 'white'},
    darkText:      {base: '#707070', contrast: '#989898'},
    background:    {base: '#FFFFFF', contrast: '#F4F4F4'},
    baseboard:      {base: Platform.OS === 'ios' ? '#FFFFFFcc' : '#FFFFFF', contrast: 'white'},
    border:        {base: '#D9D9D9', contrast: 'white'},
    }
}

// export function darkThemeColors() { 
//     return{
//     ...colors(),
//     text:          {base: 'white',      contrast: '#989898'},
//     elevated:      {base: '#4A4A4A',    contrast: 'white' },
//     background:    {base: 'black',      contrast: '#212121'},
//     foreground:    {base: '#3B3B3B',    contrast: 'black'},
//     baseboard:     {base: '#020202d0',  contrast: 'white'},
//     selected:      {base: 'black',      contrast: '#3B3B3B'},
//     disselected:   {base: '#4A4A4A',    contrast: '#3B3B3B'},
//     overlay:       {base: 'rgba(0, 0, 0, 0.70)', contrast: 'white'}
//     }
// }

// export function lightThemeColors() {
//     return{
//     ...colors(),
//     text:          {base: '#464646', contrast: '#989898'},
//     elevated:      {base: 'transparent', contrast: 'transparent'},
//     background:    {base: '#FFFFFF', contrast: '#EEEEEE'},
//     foreground:    {base: 'white', contrast: 'white'},
//     baseboard:     {base: '#FFFFFFcc', contrast: 'white'},
//     selected:       {base: '#FEFEFE', contrast: '#F4F4F4'},
//     disselected:      {base: '#9D9D9D', contrast: '#F4F4F4'},
//     overlay:       {base: 'rgba(250, 250, 250, 0.70)', contrast: 'black'}
//     }
// }

export const shadows = {
    default: (elevation, c) => {
      
      return {
          shadowColor: 'black',
          shadowOffset: { width: 10, height: 10 },
          shadowOpacity: 0.2,
          shadowRadius: 5,
          elevation,
          borderColor: c ? c.elevated : 'transparent',
          borderWidth: 1, 
          borderStyle: 'solid',
      }
    },
    tabs:{
      shadowColor: 'black',
      shadowOffset: { width: 0, height: 10 },
      shadowOpacity: 0.5,
      shadowRadius: 15,
      elevation: 5
    },
    tile:{
      shadowColor: 'black',
      shadowOpacity: 0.3,
      shadowRadius : 10,
      elevation: 7,
      shadowOffset: {width: 0, height: 20}
    },
    avatar: {
      shadowColor: 'lightgrey',
      shadowOpacity: 0.3,
      shadowRadius : 4,
      shadowOffset: {width: 0, height: 4}
    }, 
    paper: {
        shadowColor: '#000',
        shadowOpacity: 0.2,
        shadowRadius : 10,
        shadowOffset: {width: 0, height: 4}
      }, 
    bottom: {
      shadowColor: 'black',
      shadowOpacity: 0.3,
      shadowRadius : 4,
      elevation: 7,
      shadowOffset: {width: 0, height: 15}
    },
    grade:(c) =>  {
        return{
          shadowColor: 'black',
          shadowOpacity: 0.3,
          shadowRadius : 5,
          elevation: 7,
          shadowOffset: {width: 10, height: 10},        
        //   borderColor: c ? c.elevated : 'transparent',
        //   borderWidth: 1, 
          borderStyle: 'solid',
  
  
      }
    }
  }
export const containers = {
    centerWrapRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap'
        // alignSelf: 'stretch'
    },
    centerRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
        // alignSelf: 'stretch'
    },
    leftRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        alignSelf: 'stretch'
    },
    leftRowWrap: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    leftCenterRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row'
    },
    leftBottomRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexDirection: 'row'
    },
    rightRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        // alignSelf: 'stretch'
    },
    betweenRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        // alignSelf: 'stretch'
    },
    betweenCenterRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        // alignSelf: 'stretch'
    },
    aroundRow: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        // alignSelf: 'stretch'
    },
    centerTopRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        // alignSelf: 'stretch'
    },
    centerBottomRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'row'
        // alignSelf: 'stretch'
    },
    leftTopRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        // alignSelf: 'stretch'
    },
    rightTopRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        flexDirection: 'row',
        // alignSelf: 'stretch'
    },
    betweenTopRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row'
        // alignSelf: 'stretch'
    },
    topCenterRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    centerColumn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // alignSelf: 'stretch'
    },
    centerBetweenColumn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
    },
    centerColumnNoStretch:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    leftColumn: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },
    leftCenterColumn:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        alignSelf: 'stretch'
    },
    leftBetweenColumn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },
    rightColumn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'column'
    },
    betweenColumn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column'
    },
    aroundColumn: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column'
    },
    centerTopColumn: {
        display: 'flex',
        justifyContent: 'flex-start',
        // justifyContent: 'center',
        // alignItems: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column'
    },
    topCenterColumn: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column'
    },
    leftTopColumn: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },
    rightTopColumn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },
    centerBottomColumn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'column'
    },
    leftBottomColumn: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexDirection: 'column'
    },
    rightBottomColumn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'column'
    },
    wrapBetweenTopRow:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    wrapCenterRow:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap'
        // alignSelf: 'stretch'
    },
    wrapLeftRow:{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap'
        // alignSelf: 'stretch'
    },
    wrapTopRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    wrapBetweenRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    wrapAroundRowTop: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    wrapAroundRow: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    padding: {
        padding:20
    },
    paddingHorizontal: {
        paddingHorizontal: 20
    },
    paddingVertical: {
        paddingVertical: 20
    },
    margin: {
        margin: 10
    },
    marginHorizontal: {
        marginHorizontal: 10
    },
    marginVertical: {
        marginVertical: 10
    }
}
export const page = {
    contactTileBackground: (selected) => {
        return selected ? 
        {
            minHeight: hpCss(100),
            width: wpCss(100),
            zIndex: 22,


        } 
        : {
            width: '100%'
        }
    },
    background: (_colors, _getWidth, _getHeight) => {
        return{
            backgroundColor: _colors?.page,
            minHeight: _getHeight(100),
            width: _getWidth(100),
            ...containers.centerTopColumn,
            elevation: 1
        }
    },
    contrastBackground: (_colors) => {
        return{
            backgroundColor: _colors?.foreground,
            minHeight: hpCss(100),
            width: wpCss(100),
            ...containers.centerTopColumn
        }
    }
}
export const text = {
    huge: {
        fontSize: 70
    },
    xlarge: {
        // color: colors.text,
        fontSize: 38
    },
    large: {
        // color: colors.text,
        fontSize: 32
    },
    medium: {
        // color: colors.text,
        fontSize: 27
    },
    small: {
        // color: colors.text,
        fontSize: 21
    },
    tiny: {
        // color: colors.text,
        fontSize: 15
    },
    superTiny: {
        // color: colors.text,
        fontSize: 10
    },
    bold: {
        fontWeight: 'bold'
    },
    italics: {
        fontStyle: 'italic'
    }, 
    normal: {
        // color: colors.text,
    },
    title: {
    },
    disabled : {
        color: 'grey'
    },
    center: {
        textAlign: 'center'
    },
    left: { 
        textAlign: 'left'
    },
    right: {
        textAlign: 'right'
    },
    white: {
        color: 'white'
    },
    
    primary: {
        color: colors().primary
    },
    accentContrast: (_c) => {
        return{
            color: invertColor(_c?.accent)
        }
    },
    accent: (_colors) => {
        return{
            color: _colors?.accent
        }
    },
    contrast: (_colors) =>  {
        return{
            color: _colors?.text
        }
    },
    base: (_colors) => {
        return{
            color: _colors?.text
        }
    },
    reverseBase: (theme) =>  {
        return theme === 'dark'
            ? {color: lightThemeColors().text}
            : {color: darkThemeColors().text}
    },
    visible: (c) => {
        return{ color: invertColor(c)}
    },
    light:{
        // color: colors.text
    }, 
    dark: {
        // color: colors.text
    },
    pdark: {
        // color: colors.pdark
    },
    danger: (c) =>  {return {
        color: c.danger
    }},
    outline: function (color, height) {
        const radius = height / 2
        return {
            borderRadius: radius,
            borderColor: color,  
            borderStyle: 'solid',
            borderWidth: 1,
            padding: radius
        }
    }
}
export const grade = {
    default: (_colors, scale) => {
        // console.error(_colors?.disselected)
        return {
            // backgroundColor: scale.interpolate({
            //     inputRange: [0.7, 1],
            //     outputRange: [_colors?.disselected, _colors?.selected]
            // }),
            margin: 20,
            height: 90,
            width: 90,
            borderRadius: 45,
            borderWidth: 5,
            borderColor: _colors?.accent,
            // backgroundColor: _colors?.background,
            ...containers.centerColumn

        }
    },
    square: (_colors, scale, selected) => {
        return {
   
            margin: 20,
            height: 75,
            width: 75,
            borderRadius: 20,
            ...containers.centerColumn,

        }
    },
    text: (_colors, grade, inlist=false, intable) => {
        let color;
        switch(grade){
            case 'A': color = _colors?.gradeA; break;
            case 'B': color = _colors?.gradeB; break;
            case 'C': color = _colors?.gradeC; break;
            case 'D': color = _colors?.gradeD; break;
            case 'F': color = _colors?.gradeF; break;
        }

        return inlist ?
        {
            ...text.bold,
            ...text.medium,
            color,
        } : intable ? 
        {
            ...text.bold,
            ...text.small,
            color
        } :
        {
            ...text.bold,
            ...text.large,
            color,
        }
    },
    list: (_colors) => {
        return{
            // margin: 10,
            height: 50,
            ...shadows.avatar,
            width: 50,
            borderRadius: 25,
            borderWidth: 3,
            borderColor: _colors?.accent,
            backgroundColor: _colors?.card,
            ...containers.centerColumn,
            

        }
    },
    table: (_colors) => {
        return{
            // margin: 10,
            height: 35,
            ...shadows.avatar,
            width: 35,
            borderRadius: 25,
            borderWidth: 2,
            borderColor: _colors?.accent,
            backgroundColor: _colors?.card,
            ...containers.centerColumn,
            

        }
    },
    contact: (_colors) => {
        return{
            margin: 10,
            height: 100,
            ...shadows.avatar,
            width: 100,
            borderRadius: 50,
            borderWidth: 3,
            borderColor: _colors?.accent,
            backgroundColor: _colors?.card,
            ...containers.centerColumn,
            

        }
    },
    tiny: (_colors) => {
        return {
            margin: 5,
            height: 40,
            width: 40,
            borderRadius: 20,
            // borderWidth: 1,
            // borderColor: _colors?.accent,
            backgroundColor: _colors?.text,
            ...containers.centerColumn

        }
    },

}

export const searchbar = {
    contactPage: (_colors) =>  {
        let base = {}

        return {
            marginTop: 10,
            marginBottom: 20,
            height: 50,
            width: '100%',
            paddingHorizontal: 10,
            borderRadius: 12,
            backgroundColor: _colors?.page,
            border: "solid 1px " + _colors?.overlay,
            transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
            ...shadows.avatar,
            ...containers.betweenCenterRow
        }
    }
}
export const input = {
   
    default: (_colors, appTheme) => {
        let isDark = appTheme === 'dark';
        return{
            margin: 20,
            height: 50,
            width: '100%',
            alignSelf: 'center',
            paddingHorizontal: 10,
            borderRadius: 20,
            backgroundColor: isDark ? _colors?.card: _colors?.card,
            // ...shadows.grade(_colors),
            ...containers.betweenCenterRow
        }
    },
    textarea: (_colors, appTheme) =>  {
        let isDark = appTheme === 'dark';
        return{
        margin: 20,
        alignSelf: 'stretch',
        // alignSelf: 'center',
        paddingHorizontal: 10,
        borderColor: _colors?.overlay,
        borderWidth: 1,
        borderRadius: 20,
        backgroundColor: isDark ? _colors?.card: _colors?.card,
        ...shadows.avatar,
        ...containers.betweenCenterRow,

        height: 150,
        }
    },
    variable: {
        borderRadius: 10,
        marginVertical: 10, 
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
    },
    login: {

        marginVertical: 10,
        paddingHorizontal: 18,
        height: 40,
        borderBottomWidth: 1,
    },
    survey: (_colors, appTheme) =>  {
        const isDark = appTheme === 'dark'
        return{
        backgroundColor: isDark ? _colors?.card: _colors?.card,
        marginVertical: 10,
        paddingHorizontal: 18,
        height: 40,
        color: _colors?.text,
        borderColor: _colors?.accent,
        borderBottomWidth: 1,
        borderStyle: 'solid' ,
        }
    },
    padding: {
        paddingHorizontal: 18
    }
}
const positionBase = {
    position: 'absolute',
    zIndex: 2,
    paddingVertical: 25,
 
}

export const buttons = {
    submit: (_colors) =>  {
        return{
        ...containers.centerRow,
        height: 50,
        backgroundColor: _colors?.accent,
        borderRadius: 12,
        // ...shadows.default(7)
        }
    },
    round: {
        ...containers.centerRow,
        height: 50,
        width: 50,
        borderRadius: 25,
        marginHorizontal: 15
    },
    bottomRight: {
        ...positionBase,
        ...containers.rightRow,
        bottom: -25,
        right: 0,
        height: 75,
    },
    topRightEdge: {
        ...positionBase,
        top: 20,
        right: 0,
        flex: 0,
        backgroundColor : 'red'
    },
    regularFill: (_colors) => {
        return{
        ...containers.centerRow,
        flex:1,
        height: 40,
        backgroundColor: _colors?.accent,
        borderRadius: 12,
        // ...shadows.default(7),
        }
    },
    disabled:{
        // backgroundColor: colors.charcoal
    },
    forth: {
        maxWidth: '25%'
    },
    half: {
        maxWidth: '50%'
    },
    regularOutline: (_colors) =>  {
        return{
        ...containers.centerRow,
        flex: 1,
        height: 40,
        backgroundColor: _colors?.card,
        borderRadius: 12,
        // ...shadows.default(7, _colors),
        marginVertical: 10,
        flexShrink: 2,
        }
    },
    base: (getWidth) => {
        return{
        ...containers.centerColumn,
        width: getWidth(80),
        marginBottom: 0,
        marginHorizontal: 0,
        // height: 40,
        paddingHorizontal: 19,
        paddingVertical: 15,
        flexShrink: 2,
        }
 
    },
    smallOutline: (_colors) => {
        return{
        ...containers.centerRow,
        // height: 40,
        paddingHorizontal: 5,
        paddingVertical: 5,
        borderRadius: 12,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: _colors?.text,
        marginVertical: 10
        }
    },
    danger: (_colors) =>  {
        return{
        ...containers.centerRow,
        flex: 1,
        height: 40,
        backgroundColor: _colors?.danger,
        borderRadius: 12,
        ...shadows.default(7),
        marginVertical: 10,
        flexShrink: 2,
        }
    },
    group:(c) =>  {
        return{ backgroundColor: c.background}
    },
    groupButton: {
        backgroundColor: 'transparent'
    },
    selected: (_colors) =>  {
        return{
            backgroundColor: _colors?.accent
        }
        
    },
    selText: (_colors) => {
        return text.accentContrast(_colors)
    },
    text: {
        // color: colors.pdark
    },
    checkbox :{
        // backgroundColor: colors.foreground,
        borderWidth: 0
    },
    logout: (_colors) =>  {
        return{
        ...containers.centerRow,
        height: 40,
        backgroundColor: _colors?.danger,
        borderRadius: 12,
        }
    },
    
}
export const today = {
    meetingTile: (_colors) => {
        return{
            backgroundColor: _colors?.card,
            width: '100%',
            borderRadius: 10,
            padding: 15,
            margin : 10,
            ...shadows.default(4, _colors)

        }
    },
    contactTile: (_colors) => {
        return{
            backgroundColor: _colors?.card,
            borderRadius: 20,
            height: 100,
            width: 'auto',
            minWidth: 150,
            ...containers.leftTopRow,
            ...shadows.avatar,
            marginRight:10
        }
    },
    outer: (_colors, _wp, full) => {
        
        return[{
            backgroundColor: _colors?.accent,
            paddingHorizontal: 15,
            paddingVertical: 20,
            borderRadius: 20,
            alignSelf: 'center',
            maxWidth: Math.min(_wp(100), 800),
            flex: 1,
        },
        shadows.default(1),       
    ]
    },
     appointmentOuter: (_colors, theme, wp, full) => {
        
        return[{
            backgroundColor: theme === 'dark' ? _colors?.card : _colors?.card,
            paddingHorizontal: 15,
            paddingVertical: 20,
            width: Platform.isPad ? wpCss(100) > 800 ?  wpCss(60) : wpCss(60) : '90%',
            alignItems: 'stretch',
            borderRadius: 20,
        },
        !full && shadows.default(1),       
    ]
    },
    title: (_colors) => {
        return{
            color: invertColor(_colors?.accent),
            ...text.bold,
            ...text.medium
        }
    }, appointmentTitle:(_colors) => {
        return{
            color: _colors?.text,
            ...text.bold,
            ...text.tiny
        }
    }, 
    subtitle: (_colors) => {
        return{
            color: invertColor(_colors?.accent),
            ...text.small
        }
    },
    listItem: {
        padding: 0,
        backgroundColor: 'transparent'
    }
}
export const dividers = {
    container: {
        ...containers.leftRow,
        height: 25,
        paddingHorizontal: 20,
    },
    title: {
        fontSize: 14,
        margin: 0 ,   
        textAlign: 'left' 
    },
    buttonTitle:{
        // color: colors.pdark,
        fontSize: 12
    },
    rightButton: {
        height: 15,
        flex: 1,
        ...containers.rightRow
    },
    leftButton: {
        height: 15,
        flex: 1,
        ...containers.leftRow
    }
}
export const alerts = {
    delete: (yesFunc, noFunc, yesTitle = 'Delete', noTitle = 'Cancel') => [
        {text: noTitle, onPress: () => noFunc(), style: 'cancel'},
        {text: yesTitle, onPress: () => yesFunc() }
    ],
    confirm:  (confirmFunc, rejectFunc) => [
        {text: 'Dismiss', onPress: () => rejectFunc()},
        {text: 'Confirm', onPress: () => confirmFunc()}
    ],
    yesno: (yesFunc, noFunc) => [
        {text: 'No', onPress: () => noFunc()},
        {text: 'Yes', onPress: () => yesFunc()}
    ],
    
    textCall: (textFunc, callFunc) => [
        {text: 'Call 📞', onPress: () => callFunc()},
        {text: 'Text 📱', onPress: () => textFunc()},
        {text: 'Cancel', onPress: () => {}}
    ],
    surveys: (editFunc, addFunc ) => [
        {text: 'Edit ✏', onPress: editFunc},
        {text: 'Add Response ➕', onPress: addFunc},
     ]
}
export const historyStyle = {
    circle: (_colors) => {return {
        width: 16,
        height: 16,
        borderRadius: 10,
        position: "absolute",
        left: 116,
        top: 0,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: _colors?.accent
    }
      },
      detailsContainerStyle: (_colors) => {
          return {
        marginBottom: 20,
        width: 'auto', 
        paddingHorizontal: 10,
        borderRadius: 10,
        ...shadows.paper
        }
    },

      timeContainerStyle: (_colors) => {
        return {
        borderColor: _colors?.accent,
        backgroundColor: _colors?.accent,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        padding: 2,
        height: 35,
        ...shadows.paper,

        }
      },
      selectedTimeContainerStyle: (_colors) => {
        return {
        borderColor: _colors?.danger,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        padding: 2,
        height: 35
        }
      },
      container: {
            flex:1
      },
      list: {
        // minHeight: hp(50),
        flex:1,
        marginTop:20,
        
      },
      title:{
        fontSize:16,
        fontWeight: 'bold'
      },
      descriptionContainer:{
        flexDirection: 'row',
        paddingRight: 50
      },
      image:{
        width: 50,
        height: 50,
        borderRadius: 25
      },
      textDescription: {
        marginLeft: 10,
        color: 'gray'
      }
}

export const surface = {
    paper : (_c, _wp) => {
        return{
          maxWidth: wpCss(95),
          width: 500,
          marginHorizontal: 'auto',
          marginVertical: 30,
          paddingHorizontal: 30,
          paddingBottom: 30,
          borderRadius: 6,
          ...shadows.paper,
          backgroundColor: _c?.card
        }
    },
    contactsTable: (_c, _wp) => {
        return{
          maxWidth: wpCss(95),
          width: 450,
          marginHorizontal: 'auto',
          marginVertical: 30,
          paddingHorizontal: 30,
          paddingBottom: 30,
          borderRadius: 6,
          ...shadows.paper,
          backgroundColor: _c?.card
        }
    },
    tile :  (_c, _wp) => {
        return{
          maxWidth: '90%',
          width: '90%',
          alignSelf: 'left',
          padding: 5,
          margin: 10,
          borderRadius: 6,
        //   ...shadows.paper,
          backgroundColor: _c?.card
        }
    },
}

import React, { useContext } from 'react'
import {
    View,
    Text,
} from 'react-native'
import { Avatar, Popover, IconButton } from '@material-ui/core'
import { containers, text} from '../theme'
import Moment from 'moment';
import _ from 'lodash';
import { SettingsContext } from '../Managers/Settings'


export const AvatarDot = props => {  
    const { first_name, last_name, time, source,  } = props
    const [anchorEl, setAnchorEl]  = React.useState(null)
    const [ open, setOpen ] = React.useState(false)
    const onPress = (event) => {
      setAnchorEl(event.currentTarget)
      setOpen(!open)
      props.onPress && props.onPress()
    }
    const  title = _.upperFirst(first_name)[0] +  _.upperFirst(last_name)[0]
    const { colors } = useContext(SettingsContext)
    const info = (
      <View style={containers.centerColumn}>
        <Avatar
          source={source ? source : require('../Images/OutreachLogo.png')}
          onClick={onPress}
        >
          <Text style={[text.tiny, text.bold, text.accentContrast(colors)]}>{title}}</Text>
        </Avatar>
        <Text style={[text.tiny, text.bold, text.accentContrast(colors)]}>{_.upperFirst(first_name) + ' ' + _.upperFirst(last_name)}</Text>
        <Text style={[text.superTiny, text.bold, text.accentContrast(colors)]}> {Moment(time).format('ll')}</Text>
      </View>
    )
    return(
      <Popover 
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        popover={info}
        backgroundColor={colors?.accent}
        open={open}
        >
          <IconButton 
            edge="end"
            aria-haspopup="true"
            color="primary"
            onClick={onPress}>
          <Avatar
            edge="end"
            aria-haspopup="true"
            rounded
            alt={require('../Images/OutreachLogo.png')}
            src={source}
            backgroundColor={colors?.accent}
            size={20}
          />
        </IconButton>
      </Popover>
    )

  }


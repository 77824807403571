import React from 'react';
import {invertColor, isColorDark, lighten, darken, colorNameToHex}  from '../Utilities';
import {first } from 'lodash';
import { useSettings } from '../Managers/Settings'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'

// export type ButtonProps= {
//     title: string,
//     onClick?: any,
//     icon?: ReactNode,
//     color?: string,
//     iconOnly?: boolean,
//     letterOnly?:boolean,
//     link?: string,
//     fill?: boolean,
//     invert?: boolean,
//     transform?: Transform
// }

const RButton = styled.button`

        background-color: ${({background}) => background};
        border: none;
        color: ${({color}) => color};
        padding: ${({iconOnly, letterOnly}) => iconOnly || letterOnly ? "5px" : "7px 15px"};
        width: ${({letterOnly}) => letterOnly ? "3em" : "auto"};
        border-radius: 3px;
        transition: all 0.2s ease-in-out;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 0.3em;
    &:hover{
        color: ${({hoverColor}) => hoverColor};
        background-color: ${({hoverBackground}) => hoverBackground};
        ${({transform}) => transform.hover};
    }

    &:focus{
        ${({transform}) => transform.focus};
    }
`

export default function Button({
    title: _title, 
    onClick, 
    onClickWithEvent,
    color: _color,
    icon,
    iconOnly,
    letterOnly,
    link,
    fill,
    invert,
    transform = Transforms.none
} ){
    const history = useHistory()
    const { colors } = useSettings()
    const isDark = false
    if(!_color){
        _color = colors.accent
    }

    // need to convert color to hex
    _color = colorNameToHex(_color)
  

    let color;
    let background;
    let hoverBackground
    let hoverColor

    if(!isDark){

         background = !fill ? "transparent" : _color + "44"
         color =  _color
    
         hoverBackground = invert ? _color : isColorDark(_color) ?  lighten(_color, 2) : lighten(_color, 3) 
         hoverColor =  invertColor(hoverBackground) 
    } else {
        background = !fill ? "transparent" : isColorDark(_color) ?  darken(_color, 8) : lighten(_color, 3)
        color =  _color
    
        hoverBackground = invert ?  darken(_color, 3) : isColorDark(_color) ?  darken(_color, 3) : darken(_color, 3) 
        hoverColor = invertColor(hoverBackground)

    }


    const title = (iconOnly ? "" : letterOnly ? `${first(_title)}` :  <span>&nbsp;{_title}</span>)
        

    const clicked = (e) => {
        if(link){
            history.push(link)
        }
        if(onClickWithEvent){
            onClickWithEvent(e)
        } else if(onClick){
            onClick()
        }

    }


    const renderButton = () => (           
         <RButton 
              hoverColor={hoverColor}
              background={background}
              hoverBackground={hoverBackground}
              color={color}
              transform={transform}
              onClick={clicked}>
            {!!icon && icon}{title}
        </RButton>
               
 
    )
    

    return(
        
        renderButton()
        
    )
}

export const Transforms = {
    scale : createTransform("scale(1)", "scale(1.1)", "scale(0.97)"),
    none: createTransform(null, null, "scale(0.95)")    
}


// type Transform = {
//     default : string
//     hover: string;
//     focus: string;    

// }

function createTransform(type = "" , hover = "", focus = ""){
    return {
        default: type ? `transform: ${type}`  : "",
        hover: hover ? `transform: ${hover}` : "",
        focus: focus ? `transform: ${focus}` : ""
    } 
}
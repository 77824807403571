import React, { useContext } from 'react';
import _ from 'lodash';
import { surface, text} from '../theme'
import { wp } from '../Fork/react-native-responsive-screen';
import {  Collapse, Grow } from '@material-ui/core';
import { FlatList } from 'react-native';
import { SettingsContext } from '../Managers/Settings';
import { ListItem } from './ListItem';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { CardHeader, SmallCard } from './card';

export const SubOrgs = props => {

    const { colors } = useContext(SettingsContext)
    const {suborgs, name} = props

    const addOrg = () => {
        props.onAddOrg && props.onAddOrg()
    }

    const renderItem = ({item}) => {
        const name = _.upperFirst(item?.name)
        const onSelect = () => {
            props.onSelect && props.onSelect(item)
        }

        return(
            <Grow in={!!item} >
                <ListItem
                    titleStyle={[text.bold, text.small, text.center, text.base(colors)]}
                    title={_.truncate(name, 25)}
                    onPress={onSelect}
                    noImageTitle={_.upperCase(_.first(item?.name))}
                    subtitleStyle={[text.tiny, text.contrast(colors)]}
                    containerStyle={{...surface.tile(colors, wp)}}
                    chevron
                    leadingImage={item?.image || '../Images/OutreachLogo.png'}
                    />
            </Grow>
        )

    }

    return(
        <Collapse in={true} >
            <div>
            <SmallCard>
                <CardHeader title={name + ' Sub-Organizations'} buttonProps={{
                    icon:  <AddRoundedIcon />,
                    color: colors.accent,
                    onClick: addOrg
                }}/>
                    <FlatList 
                        data={suborgs}
                        renderItem={renderItem}
                    />
            </SmallCard>
            </div>
        </Collapse>
    )

}


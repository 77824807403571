import React, { useEffect } from 'react'
import {
    View,
} from 'react-native';
import {  Logo,
     TextButton,
     SingleButton,
     Input,
     IntroContainer } from '../Components/exports';
import { text, containers, surface } from '../theme';
import { useSettings } from '../Managers/Settings';
import { wp} from '../Fork/react-native-responsive-screen';
import { Grow, Slide } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAlert } from '../Managers/Alert';
import { useUser } from '../Managers/UserManager';


export const Login = props => {
    const { 
      onEmailChange, 
      onPasswordChange, 
      onNext, 
      activated,
      direction,
      getTimeout,
   } = props
    const history = useHistory()
    const Alert = useAlert()
    const {colors, clear} = useSettings()

    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [passwordReset, setPasswordReset] = React.useState(false)

    const {logout, login: _login, resetPassword } = useUser()

    React.useEffect(() => {
      onEmailChange && onEmailChange(email)
    },[email])

    React.useEffect(() => {
      onPasswordChange && onPasswordChange(email)
    },[passwordReset])

 
  
    const toggleResetPassword = () => setPasswordReset(!passwordReset)

    const login = async() => {
      // console.log('login')
      if(!email) {
        Alert.warning('Email Required'); 
        return
      }

      if(passwordReset) {
        try{
          let result = await resetPassword(email)
          if(result) {
            Alert.success('Email Sent')
          }
        } catch(e) {
          Alert.error(e)
        }
        return 
      }


      if(!password) {
        Alert.error('Password Required');
        return
      }    
      try{
        await _login(email, password)
        history.push('/dashboard')
      } catch(e) {
        console.error(e)
        Alert.warning(e)
      }
        
    }

    const renderImage = () => {
      return(
        <View style={{maxHeight: 400, ...containers.topCenterColumn}} >
          <Logo  visible />
        </View>
      )
    }

   const renderLoginButton = () => {
        return(
          <SingleButton
            style={{
              alignSelf: 'center',
              borderRadius: 5, 
              marginTop: 20,
              width: 400,
              backgroundColor: colors?.primary, 
              height: 60,
            }}
            onPress={login}
            title={!passwordReset ? "Login" : "Reset"}
        />
        )

    }

    const renderBottom = () => {
      return(
  
            <View style={containers.centerColumn}>
              {!passwordReset && <Grow in={!passwordReset} >
                  <div>
                    <TextButton 
                      onPress={onNext}
                      title={('Don\'t have an account?')}
                      textStyle={text.small}
                      containerStyle={{margin: 10}}
                    />
                </div>
              </Grow>}
               <Grow in={true} >
                <div> 
                  <TextButton 
                    containerStyle={{margin: 10}}
                    textStyle={text.small}
                    title={!!passwordReset ? 'I know my password' : 'Forgot password?'}
                    onPress={toggleResetPassword}
                  />
                </div>
              </Grow>
            </View>
      )
    }
    const checkForLoginRequest = (event) => {
      // console.log(event)
    }


    
    const renderEmail = () => {
      return(
          <Input 
              returnKeyType={'next'}
              placeholder={'Email'}
              autoCorrect={false}
              autoCompleteType='email'
              autoCapitalize="none"
              textContentType={'emailAddress'}
              keyboardType={'email-address'}
              value={email}
              onChangeText={setEmail}
          />
      )
    }
  
    const renderPassword = () => {
      return(
          <Input
              placeholder={'Password'}
              secureTextEntry
              value={password}
              onChangeText={setPassword}
              onSubmitEditing={login}
              
          />
      )
    }
      
      return(
          <IntroContainer key="login-page" >
            <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
              <div>
                <View style={surface.paper(colors, wp)}>
                  {renderImage()}
                  <form onSubmit={login}>
                    <View style={{ marginHorizontal: 'auto', maxWidth: 500}}>
                      {renderEmail()}
                      {!passwordReset && renderPassword()}
                      {renderLoginButton()}
                    
                    </View>
                
                  </form>
                  {renderBottom()}
                </View>
              </div>
            </Slide>
          </IntroContainer >
        )

    }

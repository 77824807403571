import React from 'react';
import { Text } from 'react-native';
import { useSettings } from '../Managers/Settings';
import { containers, text } from '../theme';
import { EditableTag } from './Tags';
import { keys, map } from 'lodash';
import { CardHeader, SmallCard } from './card';



export const Studies = ({person}) => {
    const { colors } = useSettings()
    // console.log(person?.studies_profile?.confirmations)
    const NoProfileView = () => (
      <div style={{...containers.centerRow, padding: 10}}>
            <Text style={[text.small, text.bold, text.contrast(colors)]}>Not Registered</Text>
        </div>
    )

    const Data = () => {
      if(keys(person?.studies_profile?.confirmations)?.length === 0){
        return (
        <div style={{...containers.centerRow, padding: 10}}>
          <Text style={[text.small, text.bold, text.contrast(colors)]}>No Completed Lessons</Text>
        </div>
        )
      }
       return(
       <div style={containers.leftRowWrap}>
            {map(person?.studies_profile?.confirmations, con => {
                const title =  lessonsCards?.[con?.index - 1]?.topic 

                const name = `Lesson ${con?.index}: ${title ? title : ""}`;
                const color = con?.response === "true" ? "#00EE00" : "#EE0000";
                return (
                    <EditableTag key={name} tag={{name, color}} selected/>
                )
            })}
            
        </div>
      )
    }
    return(
        <SmallCard >
            <CardHeader 
              title={'Bible Studies'} 
              subtitle={person?.studies_profile && "Registered"}
              style={{paddingHorizontal: 0}} />    
            {/* <Text>TESTING VERSION</Text> */}
            {!person?.studies_profile && NoProfileView()} 
            {!!person?.studies_profile && Data()}
        </SmallCard>
    )
}


const lessonsCards = [
    {
      'id': 1,
      'title': 'Can God Be Trusted?',
      'topic': "Bible Validity"
    },
    {
      'id': 2,
      'title': 'Seeing the Signs',
      'topic': "Last Day Events"
    },
    {
      'id': 3,
      'title': 'Why does God Allow Suffering?',
      'topic':"Suffering" 
  },
    {
      'id': 4,
      'title': 'Hope for a Planet in Crisis',
        'topic':"Sin Origins"
    },
    {
      'id': 5,
      'title': 'As Good as It Gets',
        'topic':"Heaven"
    },
    {
      'id': 6,
      'title': 'Peace On Earth',
        'topic':"10 Commandments"
    },
    {
      'id': 7,
      'title': 'Quality Time',
        'topic':"Sabbath"
    },
    {
      'id': 8,
      'title': 'The Day That Disappeared',
        'topic':"Sabbath"
    },
    {
      'id': 9,
      'title': 'The War Is Over',
        'topic':"Surrender"
    },
    {
      'id': 10,
      'title': 'The Mystery of Death',
        'topic':"State of the Dead"
    },
    {
      'id': 11,
      'title': 'Revelation\'s 1,000 Years',
        'topic':"Millennium"
    },
    {
      'id': 12,
      'title': 'Hellfire: Would God Really Do That?',
        'topic':"Hell"
    },
    {
      'id': 13,
      'title': 'The Second Coming of Jesus',
        'topic':"Second Coming"
    },
    {
      'id': 14,
      'title': 'Buried Alive?',
        'topic':"Baptism"
    },
    {
      'id': 15,
      'title': 'Living Life to the Fullest!',
        'topic':"Health"
    },
    {
      'id': 16,
      'title': 'Rebuilding the Temple',
        'topic':"Sanctuary"
    },
    {
      'id': 17,
      'title': 'The Messiah and the Judgment',
        'topic':"Judgement"
    },
    {
      'id': 18,
      'title': 'The Mystery Beast of Revelation',
        'topic':"Revelation 13"
    },
    {
      'id': 19,
      'title': 'The Mark of the Beast',
        'topic':"Mark of the Beast"
    },
    {
      'id': 20,
      'title': 'The United States in Bible Prophecy',
        'topic': "U.S. in Prophecy"
    },
    {
      'id': 21,
      'title': 'In Search of the Church',
        'topic':"Church"
    },
    {
      'id': 22,
      'title': 'The Fall of Babylon',
        'topic':"Babylon"
    },
    {
      'id': 23,
      'title': 'Prophets and Prophecy',
        'topic':"Prophets"
    },
    {
      'id': 24,
      'title': 'More Than Enough',
        'topic':"Tithe"
    },
    {
      'id': 25,
      'title': 'The Gift of the Spirit',
        'topic':"Holy Spirit"
    }
  ];

import React, { useContext, useState } from 'react'
import _ from 'lodash';
import { createManagerInfo } from '../Utilities';
import Moment from 'moment';
import { ORG_TABLE} from '../Constants';
import { useNetwork } from './Database';
import { useUser } from './UserManager';
import { useStorage } from './Storage';


const OrgsContext = React.createContext()
export const OrgsProvider = ({children}) => {
    const [orgs, setOrgs] = useState([])

    let { user } = useUser()
    const Database = useNetwork()
    const Storage = useStorage()
    
    const managerName = createManagerInfo('ORG_MANAGER', 1)


    const getOrgs = async (force = false) => {
      try{
        let response = await Database.getCachePriority('/orgs',force)
        setOrgs(response)
        Storage.setEncryptedTable(response, ORG_TABLE)
        return response
      } catch (e){
        return Promise.reject(e.error)
      }
    }
  
  
   const getOrg = async (id) => {
     
    try{
     return Database.get('/orgs/' + id)
    } catch(e) {
      return Promise.reject(e.error)
    }
   }
  
    
  
    const getOrgSurveys = async (id) => {
      try{
      if(id === null || id === undefined){ return Promise.reject('No Org Identification Provided')}
      let surveys = Database.get(`/orgs/${id}/surveys`)
      return surveys
    } catch(e) {
      return Promise.reject(e.error)
    }
    }
  
  
    const getContactsForOrg = async (id) => {
      try{
        if(!id) {return Promise.reject('No Organization Identification Provided')}
        let response = await Database.getCachePriority(`/orgs/${id}/contacts`)
        return response
      } catch(e) {
        return Promise.reject(e.error)
      }
    }

    const create = async (org) => {
      try{
        let newOrg = await Database.post('/orgs', org, managerName)
        _addToVisibleList(newOrg)
        await generateOrgCode(newOrg.id)
        return newOrg
      } catch(e) {
        if(e.error) { return Promise.reject(e.error) }
        else { return Promise.reject(e)}
      }
    }
  
    const createTeam = async (team) => {
      if(!team){return Promise.reject('No team information provided')}
      let response = await Database.post(`/teams`, team, managerName)
      return response
      
    }
  
    const _addToVisibleList = (org) =>{
      const _orgs = _.unionWith(orgs, [org], (a,b) => a.id === b.id)
      setOrgs(_orgs)
    }
  
    const updateOrg = async (org) => {
        if(!org.id){return Promise.reject('Organization is missing necessary identification.')}
        let success = await Database.put('/orgs/' + org.id, org, managerName)
        const _orgs = orgs.map(u_org => {
            if(u_org.id === org.id){
              return u_org
            } else {
              return org
            }
          })
        setOrgs(_orgs)
        return success
    }

    const setOrgImage =  async (org, image) => {
      if(!org || !image) { return Promise.reject('No Org Or Image')}
      try{
        org.image = image
        // do update when you get a function
        return Database.put(`/orgs/${org.id}`, org, managerName)
      } catch(e) {
        return Promise.reject(e.error)
      }
    }

    const updateTagColor = async(org, color) => {
      if(!org || !org.id || !color){ return Promise.reject('No Org or Color Provided')}
      try{
        return Database.put(`/orgs/${org.id}/color`, color, managerName)
      }catch(e) {
        return Promise.reject(e.error)
      }
    }
  
    const generateOrgCode = async (id) => {
      try{
        if(id === null || id === undefined){return Promise.reject('No Organization Identification Provided')}
        return Database.post(`/orgs/${id}/codes`, null, managerName)
      } catch(e) {
        return Promise.reject(e.error)
      }
      
    }
    
    const generateTeamCode = async (id) => {
      try{
        if(id === null || id === undefined){ return Promise.reject('No Organization Identification Provided')}
        return Database.post(`/teams/${id}/codes`,null, managerName)
      } catch(e) {
       return Promise.reject(e.error)
      }
    }
  
    const deleteOrgCode = async (org_id, code) => {
      try{
        if(org_id === null || code === null){ return Promise.reject('Proper Identification Missing')}
        return Database.remove(`/orgs/${org_id}/codes/${code}`)    
      } catch(e) {
        return Promise.reject(e.error)
      }
    }
  
    const deleteTeamCode = async (team_id, code) => {
      try{
        if(team_id === null || code === null){ return Promise.reject('Proper Identification Missing')}
        return Database.remove(`/teams/${team_id}/codes/${code}`)
      } catch(e) {
        return Promise.reject(e.error)
      }
    }
  
    const promote = async (aUser, org) => {
      try{
        if(!_.has(aUser, 'id') || !_.has(org, 'id')){
          Promise.reject('Missing user to promote or Organization Identification')  
        }
        let success = await Database.put(`/orgs/${org.id}/${aUser.id}/promote`,null, managerName)      
        return success
      } catch(e) {
        return Promise.reject(e.error)  
      }
    }

    const deleteMember = async (aUser, org) => {
      try{
        if(aUser?.id || org?.id){
          Promise.reject('Missing user to delete from Organization Identification')  
        }
        let success = await Database.remove(`/orgs/${org.id}/${aUser.id}`,null, managerName)      
        return success
      } catch(e) {
        return Promise.reject(e.error)  
      }
    }   

    const getCodeForOrg = async (id) => {
      let codes = await getValidCodes(id)
      if(!!codes && _.compact(codes).length > 0){return _.compact(codes)}
      let code = await generateOrgCode(id)

      // console.log('\n\n\n\n', code)
      return [code]

      

    }

    const getValidCodes = async (id) => {
      let codes = await getOrgCodes(id)
      codes = _.map(codes, code => {
        if(Moment(code.expires_at).isAfter(Moment())){
          return code
        }
      })
      return _.compact(codes)
    }
    
    const getOrgCodes = async (id) => Database.get(`/orgs/${id}/codes`)
  
    const getTeamCodes = async (id) => Database.get(`/teams/${id}/codes`)  
  
    const leave = async (org_id) => removeFromOrg(org_id, user.id)
    
    const leaveTeam = async (team_id) => removeFromTeam(team_id, user.id)
    
    const removeFromOrg = async (org_id, user_id) => {
      if(org_id === null || org_id === undefined){ return Promise.reject('No Organization Identification Found')}
      if(user_id === null || user_id === undefined){ return Promise.reject('No User Identification Found')}
      try{
        await Database.remove(`/orgs/${org_id}/${user_id}`)
        await getOrgs()
        return true
      } catch(e) {
        return Promise.reject('There was a problem deleting the selected user.')
      }
    }
  
    const removeFromTeam = async (team_id, user_id) => {
      if(team_id === null || team_id === undefined){ return Promise.reject('No Organization Identification Found')}
      if(user_id === null || user_id === undefined){ return Promise.reject('No User Identification Found')}
      try{
        await Database.remove(`/teams/${team_id}/${user_id}`)
        return true
      } catch(e) {
        return Promise.reject('There was a problem deleting the selected user.')
      }
    }

    const getTeams = async (id) => Database.get(`/orgs/${id}/teams`)
  
    const getMembers = async (id) => {
      if(id === null || id === undefined) {return Promise.reject('No Identification Provided.')}
      let members = await Database.get(`/orgs/${id}/members`)
      return members
    }
  
    const isTeamLeader = (id) => user && user.id === id && user.id !== null

    const apply = async (code) => {
      if(code !== null) {
        try{
          code = _.trim(code)
          // console.log(code)
      let response =  await Database.post(`/orgs/join/${code}`, null, managerName)
        getOrgs()
        return response
        } catch(e) {
          console.error(e)
          if(!!e && e.status === 404){
            return Promise.reject(`We couldn't find any organizations with an access code of "${code}".`)
          }
          return Promise.reject(e.error)
        }
      } else {
        return Promise.reject('No Code Provided')
      }
    }
  


    const getCategoriesForOrg = async (orgId) => {
      if(!orgId){ return Promise.reject('No Org Identification was provided')}
      try{
        let cats = await Database.get(`/orgs/${orgId}/tag_categories`, true)
        // console.log(cats)
        return cats
      } catch(e) {
        return Promise.reject(e.error)
      }
    }
  
    const getTagsForCategory = async (orgId, catId ) => {
      if(!orgId){ return Promise.reject('No Org Identification was provided')}
      if(!catId){ return Promise.reject('No Category Identification was provided')}
      // console.log(`/orgs/${orgId}/tag_categories/${catId}/tags`)
      try{
        let tags = await Database.get(`/orgs/${orgId}/tag_categories/${catId}/tags`, true)
        return tags
      } catch(e) {
        return Promise.reject(e.error)
      }
    }

    const createTagCategory = async (orgId, category ) => {
      if(!orgId){ return Promise.reject('No Org Identification was provided')}
      if(!category){ return Promise.reject('No category was provided')}
      if(!category.name){ return Promise.reject('Each category needs a name.')}
      try{
        const newCat = await Database.post(`/orgs/${orgId}/tag_categories/`, category)
        return newCat
      } catch(e) {
        return Promise.reject(e.error)
      }
    }

    const updateTagCategory = async (orgId, category ) => {
      if(!orgId){ return Promise.reject('No Org Identification was provided')}
      if(!category){ return Promise.reject('No category was provided')}
      if(!category.name){ return Promise.reject('Each category needs a name.')}
      if(!category.id) { return Promise.reject('No category identification was provided')}
      try{
        const newCat = await Database.put(`/orgs/${orgId}/tag_categories/${category.id}`, category)
        return newCat
      } catch(e) {
        // console.error(e)
        return Promise.reject(e.error)
      }
    }

    const deleteTagCategory = async (orgId, catId ) => {
      if(!orgId){ return Promise.reject('No Org Identification was provided')}
      if(!catId){ return Promise.reject('No Category Identification was provided')}
      try{
        await Database.remove(`/orgs/${orgId}/tag_categories/${catId}/`)
        return getCategoriesForOrg(orgId)
      } catch(e) {
        return Promise.reject(e.error)
      }
    }

    const createTag = async (orgId, catId, tag ) => {
      if(!orgId){ return Promise.reject('No Org Identification was provided')}
      if(!catId){ return Promise.reject('No Category Identification was provided')}
      if(!tag){ return Promise.reject('No Tag was provided')}

      try{
        const newTag = await Database.post(`/orgs/${orgId}/tag_categories/${catId}/tags`, tag)
        return newTag
      } catch(e) {
        return Promise.reject(e.error)
      }
    }

    const deleteTag = async (orgId, catId, tagId ) => {
      if(!orgId){ return Promise.reject('No Org Identification was provided')}
      if(!catId){ return Promise.reject('No Category Identification was provided')}
      if(!tagId){ return Promise.reject('No Tag Identification was provided')}

      try{
        await Database.remove(`/orgs/${orgId}/tag_categories/${catId}/tags/${tagId}`)
        return getTagsForCategory(orgId, catId)
      } catch(e) {
        return Promise.reject(e.error)
      }
    }
  
    return(
      <OrgsContext.Provider value={{
        orgs,
        getOrgs,
        getOrg,
        getOrgSurveys,
        getContactsForOrg,
        create,
        createTeam,
        _addToVisibleList,
        updateOrg,
        setOrgImage,
        updateTagColor,
        generateOrgCode,
        generateTeamCode,
        deleteOrgCode,
        deleteTeamCode,
        promote,
        deleteMember,
        getCodeForOrg,
        getValidCodes,
        getOrgCodes,
        getTeamCodes,
        leave,
        leaveTeam,
        removeFromOrg,
        removeFromTeam,
        getTeams,
        getMembers,
        isTeamLeader,
        apply,
        getCategoriesForOrg,
        getTagsForCategory,
        createTagCategory,
        updateTagCategory,
        deleteTagCategory,
        createTag,
        deleteTag,

      }}>
        {children}
      </OrgsContext.Provider>
    )    
}


  
export const useOrgs = () =>  useContext(OrgsContext)
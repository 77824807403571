import React from 'react';
import {ChevronLeftRounded} from '@material-ui/icons'
import { useSettings } from '../Managers/Settings';

export const Left = props => {

   const { colors } = useSettings()
    return( 
     <ChevronLeftRounded 
        fontSize={'large'}
        style={{color: 'white'}} />
    )
  }
import React from 'react';
import {
    View,
} from 'react-native';
import {
    Paper
} from '@material-ui/core';
import _ from 'lodash';
import { wp } from '../Fork/react-native-responsive-screen';
import { Input, TwoButtonOption, SectionBar, AddressInput } from '../Components/exports';
import {  Navigation } from '../Managers/exports';
// import { useStyles } from '../Components/OutreachStyles'
// import {  OutreachTableToolbar, } from '../Components/OutreachTables';
import { containers } from '../theme';
import { formatPhone } from '../Utilities';
import { useOrgs } from '../Managers/Orgs';
export function OrgEdit({ org: _org }) {

    const [org, setOrg] = React.useState(null)
    // const classes = useStyles();
    const OrgsManager = useOrgs()
    React.useEffect(() => {
        if(!_org?.id){ return }
        OrgsManager.getOrg(_org.id)
        .then(setOrg)
        .catch(console.error)
        }, [_org])

    const onChangeAddress = (value) => {
        // console.log(value)
        // console.log(org)
        let _org = org
        _org.address = value
        setOrg(_org)
    }

    const onChangeFields = (value) => {
        // console.log(value)
        let _org = org
        _org.email = value.email
        _org.name = value.name
        _org.phone = value.phone
    }

    const onCancel = () => {
        Navigation.gotoOrgs();
    };

    const submit = async () => {

        try {
            // console.log(org)
            await OrgsManager.update(org)

            Navigation.gotoOrgs();

        } catch (e) {
            console.log('Whoops', e)

        }
    }



    let toolBarProps = {
        numSelected: 0,
        headerValue: "Edit Org",
        showAdd: false
    }

    if (org === undefined) {
        return (<div />)
    }

    return (
        <>
            <View

                keyboardShouldPersistTaps={'handled'}
                style={{ alginSelf: 'stretch' }}
                scrollEnabled={true}>
                <Paper style={{ maxWidth: wp(70) }}>
                    <View  style={{ marginHorizontal: 10, alignSelf: 'stretch' }}>
                        {/* <OutreachTableToolbar {...toolBarProps} /> */}
                        <SectionBar title={'Edit Org'} />
                        <View style={[containers.leftRowWrap, { alignSelf: 'stretch' }]}>
                            {!!org ? <EntityFields
                                org={org}
                                onChange={(o) => onChangeFields(o)}
                                orgEmail={org?.email}
                                orgName={org?.name}
                                orgPhone={org?.phone}
                            />
                                : null}
                            <View style={containers.leftTopColumn}>
                                <SectionBar title={'Address'} />
                                <AddressInput
                                    key={_.random(0, 0xffffff)}
                                    address={org?.address}
                                    style={{ marginHorizontal: 20 }}
                                    onChange={address => onChangeAddress(address)} />
                            </View>
                            <View >
                            </View>
                        </View>
                        <View style={[containers.leftRowWrap, orgStyles.subContainer, { marginBottom: 40 }]}>
                            <TwoButtonOption
                                onLeftButtonPress={onCancel}
                                onRightButtonPress={submit}
                                leftTitle={'Cancel'}
                                rightTitle={'Submit'} />
                        </View>
                    </View>
                </Paper>
            </View>

        </>
    )
}

export const EntityFields = props => {
    const {immutable, entity, errorKey, nameErrors, emailErrors, phoneErrors} = props
    let [email, setEmail] = React.useState(props.email)
    let [name, setName] = React.useState(props.name)
    let [phone, setPhone] = React.useState(props.phone)
    
    const { nameTitle, phoneTitle, emailTitle} = props
    const onEmailChange = (value) => {
        setEmail(value)
        
    }
    React.useEffect(() => {
    
        setName(props.name)
        setEmail(props.email)
        setPhone(props.phone)
    },[])

    
    

    const onNameChange = (value) => {
        setName(value)
        
    }

    const onPhoneChange = (value) => {
        let _phone = formatPhone(value)
        setPhone(_phone)
        
    }

    React.useEffect(() => {
        props.onChange && props.onChange({
            ...entity,
            name, 
            email,
            phone
        })
    },[name, email, phone])

    return (
            <  >
                <Input
                    hasError={_.includes(nameErrors, errorKey)}
                    editable={!immutable}
                    underlineColorAndroid="transparent"
                    disabled={props.nameDisabled}
                    placeholder={nameTitle || `${props.entityType || ''} Name`}
                    value={ name || ''}
                    onChangeText={onNameChange}
               />
                <View style={{width: '100%', ...containers.betweenRow}}>
                    <Input
                        hasError={_.includes(phoneErrors, errorKey)}
                        editable={!immutable}
                        underlineColorAndroid="transparent"
                        placeholder={phoneTitle ||`${props.entityType || ''} Telephone`}
                        value={ phone || ''}
                        disabled={props.phoneDisabled}
                        onChangeText={onPhoneChange}
                        style={{ flex: 1, marginRight: 5 }}
                  />
                    <Input
                        hasError={_.includes(emailErrors, errorKey)}
                        editable={!immutable}
                        underlineColorAndroid="transparent"
                        disabled={props.emailDisabled}
                        placeholder={emailTitle || `${props.entityType || ''} E-mail`}
                        value={ email || ''}
                        onChangeText={onEmailChange}
                        style={{ flex: 1 }}
                 />
                </View>
            </>
                
 
    )
}

export const orgStyles = {
    container: (colors) => {
        return {
            backgroundColor: colors?.background,
            justifyContent: 'flex-start',
            width: '100%',
            maxWidth: wp(100)
        }

    },
    outerContainer: (colors) => {
        return {
            backgroundColor: colors?.danger,
            alignSelf: 'center',
            maxWidth: wp(100),


        }
    },
    subContainer: {
        paddingVertical: 10,

    }
}

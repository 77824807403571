
import { Search, Local, MemberLocal} from './Search';
// import { MapManagement } from './Map';
// import { Lookups } from './Lookups';

// import { FirebaseManager } from './Firebase';

export const LocalSearch = new Local();
export const SearchEngine = new Search();
export const MemberSearch = new MemberLocal();
// export const MapManager = new MapManagement()
// export const LookupManager = new Lookups();
// export const Firebase = new FirebaseManager()




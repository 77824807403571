import React, { useContext } from 'react';
import { SettingsContext } from '../Managers/Settings';
import { motion } from "framer-motion"
import { containers } from '../theme'

export const LoadingAnimation = React.memo(props => {
  let width = 150;
  let height = 150;
  let gap = 1.3;

  const outer = {
    borderRadius: '50%',
    position: 'absolute',
    width: width - 20,
    height: height - 20,
    zIndex: 1,
    top: 'calc(50% - ' + {height},
    left: 'calc(50% - ' + {width},
  };
  
  const inner = {
    borderRadius: '50%',
    position: 'absolute',
    width: (width - 20) / gap,
    height: (height - 20) / gap,
    top: 'calc(50% - ' + {height} + ' / ' + {gap} + ')',
    left: 'calc(50% - ' + {width} + '  / ' + {gap} + ')',
    zIndex: 2,
  };

  const { colors } = useContext(SettingsContext)
      return(
        <div style={{width: width, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <motion.div
            style={{
              background: colors?.accent?.base,
              ...outer,
            }}
            animate={{
              // y: [-40, -100, -40, -100, -40],
              scale: [1, 1.5, 1, 1.5, 1],
              rotate: [0, 0, 180, 0, 0],
              // borderRadius: ["50%", "50%", "40%", "50%", "50%"]
            }}
            transition={{
              duration: 3,
              ease: "easeInOut",
              times: [0, 0.2, 0.4, 0.6, 1],
              loop: Infinity,
              repeatDelay: 0,
            }}
          />
          <motion.div
            style={{
              background: colors?.background,
              ...inner,
            }}
            animate={{
              // y: [-40, -100, -40, -100, -40],
              scale: [1, 1.5, 1, 1.5, 1],
              rotate: [0, 0, 180, 0, 0],
              // borderRadius: ["50%", "50%", "40%", "50%", "50%"]
            }}
            transition={{
              duration: 3,
              ease: "easeInOut",
              times: [0, 0.2, 0.4, 0.6, 1],
              loop: Infinity,
              repeatDelay: 0,
              delay: 0.3
            }}
          />
        </div>
      )
})

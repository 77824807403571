import React, { useRef } from 'react';
import { IconButton } from '@material-ui/core';
import { 
    View,
    Text, 

 } from 'react-native';
import { containers, text } from '../theme';
import { Left, Right} from '../Components/exports';
import { wpCss } from '../Fork/react-native-responsive-screen';
import { debounce } from 'lodash';

export const BottomButtons = props => {
    const onNext = () => {
        props?.onNext && props.onNext()
    }


    const onBack = () => {
        props?.onBack && props.onBack()
    }

 
    const tstyle = [text.bold, text.white, text.small]
    return(
        <View  style={{flex: 1, ...containers.centerBottomColumn, paddingBottom: 50}}>
            <View  style={{...containers.aroundRow, width: wpCss(100)}}>
                <IconButton 
                    onClick={onBack}
                    style={{...containers.betweenRow, margin: 20}}>
                    <Left />
                    <Text style={tstyle}>{props.leftTitle || 'Back'}</Text>
                    
                </IconButton>
                <IconButton 
                    onClick={onNext}
                    style={{...containers.betweenRow, margin: 20}}>
                    <Text style={tstyle}>{props.rightTitle || 'Continue'}</Text>
                    <Right />
                </IconButton>
            </View>
         </View>
    )
 }

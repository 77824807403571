import React from 'react'
import { set } from 'lodash';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';


export const Table = props => {

    let {data, onClick, columns, options, title, onRowsDelete, onDownload, onViewColumnsChange} = props


    const onRowClick = (row, meta) => {
      let obj = {}
      for(let index in columns){
        set(obj, `${columns[index]?.name}`, row[index])
      }
      onClick(obj)
    }

    const _onRowsDelete = async (rowsDeleted, data, newTableData) => {
        /**
         * 	Callback function that triggers when row(s) are deleted. 
         * function(rowsDeleted: object(lookup: {[dataIndex]: boolean},
         *  data: arrayOfObjects: {index: number, dataIndex: number}), 
         * newTableData) => void OR false (Returning false prevents row deletion.)
         */
         if(onRowsDelete){
             if(onRowsDelete().then){
                return await onRowsDelete(rowsDeleted,data, newTableData)
             } else {
                 return onRowsDelete(rowsDeleted,data, newTableData)
             }
            
         }  else {
             return false
         }
        
    }
    const onCellClick = (info, meta, index, dindex) => {
        let item = data[meta.dataIndex]
        onClick(item, meta)
    }

    const _onDownload = (buildHead, buildBody, columns, data) => {
        // function(buildHead: (columns) => string, buildBody: (data) => string, columns, data) => string
        if(onDownload){
            return onDownload(buildHead, buildBody, columns, data)
        } else {
            return String(buildHead(columns) + buildBody(data))
        }
    }

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            paper: {
                width: '100%'
            },
            MUIDataTableBodyCell: {
                root: {
                    padding: 2,
                },
            
            },
            MuiTableCell: {
                root:{
                    textAlign: "center"
                }
            },
            MUIDataTableHeadCell:{
                root:{
                    justifyContent: 'center'
                },
                contentWrapper:{
                    justifyContent: 'center'
                }
            } 
        } 
    })

    return(
     <MuiThemeProvider theme={getMuiTheme()}> 
            <MUIDataTable 
                title={title}
                data={data} 
                columns={columns} 
                options={{
                    onCellClick,
                    onDownload: _onDownload,
                    onRowsDelete: _onRowsDelete,
                    onViewColumnsChange,
                    elevation: 0,
                    selectToolbarPlacement: 'above',
                    filterType: 'checkbox',
                    searchPlaceholder: 'Search',
                    enableNestedDataAccess: ".",
                    // responsive: 'standard',
                    textLabels:{
                        selectedRows: {
                            delete: "Archive",
                            deleteAria: "Archive Selected Rows",
                        }
                    },
                    print: false,
                    downloadOptions: {
                        filename: `Outreach_Data_${dayjs().format('DD/MM/YYYY')}`,
                        filterOptions: {
                            useDisplayedColumnsOnly: true,
                            useDisplayedRowsOnly: true,
                            useSelectedRowsWhenSelected: true,

                        }
                    },
                    ...options
                }}
            />
        </MuiThemeProvider>
    )
}
import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { text, buttons ,  containers } from '../theme';
import { invertColor } from '../Utilities';
import _ from 'lodash';
import { useSettings } from '../Managers/Settings';
import { wp } from '../Fork/react-native-responsive-screen';
import { Button } from '@material-ui/core';

export const TwoButtonOption = React.memo(props => {

    const rightAction = () =>  {
        props.rightAction && props.rightAction()

        //depricated
        props.onRightButtonPress && props.onRightButtonPress()
    }
    const leftAction = () => {
        props.leftAction && props.leftAction() 
        console.log()
        //depricated
        props.onLeftButtonPress && props.onLeftButtonPress()
    }

        const { colors } = useSettings()

          return(
            <View style={{...containers.centerRow, width:'100%'}}>
              <Button variant="outlined" size="large" color="primary"  onClick={leftAction}>
                 <Text style={
                    [text.tiny, 
                    text.bold, 
                    text.base(colors)]}
                >
                    {props.leftTitle ? props.leftTitle : 'CANCEL'}
                </Text>
              </Button>
              <View style={{marginHorizontal: 10}} />
              <Button variant="contained" size="large" color="primary"  onClick={rightAction}>
                 <Text style={
                     [text.tiny, 
                     text.bold, 
                     text.accentContrast(colors)
                     ]}
                     >
                    {props.rightTitle ? props.rightTitle : 'SAVE'}
                </Text>
              </Button>
          </View> 
          )
})

export const TextButton = (props) => {   

    const { colors } = useSettings()
    let { containerStyle, textStyle, title, onPress} = props
    containerStyle = containerStyle || {}
    return(
        <TouchableOpacity 
            style={{paddingHorizontal: 20, marginVertical:10, ...containerStyle}}
            onPress={() => _.attempt(onPress)}
        >
            <Text style={[text.large, text.contrast(colors), textStyle ? textStyle : {}]}>{title}</Text>    
        </TouchableOpacity>
    )

}

export const BlockButton = (props) => {   
    const { colors } = useSettings()
    
    const button = {
        color: colors?.foreground,
    }
    return(
        <TouchableOpacity 
            style={buttons.submit(colors)} 
            onPress={() => _.attempt(props.onPress)}>
            <Text style={[ button, text.bold ]}>{props.title}</Text>
        </TouchableOpacity>
    )
}


export const FullButton = (props) => {
    const padding = props.padding ? 15 : 5
    const textStyle = [
        text.small, 
        {color: 'white'},
        { marginVertical: 10}, props.textStyle ? props.textStyle : {}]
    let dis = props.disabled
    const style = [
        buttons.base(wp), 
        containers.centerColumn,
        {paddingVertical: padding,} 
        ,dis ? buttons.disabled : {} ,
            props.style ? props.style : {}]
    return(
        <TouchableOpacity style={style} onPress={() => _.attempt(props.onPress)}>
                <Text style={textStyle} >{props.title}</Text>
        </TouchableOpacity>
    )

    
}



export const SingleButton = (props) => {
    const { colors } = useSettings()
    const {  textStyle, containerStyle, buttonStyle, title, onPress, shadow } = props        
    return (
        <View style={[
            
            containers.aroundRow, 
            {minWidth: 100},
            containerStyle ? containerStyle : null,
            ]}>
            <TouchableOpacity 
                style={[buttons.regularFill(colors), buttonStyle, shadow, ]} 
                onPress={() => _.attempt(onPress)}
            >
                <Text style={[text.tiny,text.bold, text.accentContrast(colors), textStyle]}>{title ? title : 'Save'}</Text>
            </TouchableOpacity> 
        </View> 
    )
}


export const SmallButton = (props) => {
    const { colors } = useSettings()
    const button = {
        color: colors?.foreground,
    }
    const width = [
        {width: props.width},
        {margin: 'auto'}
    ]

    return(
        <TouchableOpacity 
            style={[buttons.logout(colors), width]} 
            onPress={() => _.attempt(props.onPress)}>
            <Text style={[ button, text.bold, text.tiny ]}>{props.title}</Text>
        </TouchableOpacity>
    )
}


export const RoundedButton = (props) => {
    const { colors } = useSettings()
    const button = {
        color: invertColor(colors?.accent),
    }
    const btnStyle = [
        {width: props.width},
        {height: props.height},
        {margin: 'auto'},
        {backgroundColor: (colors?.accent)},
    ]
    const containerStyle = props.containerStyle
    return(
        <View style={[                  
            containers.aroundRow, 
            {minWidth: 100},
            containerStyle ? containerStyle : null,
            ]}>
            <TouchableOpacity 
                style={[buttons.round, btnStyle]} 
                onPress={() => _.attempt(props.onPress)}>
                <Text style={[ button, text.bold, ]}>{props.title}</Text>
            </TouchableOpacity>
        </View>
    )

}
import React from 'react';
import { View } from 'react-native';
import { containers } from '../theme';


export const IntroSection = props => {
    const style = props.style || {}
    // const flex = props.flex || 1
    return(
        <View style={{width: '100%', alignSelf: 'center', ...containers.centerColumn, position: 'relative',  ...style}}>
            {props.children}
        </View>
    )
}
import React from 'react';
import { ParticleBackdrop, LoadingAnimation } from '../Components/exports';
import { containers } from '../theme'
import { wpCss, hpCss } from '../Fork/react-native-responsive-screen';

export const Loading = React.memo(props => {
// console.log("loading")
  return (
    <>
      <ParticleBackdrop />
      <div style={{
        height: hpCss(100),
        ...containers.centerColumn, 
        ...containers.centerRow}} >
        <LoadingAnimation />
      </div>
    </>
  )
})

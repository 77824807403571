"use strict";

import React from "react";
import {
  StyleSheet,
  Image,
  View,
  Text,
  TouchableOpacity,
  FlatList,
} from "react-native";
import _ from 'lodash';
import { motion} from 'framer-motion'


const defaultCircleSize = 16;
const defaultCircleColor = "#007AFF";
const defaultLineWidth = 2;
const defaultLineColor = "#007AFF";
const defaultTimeTextColor = "black";
const defaultDotColor = "white";
const defaultInnerCircle = "none";

export default class Timeline extends React.Component {
  constructor(props, context) {
    super(props, context);

    this._renderRow = this._renderRow.bind(this);
    this.renderTime = (this.props.renderTime
      ? this.props.renderTime
      : this._renderTime
    ).bind(this);
    this.renderDetail = (this.props.renderDetail
      ? this.props.renderDetail
      : this._renderDetail
    ).bind(this);
    this.renderCircle = (this.props.renderCircle
      ? this.props.renderCircle
      : this._renderCircle
    ).bind(this);
    this.renderEvent = this._renderEvent.bind(this);

    this.state = {
      data: this.props.data,
      // dataSource: ds.cloneWithRows(this.props.data),
      x: 0,
      width: 0
    };
  }
  componentDidUpdate(prev){
    if(!_.isEqual(prev, this.props)){
      this.setState({data: this.props.data})
    }
  }


  render() {
    const variants = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          delayChildren: 0.5,
        },
      },
      hidden: {
        opacity: 0,
        transition: {
          when: "afterChildren",
        },
      },
    }
    return (
      
      <View style={[styles.container, this.props.style]}>
      <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
      >
        <FlatList 
          ref="listView"
          style={[styles.listview, this.props.listViewStyle]}
          data={this.state.data}
          extraData={this.state}
          renderItem={this._renderRow}
          keyExtractor={(item, index) => String(JSON.stringify(item))}
          automaticallyAdjustContentInsets={false}
          {...this.props.options}
        />
        </motion.div>

        
      </View>
    );
  }

  _renderRow({item: rowData, index: rowID}) {
    let content = null;
    switch (this.props.columnFormat) {
      case "single-column-left":
        content = (
          <View style={[styles.rowContainer, this.props.rowContainerStyle]}>
            {this.renderTime(rowData, null, rowID)}
            {this.renderEvent(rowData, null, rowID)}
            {this.renderCircle(rowData, null, rowID)}
          </View>
        );
        break;
      case "single-column-right":
        content = (
          <View style={[styles.rowContainer, this.props.rowContainerStyle]}>
            {this.renderEvent(rowData, null, rowID)}
            {this.renderTime(rowData, null, rowID)}
            {this.renderCircle(rowData, null, rowID)}
          </View>
        );
        break;
      case "two-column":
        content =
          rowID % 2 === 0 ? (
            <View style={[styles.rowContainer, this.props.rowContainerStyle]}>
              {this.renderTime(rowData, null, rowID)}
              {this.renderEvent(rowData, null, rowID)}
              {this.renderCircle(rowData, null, rowID)}
            </View>
          ) : (
            <View style={[styles.rowContainer, this.props.rowContainerStyle]}>
              {this.renderEvent(rowData, null, rowID)}
              {this.renderTime(rowData, null, rowID)}
              {this.renderCircle(rowData, null, rowID)}
            </View>
          );
        break;
    }

    const item = {
      visible: { opacity: 1, y: 0, scale: 1},
      hidden: { opacity: 0, y: 20, scale: 0.8 },
      exit: { opacity: 0, y: 20, scale: 0.8 }
    }

    return (
      <motion.div 
        variants={item}
        exit="exit"
        transition={{
          delay: rowID * (0.05) + 0.7,
          x: { type: "spring", stiffness: 100 },
          scale: { type: "spring", stiffness: 100 },
        }}
        >
        <View key={rowID}>
          {content}
        </View>
      </motion.div>);
  }

  timePressed = (rowData) => {
    if(this.props.timePressed) {
      this.props.timePressed(rowData)
    }
  }

  _renderTime(rowData, sectionID, rowID) {
    if (!this.props.showTime) {
      return null;
    }
    var timeWrapper = null;
    switch (this.props.columnFormat) {
      case "single-column-left":
        timeWrapper = {
          alignItems: "flex-end"
        };
        break;
      case "single-column-right":
        timeWrapper = {
          alignItems: "flex-start"
        };
        break;
      case "two-column":
        timeWrapper = {
          flex: 1,
          alignItems: rowID % 2 === 0 ? "flex-end" : "flex-start"
        };
        break;
    }
    var timeStyle;
    var timeContainerStyle;
    if (rowData.selected){
      timeStyle = this.props.selectedTimeStyle ? this.props.selectedTimeStyle : this.props.timeStyle
       timeContainerStyle = this.props.selectedTimeContainerStyle ? this.props.selectedTimeContainerStyle : this.props.timeContainerStyle
    } else {
      timeStyle = this.props.timeStyle
      timeContainerStyle = this.props.timeContainerStyle
    }
    
    return (
      <TouchableOpacity style={timeWrapper} 
      disabled={rowData?.immutable}
      onPress={() => { if(rowData.selected) {this.timePressed()}}}>
        <View style={[styles.timeContainer, timeContainerStyle]}>
          <Text style={[styles.time, timeStyle]}>
            {!rowData.selected ? rowData.time : 'DELETE' }
          </Text>
        </View>
      </TouchableOpacity>
    );
  }

  _renderEvent(rowData, sectionID, rowID) {
    const lineWidth = rowData.lineWidth
      ? rowData.lineWidth
      : this.props.lineWidth;
    const isLast = this.props.renderFullLine
      ? !this.props.renderFullLine
      : this.state.data.slice(-1)[0] === rowData;
    const lineColor = isLast
      ? "rgba(0,0,0,0)"
      : rowData.lineColor ? rowData.lineColor : this.props.lineColor;
    let opStyle = null;

    switch (this.props.columnFormat) {
      case "single-column-left":
        opStyle = {
          borderColor: lineColor,
          borderLeftWidth: lineWidth,
          borderRightWidth: 0,
          marginLeft: 20,
          paddingLeft: 20
        };
        break;
      case "single-column-right":
        opStyle = {
          borderColor: lineColor,
          borderLeftWidth: 0,
          borderRightWidth: lineWidth,
          marginRight: 20,
          paddingRight: 20
        };
        break;
      case "two-column":
        opStyle =
          rowID % 2 === 0
            ? {
                borderColor: lineColor,
                borderLeftWidth: lineWidth,
                borderRightWidth: 0,
                marginLeft: 20,
                paddingLeft: 20
              }
            : {
                borderColor: lineColor,
                borderLeftWidth: 0,
                borderRightWidth: lineWidth,
                marginRight: 20,
                paddingRight: 20
              };
        break;
    }
    return (
      <View
        style={[styles.details, opStyle,]}
        onLayout={evt => {
          if (!this.state.x && !this.state.width) {
            const { x, width } = evt.nativeEvent.layout;
            this.setState({ x, width });
          }
        }}
      >
        <TouchableOpacity
          disabled={rowData?.immutable}
          style={[this.props.detailContainerStyle]}
          onLongPress={() =>
            this.props.onEventPress ? this.props.onEventPress(rowData) : null
          }
        >
          <View style={styles.detail}>
            {this.renderDetail(rowData, sectionID, rowID)}
          </View>
          {this._renderSeparator()}
        </TouchableOpacity>
      </View>
    );
  }

  _renderDetail(rowData, sectionID, rowID) {
    if(!!rowData.customView){
      return rowData.customView
    } else {
      let title = rowData.description ? (
        <View >
          <Text style={[styles.title, this.props.titleStyle]}>
            {rowData.title}
          </Text>
          <Text style={[styles.description, this.props.descriptionStyle]}>
            {rowData.description}
          </Text>
        </View>
      ) : (
        <Text style={[styles.title, this.props.titleStyle]}>{rowData.title}</Text>
      );
      return <View style={styles.container}>{title}</View>;
    }
  }

  _renderCircle(rowData, sectionID, rowID) {
    var circleSize = rowData.circleSize
      ? rowData.circleSize
      : this.props.circleSize ? this.props.circleSize : defaultCircleSize;
    var circleColor = rowData.circleColor
      ? rowData.circleColor
      : this.props.circleColor ? this.props.circleColor : defaultCircleColor;
    var lineWidth = rowData.lineWidth
      ? rowData.lineWidth
      : this.props.lineWidth ? this.props.lineWidth : defaultLineWidth;

    var circleStyle = null;

    switch (this.props.columnFormat) {
      case "single-column-left":
        circleStyle = {
          width: this.state.x ? circleSize : 0,
          height: this.state.x ? circleSize : 0,
          borderRadius: circleSize / 2,
          backgroundColor: circleColor,
          left: this.state.x - circleSize / 2 + (lineWidth - 1) / 2
        };
        break;
      case "single-column-right":
        circleStyle = {
          width: this.state.width ? circleSize : 0,
          height: this.state.width ? circleSize : 0,
          borderRadius: circleSize / 2,
          backgroundColor: circleColor,
          left: this.state.width - circleSize / 2 - (lineWidth - 1) / 2
        };
        break;
      case "two-column":
        circleStyle = {
          width: this.state.width ? circleSize : 0,
          height: this.state.width ? circleSize : 0,
          borderRadius: circleSize / 2,
          backgroundColor: circleColor,
          left: this.state.width - circleSize / 2 - (lineWidth - 1) / 2
        };
        break;
    }

    var innerCircle = null;
    switch (this.props.innerCircle) {
      case "icon":
        let iconSource = rowData.icon ? rowData.icon : this.props.icon;
        let iconStyle = {
          height: circleSize,
          width: circleSize
        };
        innerCircle = (
          <Image
            source={iconSource}
            style={[iconStyle, this.props.iconStyle]}
          />
        );
        break;
      case "dot":
        let dotStyle = {
          height: circleSize / 2,
          width: circleSize / 2,
          borderRadius: circleSize / 4,
          backgroundColor: rowData.dotColor
            ? rowData.dotColor
            : this.props.dotColor ? this.props.dotColor : defaultDotColor
        };
        innerCircle = <View style={[styles.dot, dotStyle]} />;
        break;
    }
    return (
      <View style={[styles.circle, circleStyle, this.props.circleStyle]}>
        {innerCircle}
      </View>
    );
  }

  _renderSeparator() {
    if (!this.props.separator) {
      return null;
    }
    return <View style={[styles.separator, this.props.separatorStyle]} />;
  }
}

Timeline.defaultProps = {
  circleSize: defaultCircleSize,
  circleColor: defaultCircleColor,
  lineWidth: defaultLineWidth,
  lineColor: defaultLineColor,
  innerCircle: defaultInnerCircle,
  columnFormat: "single-column-left",
  separator: false,
  showTime: true
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  listview: {
    flex: 1,
  },
  sectionHeader: {
    marginBottom: 15,
    backgroundColor: "#007AFF",
    height: 30,
    justifyContent: "center"
  },
  sectionHeaderText: {
    color: "#FFF",
    fontSize: 18,
    alignSelf: "center"
  },
  rowContainer: {
    flexDirection: "row",
    flex: 1,
    //alignItems: 'stretch',
    justifyContent: "center"
  },
  timeContainer: {
    minWidth: 45
  },
  time: {
    textAlign: "center",
    color: defaultTimeTextColor
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: 10,
    position: "absolute",
    left: -8,
    alignItems: "center",
    justifyContent: "center"
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: defaultDotColor
  },
  title: {
    fontSize: 16,
    fontWeight: "bold"
  },
  details: {
    borderLeftWidth: defaultLineWidth,
    flexDirection: "column",
    flex: 1
  },
  detail: { paddingTop: 10, paddingBottom: 10 },
  description: {
    marginTop: 10
  },
  separator: {
    height: 1,
    backgroundColor: "#aaa",
    marginTop: 10,
    marginBottom: 10
  }
});

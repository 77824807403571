
import React, { useContext } from 'react';
import { 
    View, 
 } from 'react-native';
import { SectionBar } from './exports';
import { text, } from '../theme'
import { SettingsContext } from '../Managers/Settings';
import { TabbedPageView } from './TabbedPageView';



export const Gender = React.memo(props => {
    const genders = [{title: 'Male', action: () => updateGender('Male')}, {title: 'Female', action: () => updateGender('Female')}]
 
    const updateGender = (gender) => {
      if(props.onChange){
        props.onChange(gender)
      }
      
    }

    const { colors } = useContext(SettingsContext)
    return(
        
          <View style={{alignSelf: 'stretch'}}>
            <SectionBar title={'Gender'}/>
            <TabbedPageView 
              onlyTabs 
              buttons={genders} 
              transparent 
              textStyle={text.base(colors)}/>             
          </View>
        )
  })
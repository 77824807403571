import React from 'react';
import { SectionList} from 'react-native';
import { ListItem } from './exports';
import { useSettings } from '../Managers/Settings';
import { text, surface, alerts } from '../theme';
import _ from 'lodash';
import { wp } from '../Fork/react-native-responsive-screen';
import { CardHeader, Chip, Collapse, Grow, IconButton } from '@material-ui/core';
import { AlertPositions } from '../Constants';
import { SmallCard } from './card';
import { useAlert } from '../Managers/Alert';
import { useOrgs } from '../Managers/Orgs';



export const Members = props => {
    const [ members, setMembers] = React.useState([])
    const [refresh, setOnRefresh] = React.useState()
    const {colors} = useSettings()
    const OrgsManager = useOrgs()
    const Alert = useAlert()

    React.useEffect(() => {
        if(!props.org_id){return}
        
        OrgsManager.getMembers(props.org_id)
        .then(sortMembers)
        .catch()
    },[props.org_id, refresh])

    const sortMembers = (unsortedMembers) => {
        let roleMap = {}

        // because duplicates....
        unsortedMembers = _.uniqBy(_.sortBy(unsortedMembers, ['role.name']), 'user.id')
        
        _.forEach(unsortedMembers, member => {
            const role = _.get(member, 'role.name', null)
            roleMap[role] = roleMap[role] 
                ? [...roleMap[role], member]
                : [member]  // if !exists create, else append
        })
        
        
        let keys = _.keys(roleMap)
        let sortedMembers = _.map(keys, role => {
            let title = role.replace('org_', '')
            title = _.upperFirst(title) + 's'
            return {title: title, data: roleMap[role]}
        })        
        setMembers(sortedMembers)
    
    }
    const keyExtractor =(item) => {
        return String(item?.user?.id + 'member')
    }
    const renderItem = ({item, index, section:{title :subtitle}}) => {
        const {user} = item
        const name = _.get(user, 'first_name', '') + " " + _.get(user, 'last_name', '')
        // console.log(user)
        const initials = _.upperCase(_.first(_.get(user, 'first_name', '') + _.first(_.get(user, 'last_name', ''))))
        const changes = subtitle !== 'Admins' ? () => renderChanges(user) : _.noop
        return(
   
            <Grow in={!!item} >
            <ListItem
                titleStyle={[text.bold, text.small, text.center, text.base(colors)]}
                title={name}
                noImageTitle={initials}
                subtitle={singular(subtitle)}
                subtitleStyle={[text.tiny, text.contrast(colors)]}
                containerStyle={{...surface.tile(colors, wp)}}
                rightIcon={props.admin ? changes(): null}
                leadingImage={item?.user?.image || '../Images/OutreachLogo.png'}
                />
            </Grow>
        )

    }
    const _promote = async (member) => {
        try{
            await OrgsManager.promote(member, {id: props.org_id} )
            
        } catch(e) {
            Alert.error(e)
        } finally {
            setOnRefresh(true)
        }
    }

    const promote = (member) => {
        Alert.warning(
          `Are you sure you want to promote ${member?.first_name || ''} to Admin?`,
          alerts.confirm(() => _promote(member), _.noop),
          AlertPositions.bottomCenter
        
        )
    }

    const deleteMember = (member) => {
        try{
            OrgsManager.deleteMember(member, {id: props.org_id})
        } catch(e) {
            Alert.error(e)
        } finally {
            setOnRefresh(true)
        }
    }   
    const _deleteMember = (member) => {
        Alert.warning(
          `Are you sure you want to delete ${member?.first_name || ''}?`,
          alerts.confirm(() => deleteMember(member), _.noop),
          AlertPositions.bottomCenter
        
        )
    }

    const renderChanges = (member) => {

        return(
            <>
            <Grow in={true}>
                <div>
                    <IconButton onClick={() => promote(member)}>
                        <Chip label={'PROMOTE'} color={'primary'}/>
                    </IconButton>
                </div>
            </Grow>
            {/* <Grow in={true}>
                <div>
                    <IconButton onClick={() => deleteMember(member)}>
                        <Chip label={'DELETE'} color={'secondary'}/>
                    </IconButton>
                </div>
            </Grow> */}
            </>
        )
    }
    

    const renderSectionHeader = ({section:{title}}) => { 
        return(
            <CardHeader title={title}/>
        )

    }

    return(
        <Collapse in={!!members}>
            <SmallCard>
                <SectionList 
                    keyExtractor={keyExtractor}
                    sections={members}
                    renderItem={renderItem}
                    renderSectionHeader={renderSectionHeader}
                />
            </SmallCard>
        </Collapse>
    )
                
}

const singular = (word) => {
    if(_.endsWith(word, 's') || _.endsWith(word, 'S')){
        return word.slice(0, -1); 
    } else {
        return word
    }
}




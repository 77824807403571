import dayjs from 'dayjs';
import { flatMap, includes, join, lowerCase, map, split, trim, upperFirst, values } from 'lodash';
import React, { useState } from 'react';
import { Importer as _Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { CardHeader, FullCard } from './card';
import { nanoid } from 'nanoid'
import { ContactManager, Storage } from '../Managers/exports';
import { useAlert } from '../Managers/Alert';
import { BackButton } from './BackButton';
import {useSettings} from '../Managers/Settings'
import {invertColor, lighten, darken} from '../Utilities'
import { withStyles } from '@material-ui/styles';
import {Collapse, LinearProgress as _LinearProgress, Zoom} from '@material-ui/core'
import { alerts, containers } from '../theme';
import styled from 'styled-components';
import { ADVENTIST, AlertPositions } from '../Constants';
import { ContactsImportTable } from './ContactImportTable';

const LinearProgress = withStyles({
    root:{
        height: 7,
        borderRadius: 7,
        width: '100%'
    }

})(_LinearProgress)

const Number = styled.span`
    color: ${({colors}) => colors.accent};
    font-weight: bold;
    font-size: 10em;
    font-style: italic;

    @media (max-width: 600px){
        font-size: 6em;
    }
`

export const Importer = ({org_id, series_id}) => {

    const [contacts, setContacts] = useState([])
    const [errors, setErrors] = useState([])
    const [importID, setImportID] = useState(nanoid())
    const [selectedContact, setSelectedContact] = useState(false)
    const [status, setStatus] = useState("Import Contacts")
    const [submitting, setSubmitting] = useState(false)
    const [progress, setProgress] = useState(0)
    const Alert = useAlert()
    const {colors} = useSettings()
    
    const prepMyAppForIncomingData = () => {
        setStatus("Importing Contacts Into Browser")
    }


    const addError = (message) => {
        setErrors([...errors, message])
    }

    const commitContacts = async (cs) => {
        let id = nanoid()
        setStatus("Finnishing Browser Import")
         let items = await Storage.get(importID)
        if(!items){
            items = {}
        }
        items[id] = cs
        try{
            await Storage.set(items, importID)
        } catch(e) {
            Alert.error(e)
        }

        return
    }

    const  createContact = (row) => {

        const isSet = (value) => !!value && String(value).trim() !== ""
        const isNotSet = (value) => !!value && String(value?.trim()) === "" || !value
        const isFemale = (value) => {
            const tests = ["Female", "Woman", "Women", "Girl", "F", "Fem", "Lady", "G", "Lass"]
            for(let test in tests){
                if(includes(lowerCase(test), lowerCase(value))){
                    return true
                }
            }
            return false
        }
        
        let {
            name,
            created_at,
            first_name,
            last_name,
            email,
            grade,
            address1,
            address2,
            city,
            state,
            zip,
            country,
            member,
            gender,
            source,
            phone,
            phone2,
            phone3,
            notes
        } = row

        // Name
        if(isSet(name) && isNotSet(first_name) || isSet(name) && isNotSet(last_name)){
            let [_first_name, ..._last_name] = map(split(trim(name), ' '), n => upperFirst(n))
            last_name = join(_last_name, ' ')
            first_name = _first_name
        }

        // Grade
        switch(lowerCase(grade)?.trim()){
            case "a": grade = "A"; break
            case "b": grade = "B"; break
            case "c": grade = "C"; break
            case "d": grade = "D"; break
            case "f": grade = "F"; break
            default: addError("Invalid Grade Type for " + first_name )
        }

        // Address
        let address = {
            address1,
            address2,
            city, 
            state,
            zip,
            country
        }

        // Member
        member = member === ADVENTIST

        // Gender
        gender = isFemale(gender) ? "Female" : "Male"

        // Created At
        created_at = dayjs(created_at).toISOString()

        // Phone
        let phones = []
        const _phones = [phone, phone2, phone2]
        for(let p of _phones){
            if(isNotSet(p)){ continue }
            phones.push({number: p}) 
        } 

        let contact = {
            address,
            archived: false,
            email,
            first_name,
            gender,
            grade,
            last_name,
            member,
            notes,
            orig_created_at: created_at,
            owner:{
                org: {id: org_id}
               },
            phones,
            series_id,
            source
        }

        return contact
    }

    const onComplete = (file, preview, fields, columnFields) => {
        Storage.get(importID)
        .then(items => {
            let value = flatMap(values(items))
            setContacts(value)
            setStatus("Press 'Finish' to upload to Outreach.")
            Alert.warning(
                "You're not done yet.  Upload to outreach?", 
                alerts.yesno(submitContacts, () => Alert.default("Press Finish to upload when ready.", null, AlertPositions.topCenter)),
                 AlertPositions.topCenter)
        })
        .catch(Alert.error)
    }

    const clean = () => {
        Storage.clearTable(importID)
        setContacts([])  
    }

    const goToMyAppNextPage = async (file, preview, fields, columnFields ) => {
        //submit the contacts
        setStatus("Uploading Contacts to Outreach")
        await submitContacts()
    }

    const wait = async (value = 300) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve()
            }, value)  
        }) 
    }

    const submitContacts = async () => {
        setSubmitting(true)
        let chunk = 100 / contacts.length
        setErrors([])
        let _progress = 0 


        for(let contact of contacts){
            // post contact
            try{
                await ContactManager.createContact(contact)
                _progress += chunk
                setProgress(_progress)
            } catch(e) {
                setErrors([...errors, e])
                Alert.error(e)
            }
        }
        if(errors.length > 0){
            Alert.error("Several Errors were encounted during the upload")
        }
        setSubmitting(false)
        setStatus(errors?.length > 0 ? "Contact Upload Failed" : "Contacts Uploaded to Outreach")
        return 
        
    }
    const onGradeChange = (grade, index) => {
        let _contacts = contacts
        _contacts[index].grade = grade
        setContacts([..._contacts])
    }
    const onAdventistChange = (change, index) => {
        let _contacts = contacts
        if(_contacts[index].member){
            _contacts[index].member = false
        } else {
            _contacts[index].member = true
        }
        setContacts([..._contacts])
    }

    return(
        <>
 
        <style>
            {`
            .CSVImporter_TextButton{
                background-color: ${colors.accent};
                color: ${invertColor(colors.accent)};
                border: none;
                border-radius: 3px;
                padding: 7px 15px;
                transition: all 0.2s ease-in-out;
            }
            .CSVImporter_TextButton:hover{
                color: ${invertColor(darken(colors.accent, 2))} !important;
                background-color: ${lighten(colors.accent, 2)} !important;
            }
            .CSVImporter_ProgressDisplay__progressBarIndicator{
                background: linear-gradient(0.25turn, ${darken(colors.accent, 2)}, ${colors.accent});
            }
            .CSVImporter_FileSelector{
                border: .25em dashed ${lighten(colors.accent, 4)};
                background: ${lighten(colors.accent, 4)};
                transition: all 0.2s ease-in-out;
                font-weight: bold;
                color: ${invertColor(lighten(colors.accent, 4))};
            }
            .CSVImporter_FileSelector:hover{
                border: .35em dashed ${lighten(colors.accent)};
                background: ${lighten(colors.accent, 3)} !important;
                color: ${invertColor(lighten(colors.accent, 3))} !important;
            }
            
            .CSVImporter_FormatPreview__headerToggle>input[type=checkbox]:active{
            }
            .CSVImporter_ColumnDragTargetArea__boxValue{
                background: linear-gradient(0.25turn, ${darken(colors.accent, 2)}, ${colors.accent});
                border-radius: 7px;
            }
            .CSVImporter_ColumnDragTargetArea__boxPlaceholderHelp{
                color: ${invertColor(darken(colors.accent, 2))};
            }

            .CSVImporter_ProgressDisplay__status{
                font-weight: bold
            }

            .CSVImporter_ImporterFrame{
                border: none;
            }
            .CSVImporter_FormatRawPreview__scroll{
                height: auto;
                max-height: 50vh;
            }
            .CSVImporter_FormatDataRowPreview__table>thead>tr>th{
                color: ${darken(colors.accent)}
            }

            .CSVImporter_ProgressDisplay__status{
                visibility: hidden;
            }
            .CSVImporter_ProgressDisplay__status:after{
                content: '${status}';
                visibility: visible;
            }


            `}
        </style>
        <BackButton onBack={clean}/>
        <FullCard>

        
            <CardHeader title={"File Upload"} />
            <Collapse in={!submitting} timeout={300}>
            <_Importer
            assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
            restartable={true} // optional, lets user choose to upload another file when import is complete
            onStart={({ file, fields, columns, skipHeaders }) => {
                prepMyAppForIncomingData();
            }}
            processChunk={async (rows, { startIndex }) => {
            let cs = []
            for (let row of rows) {
                let c = createContact(row);
                cs.push(c)
            }
            await commitContacts(cs)
            }}
            onComplete={({ file, preview, fields, columnFields }) => {
            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                onComplete(file, preview, fields, columnFields);
            }}
            onClose={({ file, preview, fields, columnFields }) => {
            // optional, invoked when import is done and user clicked "Finish"
            // (if this is not specified, the widget lets the user upload another file)
                goToMyAppNextPage(file, preview, fields, columnFields );
            }}
            
        >
                <ImporterField name="name" label="Name" optional/>           
                <ImporterField name="created_at" label="Created" optional/>
                <ImporterField name="first_name" label="First Name" optional/>
                <ImporterField name="last_name" label="Last Name" optional/>
                <ImporterField name="email" label="Email" optional/>
                <ImporterField name="grade" label="Grade" optional />
                <ImporterField name="address1" label="Address" optional />
                <ImporterField name="address2" label="Address2" optional />
                <ImporterField name="city" label="City" optional />
                <ImporterField name="state" label="State" optional />
                <ImporterField name="zip" label="Zip/Postal Code" optional />
                <ImporterField name="country" label="Country" optional />
                <ImporterField name="member" label="Adventist" optional />
                <ImporterField name="gender" label="Gender" optional />
                <ImporterField name="source" label="Source" optional />
                <ImporterField name="phone" label="Phone" optional />
                <ImporterField name="phone2" label="Phone 2" optional />
                <ImporterField name="phone3" label="Phone 3" optional />
                <ImporterField name="notes" label="Notes" optional />
        </_Importer>
        </Collapse>
        <Collapse in={submitting}>
            <Zoom in={submitting} timeout={330}>
                <div style={{...containers.centerColumn, width: '100%'}}>
                <Number colors={colors} >{progress}%</Number>
                </div>
            </Zoom>
            <Zoom in={submitting} timeout={1000}>
            <div style={{...containers.centerColumn, width: '100%'}}>
                    
                    <LinearProgress 
                        variant="determinate"
                        value={progress}
                        valueBuffer={Math.max(progress + (contacts?.length / 100), 100)}
                    />
                </div>
            </Zoom>
        </Collapse>
        {<Collapse in={contacts?.length > 0} timeout={500}> <ContactsImportTable 
            contacts={contacts}
            onGradeChange={onGradeChange}
            onAdventistChange={onAdventistChange}
        /></Collapse>}
        </FullCard>
        </>
    )
}
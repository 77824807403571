import React, { useContext, useEffect } from 'react';
import { Animated, ScrollView, View,  } from 'react-native';
import Moment from 'moment';
import {map, max,reverse, random} from 'lodash';
import {  wp, hp } from '../Fork/react-native-responsive-screen';
import { SettingsContext } from '../Managers/Settings';
import { containers ,text } from '../theme';
import Tooltip from '@material-ui/core/Tooltip';

export const BarChart = props => {
    // console.log(props.data)
    const height = Math.min(wp(50), hp(50))
    const { data } = props
    const _max = max(map(data, d => d.value))
    return(
        <ScrollView
            horizontal
            contentContainerStyle={{height, ...containers.leftBottomRow, padding: 50}}
            style={{height, margin: 10}}
        >
            {map(reverse(data), d => {
                return(
                    <ROTCell 
                        height={height - wp(20)} d
                        max={_max}
                        {...d}
                    />
                )
            })}

        </ScrollView>
    )
}

export const DemoBarChart = () => {

    const height = Math.min(wp(50), hp(50))

    const _max = max(map(demoData, d => d.value))
    return(
        <ScrollView
            horizontal
            contentContainerStyle={{height, ...containers.leftBottomRow, padding: 50}}
            style={{height, margin: 10}}
        >
            {map(reverse(demoData), d => {
                return(
                    <ROTCell 
                        height={height - wp(20)} d
                        max={_max}
                        {...d}
                    />
                )
            })}

        </ScrollView>
    )
}


const ROTCell = React.memo(props => {
    const { index, title, detailedTitle, value, height, max: _max} = props
    const _animated_height = new Animated.Value(0)
    const defaultWidth = 30
    const maxHeight = defaultWidth +( (value / _max) * height)
    const width = new Animated.Value(defaultWidth)

    
    useEffect(() => {
        Animated.spring(
            _animated_height,
            {
                toValue: maxHeight,
                duration: 2000,
                delay: 10 * index,
                friction: 3
            }
        ).start()
        
    }, [])

    
    

    const { colors } = useContext(SettingsContext)
    return(
        <Tooltip
            backgroundColor={colors?.accent}
            overlayColor={colors?.overlay.base}
            title={detailedTitle}
        >
            <View style={[containers.centerColumn]}>
                <Animated.View 
                    style={{
                        height: _animated_height,

                        minHeight: defaultWidth, 
                        backgroundColor: colors?.accent,
                        width,
                        margin: 5,
                        borderRadius: defaultWidth / 2,
                }}
                />
                <Animated.Text style={{
                    transform: [
                        {rotateZ: '45deg'},
                        {translateX: 22},
                        {translateY: 10},
                    ],
                    ...text.base(colors)
                }}>
                {title}
                </Animated.Text>
            </View>
        </Tooltip>
    )
})

const demoData = map(Array(20), (item, index) => {
    return{
        index,
        title: Moment().subtract(index, 'hour').format('h:mm A'),
        value: random(0, 0xFFF),
    }
})




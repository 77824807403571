import React, { useContext} from 'react';
import { 
    Text
 } from 'react-native';

import { text} from '../theme'
import { SettingsContext } from '../Managers/Settings';
import { Fade } from '../Animations/Fade';

 export const Hint = props => {
    const _style = props.style || {} 
    const { colors } = useContext(SettingsContext)
    return(
        <Fade>
            <Text style={{
            ...text.base(colors),
            ...text.tiny,
            ...text.italics,
            ...text.center,
            ..._style

            }}>{props?.children || ''}</Text>
        </Fade>
    )

 }
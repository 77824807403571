
import React, { useState } from 'react';
import { 
    Text
 } from 'react-native';
import { Slide, Fade, Collapse } from '@material-ui/core';
import _ from 'lodash';
import { text,  } from '../theme';
import { Input,
     TabbedPageView,
     Hint,
     BottomButtons,
     IntroSection as Section,
     IntroContainer as Container,
     IntroTitle,
     OrgsPicker} from '../Components/exports';
import { wp, hp, wpCss } from '../Fork/react-native-responsive-screen';
import { AddOrg} from './exports'
import { Subject } from 'rxjs';
import { useOrgs } from '../Managers/Orgs';
import { useUser } from '../Managers/UserManager';


 export const ChooseOrg = (props) => {
    const OrgsManager = useOrgs()
    const { 
        appChange,
        onNext, 
        onBack,
        onFinish,
        contact,
        app,
        org,
        activated,
        direction,
        getTimeout,
        join,
        create,
        errorKey
    } = props

    const [ alreadyApplied, setAlreadyApplied] = React.useState(false)

    const {user} = useUser()
    const _org = {
        contact_person: {
            ..._.pick(contact, ['first_name', 'last_name', 'email']),
            name: !!contact ? contact?.first_name + ' ' + contact?.last_name  : ''
        },
        name: '',
        email: '',
        address: {},
        phone: '',
        religion: 'SDA',
        parent_id: null,
        zips: [],
        pastor:{
            
        },
        ...app
    }
    
    React.useEffect(() => {
        if(!user){ return }
        const leaving = new Subject()
        OrgsManager.getOrgs()
        .then(orgs => {
            const results = _.filter(orgs, org => org.approved === null || org.approved === undefined)
            if(results?.length > 0){
                setAlreadyApplied(true)
            } else {
                setAlreadyApplied(false)
            }
        })
        .catch()
        return () => leaving.next()
    },[user])
    
    const [inputText, setText] =  useState('')
    const [cc, updateCC] = useState('choose')
    const buttons = [
        {title: 'Member', action: () => setCC('choose')},
        {title: 'Administrator', action: () => setCC('create')}
    ]

    const updateText = (txt) => setText(String(txt).toUpperCase())
    const setCC = (choice) => {
        updateCC(choice)
    }
    const action = () => {
        if(cc === 'choose'){
            join && join(inputText)
        } else if(!org) { 
            create && create()     
        } else {
            onNext()
        }
    }

    const onAppChage= (app) => {
        appChange && appChange(app)
    }
    React.useEffect(() => {
        if(activated){
          window.addEventListener('keydown', onKeyDown)
        } else {
          window.removeEventListener('keydown', onKeyDown)
        }
         return () =>  window.removeEventListener('keydown', onKeyDown)
      }, [activated])
  
    const onKeyDown = (event) => {
        if(event?.code === 'Enter'){
            action()
        }    
    }

    return(
        <Container >
            <Section style={{minHeight: hp(25)}}>
                <Slide direction={direction} in={activated} timeout={getTimeout.edge()} >
                    <div>
                        <Text style={[text.white, text.bold, text.large, text.center]}> What role will you play?</Text>
                        </div>
                </Slide>
        
                <Slide direction={direction} in={activated} timeout={getTimeout.middle()} >
                    <div>
                    <TabbedPageView 
                        style={{minWidth: Math.min(500, wp(100)), maxWidth: wpCss(100)}} 
                        onlyTabs 
                        borderColor={'white'}
                        transparent
                        buttons={buttons}/>
                    </div>
                </Slide>
            </Section>
            <Section>
                <Slide direction={direction} in={activated} timeout={getTimeout.center()} >
                    <div>
                        <Fade in={activated} timeout={10000}>
                            <div>
                                <Hint style={text.white}>
                                    {cc === 'choose' ? 'If you received an access code from your administrator, type it in below.' : ''}
                                </Hint>
                            </div>
                        </Fade>
                        <Collapse in={cc === 'choose'}>
                            <div style={{width: '100%', alignSelf: 'stretch'}}>
                                <Input 
                            
                                    placeholder={cc === 'choose' ? 'i.e. JSX7' : 'My Awesome Organization Name...'}
                                    onTextChange={updateText}
                                    value={inputText}
                                    onSubmitEditing={action}
                                />

                            </div>
                        </Collapse>
                        <Collapse in={cc !== 'choose'}>
                            <div>
                            {activated && !alreadyApplied && contact && <AddOrg 
                                org={_org}
                                zipsEditable={true}
                                onSubmit={create}
                                errorKey={errorKey}
                                hideSubmit
                                onChange={onAppChage}
                                title={'New Organization Application'}
                            />}
                        
                            {activated && !!alreadyApplied && <div style={{maxWidth: 500}}>
                                        <IntroTitle>
                                            You already have an Organization. 
                                            If you need more, contact us at (423) 362-5800
                                        </IntroTitle>
                                        
                                </div>}
                            </div>
                        </Collapse>
                    </div>
                </Slide>
            </Section>
            <Section>
                <Slide direction={direction} in={activated} timeout={getTimeout.center()} >
                    <div>
                        <OrgsPicker 
                            onSelect={onFinish}
                            expanded={true}
                            hintStyle={text.white}
                            hideAdd 
                            showHint/>
                    </div>
                </Slide>
            </Section>
            <BottomButtons 
                onBack={onBack}
                onNext={action}
                rightTitle={cc === 'create' && !org ? 'Continue' : 'Finished'}
            />
        </Container>
    )
 }

import React from 'react';
import { 
    
 } from 'react-native';

 import { TodayWidget } from '../Components/exports';

 export const Today = props => {

    return(
        
            <TodayWidget />
    
    )
 }
import React from 'react';
import {
  Text,
  View,
  TouchableWithoutFeedback,
  UIManager
} from 'react-native';
import Moment from 'moment'
import Timeline from '../Fork/react-native-timeline-listview-master/lib/index'
import { text, containers, historyStyle} from '../theme'
import {Grade, Input, AvatarDot } from './exports'
import _ from 'lodash'

import { SettingsContext } from '../Managers/Settings';
import { ChevronRightRounded, ExpandMoreRounded, Add } from '@material-ui/icons';
import { colors, IconButton, Popover } from '@material-ui/core';
import { TwoButtonOption } from './Buttons';
import { wpCss } from '../Fork/react-native-responsive-screen';
import { CardHeader } from './card';


export class History extends React.Component {
    static contextType = SettingsContext
    constructor(props){
      super(props)

      UIManager.setLayoutAnimationEnabledExperimental
      &&UIManager.setLayoutAnimationEnabledExperimental(true)

      this.historyItems = new Set()
      this.actions = ['Called', 'Visited', 'Texted', 'Prayed for', 'Note'] 
      this.state = {
        selected: null,
        adding: false,
        actionIndex: 0,
        actionType: this.actions[0],
        date: Moment(),
        description: '',
        isDateTimePickerVisible: false,
        history: [],
        popoverId: 'contact-history-add-note'
       }

       Moment.locale('en', {
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s:  'seconds',
          ss: '%ss',
          m:  'a minute',
          mm: '%dm',
          h:  'an hour',
          hh: '%dh',
          d:  'a day',
          dd: '%dd',
          M:  'a month',
          MM: '%dM',
          y:  'a year',
          yy: '%dY'
        }
      });
    } 
    modalTextBox;

    renderSelected(){
        if(this.state.selected){
          return (
            <Text style={{marginTop:10}}>
              Selected event: {this.state.selected.title} at {this.state.selected.time}
            </Text>
          )
        }
    }


    componentDidUpdate(prev){
      if(!_.isEqual(prev, this.props)){
        let {changeHistory, notes, first_name} = this.props.contact
        this.props.contact && this.getHistory({
          changeHistory,
          notes,
          first_name
        })

      }
  }

    componentDidMount(){
      if(!this.props.contact) {return }
      let {changeHistory, notes, first_name} = this.props.contact
      this.props.contact && this.getHistory({
        changeHistory: changeHistory ? changeHistory : [],
        notes: notes ? notes : [],
        first_name
      })
    }


    
    getInteractions = async (_contact) => {

      try{
        let contact = _contact ? _contact : this.props.contact
        let {notes: interactions } = contact

        interactions = _.assign(interactions, {})
        if(!interactions) {return []}
        let inters =  _.map(interactions, interaction => {
          interaction._time = Moment(new Date(interaction.created_at))
          interaction.time = Moment(interaction.created_at).calendar()
          if(!interaction.system){
            interaction.title = interaction.created_by 
              ? 'Note by ' + (interaction.created_by?.first_name ? interaction.created_by.first_name : "")
              : 'Note'
          } 
          return interaction
        })
        return inters
      } catch(e) {
        // alert(e)
        return Promise.reject(e)
      }
    }

    getChangeHistory = async (contact) => {
      try{
      const  {changeHistory, first_name } = contact
      let changes = {...changeHistory}
      // console.log(_.map(changes, c => String(_.keys(c) + '\n\n')))
      // console.log(_.map(changes, c => c.time))
      const newChanges = _.map(changes, change => {
          let newChange = {}
          newChange.title = this.getChangeType(change.key)
          newChange.title = _.upperFirst(newChange.title)
          if(newChange.title === 'Private Property'){
            newChange.current = 'Private'
            newChange.previous = 'Private'
          } else {
            newChange.current = change.current;
            newChange.previous = change.previous;
          }
          newChange.created_by = change.created_by
          if(change.key === 'grade'){
            newChange.customView = this.gradeChange(change)
          } else if(change.key === 'owner_user'){
            newChange.customView = this.ownerChange(change)
          } else if (change.key === 'owner_org' || change.key === 'owner_team'){
            newChange.customView = this.orgOrTeamChange(change)
          } else if (change.key === 'address_id'){
            newChange.customView = this.buildAddressView(newChange)
          } else if(this.checkIfOriginalCreation(change.key)){
            newChange.description = `${first_name ? first_name + ' was added to Outreach' : 'Added to Outreach' }`
            newChange.customView = this.buildChangeView(newChange)
          } else {
            newChange.customView = this.buildChangeView(newChange)
          }
          newChange.immutable = true
          newChange._time = Moment(new Date(change.time))
          newChange.time = Moment(newChange._time).calendar()
          
          if(this.checkIfOriginalCreation(change.key)){
            newChange.description = `${first_name ? first_name + ' was added to Outreach' : 'Added to Outreach' }`
          }
          
          return {...newChange}
        })
        return newChanges
      } catch(e){
        // alert(e)
        return Promise.reject(e)
      }
    }

    ownerChange = (change) => {
      let  { colors } = this.context
      const inner = [containers.aroundRow, {backgroundColor: colors?.transparent}]
      const outer = [containers.leftColumn, { backgroundColor: colors?.transparent, padding: 10}]
      const title = [text.bold, {color: colors?.text, paddingBottom: 10}]
      let previous = {first_name:'', last_name: ''}
      let current = {first_name:'', last_name: ''}
      previous.first_name = _.get(change, 'previous.first_name', "") 
      previous.last_name  =  _.get(change, 'previous.last_name', '')
      current.first_name = _.get(change, 'current.first_name', "") 
      current.last_name = _.get(change, 'current.last_name', '')
      return (
          <View style={outer}>
            <Text style={title}>Owner Change</Text>        
            <View style={inner}>
              <View style={containers.centerColumn}>
                <Text style={text.base(colors)}>{previous.first_name}</Text>
                <Text style={text.base(colors)}>{previous.last_name}</Text>
                
            </View>
              <ChevronRightRounded  style={{color: colors?.accent}}/>
              <View style={containers.centerColumn}>
                <Text style={[text.base(colors), text.bold]}>{current.first_name} </Text>
                <Text style={[text.base(colors), text.bold]}>{current.last_name}</Text>

              </View>
              
            </View>
        </View>
      )
    }
    orgOrTeamChange = (change) => {
      let  { colors } = this.context
      const inner = [containers.aroundRow, {backgroundColor: colors?.transparent}]
      const outer = [containers.leftColumn, { backgroundColor: colors?.transparent, padding: 10}]
      const titleStyle = [text.bold, {color: colors?.text, paddingBottom: 10}]
      
      let title  = ''
      if(change.key === "owner_org"){
        title = 'Organization Change'
      } else {
        title = "Team Change"
      }
      return (
          <View style={outer}>
            <Text style={titleStyle}>{title}</Text>        
            <View style={inner}>
              <View style={containers.centerColumn}>
                <Text style={text.base(colors)}>{change.previous.name}</Text>
            </View>
              <ChevronRightRounded  style={{color: colors?.accent}}/>
              <View style={containers.centerColumn}>
                <Text style={[text.base(colors), text.bold]}>{change.current.name} </Text>
              </View>
              
            </View>
        </View>
      )
    }


    gradeChange = (change) => {
       let  { colors } = this.context
      const inner = [containers.aroundRow, {backgroundColor: colors?.transparent}]
      const outer = [containers.leftColumn, { backgroundColor: colors?.transparent, padding: 10}]
      const title = [text.bold, {color: colors?.text, paddingBottom: 10}]
      
      return (
          <View style={outer}>
            <Text style={title}>Interest Level Change</Text>        
            <View style={inner}>
              <Grade grade={change.previous} static inlist/>
              <ChevronRightRounded  style={{color: colors?.accent}}/>

              <Grade grade={change.current}  static inlist/>
            </View>
        </View>
      )
    }

    createEditor = (person) => {
      if(!person){return <View/>}
      const outer = {...containers.leftCenterRow}
      let img = person.image 
      return (
        <View style={outer}>
          <AvatarDot
            img={img}
            first_name={person.first_name}
            last_name={person.last_name}
            size={'superTiny'} 
            selected
            />
          <Text style={text.superTiny}>{person.first_name} {person.last_name}</Text>
        </View>
      )
    }

    renderCircle = (change) => {
      let { colors } = this.context
      // const avaStyle={
      //   shadowColor: 'black',
      //   shadowOffset: {width: 10, height: 10},
      //   shadowOpacity: 0.1, 
      //   shadowRadius: 10,
      //   elevation: 4
      // }
      const person = _.get(change, 'created_by',{})
      const img = _.get(person, 'image')// || 'https://www.paradisenapa.com/wp-content/uploads/2013/10/black_business_user.png'
      const first = _.get(person, 'first_name')
      const last = _.get(person, 'last_name')

      return (
            <View style={historyStyle.circle(colors)}>
              <AvatarDot
                time={change.created_at}
                source={img}
                first_name={first}
                last_name={last}
                size={10} 
                selected
                // style={avaStyle}
                />
             </View>
      )
    }
    

    checkIfOriginalCreation = (key) => {
      if(key === 'created_at'){
        return true
      }
    }

    buildAddressView = (change) => {
      let { colors } = this.context
      const inner = [containers.leftBetweenColumn]
      const outer = [containers.leftColumn]
      let previous, current ;
      if(change.previous){
        let address1 =  _.get(change, 'previous.address1')
        let address2 =  _.get(change, 'previous.address2')
        let state    =  _.get(change, 'previous.state')
        let city     =  _.get(change, 'previous.city')
        let zip     =  _.get(change, 'previous.zip')
        city =  city ? city + ',' : city
        previous =  <View style={containers.leftColumn}>
            <Text style={[text.tiny, text.base(colors)]} numberOfLines={4} >
            {address1} {address2} {city} {state} {zip}
            </Text>
        </View> 
      }
      if(change.current){
        let address1 =  _.get(change, 'current.address1')
        let address2 =  _.get(change, 'current.address2')
        let state    =  _.get(change, 'current.state')
        let city     =  _.get(change, 'current.city')
        let zip     =  _.get(change, 'current.zip')
        city =  city ? city + ',' : city
        current =  <View style={containers.leftColumn}>
          <Text style={[text.tiny, text.bold, text.base(colors)]} numberOfLines={4} >
            {address1} {address2} {city} {state} {zip}
            </Text>
        </View> 
      }
      return(
        <View style={outer}>
      
          <View style={inner}>
          {previous}
          <View style={{width: '100%',...containers.centerRow, marginVertical: 3}}>
            <ExpandMoreRounded style={{color: colors?.accnet.base}} />
          </View>
          {current}
        </View>
      </View>
      )
    }
    
    buildChangeView = (change) => {
      let { colors } = this.context
      const inner = [containers.wrapAroundRow]
      const outer = [containers.leftColumn, ]
      const textStyle = [text.tiny, text.base(colors)]
      let description = change.description

      if(!change.previous || String(change.previous).trim() === ''){
        
        description = description ? description :  `Created ${change.current}`
        
        return (
              <Text style={textStyle}>{description}</Text>
          )
      } 
      else if(String(change.current).trim() === '') {
        description =  `Removed ${change.previous}`
        return (
              <Text style={textStyle}>{description}</Text>
        )
      }
      else {
        return (
          <View style={outer}>
            <View style={inner}>
              <Text style={textStyle}>{change.previous}</Text>
              
              <ChevronRightRounded style={text.accent(colors)} />
              <Text style={[textStyle, text.bold]}>{change.current}</Text>
            </View>
          </View>
        )
      }
    }


    getChangeType = (property) => {

        switch(property){
            case 'id': return 'ID Change'
            case 'prefix': return 'Prefix Change'
            case 'first_name': return 'First Name Change'
            case 'middle_name': return 'Middle Name Change'
            case 'last_name': return 'Last Name Change'
            case 'suffix': return 'Suffix Change'
            case 'email': return 'Email Change'
            case 'gender': return 'Gender Change'
            case 'age': return 'Age Change'
            case 'grade': return 'Interest level Change'
            case 'address': return 'Address Change'
            case 'img': return 'Profile picture Change'
            case 'phones': return 'Phone number list Change'
            case 'tags': return 'Tags Change'
            case 'created_at': return 'Contact Created'
            case 'address_id': return 'Address Change'
            case 'owner_user': return 'Owner Change'
            case 'owner_org' : return 'Organization Change'
            case 'owner_team' : return 'Team Change'
            default: return 'Private Property'
        }
    }
    getHistory = async (contact = null) => {
      
      if(!contact ){ return }
      let changes = []
      let inters = []
      try{
        changes =  await this.getChangeHistory(contact)
        // _.forEach(changes, c => console.log(c._time))
        inters =  await this.getInteractions(contact)
      } catch (e) {
        // Alert.alert('Something went wrong', e)
      }  
      let history = [
        ...changes, 
        ...inters]
      // history = [ ...inters]
      history = _.orderBy(history, ['_time'], ['desc'])
      this.setState({history})         
          
          
    }

   submitHistoryEvent = async () => {
      let description = this.state.description
      await this.closeModal()
      this.props.onCreate && this.props.onCreate(description)
      this.setState({description: ''})
    }


    render() {
      return (
        <View style={[historyStyle.container]}>
          <CardHeader title="Notes" buttonProps={{
            icon: this.getDots(),
            iconOnly: true,
            color: colors.accent,
            invert: true,
            fill: true
          }} />
          {this.getModal()}
          {this.getTimeline()}
          {/* {this.getDots()} */}

  
        </View>
      );
    }


    getDots = () => {
      return  (
          <IconButton 
            aria-haspopup="true"
            style={{padding: 0}}
            aria-controls={this.state.popoverId}
            color="primary"
            onClick={this.addHistory}>
              <Add />
          </IconButton>

      ) 
  
    }

    getTimeline = () => {
      let { colors } = this.context      
      return(
        <Timeline 
          style={historyStyle.list}
          data={this.state.history}
          lineColor= {colors?.accent}
          rowContainerStyle={{paddingHorizontal: 2, width: '98%'}}
          renderCircle={this.renderCircle}
          descriptionStyle={{color: colors?.text}}
          titleStyle={text.contrast(colors)}
          options={{
            style:{ paddingTop:5 },
          }}
          
          timePressed={() => this.confirmDeleteHistory(this.state.selected)}
          timeContainerStyle={[historyStyle.timeContainerStyle(colors), containers.centerColumn]}
          selectedTimeContainerStyle={[historyStyle.selectedTimeContainerStyle(colors), containers.centerColumn]}
          timeStyle={[text.superTiny, text.accentContrast(colors), {width: 95}]}
          selectedTimeStyle={[text.superTiny, {color: colors?.danger, width: 95}]}
          onEventPress={this.onEventPress}   
          showTime={true}                 
          separator={false}
          detailContainerStyle={historyStyle.detailsContainerStyle(colors)}
          circleColor={colors?.plight}
          innerCircle={'dot'}
      />
      )
    }
    dismissKeyboard = () => {
      this.setState({adding: false})
    }

    getModal = () => {
      let { colors } = this.context
      return(
        <Popover 
          id={this.state.popoverId}
          // anchorEl={this.state.popoverId}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.adding}

        >
 
          <View style={{
            padding: 20,
            maxWidth: wpCss(90),
            width: 600, 
            ...containers.centerColumn}}>
            
              <Text style={[text.medium, text.base(colors)]}>Add a Note</Text>
              <Text style={[text.superTiny, text.base(colors), text.center]}> {Moment(this.state.date).format('dddd, [on the] Do [of] MMMM YYYY ')}</Text>
            
            <TouchableWithoutFeedback style={{width: '100%'}}>
              <Input
                ref={ref => this.modalTextBox = ref}
                placeholderTextColor={colors?.text}
                placeholder={`Start typing A Note...`}
                value={this.state.description}
                onChangeText={val => this.onTextChange(val)}
                style={{ width: '100%', height: 150}}
                multiline
                />
              </TouchableWithoutFeedback>
                <TwoButtonOption 
                  leftAction={this.closeModal}
                  rightAction={this.submitHistoryEvent}

                />
          

            {/* <DateTimePicker
              isVisible={this.state.isDateTimePickerVisible}
              onConfirm={this.handleDatePicked}
              onCancel={this.hideDateTimePicker}
              value={new Date()}
              mode={'datetime'}
              maximumDate={new Date()}
              /> */}

        </View>
        </Popover>
         
      )
    }

    onEventPress = (item) => {
      if(!item.editable){return }
      item.selected = item.selected ? false : true
      let history = this.state.history
      if(item.selected){
        this.setState({selected: item, history})
      } else {
        this.setState({selected: null, history})
      }
      
    }
  
    confirmDeleteHistory = (item) => {
      
      // Alert.alert(
      //   'Are You Sure?', 
      //   'Permanently delete this item?', 
      //   alerts.delete(
      //     () => this.deleteHistoryItem(item),
      //     _.noop ),
      //   { cancelable: true }
      // )
    }
    deleteHistoryItem = (item) => {
      this.setState({selected: null})
      this.props.onDelete && this.props.onDelete(item)
    }
    onTextChange = (description) => this.setState({description})
    closeModal = () => {
      return new Promise((resolve, reject) => {
        this.setState({adding: false}, () => setTimeout( resolve ,1000))  
      })
    }
    showDateTimePicker = () => this.setState({isDateTimePickerVisible: true})
    handleDatePicked = (date) => this.setState({date: date}, this.hideDateTimePicker)
    hideDateTimePicker = () => this.setState({isDateTimePickerVisible: false})
    addHistory = (event) => this.setState({popoverId: event.currentTarget, adding: true})
    

  
  }
  

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSettings } from '../Managers/Settings';
import ContactMailRoundedIcon from '@material-ui/icons/ContactMailRounded';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import { concat, map, sortBy } from 'lodash';
import {  useLocation, withRouter } from 'react-router-dom';
import { ArchiveRounded, ListAlt, ListAltOutlined } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { useNetwork } from '../Managers/Database';
import { useUser } from '../Managers/UserManager';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'bold',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

const  StyledTreeItemComponent = props => {
  const classes = useTreeItemStyles();
  
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, link, extraAction, ...other } = props;
  function action(){
    props.history.push(link)
    if(extraAction){
      extraAction()
    }
  }

  return (
    <TreeItem
    key={labelText}
      onLabelClick={action}
      onIconClick={action}
      label={
        
            <Box className={classes.labelRoot} to={link} >
              <LabelIcon color="inherit" className={classes.labelIcon} />
            
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
              
              <Typography variant="caption" color="inherit">
                {labelInfo}
              </Typography>
            </Box>
        
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

const StyledTreeItem = withRouter(StyledTreeItemComponent)

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export const SideBar = () => {
const classes = useStyles();
  const [ orgs, setOrgs] = React.useState([])
  // const [ series, setSeries] = useState({})
  const [expanded, setExpanded] = useState("")
  const location = useLocation()
  const {updatePrefs} = useUser()
  const Database = useNetwork()

  const {prefs, colors } = useSettings()

  useEffect(() => {
    Database.get('/users/sidebar.json')
    .then(result => {
      if(!result){ return }
      let {orgs: _orgs} = result
      _orgs =  sortBy(_orgs, ['name'])
      setOrgs(_orgs)
      // setSeries(_orgs[0]?.series)
      let ex = concat(expanded, [`/dashboard/${_orgs?.[0]?.id}/contacts`])
      setExpanded(ex)
    })
    .catch(console.error)

  }, [])

  useEffect(() => {
    if(!prefs?.currentOrg?.id){ return }
    setExpanded(`/dashboard/${prefs?.currentOrg?.id}/contacts`)
  }, [prefs?.currentOrg?.id])



  const renderInner = (org) => {
    return(
        <>
          <StyledTreeItem  
            color={colors?.accent}
            bgColor={colors?.transparent}
            key={`/dashboard/${org.id}/contacts`}
            nodeId={`/dashboard/${org.id}/contacts`}
            labelText="Contacts"
            link={`/dashboard/${org.id}/contacts`} 
            labelIcon={ContactMailRoundedIcon} 
          >
           <StyledTreeItem  
                color={colors?.accent}
                bgColor={colors?.transparent}
                key={`/dashboard/${org.id}/contacts/archived`}
                nodeId={`/dashboard/${org.id}/contacts/archived`}
                labelText={"Archived"}
                link={`/dashboard/${org.id}/contacts/archived`} 
                labelIcon={ArchiveRounded} />

            {map(org?.series, s => {
                const setActiveSeries = () => {
                  let {decision_cards, ...rest} = s
                  updatePrefs({currentSeries: {...rest}})
              }
              return <StyledTreeItem  
                color={colors?.accent}
                bgColor={colors?.transparent}
                extraAction={setActiveSeries}
                key={`/dashboard/${org.id}/contacts/series/${s.id}`}
                nodeId={`/dashboard/${org.id}/contacts/series/${s.id}`}
                labelText={s?.name}
                link={`/dashboard/${org.id}/contacts/series/${s.id}`} 
                labelIcon={ContactMailRoundedIcon} />
            })
                
              }
            </StyledTreeItem>
          {org?.series?.length > 0 && <StyledTreeItem
              // link={`/dashboard/${org.id}/decisions`}
              key={`/dashboard/${org.id}/decisions`}
              nodeId={`/dashboard/${org.id}/decisions`}
              labelText={"Decision Cards"}
              labelIcon={ListAltOutlined}
              color={colors?.accent}
              bgColor={colors?.transparent}
          >

            {map(org?.series, s => {
              if(s?.decision_cards){
                return (
                  <StyledTreeItem  
                    color={colors?.accent}
                    bgColor={colors?.transparent}
                    key={`/dashboard/${org.id}/decisions/${s.id}`}
                    nodeId={`/dashboard/${org.id}/decisions/${s.id}`}
                    labelText={s?.name}
                    link={`/dashboard/${org.id}/decisions/${s.id}`} 
                    labelIcon={ListAlt} />
                )
              } else {
                return null
              }
            })
            }
        </StyledTreeItem>}
      </>
    )
  }

  return(
    <TreeView
          className={classes.root}
          // defaultExpanded={expanded }
          // defaultSelected={expanded }
          multiSelect={false}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
            {orgs?.length === 1 && <>
                        <StyledTreeItem
                        link={`/dashboard/${orgs[0].id}`}
                        key={`/dashboard/${orgs[0].id}`}
                        nodeId={`/dashboard/${orgs[0].id}`}
                        labelText={orgs?.[0]?.name}

                        labelIcon={ApartmentRoundedIcon}
                        color={colors?.accent}
                        bgColor={colors?.transparent}
                      />
                      {renderInner(orgs[0])}
                      </>
                }

            {orgs?.length > 1 && map(orgs, (org,i) => {
              const setActiveOrg = () => {
                  let {series, ...rest} = org
                  updatePrefs({currentOrg: {...rest}, currentSeries: null})
              }
              return(
                <StyledTreeItem
                  link={`/dashboard/${org.id}`}
                  key={`/dashboard/${org.id}`}
                  nodeId={`/dashboard/${org.id}`}
                  labelText={org?.name}
                  extraAction={setActiveOrg}
                  labelIcon={ApartmentRoundedIcon}
                  color={colors?.accent}
                  bgColor={colors?.transparent}
                >
                  {renderInner(org)}

                </StyledTreeItem>
              )
            })}

        </TreeView>
  )
}

import React, {useEffect} from 'react';
import { Animated, Text } from 'react-native';
import { useSettings } from '../Managers/Settings';
import { grade } from '../theme';

export const Grade = React.memo(props => {
  const {inlist, intable, static: _static, grade: g} = props
    const scale = _static ? new Animated.Value(1) : new Animated.Value(0)
    
    useEffect(() => {
      if(!props.static){
        Animated.spring(
          scale,
          {
            toValue: 1,
            friction: 3
          }).start()
      }
    })

    const { colors } = useSettings()
        return(
          <Animated.View key={g} style={[{transform: [{scale}]}, inlist ? grade.list(colors) : intable ? grade.table(colors) : grade.contact(colors) ]}>
              <Text style={grade.text(colors, g, inlist, intable)}>{g}</Text>
          </Animated.View>
    ) 
  })
  
export const GradeLetter = React.memo(props => {
    const {inlist, grade: g} = props
    const { colors } = useSettings()
    return(
      <Text style={grade.text(colors, g, inlist)}>{g}</Text>
    )
})
    
    
  
import React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components'
import { useSettings } from '../Managers/Settings';
import { OrgsPicker } from './OrgPicker';
import {useHistory} from 'react-router-dom'
import { useUser } from '../Managers/UserManager';




const Container = styled.div`

`

export function DashBoard({org_id}){


    // redirect to current org contacs
    const {prefs} = useUser()
    const history = useHistory()
    // console.log(prefs)
    useEffect(() => {
        if(prefs?.currentOrg?.id){
            history.push(`/dashboard/${prefs.currentOrg.id}/contacts`)
        }
        // UserManager.updatePrefs({currentOrg: null})
    }, [prefs?.currentOrg?.id])

    return(
        <Container>
                <OrgsPicker expanded showHint/>
        </Container>
    )
}
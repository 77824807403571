
import Moment from 'moment'
import {get, upperFirst, words, map, join, filter} from 'lodash';
export const UNSYNCED_ITEMS = String('ofaifj;oaew');
export const DATABASE_CACHE = String('cacfjowifj;je');
export const USER_DATA_TABLE = String('fkwpofa;wf');
export const SETTINGS_TABLE = String('foewjoijcjnakewf');
export const NETWORK_TABLE = String('3r92jfkslmflw');
export const ORG_TABLE = String('fwf;afj;oeifj');
export const ADVENTIST = "Adventist"

// export const _BASE = 'dev01-nehemiah.iiw.us'
export const _BASE = 'outreach.iiw.org'
export const BASE = 'https://' + _BASE
export const UNSECURE_BASE = 'http://' + _BASE
export const SOCKET_BASE = 'wss://' + _BASE + '/cable'
export const SECURE_API_BASE = BASE + '/api/v1'
export const UNSECURE_API_BASE = UNSECURE_BASE + '/api/v1'

export const drawerWidth = 240;
export const circleSize = 300;
export const AlertTypes = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
  default: 'default'
}

export const AUTHENTICATION_STATUS = {
    authenticated: 'Authenticated',
    unauthenticated: 'Unathenticated',
    unknown: 'Unknown'
}

export const validateOrg = (application, keys) => {
  return new Promise((resolve, reject) => {
      for(let key of keys){
          let item = get(application, `${key}`)
          if(key === 'zips'){

            let nonValid = filter(
                 item, 
                 zip => !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) && !/^(?:[ABCEGHJ-NPRSTVXY]\d[A-Z][ -]?\d[A-Z]\d)$/i.test(zip) 
              )

              if(nonValid.length > 0){
                reject({key, message: `Invalid Zip/Postal Codes: ${join(nonValid, ' ')}`})
              }
              
          }
          else if( item === '' || item === null || item === undefined){
             let _words = words(key)
             _words = map(_words, word => upperFirst(word) + ' ')
             const name = join(_words, ' ')
             reject({key, message: `${name} Required`})
          }
      }
      resolve()
  })
}

export const ORG_VALIDATION = [
  'name',
  'contact_person.first_name',
  'contact_person.last_name',
  'contact_person.email',
  'contact_person.phone',
  'pastor.first_name',
  'pastor.last_name',
  'pastor.phone',
  'pastor.email',
  'address.address1',
  'address.city',
  'address.state',
  'address.zip',
  'conference',
  'zips'
]
export const SUB_ORG_VALIDATION = [
  'name',
  'address.address1',
  'address.city',
  'address.state',
  'address.zip',
  'conference',
  'zips'
]

export const AlertPositions = {
  bottomCenter:  {
    vertical: 'bottom',
    horizontal: 'center',
  },
  bottomLeft: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  bottomRight: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  topRight: {
    vertical: 'top',
    horizontal: 'right',
  },
  topCenter: {
    vertical: 'top',
    horizontal: 'center',
  },
  topLeft: {
    vertical: 'top',
    horizontal: 'left',
  },
  centerCenter:{
    vertical: 'center',
    horizontal: 'center'
  }
}

export const weekdays = {            
    Sun : 1,
    Mon : 2,  
    Tue : 3,   
    Wed : 4,    
    Thu : 5,    
    Fri : 6,    
    Sat : 7
}
export const months = {
    Jan : 1,
    Feb:  2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
}
export const relative_days = {
    '1st': 1,
    '2nd': 2,
    '3rd': 3,
    '4th': 4,
    '5th' : 5,
    'last': 6  // obviously last will not be a weekend day
}
export const defaultTime = {
  allDay:  false,
  dateKey: '',
  recurring:  false,
  type:'datetime',
  days: {},
  months: {},
  days_of_the_month: {},
  start_date: Moment(),
  end_date: Moment(),
  duration: Moment.duration(30, 'minutes'),
  end_repeat:  null,
  frequency: 'Never',
  spacing: 1,
  id:  null,
  days_message:  '',
  recurrence_message: '',
  changingRecurrenceType:  false,
  date_type_index:  0,
  relative_day: null, // 1st, 2nd...las)t
  relative_day_position:  null,
  exceptions: []
}
export const getRelativeDay = (date, relative_day, relative_day_position) => {
    var day;
    switch(relative_day_position){
        case 'last': {
            const ldom = Moment(date).endOf('month').startOf('day')
            const ldomIndex = ldom.day()
            const index = weekdays[relative_day] - 1
            const difference = ldomIndex < index ? 7 + ldomIndex - index : ldomIndex - index
            day = Moment(ldom).subtract(difference, 'days')
            break;
            }
        default: {
            const fdom = Moment(date).startOf('month').startOf('day')
            const fdomIndex = fdom.day() 
            const index = weekdays[relative_day] - 1
            const difference = index >= fdomIndex ?  index - fdomIndex : 7 - fdomIndex + index
            const multiplier = relative_days[relative_day_position] - 1
            day = Moment(fdom).add(multiplier  * 7 + difference, 'days')
            if(fdom.month() !== day.month()){return null}
        }
    }
    return day
}

export const LightMapTheme = (colors) => {
  return [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "landscape.natural.terrain",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": colors.accent
        },
        {
          "saturation": -55
        },
        {
          "lightness": 55
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": colors.accent
        },
        {
          "saturation": -80
        },
        {
          "lightness": 75
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "stylers": [
        {
          "weight": 1
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": colors.accent
        },
        {
          "saturation": -75
        },
        {
          "lightness": 70
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  
  ]
} 
 
export const DarkMapTheme = (colors) =>  {
  return [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#212121"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#212121"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "saturation": -55
          },
          {
            "lightness": -65
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "saturation": -30
          },
          {
            "lightness": -85
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#181818"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#1b1b1b"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#2c2c2c"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#8a8a8a"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": colors.accent
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color":colors.accent
          },
          {
            "saturation": -70
          },
          {
            "lightness": -65
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#3c3c3c"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": colors.accent
          },
          {
            "saturation": -45
          },
          {
            "lightness": -55
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#4e4e4e"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": colors.accent
          },
          {
            "saturation": 55
          },
          {
            "lightness": -40
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#404040"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": colors.accent
          },
          {
            "saturation": -80
          },
          {
            "lightness": -40
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#3d3d3d"
          }
        ]
      }
    ]
}
export const getMomentCalendar = (date) => Moment(date).calendar(null, {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: 'DD/MM/YYYY'
})

export const CONFERENCES = [ 
'Alberta Conference',
'British Columbia Conference',
'Manitoba-Saskatchewan Conference',
'Maritime Conference',
'Ontario Conference',
'Quebec Conference',
'Seventh-day Adventist Church in Newfoundland and Labrador',
'Allegheny East Conference (AAC)',
'Allegheny West Conference (AAC)',
'Chesapeake Conference',
'Mountain View Conference',
'New Jersey Conference',
'Ohio Conference',
'Pennsylvania Conference',
'Potomac Conference',
'Illinois Conference',
'Indiana Conference',
'Lake Region Conference (AAC)',
'Michigan Conference',
'Wisconsin Conference',
'Central States Conference (AAC)',
'Dakota Conference',
'Iowa-Missouri Conference',
'Kansas-Nebraska Conference',
'Minnesota Conference',
'Rocky Mountain Conference',
'Alaska Conference',
'Idaho Conference',
'Northern New England Conference',
'Montana Conference',
'Oregon Conference',
'Upper Columbia Conference',
'Washington Conference',
'Arizona Conference',
'Central California Conference',
'Hawaii Conference',
'Nevada-Utah Conference',
'Northern California Conference',
'Southeastern California Conference',
'Southern California Conference',
'Carolina Conference',
'Florida Conference',
'Georgia-Cumberland Conference',
'Gulf States Conference',
'Kentucky-Tennessee Conference',
'South Atlantic Conference (AAC)',
'South Central Conference (AAC)',
'Southeastern Conference (AAC)',
'Arkansas-Louisiana Conference',
'Oklahoma Conference',
'Southwest Region Conference (AAC)',
'Texas Conference',
'Texico Conference'

]
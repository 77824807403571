

import React from 'react';
import { 
  View
 } from 'react-native';
 import { IconButton } from '@material-ui/core';
import { useSettings } from '../Managers/Settings';
import { wp } from '../Fork/react-native-responsive-screen';
import { containers, surface } from '../theme';
import { SectionBar } from './SectionBar';
import EditIcon from '@material-ui/icons/Edit';
import { Tags } from './Tags';
import { useAlert } from '../Managers/Alert';
import { useOrgs } from '../Managers/Orgs';



export const OrgTags = props => {
  const { org } = props
  const [editing, setEditing] = React.useState(false)
  const [ categories, setCategories] = React.useState([])
  const Alert = useAlert()
  const OrgsManager = useOrgs()
  const {colors} = useSettings()

  const toggleEdit = () => setEditing(!editing)

  const renderHeader = () => {
    return (
        <div style={{...containers.betweenRow}}>
          <div>
            <SectionBar title={'Tags'} style={{paddingHorizontal: 0}} />                        
          </div>
        <IconButton 
          aria-label="edit" 
          style={{marginTop: 5, transition: 'all .2s ease'}} 
          onClick={toggleEdit} 
          color={editing? 'primary' : 'default'}
        >
          <EditIcon />
        </IconButton>
      </div>
    )
  }

  React.useEffect(() => {
    props.org.id && OrgsManager.getCategoriesForOrg(props.org.id)
    .then(setCategories)
    .catch(Alert.error)
  }, [props.org])

  return(
    <View style={surface.paper(colors, wp)}>
      {renderHeader()}
      <Tags
          org={org}
          containerStyle={{margin:0}}
          contactCategories={categories} 
      />
    </View>
  )
}


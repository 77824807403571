import React, {useEffect} from 'react';

import _, { map } from 'lodash';
import { Database } from '../Managers/exports';
import { Paper } from '@material-ui/core';
import { useSettings } from '../Managers/Settings';
import { SectionBar  } from '../Components/exports';

 export const Series = () => {
    const [ series, setSeries] = React.useState([])
    const {prefs} = useSettings()

    useEffect(() => {
        const call = async () => {
            const id = prefs?.currentOrg?.id
            let response = await Database.get(`/orgs/${id}/series`)
            setSeries(response)
            // console.log(response)
        }
        call()

    }, [prefs?.currentOrg?.id])

    return(
        <div>
            {
            map(series, s => (
                <Paper>
                    <SectionBar title={s?.name}/>
                </Paper>
            ))
            }
        </div>
    )

 }

 

import React from 'react';

import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { 

  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select, 
  MenuItem } from '@material-ui/core';


import * as firebase from "firebase/app";
import "firebase/database";
import { useAlert } from '../Managers/Alert';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// Add the Firebase products that you want to use



  const useStyles = makeStyles((theme) => ({
    formControl: {
      '& .MuiTextField-root': {
        marginTop: theme.spacing(2),
      },
    }
  })
  );


  export const AlertDialog = props => {
      
    const { visible  } = props
    const [topic, setTopic] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');

    const Alert = useAlert()

    const onTopicChange = (event) => {
      setTopic(event.target.value);
    };
    const onEmailChange = (event) => {
        setEmail(event.target.value);
      };
    const onMessageChange = (event) => {
    setMessage(event.target.value);
    };
    const classes = useStyles();

    const send = () => {

        
        close()
        firebase.database().ref('OutreachSupportRequests').push({
          email,
          message,
          topic,
          datetime: _.now(),

        })
        Alert.success('Message sent')
    }
    const close = () => {
        props.close && props.close()
    }

    return(
      <div>
        <Dialog
          open={visible}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Need Help?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Fill out this form and we'll get back to you! Please select the appropriate topic below.
            </DialogContentText>
            <form>
              <FormControl required fullWidth className={classes.formControl}>
                <InputLabel htmlFor="topic">Topic</InputLabel>
                <Select
                  labelId="topic-select-label"
                  id="topic-simple-select"
                  value={topic}
                  onChange={onTopicChange}
                >
                  <MenuItem value={'support'}>General Support</MenuItem>
                  <MenuItem value={'feature'}>Feature Request</MenuItem>
                  <MenuItem value={'bug'}>Report a bug</MenuItem>
                </Select>

                <TextField
                  autoFocus
                  // margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  value={email}
                  onChange={onEmailChange}
                />

                <TextField
                  id="outlined-multiline-flexible"
                  label="Message"
                  multiline
                  value={message}
                  onChange={onMessageChange}
                  rowsMax={6}
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Close
            </Button>
            <Button onClick={send} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
    
  }

import React, { useEffect, useState } from 'react';
import { 
    View,
    Text,
    ScrollView,
 } from 'react-native';
 import _ from 'lodash';
 import { useSettings } from '../Managers/Settings'
import { Input } from './exports';
import {text, containers } from '../theme';
import { MemberSearch } from '../Managers/exports';
import { Avatar, Collapse, IconButton } from '@material-ui/core';
import { motion } from "framer-motion"
import { useOrgs } from '../Managers/Orgs';


export const MemberAvatars = ({org, onSelect, owner }) => {

    let [members, setMembers]  = React.useState([])
    let [filteredMembers, setFilteredMembers]  = React.useState([])
    let [ searchText, setSearchText] = React.useState([])
    const [ limit, setLimit] = React.useState(30)

    const OrgsManager = useOrgs()
    
    useEffect(() => {
        if(!org?.id){ return }
        OrgsManager.getMembers(org?.id)
        .then(_members => {
            let ms = _.uniqBy(_members, 'user.id')
            setMembers(ms)
            setFilteredMembers(ms)
            MemberSearch.setDataSorce(ms)
        })
        .catch(e => {

        })
    }, [org])

    const reset =()=> {
        setFilteredMembers(members)
    }
    const search = (txt) => {
        setSearchText(txt)
        if(_.trim(txt) === ''){
            reset()
        }
        let results =  MemberSearch.search(txt)
        // console.log(results)
        setFilteredMembers(results)

    }

    const { colors, appTheme} = useSettings()
    const isDark = appTheme === 'dark'

    return(
        <View style={{
            flex:1, 
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            backgroundColor: colors?.background,
            width: '100%'
        }}>

            <Input 
                placeholder={'Assign to...'}
                onTextChange={search}
                value={searchText}

            />
            <ScrollView contentContainerStyle={[{paddingHorizontal: 20, alignSelf: 'stretch'}, containers.leftRowWrap]}>
                {_.map(filteredMembers, (m, i) => {  
                    if(i > limit)  {
                        return <> </>
                    }
                    return (
                        <MemberAvatar 
                            persist
                            onPress={onSelect}
                            isOwner={owner?.id === m?.user?.id}
                            key={i}
                            person={m?.user}
                        />
                    )
                })}
            </ScrollView>
        </View>
    )  
  
}

export const MemberAvatar = props => {

    const { person, persist } = props
    const onPress= () => {
        props.onPress && props.onPress(props.person)
    }

    const [hovering, setHovering] = useState(false)
    const onHover = () => setHovering(true)
    const onHoverEnd = () => setHovering(false)
    const {colors} = useSettings
    if(!person) {return <></>}
        const ownerStyle=[
        text.bold,
        text.accent(colors),
        text.tiny
    ]
    const notOwnerStyle = [
        text.contrast(colors),
        text.tiny
    ]
    const avatarStyle={
        borderWidth: 3,
        borderRadius: 35,
        borderColor: colors?.accent,
        borderStyle: 'solid'
    }
    const first_name = person?.first_name || '';
    const last_name = person?.last_name || '';
    const initials = _.upperCase(`${_.first(first_name) || ''}${_.first(last_name) || ''}`)
                    
    return(
        <motion.div
            animate={{scale: 1, opacity: 1}}
        >
        <div  style={{...containers.centerColumn, margin: 10}} 
            onMouseEnter={onHover}
            onMouseLeave={onHoverEnd}
        >
            <IconButton  onClick={onPress}>
            <Avatar 
                style={props?.isOwner ? avatarStyle : {}}
                src={person?.image} 
                size={120}
            >{initials}</Avatar>
            </IconButton>
            <Collapse in={persist || hovering}>
                <div style={containers.centerColumn}>
                    <Text style={props.isOwner ? ownerStyle : notOwnerStyle}>{first_name}</Text>
                    <Text style={props.isOwner ? ownerStyle : notOwnerStyle}>{last_name}</Text>
                </div>
            </Collapse>
        </div>
        </motion.div>
        
    )

}
const widthPercentageToDP = widthPercent => {
  return(String(widthPercent + 'vw'))
};
const wpCss = (args) => widthPercentageToDP(args)


const heightPercentageToDP = heightPercent => {
  return(String(heightPercent + 'vh'))
};
const hpCss = (args) => heightPercentageToDP(args)

const hp = (percentage) => window.innerHeight * (percentage/ 100)
const wp = (percentage) => window.innerWidth * (percentage / 100)

const listenOrientationChange = (args) => {}
const removeOrientationListener = listenOrientationChange
const listenOrientationChangeFunctional = removeOrientationListener


export {
  widthPercentageToDP,
  heightPercentageToDP,
  wpCss, 
  hpCss,
  wp,
  hp, 
  listenOrientationChange,
  removeOrientationListener,
  listenOrientationChangeFunctional
};

import React, { useContext } from 'react'
import {
    View,
    Text,
} from 'react-native'

import  {   dividers, text } from '../theme';
import _ from 'lodash';
import { SettingsContext } from '../Managers/Settings';

export const SectionBar = props => {

        
        let style = props.style ? props.style : {}
        const title =  !props?.raw ? _.upperCase(_.get(props, 'title', '')) : props?.title
        const rightButtonTitle = !props?.raw ? _.upperCase(_.get(props, 'rightButtonTitle', '')) : props?.rightButtonTitle
        const rbaction = props.onPress ? props.onPress : _.noop   
        const { disabledTopMargin } = props

        const { colors } = useContext(SettingsContext)
      
        return(
            <View style={[dividers.container, (disabledTopMargin ? {} : {marginTop: 20}), style]}>
            <Text style={[
                dividers.title, 
                text.bold,
                text.tiny,
                text.contrast(colors),
                {flex: 1}]}>{title}</Text>
            {!!rightButtonTitle &&<Text 
                onPress={rbaction}
            style={[
                text.right,
                text.bold,
                text.tiny,
                text.contrast(colors),
            {flex: 1}]}>{rightButtonTitle}</Text>}
                
        </View>
        )

}
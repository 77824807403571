import React from 'react';
import { 
    View, 
    TouchableOpacity,
    Text,  
    Animated,
 } from 'react-native';
 import _ from 'lodash';
import { containers, text } from '../theme';
import { SettingsContext } from '../Managers/Settings';
import LinearGradient from 'react-native-linear-gradient';
export class TabbedPageView extends React.Component {
    static contextType = SettingsContext
    constructor(props){
        super(props)
        this.state={
            ...props,
            lineWidth: 0,
            selectedIndex: 0
        }
    }
    x = new Animated.Value(0)
    
    
    onPress = async (button) => {
        let action = button?.action || _.noop
        try{
            await this.animateToIndex(button.index)

        } catch(e){
            
        }
        this.setState({selectedIndex : button.index})
        this.props.onChange && this.props.onChange(button.index)
        _.attempt(action) 

    }

    animateToIndex = async (index) => {
        return new Promise((resolve, reject) => {
            Animated.spring(
                this.bar,
                {
                    toValue: index,
                    duration: 240,
                }
            ).start(resolve)
        })
    }

    componentDidUpdate(prev){
        if(!_.isEqual(prev, this.props)){
            this.setState({...this.props})
        }
    }

    setupView = ({nativeEvent: {layout}}) => {
        this.bar = new Animated.Value(this.state.selectedIndex)
         const length  = this.props?.buttons?.length || 1
         const unit = layout.width / length
         
         
         const maxDistance = unit * ( length - 1 )
        const max = length - 1
        
        this.x = this.bar.interpolate({
            inputRange: [0, max],
            outputRange: [0, maxDistance]
        })
        this.setState({lineWidth: unit})


    }

    renderButtons = () => {
        let { colors, appTheme } = this.context 
        const isDark = appTheme === 'dark'
        let { buttons, selectedIndex, onlyTabs, transparent, lineWidth, textStyle} = this.state
        
        let titles = _.map(buttons, (button, index) => {
            const style = [
                text.small, 
                text.center,
                index === selectedIndex ? text.bold : {},
                transparent ?  text.white : text.base(colors),
                textStyle ? textStyle : {}
            ]
            const onClick = () => this.onPress({...button, index})
            const buttonStyle = 
                {
                    paddingVertical: 10,
                     maxHeight: 100,
                    flex:1
                }
            

   
            return (
                <TouchableOpacity
                    
                     key={index} 
                     style={buttonStyle} 
                     onPressIn={onClick}>
                    <Text style={style}>{button.title}</Text>
                </TouchableOpacity>
            )
        })

        let style=[
            containers.betweenRow,
            { 
                paddingHorizontal: 10,
                width: '100%',
                backgroundColor:  this.props.transparent 
                    ? colors?.transparent  
                        : !onlyTabs && isDark 
                            ? colors?.background
                                : colors?.background,
            },
             
            ]
        return (
            <View style={{alignSelf: 'stretch', ...containers.centerColumn}}>
            <View style={style}>{titles}</View>
            <View style={{alignSelf: 'stretch'}}>
                <Animated.View 
                    style={{
                        height: 4,
                        width: lineWidth,
                        backgroundColor: colors?.accent,

                        transform: [{
                            translateX: this.x
                        }]
                    }}
                />
            </View>
            </View>
        
        )
    }

    renderView = () => {
        let { buttons, selectedIndex } = this.state
        let { appTheme, colors } = this.context
        let view = buttons[selectedIndex]?.view
        const isDark = 'dark' === appTheme
        
            
        
        return (
            <LinearGradient 
                colors={
                    isDark 
                    ?  [colors?.background , colors?.background] 
                    : [colors?.dark, colors?.background]}
                locations={[0.0,0.01]}
                style={{height: '100%'}}
                    >{view ? view() : <></>}
            </LinearGradient>
        )
    }


    render() {
        let { onlyTabs, transparent, style} = this.props
        const _style = style ?? {}
        const { colors, appTheme} = this.context
        let isDark = appTheme === 'dark'
        return(
            
            <View 
            onLayout={this.setupView}
            style={[
                {
                    alginSelf: 'stretch', 
                    height: onlyTabs ? 'auto' : '100%',
                    backgroundColor: transparent 
                        ? colors?.transparent 
                            : isDark 
                                ? colors?.background
                                    : colors?.background,
                    marginBottom: onlyTabs ? 10 : 0
                    },
                    // onlyTabs && !transparent &&  shadows.default(0) ,
                    _style
                ]
                }>
                {this.renderButtons()}
                {!onlyTabs && this.renderView()}
            </View>
            
        )

        
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { containers, text } from '../theme';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { IconButton } from '@material-ui/core';
import { View , Text} from 'react-native';


export const TempPagination = props => {

    const { page, nextPage} = props

    const onNext = () => {
        props.onNext && props.onNext(null, page + 1)
    }
    const onPrevious = () => {
        if(page <= 1){
            return 
        } else {
            props.onPrevious && props.onPrevious(null, page - 1)
        }
        
    }

    return(

        <View style={[containers.aroundRow]}>
             <View style={styles.button}>
                <IconButton onClick={onPrevious} disabled={page <= 1}>
                    <ChevronLeftRoundedIcon />
                </IconButton>
            </View>
            <View style={styles.outerNumber}>
                <Text style={styles.number}>
                    {page}
                </Text>
            </View>
            <View style={styles.button}>
                <IconButton onClick={onNext} disabled={!nextPage}>
                    <ChevronRightRoundedIcon />
                </IconButton>
            </View>
      </View>
    )
}

TempPagination.propTypes = {
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired
  };
const size = 25
const styles = {
    button: {
        height: size,
        width: size,
        borderRadius: size / 2,
        borderWidth: 1,
        borderColor: 'lightgrey',
        borderStyle: 'solid',
        marginHorizontal: 10,
        // padding: 1,
        ...containers.centerColumn

    },
    outerNumber: {
        height: size,
        width: 'auto',
        minWidth: size,
        borderRadius: size / 2,
        borderWidth: 1,
        borderColor: 'lightgrey',
        borderStyle: 'solid',
        marginHorizontal: 10,
        padding: 4,
        ...containers.centerColumn

    },
    number: {
        ...text.tiny,
    }
}
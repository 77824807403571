import React from 'react'
import { motion } from 'framer-motion'

export const MountTransition = props => {
  const { children, slide = 0, slideUp = 0, noFade = false, expand = false, } = props

  return (
    <motion.div
      exit={{ opacity: noFade ? 1 : 0, x: slide, y: slideUp, height: expand ? 0 : 'auto', overflow: expand ? 'hidden' : 'visible' }}
      initial={{ opacity: noFade ? 1 :  0, x: slide, y: slideUp, height: expand ? 0 : 'auto', overflow: expand ? 'hidden' : 'visible' }}
      animate={{ opacity: 1, x: 0, y: 0, height: 'auto', overflow: 'visible' }}
    >
      {children}
    </motion.div>
  )
}
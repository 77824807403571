import React, { useEffect } from 'react';
import { View, Text, Animated } from 'react-native';
import PropTypes from 'prop-types';
import { makeStyles, emphasize, withStyles } from '@material-ui/core/styles';
import { alerts, containers } from '../theme';
import _ from 'lodash';
import { hpCss } from '../Fork/react-native-responsive-screen'
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { 
   Popover,
   Toolbar,
   Hidden,
   Drawer,
   IconButton,
   CssBaseline,
   AppBar,
   Box,
   Avatar,
   useScrollTrigger,
  Slide,
  List,
  ListItem,
  ListItemText,
  Select,
  Typography,
  ListItemSecondaryAction,

 } from '@material-ui/core';
import { text, shadows } from '../theme';
import { useSettings } from '../Managers/Settings';
import { Fade } from '../Animations/Fade'
import { SmallButton, RoundedButton, ColorPicker, AlertDialog,ParticleBackdrop } from '../Components/exports';
import { darken } from '../Utilities';
import { AlertPositions, circleSize, drawerWidth} from '../Constants'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {SideBar} from './sidebar'
import { Redirect, useParams } from 'react-router-dom';
import { Organization } from './orgs';
import { Contacts } from './contacts';
import { Series as SeriesPage } from './series'
import { AnimatedRoutes, RouteTransition } from '../Animations/RouteTransition'
import { useHistory } from 'react-router-dom'
import { LogoWord } from '../Components/Logo';
import { DecisionCards } from './decision-cards';
import { DashBoard } from '../Components/dashboard';
import dayjs from 'dayjs';
import relativeTime  from 'dayjs/plugin/relativeTime'
import { FiberManualRecordRounded, Notifications } from '@material-ui/icons';
import { useAlert } from '../Managers/Alert';
import { useOrgs } from '../Managers/Orgs';
import { useUser } from '../Managers/UserManager';


dayjs.extend(relativeTime)


const dropCircle = {
  default: (_colors, trans) => {
      return {
          backgroundColor: _colors?.accent,
          width: circleSize,
          height: circleSize,
          position: 'absolute',
          top:  -circleSize / 1.5,
          // left: 0,
          borderRadius: "50%",
          transform: [{translateY: trans}, {scaleX: 1.5}]
      }
  },
  done: (_colors) => {
      return {
          backgroundColor: _colors?.accent,
          width: circleSize,
          height: circleSize,
          position: 'absolute',
          top:  -circleSize / 1.5,
          // left: 0,
          borderRadius: "50%",
          transform: [{translateY: 0}, {scaleX: 1.5}]
      }
  }
}

const DropCircle = props => { 
  const transY = new Animated.Value(-circleSize / 2)
  const {colors} = useSettings()

  useEffect(() => {
      Animated.spring(
          transY,
          {
              toValue: 0,
              friction: 3,
              duration: 1000,
              // useNativeDriver: true,
              // delay: 500
           }
      ).start()
      
  },[''])

  return(
   <Animated.View  style={[dropCircle.default(colors,  transY ),  shadows.default(0)]} />
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} style={{flex:1}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function ListItemLink(props) {
  return <ListItem style={{
    paddingTop: 2, 
    paddingBottom: 2,
    


  
  }}  {...props} />;
}


const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    height: hpCss(100),
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerContainer: {
    // ...containers.topCenterColumn,
    backgroundColor: theme.palette.background.paper
  },
  tabContainer: {
    // ...containers.topCenterColumn,
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    textAlign: 'left',
    borderRight: `0px solid ${theme.palette.divider}`,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: '10px 0px 34px -15px rgba(0,0,0,0.18);',
  },
  content: {
    flex: '1 0 auto',
  [theme.breakpoints.down('sm')]: {
      padding: 0 //theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    
    maxWidth: `95vw`,
    margin: "0 auto",
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  closeMenuButton: {
    marginLeft: 190,
    position: 'absolute',
    zIndex: 1,
  },
  grow: {
    flexGrow: 1,
  },
  profile: {
    width: circleSize,
    height: 500,
  },
  avatar: {
    border: '4px solid #ececec',
    width: 80, 
    height: 80,
  },
  logo: {
    fontSize: '1.65rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    margin: 'auto',
    padding:43,
  },
  footer: {
    padding: '10px 0',
    borderTop: '1px solid #e7e7e7',
    flexShrink: 0,

    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  footerLink: {

    '& a': {
      fontSize: 12,
      textTransform: 'uppercase',
      textDecoration: 'none',
      color: '#646464',
      fontWeight: '600',
      transition: 'all 0.4s ease',
    },

    '&:hover': {
      cursor: 'pointer',
      
      '& a': {
        color: theme.palette.primary.main,
        
      },
    }
  },
  formControl: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
      // width: '25ch',
    },
  },
  notifications: {
    width: 320,
    maxHeight: 320,
    backgroundColor: theme.palette.background.paper,
  },
  new: {
    '& .MuiListItemText-primary': {
      color: '#000',
      fontWeight: 'bold',
    },

    '& .MuiListItemText-secondary': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    
  },
  dot: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    fontSize: 27,
  }
})
);

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.main,
    height: theme.spacing(4),
    width: 'auto',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: darken(theme.palette.primary.main, 1)
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.primary.main, 0.12),
    },
  },
}))(Select);

class RenderArrow extends React.Component {

 ani = new Animated.Value(this.props.value ? 1 : 0)

componentDidUpdate(prev) {
  if(!_.isEqual(prev, this.props)){
    this.animate(this.props.value)

  }
}

animate = (value) => {
  Animated.spring(
    this.ani,
    {
        toValue: value ? 1 : 0,
        friction: 3,
        duration: 700,
    } 
  ).start()
}

  render() {
      return(

        <Animated.View style={{
            transform:[
                {translateX: -5},
                {rotate: this.ani.interpolate({
                    inputRange: [0,1],
                    outputRange: ['0deg', '180deg']
                    })}] 
            }}>
            <ExpandMoreIcon />
        </Animated.View>
    )
    }

}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


export const Main = props =>  {
  const { window } = props;

  let {prefs, colors} = useSettings()
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [orgsOpen, setOrgsOpen] = React.useState(false);
  const [ subOrgsOpen, setSubOrgsOpen] = React.useState(false);
  const [seriesOpen, setSeriesOpen] = React.useState(false);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';
  const [supportOpen, setSupportOpen] = React.useState(false);
  
  const leaving = new Subject()
  const history = useHistory()
  const [notify, setnotify] = React.useState(null);
  const org = prefs?.currentOrg
  const seri = prefs?.currentSeries
  const Alert = useAlert()
  const params = useParams()
  const OrgsManager = useOrgs()
  const {user, logout, setAccentColor} = useUser()
  const handleNotifications = (event) => {
    setnotify(event.currentTarget);
  };

  const closeNotifcations = () => {
    setnotify(null);
  };

  const notifyOpen = Boolean(notify);


  const handleSupportOpen = () => {
    setSupportOpen(true);
  };

  const handleSupportClose = () => {
    setSupportOpen(false);
  };


  const handleChange = (event, org) => {

    if(seriesOpen) { setSeriesOpen(false) }
    if(orgsOpen) { setOrgsOpen(false) }
    history.push(`/dashboard/org/${org.id}`)
  };

  const handleProfileOrg = (event, org) => {
    // need to rework this, navigates to organization page by index of pages.js
    handleChange(event, org)
    handleMenuClose()
  }


  // useEffect(() => {

  //   user?.prefs?.currentOrg?.id && OrgsManager.getNotifications(user.prefs.currentOrg.id)
  //   .then(notifications => {

  //   }).catch(e => {
  //     console.error(e)
  //   })

  // },[user])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    handleMenuClose()
    Alert.warning(
      'Are you sure you want to Logout?',
      alerts.confirm(logout, _.noop),
      AlertPositions.topCenter
    )

  }

  const onColorChange = (color, event) => {
    // console.log('Setting accent Color = ', color)
    setAccentColor(color.hex)
  };

  const handleOrgsToggle = () => {
    setOrgsOpen(!orgsOpen);
    
  };

  const handleSubOrgsToggle = () => {
    setSubOrgsOpen(!subOrgsOpen);
  }

  const handleSeriesToggle = () => {
    setSeriesOpen(!seriesOpen);
  };

  const profilePicture = _user => {
    let first_name = _.upperFirst(_.get(_user, 'first_name[0]', ''))
    let last_name = _.upperFirst(_.get(_user, 'last_name[0]', ''))
    
    return (
        <Fade
          scale
          translate
          inverse
          spring
          friction={3}

        >
        <View style={{
          alignSelf: 'center',
          width: '100%',
          ...containers.centerRow,
          transform: [{translateY: circleSize / 5 }]
        }}>
            <Avatar
              size='xxxlarge' 
              src={user?.image}
              alt={'../Images/OutreachLogo.png'}
              className={classes.avatar}
            >
              <Text style={[ text.medium, text.bold, text.white, ]}>{first_name}{last_name}</Text>
            </Avatar>
        </View>
         </Fade>
      
    )
  }
  

  const renderMenu = () => {
          return(
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              id={menuId}
              keepMounted
              open={isMenuOpen}
              onClose={handleMenuClose}
            >
              <Box
                className={classes.profile}
              >
              {!!anchorEl && <DropCircle closing={anchorEl} />}
              {!!anchorEl && profilePicture(user)}
              <View style={{paddingHorizontal: 20,}}>
                <div style={{paddingTop: circleSize / 5, textAlign: 'center'}} >
                  <Text style={[text.bold, text.small, text.disabled]}>
                    {user?.first_name} {user?.last_name}
                  </Text>
                </div>
                <Text style={[text.tiny, text.disabled, containers.centerRow, {paddingTop: 5, paddingBottom: 25}]}>
                  {user?.email}
                </Text>

                <div style={{height: 30}} />
                  <Text style={[text.tiny, text.disabled, containers.leftRow, text.bold, {paddingTop: 20, paddingBottom: 10}]}>
                    Appearance
                  </Text>
                <ColorPicker 
                  style={[containers.centerRow, containers.paddingVertical,]}
                  color={_.clone(colors?.accent)}
                  onChangeComplete={onColorChange}
                />
                {/* <Delete /> */}
                <div style={{height: 50}} />
                {/* <SmallButton title={"clear"} width={120} onPress={() => UserManager.updatePrefs({currentOrg: null})} /> */}
                <SmallButton title='Logout' width={120} onPress={logout} />
                </View>
            </Box>

            </Popover>
          )
  }

  const drawer = (color) =>  (
    <div>

        <Box pb={4}/>
        <LogoWord color={color} scale={0.75} delay={0} />
        <SideBar />

    </div>
  );

      

  const container = window !== undefined ? () => window().document.body : undefined;
  const {location} = props
  const path = location?.pathname

  const testNotifications = [
    {
      body: "Prayer Request - We need more cheezits" ,
      date: "2020-11-10T00:00:00.000Z",
      new: true,
    },
    {
      body: "Prayer Request - Think of the children they are great" ,
      date: "2020-11-09T00:00:00.000Z",
      new: true,
    },
    {
      body: "Prayer Request - My name isn't Tim Apple you know" ,
      date: "2020-11-09T00:00:00.000Z",
      new: false,
    },
    {
      body: "Prayer Request - God bless America (and all the other countries)" ,
      date: "2020-11-08T00:00:00.000Z",
      new: false,
    },
    {
      body: "Prayer Request - Material-UI for Figma. Not new, but improved!" ,
      date: "2020-11-07T00:00:00.000Z",
      new: false,
    },


  ];
  
  
  const closeAll = () => {

    setOrgsOpen(false)
    setSubOrgsOpen(false)
    setSeriesOpen(false)
  }

  const onSeriesSelect = () => {
    
    closeAll()
  }
  const renderMain = () => {
    return(
      <main className={classes.content}>
        <div className={classes.toolbar} />
            {/* <Collapse in={orgsOpen}>
              <SectionBar title={'My Organizations'} disabledTopMargin={true}/>
                {!!orgsOpen && <OrgsPicker 
                onSelect={closeAll}
                expanded={orgsOpen} />}
            </Collapse>
            {prefs?.currentOrg?.suborgs?.length > 0 && <Collapse in={orgsOpen}>
              <SectionBar title={prefs?.currentOrg?.name + ' Sub Organizations'} disabledTopMargin={true}/>
                {!!orgsOpen && <SubOrgsPicker 
                  onSelect={closeAll}
                  expanded={orgsOpen || subOrgsOpen} 
                  org={prefs?.currentOrg} />}
            </Collapse>}
            <Collapse in={seriesOpen}>
              <SectionBar title={`Series for ${prefs?.currentOrg?.name}`} disabledTopMargin={true} />
              {!!seriesOpen && <SeriesPicker 
                onSelect={onSeriesSelect}
                expanded={seriesOpen} 
                org={prefs?.currentOrg}/>}
            </Collapse> */}
            <AnimatedRoutes exitBeforeEnter initial={true}>
              <RouteTransition 
                exact 
                path={`/dashboard`}
                slideUp={0}
                children={<DashBoard />}
              />
              <RouteTransition exact slideUp={30} path={`/dashboard/:org_id`}>
                <Organization />
              </RouteTransition>
              <RouteTransition exact slideUp={30} path={`/dashboard/:org_id/series`} >
                <SeriesPage />
              </RouteTransition>
              <RouteTransition exact slideUp={30} path={`/dashboard/:org_id/decisions/`} >
                <DecisionCards />
              </RouteTransition>
              <RouteTransition exact slideUp={30} path={`/dashboard/:org_id/decisions/:series_id`} >
                <DecisionCards />
              </RouteTransition>
              <RouteTransition  slideUp={30} path={`/dashboard/:org_id/contacts/archived`} >
                <Contacts archived={true} />
              </RouteTransition>
              <RouteTransition  slideUp={30} path={`/dashboard/:org_id/contacts/series/:series_id`} >
                <Contacts />
              </RouteTransition>
              <RouteTransition  slideUp={30} path={`/dashboard/:org_id/contacts`} >
                <Contacts />
              </RouteTransition>

    
              <RouteTransition exact slideUp={30} path={'*'}  >
                <Redirect to={'/dashboard'} />
              </RouteTransition>
            </AnimatedRoutes>
          <AlertDialog visible={supportOpen} close={handleSupportClose}/>
      </main>
    )
  }

  const renderFooter = () => {
    return(
      <footer id="footer" className={classes.footer}>
      <div style={{paddingLeft: 15, paddingRight: 15}}>
        <List style={{display: 'inline-block'}}>
          <ListItemLink 
            // onClick={handleSupportOpen} 
            className={classes.footerLink}>
            <ListItemText >
              <a className={classes.block } 
                target="_blank"
                href={'mailto:outreachsupport@iiw.org'} >
                Support
              </a>
            </ListItemText>
          </ListItemLink>
        </List>
        <p style={{position: 'relative', float: 'right', ...text.disabled, padding: 15, margin:0}}>&copy; {new Date().getFullYear()} It Is Written. All rights reserved.</p>
      </div>
    </footer>
    )
  }
    
  const renderAppBar = () => {
        return(
          <HideOnScroll  {...props}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="default"
                // color={'#FF0000'}
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
              <MenuIcon />
              </IconButton>
              {/* 
                <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  key={'parent-org-bread'}
                  label={org?.name}
                  variant={'outlined'}
                  onChange={handleOrgsToggle}

                >


                </StyledBreadcrumb>
                  {seri && <StyledBreadcrumb
                    key={'series-bread'}
                    label={seri?.name ? seri.name: 'Select Series'}
                    variant={'outlined'}
                    onChange={handleSeriesToggle}
                    clickable
                    
                  />}
                </Breadcrumbs>*/}
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {/* <IconButton aria-label="show new notifications" color="default" onClick={handleNotifications}>
                  <Badge badgeContent={testNotifications.length} color="secondary">
                    <Notifications />
                  </Badge>
                </IconButton> */}

                <Popover
                  open={notifyOpen}
                  anchorEl={notify}
                  onClose={closeNotifcations} 
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div>
                    <Box display="flex" flexDirection="row" pl={2} py={1} bgcolor="background.paper">
                      <Typography variant="body2" className={classes.title}>Notifications</Typography>
                    </Box>
                    <List className={classes.notifications} disablePadding>
                      {testNotifications.map((value, index) => {
                        const labelId = `checkbox-list-label-${value}`;
                        return (
                          <ListItem key={index} role={undefined} dense button divider disableTouchRipple >
                            <ListItemText id={labelId} primary={value.body} secondary={dayjs(value.date).fromNow()} className={value.new ? classes.new : null } />
                            <ListItemSecondaryAction>
                              {value.new && <FiberManualRecordRounded className={classes.dot} />  }
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                </Popover>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="default"
                >
                  {user?.image && <Avatar
                    size='xxlarge' 
                    src={user?.image}
                    alt={'../Images/OutreachLogo.png'}
                  >
                  </Avatar>}
                  {!user?.image && <AccountCircle style={{fontSize: 30,}} />}
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        )
  }

  const renderNav = () => {
    return(
      <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
            <CloseIcon/>
          </IconButton>

          {drawer(colors?.accent)}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          // style={{height: hpCss(100)}}
          variant="permanent"
          open
        >
          {drawer(colors?.accent)}
        </Drawer>
      </Hidden>
    </nav>
    )
  }


  return(
    <>
      <div className={classes.root}>
        <ParticleBackdrop clickEffects={false} parallax={false}/>
        <CssBaseline />
        {renderAppBar()}
        {renderMenu()}
        {renderNav()}
        {renderMain()}
        {renderFooter()}
        
    </div> 
    </>     
  )


}

const Wrap = props => {
  const {i, lastValue, value , children} = props
  return(
   <Slide direction={lastValue >= i ? 'down' : 'up'} in={value === i} mountOnEnter unmountOnExit>
    <div> 
      {children}
    </div>
   </Slide>
  )
}





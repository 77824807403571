import React, { useContext } from 'react';

import _ from 'lodash'
import { ListItem } from './ListItem';
import { surface } from '../theme';
import { wp } from '../Fork/react-native-responsive-screen';
import { SettingsContext } from '../Managers/Settings';
import { View } from 'react-native';

export const ContactSurveys = props => {
    const {surveys} = props

    const { colors } = useContext(SettingsContext)
        return(
            <View style={surface.paper(colors, wp)}>
                { _.map(surveys, survey =>  (
                        <ListItem 
                            title={survey?.title}
                        />
                    )
                )}
            </View>
        )
}
import React, { useContext } from 'react';
import { SettingsContext } from '../Managers/Settings';
import { 
    View, 
    Text,
    TouchableOpacity
 } from 'react-native';
import { containers } from '../theme';
import { Avatar } from '@material-ui/core';
import { ChevronRightRounded } from '@material-ui/icons';
import { motion } from "framer-motion"

export const ListItem = React.memo(props => {

    const onPress= () => {
        props.onPress && props.onPress()
    }
    const { colors } = useContext(SettingsContext)
        return(
            <TouchableOpacity 
                style={{
                    paddingHorizontal:5,
                    flex:1,
                    maxWith: '100%',
                    backgroundColor: colors?.background,
                    ...props.containerStyle}
                }
                onPress={onPress}
                disabled={!props.onPress}
            >
                <View style={{
                    ...containers.betweenRow, 
                    maxWidth: '100%',
                    ...props.contentContainerStyle}}>
                    <View style={containers.leftRow}> 
                            {props.leadingImage && <motion.div
                            whileHover={{ scale: 1.1 }}
                            animate={{scale:[0, 1], opacity:[0, 1]}}
                            transition={{duration: 0.4}}
                            >

                            <View style={{marginRight:10}}>
                                <Avatar src={props.leadingImage}>
                                    {props?.noImageTitle || ''}
                                </Avatar>
                        </View>
                        </motion.div>
                        }
                        <View style={containers.leftCenterColumn}>
                            <Text style={props.titleStyle}>{props?.title || ''}</Text>
                            <Text  style={props.subtitleStyle}>{props?.subtitle || ''}</Text>
                            </View>
                    </View>
                    <View style={containers.rightColumn}>
                        <Text style={props.rightTitleStyle}>{props?.rightTitle || ''}</Text>
                        <Text style={props.rightSubtitleStyle}>{props?.rightSubtitle || ''}</Text>
                    </View>
                    {!!props.rightIcon && props.rightIcon}
                    {!!props.chevron && <ChevronRightRounded style={{color: props.chevronColor || colors?.accent}}/>}
                </View>
            </TouchableOpacity>
        )

})


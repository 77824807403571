import React from 'react';
import { Category } from './exports'
import _ from 'lodash';

export const Categories = props => {
    const { selected, categories } = props
  
    const onSelect = (id) => {
      props.onSelect && props.onSelect(id)
    }
    
      return(
        <div style={{alignSelf: 'stretch'}}>
          {_.map(categories, (cat, index) => {
            return <Category 
              onSelect={onSelect}
              index={index}
              selected={( cat?.id === selected || categories?.length === 1)} 
              key={cat?.id} 
              category={cat} />
          })}
        </div>
      )
  }
import React from 'react';
import { 
    View,
    Text,
 } from 'react-native';
import { text, containers } from '../theme.js'
import _ from 'lodash';

import { OrgCodes, SubOrgs, Members, Hint, Zips, LoadingAnimation,} from '../Components/exports'
import { useSettings } from '../Managers/Settings';
import { hpCss } from '../Fork/react-native-responsive-screen';
import { Avatar, Collapse, IconButton,  Fade} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AddOrg } from './add-org.jsx';
import { SUB_ORG_VALIDATION, validateOrg } from '../Constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SmallCard } from '../Components/card.jsx';
import styled from 'styled-components';
import { useAlert} from '../Managers/Alert'
import { useOrgs } from '../Managers/Orgs.js';
var Carousel = require('react-responsive-carousel').Carousel;

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: '4px solid #ececec',
    width: 120, 
    height: 120,
    // position: 'absolute',
    // marginTop: 40,
    // left: '50%',
    // transform: 'translate(-50%, 0)',
  },
}));

const Container = styled.div`
  width: 100%;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
`

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-60px);
`



export const Organization = () => {  
   const history = useHistory();
   const {org_id} = useParams()
   const Alert = useAlert()
  const OrgsManager = useOrgs()
    const [ org, setOrg] = React.useState({})
    const [ app, setApp] = React.useState({})
 
    const ref = React.createRef()
    const {colors} = useSettings()

  
    const onBack = async () => {    
        move(0)  
    }
    const move = (pos) => {
        ref?.current?.moveTo && ref.current.moveTo(pos)
    } 

    const onSelectOrg = (_org) => {
      history.push(`/dashboard/org/${_org.id}`)

    }
    const onAddOrg = () => {
        move(1)
    }
    const onSubmit = async () => {
      try{
        let _app = _.cloneDeep(app)
        let first_name, last_name;
        if(_app?.contact_person?.name){
          [first_name, ...last_name] = _.map(_.split(_app.contact_person.name, ' '), n => _.upperFirst(n))
          last_name = _.join(last_name, ' ')
          _app.contact_person.first_name = first_name
          _app.contact_person.last_name = last_name
        }
          
        await validateOrg(_app, SUB_ORG_VALIDATION)
        _app.parent_id = org.id
        
        await OrgsManager.create(_app)
        OrgsManager.getOrg(org?.id)
          .then(setOrg)
          .catch(Alert.error)
        move(0)
        
      } catch(e) {
        
        Alert.error(e?.message)
        return Promise.reject(e?.key)
      } 

    }

    const onAppChage = (_app) =>{
      setApp(_app)
    } 


    const updateOrg = (_org, prefs) => {
      setOrg(_org)
      setApp(_.cloneDeep(_org))
      
    }


    React.useEffect(() => {   
  
      OrgsManager.getOrg(org_id)
        .then(setOrg)
        .catch(e => {
          Alert.error(e)
      })
      
    },[org_id])

    return(
          <>
          {!!org?.id && <>
            <Carousel 
                ref={ref} 
                showStatus={false}
                showThumbs={false}
                swipeable={false}
                showArrows={true}
                showIndicators={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <IconButton  onClick={onBack} title={label} style={{left: 15 }}>
                            <ArrowBack />
                        </IconButton>
                    )} 
                renderArrowNext={_.noop}
                > 
                <>
                
                  <Container>
                    <OrgHeader org={org} />
                    {org?.admin && <SubOrgs 
                        onAddOrg={onAddOrg}
                        onSelect={onSelectOrg}
                        suborgs={org?.suborgs} 
                    name={org?.name} />  }
                    
                    {org?.admin && <OrgCodes org={org}/>}
                    {org?.admin && (
                      <Collapse in={true}>
                          <SmallCard>
                              <Zips 
                                editing={false}
                                displayOnly
                                zips={org?.zips} />
                          </SmallCard>
                      </Collapse>)
                            }
                    <Members org_id={org?.id} admin={org?.admin} />   
                
                </Container>
                {!org?.approved && org?.admin &&  <View style={containers.centerColumn}>
                      <Hint style={text.contrast(colors)}>You've applied for access to It Is Written's contacts.</Hint>  
                      <Hint style={text.contrast(colors)}>Your application status is under review.</Hint>
                    </View>}
                </>

                {org?.admin && <AddOrg 
                    org={app}
                    suborg={true}
                    zipsEditable={false}
                    onSubmit={onSubmit}
                    onChange={onAppChage}
                    onBack={onBack}
                    parent_id={org?.id} />}

            
            </Carousel>                    
          </>}
          {!!org && !org?.id && 
            <div style={{...containers.centerRow, height: hpCss(60)}} >
              <LoadingAnimation />
            </div>
          }
          {!org && <Text style={[text.large, text.contrast(colors)]}>You Don't have an Org...</Text>}
        </>
    )
}

 const OrgHeader = props => {
  const classes = useStyles();

  const {colors} = useSettings()


      const nameStyle = {
        ...text.medium,
        ...text.bold,
        ...text.center,
        alignSelf: 'center',
        ...text.base(colors),
    }
    const { org } = props
    return(
      <Collapse in={true}>
        <SmallCard >
          <Center>
            <Fade in={true} style={{ transitionDelay: '100ms' }} >
                <Avatar
                  size='xxxlarge' 
                  className={classes.avatar}
                  src={require('../Images/OutreachLogo.png')}
                />
            </Fade>
            <Fade in={true} style={{ transitionDelay: '200ms' }}>
               <Text style={nameStyle}>{_.upperFirst(org?.name)}</Text>
          </Fade>
          </Center>

          </SmallCard>
      </Collapse>
    )
    }

 

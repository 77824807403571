import React, { useEffect, useState, useRef } from 'react';
import { 
    ScrollView,
 } from 'react-native';
import { Slide, Fade, Collapse } from '@material-ui/core';
import _, { debounce, random } from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import {  useSettings } from '../Managers/Settings';
import {  BottomButtons, IntroSection as Section, IntroContainer as Container, IntroTitle} from '../Components/exports';

import { Login } from './login';
import { useHistory, withRouter, useParams} from 'react-router-dom';
import { Name, Welcome, Register, ChooseOrg} from './exports';
import { ORG_VALIDATION, validateOrg } from '../Constants';
import { useAlert } from '../Managers/Alert';
import { useUser } from '../Managers/UserManager';
import { useOrgs } from '../Managers/Orgs';
import styled from 'styled-components'

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: linear-gradient(0.25turn, ${props => props.start}, ${props => props.end});
    display: flex;
    flex-direction: column;
`

const variation = () => {
    return random(0, 25)
}


const getTimeout = {
    edge:() => 400 + variation(),
    middle: () => 300 + variation(),
    center: () => 200 + variation()
}


const pageIndexes = new Map()
pageIndexes.set( 'login', 0)
pageIndexes.set( 'welcome', 1)
pageIndexes.set( 'name', 2)
pageIndexes.set( 'register', 3)
pageIndexes.set( 'join', 4)


const indexPages = new Map()

indexPages.set( 0, 'login')
indexPages.set( 1, 'welcome')
indexPages.set( 2, 'name')
indexPages.set( 3, 'register')
indexPages.set( 4, 'join')

 const Intro = () => {
    let {page: initialPage} = useParams()

    const alert = useAlert()
    const [index, setIndex] = useState(0)
    const [lastIndex, setLastIndex] = useState(-1)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [pascom, setPascom] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [name, setName] = useState('')
    const [app, setApp] = useState()
    const [currentOrg, setCurrentOrg] = useState()
    const [errorKey, setErrorKey] = useState()
    const history = useHistory()
    const {user, register: _register, updateUser: _updateUser, updatePrefs: _updatePrefs} = useUser()
    const OrgsManager = useOrgs()
    const {colors} = useSettings()


    useEffect(() => {
        let i = pageIndexes.get(initialPage)
        
        setIndex(i)
    }, [initialPage])

    useEffect(() => {

            const first = user?.first_name || first_name
            const last = user?.last_name  || last_name
            const _email = user?.email  || email
            const _currentOrg = user?.currentOrg 

            setFirstName(first)
            setLastName(last)
            setEmail(_email)
            setCurrentOrg(_currentOrg)
    }, [user])



    const isRegistered = () => !!user

    const hasOrgs = async () => {
       const orgs = await OrgsManager.getOrgs()
       return orgs?.length > 0 
    }
    const onNameChange = (_name) => {
        let [_first_name, ..._last_name] = _.map(_.split(_name, ' '), n => _.upperFirst(n))
        _last_name = _.join(_last_name, ' ')
        setName(_name)
        setFirstName(_first_name)
        setLastName(_last_name)
        
    }

    const onApplicationChange = (app) => setApp({...app})

    const joinOrg = async (code) => {
        try{
            if(code === ''){
                let orgs = await OrgsManager.getOrgs(true)
                if(orgs.length === 1){
                    chooseOrg(orgs[0])
                } else if(orgs.length > 1){
                    alert.warning("You need to select an Organization, or join one with an Organization Code")
                    return
                } else {
                    alert.warning("You'll need to enter an Organization code")
                    return
                }
            }
             else {
                const org = await OrgsManager.apply(code)
                await chooseOrg(org)
                // TODO: you are done.  show welcome for this org     
             }

            goHome()
            return
        } catch(e) {

            alert.warning(e)
            return
        }
    }

    const goHome = () => history.push('/dashboard')
    

    
    const getDirection = (_index) => {
        if(_index === lastIndex){
            // exiting
            if(_index > index){
                // console.log('Exit right')
                return 'left'
            } else {
                // console.log('Exit left')
                return 'right'
            }
        } else {
            //entering
            // console.log('Enter' , index > this.state.lastIndex ? 'right' : 'left')
            return  _index > lastIndex ? 'left' : 'right'
        }
    }

    const next = () => {
        if(index + 1 < pages.length - 1){
            let path = indexPages.get(index+1)
            history.push(path)
            setLastIndex(index)
            setIndex(index + 1)
        }
    }

    const skip = (count = 2) => {
        // skip === 1 is esential next
        if(index + count < pages.length - 1){
            let path = indexPages.get(index + count)
            history.push(path)
            setLastIndex(index)
            setIndex(index + count)
        }
    }

    const set = (page) => {
        // skip === 1 is esential next
        setIndex(page)
        setLastIndex(index)
    }

    const previous = () => {
        if(index > 0){
            if(isRegistered()){
                if(index === 1){
                    const path = indexPages.get(0)
                    history.push(path)
                    setIndex(0)
                    setLastIndex(index)
                } else {
                    const path = indexPages.get(1)
                    history.push(path)
                    setIndex(1)
                    setLastIndex(index)
                }
            } else {
                const path = indexPages.get(index - 1)
                history.push(path)
                setIndex(index - 1)
                setLastIndex(index)
            }
        }
    }

    const register = async () => {
        if(isRegistered()){
            next()
            return
        } else {
            try{
                let _user = await _register(email, password, pascom, first_name, last_name)
                next()
                return
            } catch(e) {
                alert.warning(e)
                return
            }
        }
    }

    const confirmNames = () => {
        if(_.trim(first_name) === ''){
            alert.warning('First name required')
            return
        } else if(_.trim(last_name) === ''){
            alert.warning('Last name required.  Type it after your first name.')
            return
        } else {
            if(isRegistered()){
                skip(2)
            } else {
                next()
            }
        }
    }
    
    const submitApplication = async () => {
        try{
            if(currentOrg){
                alert.default("You've already applied")
            } else {
                await validateOrg(app, ORG_VALIDATION)
                let _app = await OrgsManager.create(app)
                const phone = app?.contact_person?.phone
                if(phone){
                    //update user profile
                    _updateUser({phone})
                    .catch(_.noop)
                }
                chooseOrg(_app)
                next()
            }
           
        } catch(e) {
            alert.warning(e?.message)
            setErrorKey(e?.key)
            return Promise.reject(e?.key)

        }
        
    }  

    const chooseOrg = async (_currentOrg) => {
        
        try{
            await _updatePrefs({_currentOrg})
            setCurrentOrg({..._currentOrg})
            
        } catch(e){
            alert.error(e)
        }
    }


    const onWelcomeNext = () => {
        if(isRegistered()){
            skip(3)
        } else {
            next()
        }
    }



    const pages = [
        (_index) => <Login key={_index + 'fjo'} 
                        onEmailChange={setEmail}
                        onPasswordChange={setPassword}
                        direction={getDirection(_index)}  
                        activated={index  === _index} 
                        getTimeout={getTimeout}
                        onNext={next}/>,
        (_index) => <Welcome key={_index + 'fjo'}  
                        direction={getDirection(_index)}
                        activated={index === _index}
                        onBack={previous}
                        getTimeout={getTimeout}
                        onNext={onWelcomeNext} />,
 
         (_index) => <Name key={_index + 'fjo'}  
                        direction={getDirection(_index)}
                        onTextChange={onNameChange}
                        activated={index === _index}
                        onBack={previous}
                        first={first_name}
                        last={last_name}
                        name={name}
                        getTimeout={getTimeout}
                        onNext={confirmNames} />,
         (_index) => <Register key={_index + 'fjo'}
                        direction={getDirection(_index)}
                        first_name={first_name}
                        email={email}
                        activated={index === _index} 
                        password={password}
                        pascom={pascom}
                        onBack={previous} 
                        onEmailChange={setEmail}
                        onPasswordChange={setPassword}
                        onPasswordConfirmationChange={setPascom}
                        getTimeout={getTimeout}
                        onNext={register} />,
        (_index) => <ChooseOrg key={_index + 'fjo'} 
                        direction={getDirection(_index)}
                        activated={index === _index}
                        onBack={previous}
                        getTimeout={getTimeout}
                        contact={user}
                        app={app}
                        org={currentOrg}
                        join={joinOrg}
                        create={submitApplication}
                        appChange={onApplicationChange}
                        errorKey={errorKey}
                        onNext={next}
                        onFinish={goHome}/>,
 
         (_index) => <Done key={_index + 'fjo'} 
                        direction={getDirection(_index)}
                        activated={index === _index}
                        onBack={previous}
                        getTimeout={getTimeout}
                        onFinish={goHome} />,
 
     ]

    
    return(

        <Background 
            start={colors?.plight}
            end={colors?.pdark}
            >
                {_.map(pages, (page, i) => {
                return(
                    <Fade  in={i === index} key={i}>
                        <Collapse in={i===index}>
                            <div style={{position:'absolute', left:0, top:0}}>
                                <ScrollView 
                                    style={{height: '100vh', width: '100vw'}} 
                                    contentContainerStyle={{height: '100vh'}}>
                                    { page(i) }
                                </ScrollView>
                            </div>
                        </Collapse>
                    </Fade>
                )
                })}
            
        </Background>
    )

 }

 export default withRouter(Intro)


const Done = (props) => {

    const {
        activated,
        onFinish,
        onBack,
        direction,
        getTimeout
    } = props

    const history = useHistory()

    const back = () => onBack && onBack()
    const finish = () => onFinish && onFinish()

    return(
        <Container>
            <Section>
                 <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
                     <div>
                        <IntroTitle>
                            Your applicaiton has been submitted.                          
                        </IntroTitle>
                    </div>
                </Slide>
                <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
                    <div>
                        <IntroTitle>
                            In the mean time, take a look around!
                        </IntroTitle>
                    </div>
                </Slide>
            </Section>
            <Section>
                <BottomButtons 
                    onNext={finish}
                    onBack={back}
                />
            </Section>
        </Container>
    )
 }

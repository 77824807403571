import React, { useContext } from 'react';
import { 
    TouchableOpacity,
    Animated,
    Text
 } from 'react-native';
import { SettingsContext } from '../Managers/Settings';
import { containers, shadows, text} from '../theme'
import { Fade } from '../Animations/Fade';


 export const NumberPicker = React.memo(props => {
     const [ number, setNumber] = React.useState(props.number || 0)
     const [ direction, setDirection] = React.useState('right')
    const CARD_WIDTH = 120
    const CARD_HEIGHT = 40
    const ani = new Animated.Value(0)
    const angle = ani.interpolate({
        inputRange: [-1, 1],
        outputRange: ['-45deg', '45deg']
    })
    const { colors } = useContext(SettingsContext)
    React.useEffect(() => {
        setNumber(props.number || 0)
    }, [props.number])


    const decrement = () => {
        if(number === 0){
            return
        } else {
            setDirection('left')
            setNumber(number - 1)
            props.onChange && props.onChange(number - 1)
        }
    }
    const increment = () => {
        setDirection('right')
        setNumber(number +1)
        props.onChange && props.onChange(number + 1)
    }

    const styles ={
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        backgroundColor: colors?.accent,
        ...shadows.paper,
        borderRadius: CARD_HEIGHT / 2,
        paddingHorizontal: 10,
        ...containers.betweenRow
    }
    
    return(
        <Animated.View style={[styles, {
            transform:[
                {translateX: -CARD_WIDTH / 2},
                {translateY: -CARD_HEIGHT / 2},
                {rotateY: angle},
                {translateX: CARD_WIDTH / 2},
                {translateY: CARD_HEIGHT / 2},
            ]

        }]}>
            <TouchableOpacity onPress={decrement} >
                <Text style={[text.bold, text.large, text.accentContrast(colors)]}>-</Text>
            </TouchableOpacity>
            <Fade
                scale
                translate
                spring
                duration={500}
                horizontal
                friction={4}
                inverse={direction === 'left'}

                >
                <Text style={[text.bold, text.medium, text.accentContrast(colors)]}>{number}</Text>
            </Fade>
            <TouchableOpacity onPress={increment} >
                <Text style={[text.bold, text.large, text.accentContrast(colors)]}>+</Text>
            </TouchableOpacity>

        </Animated.View>
    )





 })
import React from 'react';
import { Animated,
     TouchableOpacity,
     View,
     Easing,
     Text,
    } from 'react-native';
import {  containers , text} from '../theme';
import _ from 'lodash';
import { SettingsContext } from '../Managers/Settings';
import { Fade } from '../Animations/Fade';

export class Logo extends React.Component{
    static contextType = SettingsContext
    visible = _.get(this, 'props.visible', false)
    outer = new Animated.Value(0)
    middle = new Animated.Value(0)
    inner = new Animated.Value(0)

    triangle1X = new Animated.Value(-90)
    triangle2X = new Animated.Value(-90)
    triangle3X = new Animated.Value(-90)

    componentDidMount(){
        const visible = _.get(this, 'props.visible', false)
       this.start && this.start(visible)
    }
    componentDidUpdate(prev){
        if(!_.isEqual(prev, this.props)){
            let { visible} = this.props
            
            if(_.isBoolean(visible) && visible != this.visible){
                this.start && this.start(visible)
            }
        }
    }
    logoword;
    start = (visible) => {
        const duration = 500
        const friction = 3
        if(visible){
            const toValue = 1
            Animated.spring(this.inner, {toValue, duration, friction, }).start()
            Animated.spring(this.middle,{toValue, duration, friction, delay: 100,}).start()
            Animated.spring(this.outer, {toValue, duration, friction, delay: 200,}).start()
            setTimeout(() => {
                Animated.timing(this.triangle1X, {toValue:45, duration: 400, easing: Easing.bounce}).start()
                Animated.timing(this.triangle2X, {toValue:20, duration: 400, easing: Easing.bounce, delay: 100}).start()
                Animated.timing(this.triangle3X, {toValue:-5, duration: 400, easing: Easing.bounce, delay: 200}).start()
            }, 600)

        } else {
            const toValue = 0
            Animated.spring(this.outer,{toValue, duration, friction, }).start()
            Animated.spring(this.middle,{toValue, duration, friction,  delay: 100}).start()
            Animated.spring(this.inner, {toValue, duration, friction,  delay: 200}).start()

            Animated.timing(this.triangle1X, {toValue:-90, duration: 400, easing: Easing.bounce}).start()
            Animated.timing(this.triangle2X, {toValue:-90, duration: 400, easing: Easing.bounce, delay: 100}).start()
            Animated.timing(this.triangle3X, {toValue:-90, duration: 400, easing: Easing.bounce, delay: 200}).start()
            setTimeout(() => this.start(!toValue), 1000)
        }
    }
    toggle = () => {
        this.start(!this.visible)
        this.logoword && this.logoword.actuate(0, true)
    }
    throb = () => {

    }
    render(){
        const { colors } = this.context
        
            return(
            
            <View style={[containers.centerColumn, {marginTop: 10}]}>
            <TouchableOpacity 
                style={[containers.centerRow, {transform: [{scale: 0.5}]}]} 
                onPress={this.toggle}
            >
                <Animated.View 
                    style={[styles.outer(colors), 
                    {transform: [{scale: this.outer}]},
                    containers.centerRow]}
                >
                    <Animated.View style={[
                        styles.middle(colors),
                        containers.centerRow,
                        {transform: [{scale: this.middle}]}]}>
                        
                        
                        <Animated.View
                            style={[styles.inner(colors),
                            containers.centerRow,
                            {transform: [{scale: this.inner}]}
                            ]}>
                            <View style={styles.triangles}>
                            
                            <Animated.View style={[styles.innerTri, {
                                transform:[ {translateX: this.triangle3X}]}]}>
                                <Triangle />
                                <Triangle />
                                <Triangle />
                            </Animated.View>
                            <Animated.View style={[styles.innerTri, {
                                transform:[
                                    {translateX: this.triangle2X}
                                    ]}]}>
                                <Triangle />
                                <Triangle />

                            </Animated.View>
                            <Animated.View style={[styles.innerTri, {
                                transform:[
                                    {translateX: this.triangle1X}
                                    ]}]}>
                                <Triangle />

                            </Animated.View>
                            </View>
                            </Animated.View>
                    </Animated.View>
                </Animated.View>
                
                </TouchableOpacity> 


                {
                    !this.props.hideWords && <Fade  
                                                distance={400} 
                                                inverse 
                                                scale 
                                                bounce 
                                                duration={1000} 
                                                delay={1000}
                                                style={{padding: 20, transform: [{translateY: -70}]}}
                                                >
                                                    <LogoWord />
                                                </Fade>
              
                }
                </View>
                )
            // }  else {
            //     return <Image 
            //         style={{
            //         resizeMode: 'contain',
            //         width:'100%',
            //         height: '100%',
            //         transform:[{scale: 0.7}]
            //         }}
            //         source={require('../Images/LogoWordsWhite.png')}/>
            // }
    }
}
export const Triangle = (props) => {
    const triangle= {
        width: 0,
        height: 0,
        marginTop: 6,
        marginBottom: 6,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderLeftWidth: 16,
        borderRightWidth: 16,
        borderBottomWidth: 30,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'white',
        transform: [{rotate: '90deg'}]
        }
    return(
        <View style={[triangle, props.style]} />
    )
 
}

  



const styles = {
    triangles:{
        position: 'absolute',
        left: 0,
        width: 60,
        height: 60,
        backgroundColor: 'transparent',
        ...containers.centerRow,
        transform:[{translateX: -10}],
        zIndex: 4
    },
    innerTri:{
        ...containers.centerColumn,
        width: 10,
        height: '100%',
        zIndex: 5
    },
    triangleContainer:{
        height: '100%'
    },
    outer: (colors) =>  {
        return{
        backgroundColor: colors?.foreground,
        height: 310,
        width: 310,
        borderRadius: 155,
        zIndex: 0
        }
    },
    middle: (colors) => {
        return{
    
            backgroundColor: colors?.logoBlue,
            height: 260,
            width: 260,
            borderRadius: 130,
            zIndex: 1
        }
    },
    inner: (colors) => {
        return{ 
        position: 'relative',
        backgroundColor: colors?.plight,
        height: 180,
        width: 180,
        borderRadius: 90,
        zIndex: 2
        }
    },
    // words
    word: {
        ...containers.centerRow,
        // marginBottom: 20,
        // transform: [{translateY: -70}]
    },
    letter: (colors, color) => {
        return{
            fontSize: 40,
            ...text.bold,
            color: color ? color : colors?.logoBlue
    }
    },
    letterBase: (colors, color) =>  {
        return{
            height: 20,
            marginHorizontal: 2,
            // width: '100%',
            backgroundColor: color ? color : colors?.logoBlue
        }
    }
}

export class LogoWord extends React.Component{
        
    items = []
    constructor(props){
    super(props)
    }
    add = (item) => {
    this.items.push(item)
    _.sortBy(this.items, 'id')
    }
    componentDidMount(){
        const delay = this.props.delay || 1000
        if(!delay){
            this.actuate(0, true)
        } else{
            setTimeout (() => this.actuate(0, true), delay)
        }
        
    }
    pause = (time) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(), time)
        })
        
    }
    actuate = async (id, force = false) => {
            if(force){
                this.items[id].touched && this.items[id].touched()
            }
            var upper; 
            var lower;
            if(id > 0 && id < 7){
            upper = id + 1
        lower = id - 1

            } else if(id === 0) {
                upper = 1
            } else if ( id === 7) {
                lower = 6
            } else {
                return 
            }

            while((lower !== null && lower > -1) || (upper !== null && upper < 8)){
                await this.pause(60)
                // console.log(lower)
            if(upper){this.items[upper].touched && this.items[upper].touched()}
            if(lower !==  null && lower > -1){this.items[lower].touched && this.items[lower].touched()}
            upper = upper >= 7 || !upper ? null : upper + 1
            lower = lower <= 0 || !lower ? null : lower - 1
            
            
            }
    }
    render(){
        const {color} = this.props
        let {scale} = this.props
        scale = scale ? scale : 1
      return(
        <View style={[styles.word, {transform: [{scale}]}]}>
          <Letter value='O' id={0} ref={ref => this.add(ref)} color={color} onPress={this.actuate}/>
          <Letter value='U' id={1} ref={ref => this.add(ref)} color={color} onPress={this.actuate}/>
          <Letter value='T' id={2} ref={ref => this.add(ref)} color={color} onPress={this.actuate}/>
          <Letter value='R' id={3} ref={ref => this.add(ref)} color={color} onPress={this.actuate}/>
          <Letter value='E' id={4} ref={ref => this.add(ref)} color={color} onPress={this.actuate}/>
          <Letter value='A' id={5} ref={ref => this.add(ref)} color={color} onPress={this.actuate}/>
          <Letter value='C' id={6} ref={ref => this.add(ref)} color={color} onPress={this.actuate}/>
          <Letter value='H' id={7} ref={ref => this.add(ref)} color={color} onPress={this.actuate}/>
        </View>
      )
    }
}

export class Letter extends React.Component{
    static contextType = SettingsContext
    id;
    value;
    x =  new Animated.Value(90)
    angle = this.x.interpolate({
        inputRange:[0, 90],
        outputRange: ["0deg", "90deg"],
        extrapolateRight: 'clamp'
    })
    shadow = this.x.interpolate({
        inputRange: [0, 45, 90],
        outputRange: ["90deg","90deg", "45deg"]
    })
    shadowOpacity = this.x.interpolate({
        inputRange: [0, 45, 90],
        outputRange:[0, 0.2, 1]
    })
    trans = this.x.interpolate({
        inputRange: [0,90],
        outputRange: [-10, 0]
    })
    scale = this.x.interpolate({
        inputRange: [0, 90],
        outputRange: [0, 1],
        // extrapolation: 'clamp'
    })
    

    componentDidMount(){
        this.id = _.get(this, 'props.id')
        this.value = _.get(this, 'props.value')
        this.forceUpdate()
        // this.raise(600)
    }
    onPress = () => {
        this.props.onPress && this.props.onPress(this.id)
        this.touched()
    }
    raise = (delay = 0) => {
        const toValue = 0;
        const duration = 1000;
        
        Animated.spring(
            this.x, {
                toValue, 
                duration, 
                delay,
                friction: 1,
                easing: Easing.bounce,
                }).start()
    }
    touched = () => {
        // Animated.timing(this.x, {toValue: 90, duration: 175, easing: Easing.ease, useNativeDriver: Platform.OS = 'ios'}).start()
        Animated.spring(
            this.x, 
            {
                toValue: 90, 
                duration: 275,
                easing: Easing.bounce,
                friction: 4, 
            }).start()
        setTimeout(() => this.raise(), 1000)
    }

    render(){
        const { colors } = this.context
        const {color} = this.props
        return(
            <TouchableOpacity onPress={this.onPress}>
                <Animated.View style={[{
                    transform: [
                        {rotateX: this.angle},
                        {translateY: this.trans}
                    ] },
                    containers.centerColumn]}>
                <Text style={styles.letter(colors, color)}>
                        {this.value}
                </Text>         
                <Text style={styles.letter(colors, color)}/>  

                </Animated.View>
                <Animated.View style={[
                    styles.letterBase(colors, color),
                    {transform: [
                        {scaleY: this.scale},
                        {translateY: -45},
                        {rotateX: this.shadow}
                    ],
                    opacity: this.shadowOpacity},
                    styles.letterBase(colors, color)]}/>


            </TouchableOpacity>
        )
    }
}
import React, {useState, useEffect} from 'react';
import { 
    TouchableOpacity,
    View, 
    Text,
 } from 'react-native';
 import _ from 'lodash';
 import { containers, text, shadows } from '../theme'
 import { useSettings }  from '../Managers/Settings';
import { lighten } from '../Utilities';
import { Add } from '@material-ui/icons';

import {Subject} from 'rxjs'
import { takeUntil} from 'rxjs/operators'
import { Collapse, Grow, IconButton } from '@material-ui/core';
import { Input } from './Input'; 
import { Hint } from './Hint';
import { useAlert } from '../Managers/Alert';
import { useOrgs } from '../Managers/Orgs';
import { useUser } from '../Managers/UserManager';


export const OrgsPicker = (props) => {
    const Alert = useAlert()
    let [code, onChange] = useState('')
    let [adding, setAdding] = useState(false)

    const UserManager = useUser()
    const {orgs, ...OrgsManager} = useOrgs()
    const leaving = new Subject()

    const onSelect = async (org) => {
        await UserManager.updatePrefs({currentOrg: org, currentSeries: null})
        props.onSelect && props.onSelect(org)
    }

    const apply = async  () => {
        try{
            await OrgsManager.apply(code)
            setAdding(false)
        } catch(e) {
            Alert.error(e)
        }
        
    }
    const reset = () => {
        UserManager.deleteOrgPrefs()
    }
    const {expanded, hideAdd, showHint, hintStyle} = props


    return(
        !!expanded && <Collapse in={!!expanded}>  
        {!!showHint && <Hint style={hintStyle}>Orgs you're already a part of.  Select one to continue.</Hint>}
            <View
                style={{marginBottom: 25, marginHorizontal: 0, ...containers.leftRowWrap}}
            >    
            {_.map(orgs,(org,i) => {

                return(
                    <Grow key={i} in={!!expanded} timeout={300 + (i * 100)}>
                        <div>
                        <Org 
                            onSelect={onSelect}
                            delay={props.delay || 0}
                            index={i}
                            org={org} 
                            expanded={expanded} 
                            count={orgs.length}
                            />
                        </div>
                    </Grow>)}
                )
            }
    
            </View>
            </Collapse>

    )


}
export const SubOrgsPicker = (props) => {
    const { org } = props
    const UserManager = useUser()
    const onSelect = async (_org) => {
        await UserManager.updatePrefs({currentOrg: _org, currentSeries: null})
        props.onSelect && props.onSelect(_org)
    }
  

    const {expanded, showHint, hintStyle} = props

    return(

    !!expanded && <Collapse in={!!expanded}>  
    {!!showHint && <Hint style={hintStyle}>Orgs you're already a part of.  Select one to continue.</Hint>}
        <View
            style={{marginBottom: 25, marginHorizontal: 0, ...containers.leftRowWrap}}
        >    

        {_.map(org.suborgs,(_org,i) => {

            return(
                <Grow key={i} in={!!expanded} timeout={300 + (i * 100)}>
                    <div>
                    <Org 
                        onSelect={onSelect}
                        delay={props.delay || 0}
                        index={i}
                        org={_org} 
                        expanded={expanded} 
                        count={org?.suborgs?.length ? org.suborgs.length : 0}
                        />
                    </div>
                </Grow>)}
            )
        }

        </View>
        </Collapse>

)
}

export const AddOrgByCode = props => {
    const {adding, onTextChange, onSubmitEditing, code, onAdd} = props
    const {colors} = useSettings()
    return(
        <>
            <Collapse in={adding}>
                <Grow in={adding}>
                    <div>
                        <Input 
                            placeholder={'Org Code i.e. JSIO'}
                            onTextChange={onTextChange}
                            value={code}
                            onSubmitEditing={onSubmitEditing}
                        />
                    </div>
                </Grow>
            </Collapse>
            <Collapse in={!adding}>
                <Grow in={!adding}>
                    <div style={{
                        height: 'auto',
                        borderRadius: 20,
                        margin: 10,
                        padding:20,
                        ...shadows.paper,
                        backgroundColor:  colors?.background ,
                        ...containers.centerColumn
                    }}>
                        <IconButton onClick={() => onAdd(true)} >
                            <Add />
                        </IconButton>
                    </div>
                </Grow>
            </Collapse>
        </>
    )   
}

export const Org = (props) => {
    let { expanded, org } = props   
    const onPress = () =>{
        props.onSelect && props.onSelect(org)
    }

    const {colors, prefs} = useSettings()
    const selected = org.id === _.get(prefs, 'currentOrg.id')
    return(
        !!expanded &&
            <TouchableOpacity 
                
                onPress={onPress}
                style={{
                    height: 'auto',
                    borderRadius: 20,
                    margin: 10,
                    padding:20,
                    ...shadows.paper,
                    backgroundColor: selected ? lighten(colors?.accent, 2) : colors?.page,
                    ...containers.centerColumn
            }} >
                
                <Text style={[text.small, selected ? text.accentContrast(colors) : text.base(colors),, text.bold]}>{org.name}</Text>
            </TouchableOpacity>
        
    )


}

import React from 'react';
import { 
    View, Text
 } from 'react-native';
import { SettingsContext, useSettings } from '../Managers/Settings';
import { containers, text } from '../theme';
import { GradePicker, SectionBar, AddressInput, Input} from './exports'
import _ from 'lodash';
import { formatPhone} from '../Utilities'
import EditIcon from '@material-ui/icons/Edit';
import { Collapse,  IconButton, Grow, Fade } from '@material-ui/core';
import { TwoButtonOption } from './Buttons';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { MemberAvatar, MemberAvatars } from './MemberAvatars';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { SmallCard } from './card';


export const ContactDetail = ({person, org, onDelete, onChange, onSelectContactOwner}) => {
    const [ edit, setEdit] = React.useState(false)
    const [ tempAddress, setTempAddress] = React.useState()
    const [ tempPhones, setTempPhones] = React.useState()
    const [ tempEmail, setTempEmail] = React.useState()
    const [ tempFirst, setFirst] = React.useState()
    const [ tempLast, setLast] = React.useState()
    const [ tempGrade, setGrade] = React.useState()
    const [ name, setName] = React.useState('')



    React.useEffect(() => {

        setTempAddress(person?.address)
        setTempEmail(person?.email)
        setTempPhones(person?.phones)
        setFirst(person?.first_name)
        setLast(person?.last_name)
        setGrade(person?.grade)
        setName(person?.first_name + ' ' + person?.last_name)
    },[])

    const toggleEdit = () => {
        setEdit(!edit)
    }
    const _onDelete = () => {
        onDelete && onDelete()
    }
    const onAddressChange = (address) => {
        setTempAddress(address)
    }
    const onPhonesChange = (phones) => {
        setTempPhones(phones)
    }
    const onEmailChange = (email) => {
        setTempEmail(email)
    }
    const onNameChange = (txt) => {
        setName(txt)
        let [first_name, ...last_name] = _.map(_.split(txt, ' '), n => _.upperFirst(n))
        last_name = _.join(last_name, ' ')
        setFirst(first_name)
        setLast(last_name)
    }
    const onGradeChange = (grade) => {
        setGrade(grade)
    }

    React.useEffect(() => {
        if(person.grade !== tempGrade && !!tempGrade){
            onSubmit()
        }
    }, [tempGrade])

    const onSubmit = () => {
        const tp = _.uniqBy(tempPhones, 'number')

        const _person = {
            ...person,
            address: tempAddress,
            phones: tp,
            email: tempEmail,
            first_name: tempFirst,
            last_name: tempLast,
            grade: tempGrade
        }

        onChange && onChange(_person)
        setEdit(false)
    }
    const _onSelectContactOwner = (member) => {
        onSelectContactOwner && onSelectContactOwner(member)
    }

    if(!person){
        return <></>
    }


    return(
        
       <SmallCard>
            <div style={{top: -80, position: 'absolute', left: "50%", zIndex: 2}}>
                <GradePicker 
                    onSelect={onGradeChange}
                    person={{grade: tempGrade}}/>
            </div>
            <div style={{position: 'absolute', right: 0, top:0, zIndex: 2}}>
                <IconButton 
                    aria-label="edit" 
                    style={{marginTop: 5, transition: 'all .2s ease'}} 
                    onClick={toggleEdit} 
                    color={edit? 'primary' : 'default'}
                    >
                    <EditIcon />
                </IconButton>
            </div>
            {edit && <div style={{position: 'absolute', left: 0, top:0, zIndex: 2}}>
                <IconButton 
                    aria-label="edit" 
                    style={{marginTop: 5, transition: 'all .2s ease'}} 
                    onClick={_onDelete} 
                    color={'secondary'}
                    >
                    <DeleteForeverIcon />
                </IconButton>
            </div>}

            <div style={{marginTop: 30, width: '100%'}}></div>
            <Name 
                name={name}
                editing={edit}
                onNameChange={onNameChange}
            />
            <Phones 
                editing={edit}
                onChange={onPhonesChange}
                phones={tempPhones || ''}/>
            <Email 
                editing={edit}
                onChange={onEmailChange}
                email={tempEmail || person?.email} />
            <ContactAddress 
                onChange={onAddressChange}
                editing={edit}
                address={tempAddress || person?.address} 
                    />
            <Owner 
                org={org}
                editing={edit}
                onSelectContactOwner={_onSelectContactOwner}
                owner={person?.owner?.user} />
                    
            <Collapse in={edit} timeout={200}>
                <TwoButtonOption 
                    leftAction={toggleEdit}
                    rightAction={onSubmit}
                />
            </Collapse>
        </SmallCard>
    )
}



  
const Name = props => {
   const { editing, name, onNameChange} = props
return(
    <SettingsContext.Consumer>
        {({colors}) => {
            const nameStyle = [
                text.medium,
                text.bold,
                text.center,
                {alignSelf: 'center'},
                text.base(colors)
         ]
            return(
                <>
                    <Collapse in={!!editing} >
                        <SectionBar title={'Name'}  style={{paddingHorizontal: 0}} />
                        <Input 
                            onTextChange={onNameChange}
                            value={name}
                        />
                    </Collapse>
                    <View style={{alignSelf: 'center'}}>
                        <Collapse in={!editing} >
                            <Text 
                                style={nameStyle}>
                                    {name}
                            </Text>
                        </Collapse>
                    </View>
                </>
            )
        }}
    </SettingsContext.Consumer>
)


}

class Phones extends React.Component {
    static contextType = SettingsContext;
    state={
        ...this.props,
    }

    onPhoneUpdate = (txt, index) => {
        let  phones  = this.state.phones
        let phone = phones[index]
        phone.number = formatPhone(txt)
        phones[index] = phone    
        this.setState({phones})      
        this.props.onChange && this.props.onChange(phones)
    }

    componentDidUpdate(prev){
        if(!_.isEqual(prev, this.props)){
            let editing = this.props.editing 
            let phones = this.props.phones || this.state.phones
            if(!!editing && !phones || phones.length === 0){
                this.setState({editing, phones: [ {number: '', kind: 'mobile'}]})
            } else {
                const p = _.uniqBy(phones, 'number')
                this.setState({editing, phones: p})
            }
            
        }
    }

    getAddNumber = () => {
        return(
                <View style={containers.centerRow}>
                    <IconButton 
                        color={'primary'}                        
                        onClick={this._addNumber}    
                    >
                         <AddRoundedIcon />
                    </IconButton>
                 </View>
        )
    }

    _addNumber = () => {
        let phones = this.state.phones
        this.setState({phones: [...phones, {number: '', kind: 'mobile'}]})
    }

    renderInputs = () => {
        const { phones, editing } = this.state
        return(
            <Collapse in={editing} timeout={200}>  
                {_.map(phones, (phone, index) => {
                    
                    return(
                        <Grow in={true} key={index} >
                            <div>
                           <Input 
                                
                                value={phone?.number || ''}
                                placeholder={`Phone Number #${index + 1}`}
                                onChangeText={(txt) => this.onPhoneUpdate(txt, index)}
                            />
                            </div>
                        </Grow>
                    )
                })}

                {this.getAddNumber()}

            </Collapse>
        )
    }
    renderText = () => {
        const { phones, editing} = this.state
        return(
            <SettingsContext.Consumer>
            {({colors}) => {
                return(
                    <Collapse in={!editing} timeout={200}>        
                        <View style={containers.leftColumn}>
                        
                        {_.map(phones, (phone, index) => {
                            return (
                            <Text 
                                key={phone.number + index} 
                                style={[text.tiny, text.center, text.contrast(colors)]}
                                >
                                {formatPhone(phone.number)} 
                            </Text>
                            )
                        })}
                 {(!phones || (phones.length === 1 && phones?.[0]?.number ==='')) && <Text style={[text.tiny, text.center, text.contrast(colors)]}>No Phone</Text>}
                        </View>    
                    </Collapse> 
                )}}
            </SettingsContext.Consumer>
        )
    }


    render(){
        const { phones } = this.state
        return(
            <>
                {/* <View style={{...containers.betweenRow, maxWdith: '100%'}}> */}
                    <SectionBar title={'Phone' + (phones?.length > 1 ? 's' : '')} style={{paddingHorizontal: 0}} />
                    {/* <Undo key={'key-undo'} onUndo={this.onUndo} item={_.cloneDeep(phones)}/> */}
                {/* </View> */}
                {this.renderInputs()}
                {this.renderText()}
        
            </>            
        )
    }
}

const Email = ({ email, editing, onChange}) => {
    const { colors } = useSettings()
    return(
        <>
            <SectionBar title={'Email'}  style={{paddingHorizontal: 0}}/>
            <Collapse in={editing}>
                <Input 
                    value={email || ""}
                    onChangeText={onChange}
                />
            </Collapse>

            <Collapse in={!editing}>
                <Text style={[text.tiny, text.contrast(colors)]} >
                    {email ? email : 'No Email'}
                </Text>
            </Collapse>    
        </>
    )

}

const ContactAddress = (props) => {
    const { address, editing} = props
    const {colors} = useSettings()
    const noAddress = () => {
        // const { address } = this.state
        if (!address) { return false }
        const { address1, address2, city, state, zip, country, prisonId } = address

        return (
          !address1  &&
          !address2  &&
          !city  &&
          !state  &&
          !zip  &&
          !country  &&
          !prisonId )
    }
    const secondComma = () => {
        if( (address?.address1 || address?.address2) && (address?.city || address?.state || address?.zip)){
            return ','
        } else {
            return ''
        }
    }

    const textStyle = [text.tiny, text.contrast(colors), text.left]    
    return(
        <>
            <SectionBar title={'Address'} style={{paddingHorizontal: 0}}/>
            <Collapse in={editing} timeout={200} >
                <AddressInput 
                    hideUndo
                    address={address} 
                    onChange={props.onChange}/>
            </Collapse>   
            <Collapse in={!editing} timeout={200} >
                <div>
                <View style={{...containers.leftRowWrap, alghSelf: 'left'}} >
                    {noAddress() &&  <Text style={textStyle}>No Address</Text>}
                    <Text style={textStyle}>
                        {address?.address1}{address?.address2 ? ' ' + address?.address2 : ''}{secondComma()} {address?.city}{address?.city && address?.state ? ',' : ''} {address?.state} {address?.zip}
                    </Text>
                </View>
                </div>
            </Collapse>
        </>
    )
  }

export const Owner = ({owner, editing, onSelectContactOwner, org}) => {
    return(
        <Collapse in={true}>
            <Fade in={true}>
                <div style={{...containers.leftColumn, alginSelf: 'stretch'}}>
                    {(!editing) &&  <SectionBar title={'Owner'} style={{paddingHorizontal: 0}} />}
                    {editing &&  <MemberAvatars 
                        org={org}
                        onSelect={onSelectContactOwner}
                        owner={owner}/>}
                    {!editing && <MemberAvatar person={owner} />}

                </div>
            </Fade>
        </Collapse>
    )
}


import React from 'react';
import { Animated, Easing,} from 'react-native';
import _ from 'lodash';
import './Fade.css';
import AnimateHeight from 'react-animate-height';

export class Fade extends React.Component {
  state={collapsed: this.props.collapsed}

  constructor(props){
    super(props)
  
    
    let distance = this.props.distance ? this.props.distance : 40
    let translate = this.props.translate ? distance : 0 
    let direction = this.props.translate && this.props.inverse ? -1 : 1 
    this.horizontal = this.props.horizontal
    let scale = this.props.scale ? this.props.inverse ? 2 : 0.7 : 1
    translate = direction*translate
    this.trans = this.fadeAnim.interpolate({
      inputRange: [0,1],
      outputRange: [translate, 0]
    })
    this.scale = this.fadeAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [scale, 1]
    })
  }
  fadeAnim = new Animated.Value(0)

  defaultDuration = 300
  visible = _.get(this, 'props.visible', true)
  

  componentDidMount() {
    this.mounted = true
    let visibility = 1;

    if(typeof this.props.visible === typeof true){
      visibility = this.props.visible  ? 1 : 0
    }
    let { spring,
          friction,
          bounce,
          randomize,
          duration,
          delay} = this.props

    
    let random = randomize ? _.random(0, 200) : 0
    if(spring){
      this.animation = Animated.spring(                  
        this.fadeAnim,            
        {
          toValue: visibility,                   
          duration: duration || this.defaultDuration,              
          delay: delay + random || 0 + random,
          friction: friction ? friction : 1,
          easing: Easing.bounce
        }
      )
    } else {
      this.animation = Animated.timing(                  
        this.fadeAnim,            
        {
          toValue: visibility,                   
          duration: duration || this.defaultDuration,              
          delay: delay + random || 0 + random,        
          easing: bounce ? Easing.bounce : Easing.sin
        }
      )
    }

    this.animation.start(this.onfinish)
  }

  componentDidUpdate(prev){   
    // we dont want to react on child changes 
    let {children, ...last} = prev
    let { children: f, ...next} = this.props
    
    if(!_.isEqual(last, next)){
      if(this.state.collapsed !== next.collapsed){
        this.collapseIfNeeded(next.collapsed)
        if(!!next.collasped){
          return
        }
      }
      if(this.animation){ return } //console.log('WARNING, cancelled Animation'); return }
      let toValue = _.get(this.props, 'visible', true) ? 1 : 0;
      let random = _.has(this.props, 'randomize') ? _.random(0, 200) : 0
      let delay = toValue ? _.get(this.props, 'delay', 0) + random : 0
      let duration = _.get(this.props, 'duration', this.defaultDuration)
      
      
      
      this.animation = Animated.timing(this.fadeAnim, {
          toValue, 
          duration, 
          delay, 
          // useNativeDriver: true //Platform.OS === 'ios'
        })
      this.animation.start(this.onfinish)
    }
  }

  onfinish = () => {
    let collapsed = _.get(this.props, 'collapsed', false) 
    this.collapseIfNeeded(collapsed)
    this.animation = null
  }
  


  componentWillUnmount(){
    this.mounted = false
  }

  collapseIfNeeded = (collapsed) => {
    if(!this.mounted) { return }
    if(this.state.collapsed === collapsed) { return }
    
    this.setState({collapsed})
    }


  count= 0
  render() {
    let { collapsed } = this.state
      return (
      <Animated.View    
        // key={t}              
        style={{
          opacity: this.fadeAnim, 
          transform: [
            {translateY: this.horizontal ? 0 : this.trans,},
            {translateX:  !this.horizontal ? 0 : this.trans},
            {scale: this.scale}
          ],
          ...this.props.style,        
        }}
      >
        <AnimateHeight
          id={this.key + 'height'}
          duration={ this.duration }
          height={ collapsed ? 0 : 'auto' } 
        >
        {this.props.children}
        </AnimateHeight>
      </Animated.View>
    );
  }
}


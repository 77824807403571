import React from 'react';
import _ from 'lodash';
import { ContactAttendenceForSeries } from './exports';
import { CardHeader, SmallCard} from './card';


export const Attendance = props => {
    const { person, series } = props
    const attendance = _.uniqBy(_.filter(person?.attendance, s => s?.id === series?.id), 'id')[0]
    return(
        <SmallCard horizontal>
            <CardHeader style={{paddingHorizontal: 0}} title={props?.title} />
            <ContactAttendenceForSeries series={attendance}/>
        </SmallCard>
    )
}


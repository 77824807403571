import React from 'react';
import { 
    
 } from 'react-native';
 import Box from '@material-ui/core/Box';

 export const Surveys = props => {

    return(
        <Box p={3} boxShadow={3}>
            
        </Box>
    )
 }
import { Collapse, Slide } from '@material-ui/core';
import React from 'react';
import { View } from 'react-native';
import { Fade } from '../Animations/Fade';
import { BottomButtons, IntroSection, Logo } from '../Components/exports';
import { IntroTitle } from '../Components/Titles';
import { hpCss } from '../Fork/react-native-responsive-screen';
import { containers } from '../theme';

 export const Welcome = props => {

    const { 
 
        onNext, 
        onBack,
        activated,
        direction,
        getTimeout
    } = props


    
    const renderImage = () => {
        return(
        <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
            <div>
            <IntroSection>
                <View style={{maxHeight: 400, ...containers.topCenterColumn}} >
                    <Logo  visible hideWords={true} />
                </View>
            </IntroSection>
            </div>
        </Slide>
        )
         
    }
    

    const renderWelcome = () => {
    
        return(
        <Slide in={activated} direction={direction} timeout={getTimeout.middle()}>
            <div>
            <View style={{transform: [{translateY: -80}]}} >
                <Collapse in={activated} timeout={1200} >
                    <Fade
                        visible={true}
                        style={{...containers.topCenterColumn, flex:1}}
                        transform
                        duration={400}
                        delay={1200}
                
                     >
                    <IntroTitle >Welcome to </IntroTitle>
                    <IntroTitle >Outreach </IntroTitle>
                    </Fade>
                </Collapse>
            </View>
            </div>
          </Slide>
        )
      }

    const renderBottomButtons = () => {

        return(
            <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
                <div>
                    <BottomButtons 
                        leftTitle={'Already have an account?'}
                        rightTitle={'Let\'s get Started'}
                        onNext={onNext}
                        onBack={onBack}
                    />
                </div>
            </Slide>
        )
    }

    return(
        <View style={{...containers.centerBetweenColumn, height: hpCss(100)}}>
            {renderImage()}
            {renderWelcome()}
            {renderBottomButtons()}
        </View>
    )
 }


import React, {useContext, useState, useEffect, createContext} from 'react'

import { createManagerInfo } from '../Utilities';
import { SearchEngine } from './Search';
import { useUser } from './UserManager';
import { useSettings } from '../Managers/Settings';
import { useSocket } from './Sockets';
import { useNetwork } from './Database';
import { get, now, unionWith } from 'lodash';


export const ContactContext = createContext()

export const useContacts = () => useContext(ContactContext)

export const ContactsProvider = ({children}) => {

  const manager = createManagerInfo('CONTACT_MANAGER', 1)
  useEffect(() => {
    Socket.addListener('contact', _addSingleContactToVisibleList)
  }, [])

  const {user, authenticated} = useUser()
  const [contacts, setContacts] = useState([])
  const Database = useNetwork()
  const {colors} = useSettings()
  const Socket = useSocket()
 
   const clear = () => {
    setContacts([])
   }
 
 
   const filter = (searchText, _contacts) => { 
     const data = _contacts ? _contacts : contacts
     return SearchEngine.filter(searchText, data)
   }
 
   const _addSingleContactToVisibleList = (contact) =>{
     let newContacts =  unionWith([contact], contacts,  (a,b) => a.id === b.id ? true: false) 
    setContacts([...newContacts])
   }
 
   const updateContact = async (contact) => {
      if(!contact?.id){ return Promise.reject("No Id found for countact")}
       try{
         const path = `/contacts/${contact.id}`
         const result = await Database.put(
            path,
           contact,
           manager,
          )
          // console.log(result.email, result.first_name)
         return result
     } catch (e) {
      //  console.log(e)
       let person = get(e, 'data')
       !!person && _addSingleContactToVisibleList(person)
       return Promise.reject(e.error)
     }
   }
 
   const createContact = async (contact) => {
      try{
        if(contact.note){                
            contact.notes = [{description: contact.note, created_at: now(), created_by: user, editable: true, kind: 'note'}]
          }
        let cachePaths = []
        if(contact.series_id){
          cachePaths.push(`series/${contact.series_id}/contacts`)
        } 
        if(contact?.owner?.org){
          cachePaths.push(`orgs/${contact?.owner?.org?.id}/contacts`)
        }        
          
        let newContact = await Database.post(
              '/contacts', 
              contact,
              manager,
              cachePaths
          ) 

            _addSingleContactToVisibleList(newContact)
            // console.log('New contact Created ', newContact.first_name)
            return newContact
      } catch (e) {
            console.error(e)
        return Promise.reject(e.error)
      } 
   }

   const getSingleContact = async (contact) => {
    if(!contact?.id){ return Promise.reject('No Contact Provided')}
    try{  
        let response = await Database.get(`/contacts/${contact.id}?full=true`)  
        return response
    } catch(e) {
      return new Promise.reject("No Contact Found")
    } 

   }
 
   const getColorForGrade = (grade) =>{
     let color;
     switch(grade){
       case 'A': color = colors.gradeA; break;
       case 'B': color = colors.gradeB; break;
       case 'C': color = colors.gradeC; break;
       case 'D': color = colors.gradeD; break;
       case 'F': color = colors.gradeF; break;
      }
      return color
    }

   const createNote = async (contact, details) => {
     if(!contact?.id){ return Promise.reject('No Contact Found')}
      try{
        details = {note: details}
        let result = await Database.post(
          `/contacts/${contact.id}/notes`,
          details,
          manager
        )
        return result
      } catch(e) {
        return Promise.reject(e.error)
      }
  }

 
   const deleteContact = async (contact) => {
     if(!contact?.id) { return Promise.reject('No Contact Provided')}
     try{
      await Database.remove('/contacts/' + contact.id, contact, manager) 
      let _contacts = [...contacts]
      _contacts = filter(_contacts, c => c.id !== contact.id)
      setContacts([..._contacts])
     } catch(e) {
      return Promise.reject(e.error)
     }
   }

   const deleteNote = async (contact, note) => {
     if(!contact?.id) {return Promise.reject('No Contact Found')}
     if(!note?.id){return Promise.reject("Note Identification Missing")}
     try{
       await Database.remove(`/contacts/${contact.id}/notes/${note.id}` )
       return getSingleContact(contact)
    } catch(e) {
      return Promise.reject(e?.error)
    }
   }

   const getRemoteContacts = async (page = 1, force = false) => {
     try{
      let _contacts = await Database.getCachePriority(`/contacts?page=${page}`,force )
      setContacts([..._contacts])
     } catch(e){
       return Promise.reject(e?.error)
     }
    
   }


   const getContactsForSeries = async (seriesId, page = 1, force = false) => {
    if(!seriesId){
      return Promise.reject('No Series Identification Found')
    }
    try{
      return Database.getCachePriority(`/series/${seriesId}/contacts?all=${true}`, force)
    } catch(e) {
      return Promise.reject(e.error)
    }
  }


  const getContactsForOrgAndSeries = async (org_id, series_id, force) => {
    if(!series_id){
      return Promise.reject('No Series Identification Found')
    }

    if(!org_id){
      return Promise.reject("No Org Indentification Found")
    }
    
    try{
      const result = await Database.get(`/orgs/${org_id}/series/${series_id}/contacts?all=${true}&full=true`, force)
      return result
    } catch(e) {
      return Promise.reject(e.error)
    }
  }

    
  const getContactsForEvents = async (eventId, page = 1, force = false) => {
    if(!eventId){return Promise.reject('No Series Identification Found')}
    try{
      return Database.get(`/events/${eventId}/contacts?page=${page}&per_page=${30}`)
    } catch(e) {
      return Promise.reject(e.error)
    }
  }

  const getContactsForOrg = async (orgId, page = 1, archived = false) => {
    if(!orgId){
      return Promise.reject('No Org Identification Found')      
    }
    try{
      let results =  Database.get(`/orgs/${orgId}/contacts?all=true&archived=${archived}`)
      return results
    } catch(e) {
      return Promise.reject(e.error)
    }
  }


  const bindContactToUser = async (contact_id, user_id) => {
    if(!contact_id) { return Promise.reject('No Contact Provided')}
    if(!user_id) { return Promise.reject('No User Provided')}
    try{
      let result = await Database.post(
        `/contacts/${contact_id}/users/${user_id}`, 
        {}, 
        manager, 
        )
      return result
    } catch(e) {
      return Promise.reject(e)
    }
  }


  const unbindContactToUser = async (contact_id, user_id) => {
    if(!contact_id) { return Promise.reject('No Contact Provided')}
    if(!user_id) { return Promise.reject('No User Provided')}
    try{
      let result = await Database.remove(`/contacts/${contact_id}/users/${user_id}`,{}, manager)
      return result
    } catch(e) {
      return Promise.reject(e)
    }
  }

  const bindTagtoContact = async  (tag, contact) => {
    
    if(!tag?.id){ return Promise.reject('No Tag Identification Found')}
    if(!contact?.id){ return Promise.reject('No Contact Identification Found')}
    try{
      
     const result = await  Database.post(`/contacts/${contact.id}/tags/${tag.id}`)
     return result
     
     
    } catch(e) {
      return Promise.reject(e)
    }
  }

  const unbindTagtoContact = async  (tag, contact) => {
    
   if(!tag?.id){ return Promise.reject('No Tag Identification Found')}
   if(!contact?.id){ return Promise.reject('No Contact Identification Found')}
   try{
     const result = await  Database.remove(`/contacts/${contact.id}/tags/${tag.id}?full=true`)
     return result
   } catch(e) {
     return Promise.reject(e)
   }
 }

 


 // Archives

 const setContactsArchive = async (ids, archived) => {
    try{
      if(!ids || ids?.length === 0){ return }
      const body = {
        ids,
        archived
      } 
      await Database.put('/contacts', body)
      return true
    } catch(e) {
      return Promise.reject(e)
    }

  }



  // religion
  const  setSDA = async (ids, member) => {
    try{
      if(!ids || ids?.length === 0){ return }
      const body = {
        ids,
        member
      } 
      await Database.put('/contacts', body)
      return true
    } catch(e) {
      return Promise.reject(e)
    }
  }


  return(
    <ContactContext.Provider value={{
      createNote,
      createContact,
      getSingleContact,
      bindTagtoContact,
      bindContactToUser,
      unbindContactToUser,
      unbindTagtoContact,
      updateContact,
      getContactsForEvents,
      getContactsForOrgAndSeries,
      setContactsArchive,
      setSDA,
      deleteNote,
      getColorForGrade,
      deleteContact,
      getContactsForOrg,
      
    }}
    >
      {children}
    </ContactContext.Provider>
  )

}
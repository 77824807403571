import React from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';


export const BackButton = ({onBack}) => {
    const history = useHistory()

    const goBack = () => {
        history.go(-1)
        onBack && onBack()
    }
    return (
            <IconButton onClick={goBack} >
                <ArrowBack />
            </IconButton>     
    )               
}
import { Slide } from '@material-ui/core';
import React from 'react';

import { BottomButtons, Hint, IntroContainer, IntroSection, IntroTitle } from '../Components/exports';
import { Input } from '../Components/Input';
import { SettingsContext } from '../Managers/Settings';



export const Register = props => {

    const { 
        onEmailChange, 
        onPasswordChange, 
        onPasswordConfirmationChange,
        onNext, 
        onBack,
        email,
        password,
        pascom,
        activated,
        direction,
        getTimeout,
        first_name
    } = props


    return(
        <SettingsContext.Consumer>
            {({colors}) => {
                return(
                    <IntroContainer>
                        <IntroSection flex={0.5}>
                        <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
                            <div style={{maxWidth: 500}}>
                                <IntroTitle>{`Welcome${first_name ? " " + first_name : ''}!  What's a good email and password for you?`}</IntroTitle>
                            </div>
                        </Slide>
                        </IntroSection>
                        <IntroSection flex={2}>
                            <form>
                        <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
                            <div>
                                <Input 
                                    placeholder={'Email'}
                                    autoCorrect={false}
                                    autoCompleteType='email'
                                    autoCapitalize="none"
                                    value={email}
                                    textContentType={'emailAddress'}
                                    keyboardType={'email-address'}
                                    placeholderTextColor={colors?.primary}
                                    onChangeText={onEmailChange}
                                />
                            </div>
                        </Slide>
                        <Slide in={activated} direction={direction} timeout={getTimeout.center()}>
                            <div>
                                <Input 
                                        placeholder={'Password'}
                                        secureTextEntry
                                        placeholderTextColor={colors?.primary}
                                        onChangeText={onPasswordChange}
                                    />
                            </div>
                        </Slide>
                        <Slide in={activated} direction={direction} timeout={getTimeout.middle()}>
                            <div>
                                <Input 
                                        placeholder={'Password Confirmation'}
                                        secureTextEntry
                                        onSubmitEditing={onNext}
                                        placeholderTextColor={colors?.primary}
                                        onChangeText={onPasswordConfirmationChange}
                                    />
                            </div>
                        </Slide>
                        </form>
                        {!!pascom && !!password && pascom !== password && <Hint style={{color: colors?.danger}}>
                            Your passwords do not match
                            </Hint>}
                        </IntroSection>
                        
                        <Slide in={activated} direction={direction} timeout={getTimeout.edge()}>
                            <div>
                                <BottomButtons
                                    onNext={onNext}
                                    onBack={onBack}
                                />
                            </div>
                        </Slide>
                    </IntroContainer>
                )
            }}
        </SettingsContext.Consumer>
     


    )
}


import React, {Component} from 'react';
import {
  StyleSheet,
  View
} from 'react-native';
import Root from './Pages/root';
import { SettingsProvider } from './Managers/Settings'
import { SnackbarProvider } from 'notistack';
import { AlertProvider } from './Managers/Alert';
import { StorageProvider } from './Managers/Storage';
import { UserProvider } from './Managers/UserManager';
import { DatabaseProvider } from './Managers/Database';
import { OrgsProvider } from './Managers/Orgs';
import { SocketProvider } from './Managers/Sockets';
import { ContactsProvider } from './Managers/Contacts'
import { EventsProvider } from './Managers/Events';


class App extends Component {



  render() {
    return (
      <StorageProvider>
        <SnackbarProvider preventDuplicate>
          <AlertProvider>
            <SettingsProvider>
              <DatabaseProvider>
                <SocketProvider>
                  <UserProvider>
                    <OrgsProvider>
                      <EventsProvider>
                        <ContactsProvider>
                            <View style={styles.container}>
                              <Root />
                            </View>
                          </ContactsProvider>
                      </EventsProvider>
                    </OrgsProvider>
                  </UserProvider>
                </SocketProvider>
              </DatabaseProvider>
            </SettingsProvider>
          </AlertProvider>
        </SnackbarProvider>
      </StorageProvider>
    );
  }
}

const styles = StyleSheet.create({
  // container: {
  //   flex: 1,
  //   backgroundColor: '#EEEEEE',
  //   alignItems: 'left',
  //   justifyContent: 'center',
  //   width: '100vw',
  //   height: '100vh'
  // },
  logo: {
    width: 300,
    height: 300,
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  text: {
    color: '#fff',
  },
  button: {
    borderRadius: 3,
    padding: 20,
    marginVertical: 10,
    marginTop: 10,
    backgroundColor: '#1B95E0',
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
});

export default App;

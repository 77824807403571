import React from 'react';
import { History } from './exports'
import { useSettings } from '../Managers/Settings';
import { SmallCard } from './card';
import { useAlert } from '../Managers/Alert';
import { useContacts } from '../Managers/Contacts';



export const ContactTimeline = props => {
    let [ person, updatePerson] = React.useState(props.person)
    const Alert = useAlert()
    const ContactManager = useContacts()
    React.useEffect(() => {
      updatePerson(props.person)
    },[props.person])

    // console.log(props.person.first_name, person.first_name)
    const createNote = async (description) => {
        if(!person){ return Promise.reject('No Person to create note on')}
        try{
           await ContactManager.createNote(person, description)
           const updatedContact = await ContactManager.getSingleContact(person)
          updatePerson(updatedContact)
        } catch(e) {
          Alert.alert('Something went wrong', e.error)
        } 
      }

      const deleteNote = async (note) => {
        try{
          await ContactManager.deleteNote(person, note)
        } catch(e) {
          // console.log(e)
        }
        
      }

      const { colors } = useSettings()


    return(
      <SmallCard >
          <History 
            onCreate={createNote}
            onDelete={deleteNote}
            contact={person}
          />
      </SmallCard>
      )

}
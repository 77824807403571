import React from 'react';

 import GoogleMapReact from 'google-map-react';
 import Box from '@material-ui/core/Box';
import { hpCss, wpCss } from '../Fork/react-native-responsive-screen';
import { LightMapTheme, DarkMapTheme } from '../Constants';
import { SettingsContext } from '../Managers/Settings';
 export const Map = props => {

    let [ zoom, updateZoom] = React.useState(11)
    const defaultProps = {
        center: {
            lat: 35.045631, lng:  -85.309677
        },
        zoom:11
      };
    return(
        <SettingsContext.Consumer> 
            {({colors, appTheme}) => {
                const markerHeight = 10
                return(
                    <Box p={3} boxShadow={3} style={{height:hpCss(80), width:wpCss(80)}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyB6EtKy74zq8EJzdH5eh7E5h52exD-BRRc"}}
                        defaultCenter={props.center || defaultProps.center}
                        defaultZoom={props.zoom || defaultProps.zoom}
                        options={{
                            styles: appTheme === 'light' ? LightMapTheme(colors) : DarkMapTheme(colors)
                        }}
                        zoom={zoom}
                        show
                        
                        >
                
                    </GoogleMapReact>
                </Box> 
                )
            }}

        </SettingsContext.Consumer>
    )
 }




import React from 'react';
import { 
    Animated,
    Easing,
    View
 } from 'react-native';
import _ from 'lodash';

import { containers } from '../theme';

import { SettingsContext } from '../Managers/Settings';
import { Grade } from './exports';
import AnimateHeight from 'react-animate-height';
import { IconButton } from '@material-ui/core';
import { ContactContext } from '../Managers/Contacts';




const t72deg = 1.25664
const t36deg = 0.62832146932726
export class GradePicker extends React.Component{
  static contextType = ContactContext
  constructor(props){
    super(props)
    this.setup()
    
  }
  state = {_expanded_state: false, ...this.props }
  expanded = new Animated.Value(0)
  componentDidUpdate(prev){
    if(!_.isEqual(prev, this.props)){
      this.setState({...this.props})
    }
  }

  open = () => {
    
    this.setState({_expanded_state: true}, () => {
      Animated.spring(
        this.expanded,
        {
          toValue: 1,
          friction: 4,
        }
      ).start()
    })

  }

  select = async (grade) => {
    if(!this.state._expanded_state){
      this.open();
      return 
    }
    Animated.timing(
      this.expanded,
      {
        toValue: 0,
        duration: 200,
        easing: Easing.ease
      }
    ).start()
    this.setState({_expanded_state: false})
    
    this.props.onSelect && this.props.onSelect(grade)
    if(this.props.mutate){
      if(!this.state.person){ return }
      try{
        let { person } = this.state
        person.grade = grade
        let response = await this.context.updateContact(person)
        this.props.onUpdate && this.props.onUpdate(response)
      } catch(e) {
        console.error(e)
      }
    }
  }

  renderGrades = () => {
    if(!this.state.person){return <></>}
    let { person, inlist } = this.state
    let g = person.grade
    let grades = ['A', 'B', 'C','D', 'F']
    grades = _.difference(grades, [g])
    return(_.map([...grades, g], (grade, key) => {
        return(
          <Animated.View key={key + grade} style={this.getGradePosition(grade)}>
            <IconButton 
              onClick={() => this.select(grade)}
              >
              <View style={[containers.centerColumn]}>
                   <Grade inlist={inlist} static={true} grade={grade}/>
              </View>
            </IconButton>
          </Animated.View>
        )
    })
    )
  }

  setup = () => {
    const {inlist} = this.state
   this.dist = inlist ? 45 : 85
    this.ax = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, 0]
    })
    this.ay = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, - this.dist]
    })
    this.bx = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, this.dist * Math.sin(t72deg)]
    })
    this.by = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, - this.dist * Math.cos(t72deg)]
    })
    this.cx = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, this.dist * Math.sin(t36deg)]
    })
    this.cy = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, this.dist * Math.cos(t36deg)]
    })
    this.dx = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, - this.dist * Math.sin(t36deg)]
    })
    this.dy = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, this.dist * Math.cos(t36deg)]
    })
    this.fx = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, - this.dist * Math.sin(t72deg)]
    })
    this.fy = this.expanded.interpolate({
      inputRange: [0,1],
      outputRange: [0, - this.dist * Math.cos(t72deg)]
    })
    this.mainY = this.expanded.interpolate({
      inputRange: [0, 1],
      outputRange: [0, this.dist / 1.1 ]
    })
  }


  getGradePosition = (grade) => {
    const  base = {
      position: 'absolute',
      alignSelf: 'center',      
    } 
    switch(grade){
      case 'A': return {...base, transform: [{translateX: this.ax}, {translateY: this.ay}]}
      case 'B': return {...base, transform: [{translateX: this.bx}, {translateY: this.by}]}
      case 'C': return {...base, transform: [{translateX: this.cx}, {translateY: this.cy}]}
      case 'D': return {...base, transform: [{translateX: this.dx}, {translateY: this.dy}]}
      case 'F': return {...base, transform: [{translateX: this.fx}, {translateY: this.fy}]}
      default:  return {...base, transform: [{translateX: this.ax}, {translateY: this.ay}]}

    }
  }

  render() {
    let { _expanded_state, inlist} = this.state
    const size = _expanded_state ? {
        width: 'auto',
        height: inlist ? 150 : 250,
      }
      : {
        height: inlist ? 80 : 150,
        ...containers.centerColumn,
        width: 'auto'
      }
    return(

          <AnimateHeight
            id={'gradeheight'}
            duration={ 200 }
            height={ _expanded_state ? inlist ? 150 :  300 : inlist ? 80 : 150 } 
          >
            <Animated.View style={{...size, transform: [{translateY: this.mainY}]}} >
              {this.renderGrades()}
            </Animated.View>
        </AnimateHeight>
          
    )
  }
}
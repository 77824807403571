import * as React from 'react';
import styled from 'styled-components'
import { useSettings } from '../Managers/Settings';

const RH1 = styled.h1`
    color: ${props => props.text};
`
const RH2 = styled.h2`
    color: ${props => props.text};
`
const RH3 = styled.h3`
    color: ${props => props.text};
`
const RH4 = styled.h4`
    color: ${props => props.text};
`
const RH5 = styled.h5`
    color: ${props => props.text};
`
const RH6 = styled.h6`
    color: ${props => props.text};
`
const RSpan = styled.span`
    color: ${props => props.text};
`
const RP = styled.p`
    color: ${props => props.text};
`

export const TextVariant = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    span: 'span',
    p: 'p'

}
// type TextProps = {
//     variant?: TextVariant,
//     className?: any,
//     children?: any,
//     style?: any
// }
export default function Text({variant, className, children, style}) {
    const {colors} = useSettings()
    switch(variant){
        case 'h1': return <RH1 className={className} style={style} text={colors.text}>{children}</RH1>
        case 'h2': return <RH2 className={className} style={style} text={colors.text}>{children}</RH2>
        case 'h3': return <RH3 className={className} style={style} text={colors.text}>{children}</RH3>
        case 'h4': return <RH4 className={className} style={style} text={colors.text}>{children}</RH4>
        case 'h5': return <RH5 className={className} style={style} text={colors.text}>{children}</RH5>
        case 'h6': return <RH6 className={className} style={style} text={colors.text}>{children}</RH6>
        case 'span': return <RSpan className={className} style={style} text={colors.text}>{children}</RSpan>
        case 'p':return <RP className={className} style={style} text={colors.text}>{children}</RP>
        default: return <RSpan className={className} style={style} text={colors.text}>{children}</RSpan>
    }

}
import React,{useState} from 'react';
import { SliderPicker } from 'react-color';


export function ColorPicker({onChangeComplete, style, defaultColor}){
    const [color, setColor] = useState(defaultColor)
  
   const complete = (color) => {
      setColor(color)
      onChangeComplete && onChangeComplete(color)
    }
    
    return(
        
      <SliderPicker 
          style={style ? style : {}}
          color={color}
          onChange={setColor}
          onChangeComplete={complete}
      />
    )
  }


import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs';
import { Table } from './Table';
import { Database} from '../Managers/exports'
import { Checkbox} from '@material-ui/core';
import { concat, findIndex, map } from 'lodash';
import { useSettings } from '../Managers/Settings';
import { useAlert } from '../Managers/Alert';
import { useNetwork } from '../Managers/Database';
var calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar);


  
export const DecisionCardTable = ({ org_id, series_id, survey, onAdd, onSurveySelected} ) => {
    
    const [responses, setResponses] = useState([])
    const [headers, setHeaders] = useState([])
    const [columns, setColumns] = useState([])
    const {colors} = useSettings()
    const Alert = useAlert()
    const Database = useNetwork()
    useEffect(() => {
        Database.get(`/orgs/${org_id}/series/${series_id}/surveys/${survey.id}/responses`)
        .then(data => {
          let res = map(data, d => {
            let {contact: {first_name, last_name, email, id, created_at}, address} = d
            let count = 0
            let r = {
              ...d,
              first_name,
              last_name,
              email,
              id,
              created_at,
              address: d?.contact?.address?.address1,
              address2: d?.contact?.address?.address2,
              city: d?.contact?.address?.city,
              zip: d?.contact?.address?.zip ,
              state: d?.contact?.address?.state ,
              country: d?.contact?.address?.country ,
              phone: d?.contact?.phones?.[0].number || "", 
              name: `${first_name} ${last_name}`,
            
          }
            for ( let question of d.questions){
                r[String('question' + count) ] = question
                count += 1
            }
          getHeaders(r)
          return r
          })
          setResponses(res)
        })
        .catch(Alert.error)

    }, [org_id, series_id, survey])




    useEffect(() => {
      getHeaders()
    }, [survey])

    const onClick = (value) => {
        onSurveySelected && onSurveySelected(value)
    }

    const onViewColumnsChange = (name) => {
      // find the column by name and toggle its visibility
      let _columns = [...columns]
      let index = findIndex(_columns, c => c.name === name)
      if(index < 0){
        Alert.error("Not Found")
        return
      } 
  
      _columns[index].options.display = !_columns[index].options.display
      setColumns(_columns)
  
  
    }
    const getHeaders = (contact) => {


      const question_header = map(contact?.questions, (question, index) => {
        // console.log(answer)
        const val =  {
          name: `question${index}.answer`,
          label: question.title,
          options: {
            filter: question.kind === "bool",
            customBodyRender: (q, meta) => {

              return (
                // <></>
                <Checkbox checked={q} style={{color: colors.accent}} /> 
              );
            }
          }
        }
        return val
      })
      const _columns = [
        {
            name: "id",
            label: "ID",
            options: {
              filter: false,
              display: false,
            }
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
            }
          },
        {
          name: "first_name",
          label: "First Name",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "last_name",
          label: "Last Name",
          options: {
            filter: false,
            display: false
      
          }
        },
        {
          name: "address1",
          label: "Address",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "address2",
          label: "Address 2",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "zip",
          label: "Zip/Postal Code",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "country",
          label: "Country",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "phone",
          label: "Phone",
          options: {
            filter: false,
            customBodyRender: (phone) => {
              return (
                <>{phone}</>
              );
            }
          }
        },
      
        // {
        //   name: "grade",
        //   label: "Grade",
        //   options: {
        //     filter: false,
        //     display: false,
        //     customBodyRender: (grade, tableMeta, updateValue) => {
        //       return (
        //         <Grade key={tableMeta.columnIndex + grade + 'gradeinlist'} static grade={grade} intable/>
        //       );
        //     }
        //   }
        // },
        {
            name: "created_at",
          label: "Created At",
          options: {
            filter: false,
            display: false,
            customBodyRender: (created_at, tableMeta, updateValue) => {
              return (
                dayjs(created_at).calendar()
              );
            }
          }
        },
        {
            name: "gender",
            label: "Gender",
            options: {
              filter: true,
              display: false,
              customBodyRender: (gender, tableMeta, updateValue) => {
                  return gender || "Unknown"    
              }
            }
          },   {
            name: "source",
            label: "Source",
            options: {
              filter: true,
              display: false,
              customBodyRender: (source, tableMeta, updateValue) => {
                  return source || "Unknown"    
              }
            }
          },
        // {
          //   name: "Attendance",
          //   options: {
          //     filter: true,
          //     customBodyRender: (value, tableMeta, updateValue) => {
          //       return (
      
          //       );
          //     }
          //   }
          // }
      ];



      setHeaders(question_header)
    }   

    useEffect(() => {

      // if(columns.length !== 0 || responses?.length === 0 ){
      //   return
      // }
      const _columns = [
        {
            name: "id",
            label: "ID",
            options: {
              filter: false,
              display: false,
            }
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
            }
          },
        {
          name: "first_name",
          label: "First Name",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "last_name",
          label: "Last Name",
          options: {
            filter: false,
            display: false
      
          }
        },
        {
          name: "address1",
          label: "Address",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "address2",
          label: "Address 2",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "zip",
          label: "Zip/Postal Code",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "country",
          label: "Country",
          options: {
            filter: false,
            display: false
          }
        },
        {
          name: "phone",
          label: "Phone",
          options: {
            filter: false,
            customBodyRender: (phone) => {
              return (
                <>{phone}</>
              );
            }
          }
        },
      
        {
            name: "created_at",
          label: "Created At",
          options: {
            filter: false,
            display: false,
            customBodyRender: (created_at, tableMeta, updateValue) => {
              return (
                dayjs(created_at).calendar()
              );
            }
          }
        },
        {
            name: "gender",
            label: "Gender",
            options: {
              filter: true,
              display: false,
              customBodyRender: (gender, tableMeta, updateValue) => {
                  return gender || "Unknown"    
              }
            }
          },   {
            name: "source",
            label: "Source",
            options: {
              filter: true,
              display: false,
              customBodyRender: (source, tableMeta, updateValue) => {
                  return source || "Unknown"    
              }
            }
          }
  
      ];
      const _headers = concat(_columns, headers)
      setColumns(_headers)
    }, [headers])


    return(

          <Table 
          onViewColumnsChange={onViewColumnsChange}
            options={{
              isRowSelectable: () => false,
              
            }}
            columns={columns}
            data={responses}
            onClick={onClick}
        />
    )
}

import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { Route, Switch, useLocation } from 'react-router-dom'
import { MountTransition } from './MountTransition'

export const RouteTransition = props => {
  const { children, exact = false, path, slide = 0, slideUp = 0, noFade = false, expand = false, ...rest } = props

  return (
    <Route exact={exact} path={path} {...rest}>
      <MountTransition slide={slide} slideUp={slideUp} noFade={noFade} expand={expand}>
        {children}
      </MountTransition>
    </Route>
  )
}

export const AnimatedRoutes = props => {
  const location = useLocation()
  const { children, exitBeforeEnter = true, initial = false, } = props

  return (
    <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
      <Switch location={location} key={location.pathname}>
        {children}
      </Switch>
    </AnimatePresence>
  )
}